/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Etablissement} from "../../model/bns-api/etablissement";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EtablissementService} from "../../services/bns/etablissement.service";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";

const appProperties = require('../../../../package.json');

@Component({
    selector: 'app-choix-etablissement',
    templateUrl: './choix-etablissement.component.html',
    styleUrls: ['./choix-etablissement.component.css'],
    standalone: false
})
export class ChoixEtablissementComponent implements OnInit {

  form: FormGroup;
  afficherAvertissementSnapshot: boolean = false;
  afficherAvertissementRelease: boolean = false;

  // private returnPage: string = environment.defaultPage;
  private returnPage: string | null = null;

  constructor(private fb: FormBuilder,
              private etablissementService: EtablissementService,
              private router: Router,
              private route: ActivatedRoute) {

    // Récupération des paramètres de requête de l'url pour la redirection
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.keys.length !== 0) {
        const param = paramMap.get('returnPage');
        if (param !== null) {
          this.returnPage = param;
        }
      }
    });

    this.form = this.fb.group({
      etablissement : this.fb.control<Etablissement|null>(null, Validators.required)
    });

    // Un avertissement est affiché en environnement de préproduction si une version de SNAPSHOT y est déployée
    this.afficherAvertissementSnapshot = environment.env === 'PPD' && appProperties.version.includes('-SNAPSHOT');
    // Un avertissement est affiché en environnement de préproduction si une version de RELEASE y est déployée
    this.afficherAvertissementRelease = environment.env === 'PPD' && appProperties.version.includes('-RELEASE');
  }

  ngOnInit(): void {
    const etablissement = LocalStorageHelper.getEtablissement();

    if (etablissement !== null) {
      this.form.get("etablissement")!.setValue(etablissement); // ajout en tant qu'établissement sélectionné
    }
  }


  onSubmit(): void {
    const etablissement = this.form.get('etablissement')!.value as Etablissement;

    LocalStorageHelper.setEtablissement(etablissement);

    if (environment.env === 'DEV' || environment.env === 'INT') {
      this.router.navigate(['login'],  { queryParams: { returnPage: this.returnPage }});
    } else {
      const returnPage = this.returnPage === null ? environment.defaultPage : this.returnPage;
      window.location.href = environment.bnsAuthLoginUrl + encodeURIComponent(returnPage) + '&idEtablissement=' + etablissement.idEtablissement;
    }
  }
}
