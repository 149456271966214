<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Paramétrage d'un fournisseur</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<form class="form" [formGroup]="form" *ngIf="form">
    <mat-dialog-content>
      <div class="question">Fournisseur {{data.fournisseur.nom}}</div>

      <epona-select-lieu [lieu]="lieuCourant"
                         (lieuSubmitted)="loadLieu($event)"
                         [stockerLieuSelectionne]="false"
                         [required]="true"
                         [disabled]="desactiveLieu"
                         use="form"
                         lieuLabel="Lieu de livraison"
                         batimentDefault="- Aucun -"
                         lieuDefault="- Aucun -"
                         subscriptSizing="fixed"
                         [displayHelpTooltip]="false"></epona-select-lieu>

      <mat-form-field>
        <mat-label>Code-client du lieu chez le fournisseur</mat-label>

        <input matInput formControlName="codeClient"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email du fournisseur pour ce lieu</mat-label>

        <input matInput type="email" formControlName="mail" />

        <mat-error *ngIf="form.get('mail').hasError('email')">Format email incorrect</mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button        color="accent"  (click)="close()">Annuler</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid || !lieuCourant.idLieu">Enregistrer</button>
    </mat-dialog-actions>
</form>
