<h1 *ngIf=" afficherAchat &&  afficherVente">Articles</h1>
<h1 *ngIf=" afficherAchat && !afficherVente">Articles à l'achat</h1>
<h1 *ngIf="!afficherAchat &&  afficherVente">Articles à la vente</h1>

<div class="crous-recherche">
  <epona-filtre-articles
    [filter]="filter"
    [utilisation]="utilisation"
    class="crous-recherche-filtre"
    (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-articles>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title *ngIf=" afficherAchat &&  afficherVente">Liste des articles</mat-card-title>
        <mat-card-title *ngIf=" afficherAchat && !afficherVente">Liste des articles à l'achat</mat-card-title>
        <mat-card-title *ngIf="!afficherAchat &&  afficherVente">Liste des articles à la vente</mat-card-title>

        <aide class="aide-globale">
          <dl>
            <dt *ngIf="afficherAchat">Commandable au fournisseur</dt>
            <dd *ngIf="afficherAchat">Indique si l'article a été paramétré budgétairement et peut être commandé au fournisseur.</dd>

            <dt *ngIf="afficherAchat">Stock géré comptablement</dt>
            <dd *ngIf="afficherAchat">Indique si le stock d'un article doit être suivi au niveau comptable. Si le stock d'un article est géré comptablement alors il doit l'être opérationnellement.</dd>

            <dt *ngIf="afficherAchat">Stock géré opérationnellement</dt>
            <dd *ngIf="afficherAchat">Indique si les mouvements de stock d'un article doivent être pris en compte. Si le stock d'un article n'est pas géré opérationnellement alors cet article ne sera pas proposé lors de la saisie d'un mouvement de stock ou d'un inventaire.</dd>

            <dt *ngIf="afficherAchat">Type de dépense</dt>
            <dd *ngIf="afficherAchat">Investissement ou fonctionnement, influe sur les comptes comptables possibles.</dd>

            <dt *ngIf="afficherAchat">Compte comptable Épona</dt>
            <dd *ngIf="afficherAchat">Compte comptable utilisé lors de la génération des engagements juridiques. Si non renseigné alors le compte comptable BNA est utilisé.</dd>

            <dt *ngIf="afficherAchat">Compte comptable BNA</dt>
            <dd *ngIf="afficherAchat">Compte comptable paramétré dans la BNA.</dd>


            <dt *ngIf="afficherVente">Actif</dt>
            <dd *ngIf="afficherVente">Indique si l'article doit être prise en compte, lors d'une commande, d'un inventaire ou d'un mouvement de stock.</dd>

            <dt *ngIf="afficherVente">Colisage</dt>
            <dd *ngIf="afficherVente">Définit par combien l'article peut être commandé en interne, si vide alors l'article sera commandé à l'unité.</dd>

            <dt *ngIf="afficherVente">Avec DLC</dt>
            <dd *ngIf="afficherVente">Indique si l'article a une date limite de consommation (attention : cette propriété ne concerne pas les articles avec date de durabilité minimale).</dd>

            <dt *ngIf="afficherVente">Durée de consommation</dt>
            <dd *ngIf="afficherVente">Durée de consommation (en jours) de l'article après réception dans le lieu de vente (1 jour => consommable le jour même uniquement).</dd>
          </dl>
        </aide>
      </mat-card-header>
      <mat-divider></mat-divider>

      <div class="table-actions">
        <button type="button" mat-raised-button color="primary" matTooltip="Ajouter un article depuis VEM"
                (click)="openDialogAjoutParametrage()" *ngIf="afficherVente">
          <fa-icon icon="plus"></fa-icon> Ajouter
        </button>

        <button type="button"
                mat-raised-button color="primary"
                (click)="openDialogModificationMultiple()"
                [disabled]="!selection.hasValue()">
          <fa-icon icon="edit"></fa-icon> Modifier…
        </button>

        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeArticles()"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                      [storeCode]="COLUMNS_STORE_CODE"
                                      [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100, 1000]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>


      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             (matSortChange)="onSortChanged($event)"
             aria-label="Liste de paramétrages d'articles">

        <ng-container matColumnDef="checkBox">
          <th mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['checkBox'].tooltip">
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let data;let i = dataIndex">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(data) : null"
                          [checked]="selection.isSelected(data)">
            </mat-checkbox>

            <div class="update-result">
              <span class="success"><fa-icon icon="check-circle"></fa-icon></span>
              <span class="failure"><fa-icon icon="times-circle"></fa-icon></span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sousFamille">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['sousFamille'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.sousFamille | sousFamille }}
          </td>
        </ng-container>

        <ng-container matColumnDef="familleMarches">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['familleMarches'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.familleMarches | familleMarches }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sousGroupe">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['sousGroupe'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.sousGroupe | sousGroupe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="codeArticle">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['codeArticle'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ afficherAchat ? element.codeArticleAchat : element.codeArticleVente }}</td>
        </ng-container>

        <ng-container matColumnDef="designation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['designation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ afficherAchat ? element.designationAchat : element.designationVente }}</td>
        </ng-container>


        <ng-container matColumnDef="actifVente">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['actifVente'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.actifVente"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="colisage">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['colisage'].tooltip">{{ COLUMNS['colisage'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.pcb}}</td>
        </ng-container>

        <ng-container matColumnDef="articleDlc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['articleDlc'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.articleDlc"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="delaiReceptionDlc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['delaiReceptionDlc'].tooltip">{{ COLUMNS['delaiReceptionDlc'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.delaiReceptionDlc}}</td>
        </ng-container>

        <ng-container matColumnDef="actifAchat">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['actifAchat'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.actifAchat"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="origineAchat">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['origineAchat'].tooltip">{{ COLUMNS['origineAchat'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.origineAchat === 'BNA' ? 'BNA' : element.origineAchat === 'PO' ? 'Paniers' : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="commandableExterne">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['commandableExterne'].tooltip">{{ COLUMNS['commandableExterne'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.commandableExterne" nullLabel="Non défini"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="stockGereComptablement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['stockGereComptablement'].tooltip">{{ COLUMNS['stockGereComptablement'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.stockGere"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="stockGereOperationnellement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['stockGereOperationnellement'].tooltip">{{ COLUMNS['stockGereOperationnellement'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <epona-boolean [value]="element.stockGereOperationnellement"></epona-boolean>
          </td>
        </ng-container>

        <ng-container matColumnDef="lotMarche">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lotMarche'].tooltip">{{ COLUMNS['lotMarche'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <ul *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length > 1">
              <li *ngFor="let informationsMarche of element.listeInformationsMarche">
                {{ informationsMarche.codeMarcheOrion }}.{{ informationsMarche.numeroSousLot }}
              </li>
            </ul>
            <span *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length == 1">
              {{ element.listeInformationsMarche[0].codeMarcheOrion }}.{{ element.listeInformationsMarche[0].numeroSousLot }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="typeSousLot">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['typeSousLot'].tooltip">{{ COLUMNS['typeSousLot'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <ul *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length > 1">
              <li *ngFor="let informationsMarche of element.listeInformationsMarche">
                {{ informationsMarche.typeSousLot }}
              </li>
            </ul>
            <span *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length == 1">
              {{ element.listeInformationsMarche[0].typeSousLot }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="referenceFournisseur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['referenceFournisseur'].tooltip">{{ COLUMNS['referenceFournisseur'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <ul *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length > 1">
              <li *ngFor="let informationsMarche of element.listeInformationsMarche">
                {{ informationsMarche.reference }}
              </li>
            </ul>
            <span *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length == 1">
              {{ element.listeInformationsMarche[0].reference }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="prixHt">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['prixHt'].tooltip">{{ COLUMNS['prixHt'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <!-- Article sur plusieurs sous-lots -->
            <ul *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length > 1">
              <li *ngFor="let informationsMarche of element.listeInformationsMarche">
                {{ informationsMarche.prixHt | decimal4 }}
              </li>
            </ul>

            <!-- Article sur un seul sous-lot -->
            <span *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length == 1">
              {{ element.listeInformationsMarche[0].prixHt | decimal4 }}
            </span>

            <!-- Article présent dans au moins un lot et dont le prix a été annulé suite à une révision tarifaire -->
            <aide class="aide-contextuelle" icon="warning" *ngIf="element.listeInformationsMarche && element.listeInformationsMarche.length > 0 && element.listeInformationsMarche[0].prixHt === undefined">
              Le prix de cet article a été supprimé d'Épona suite à la dernière révision tarifaire et doit être récupéré depuis le site du fournisseur via un panier.
            </aide>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <button type="button"
                    mat-button
                    (click)="openDialogModificationUnique(element)"
                    matTooltip="Modifier l'article">
              <fa-icon icon="edit"></fa-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'success': row.success === true, 'failure': row.success === false }"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
