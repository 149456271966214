import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {CommandeService} from "../../services/epona/commande.service";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {EngagementJuridiqueLigne} from "../../model/epona-api/EngagementJuridiqueLigne";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {MatTableDataSource} from "@angular/material/table";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {EngagementJuridiqueCumul} from "../../model/epona-api/EngagementJuridiqueCumul";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogDataLignesCommandeCumul} from "../../model/epona-ui/DialogDataLignesCommandeCumul";
import {
  DialogLignesCommandeCumulComponent
} from "../dialog-lignes-commande-cumul/dialog-lignes-commande-cumul.component";

@Component({
    selector: 'epona-engagement-juridique-lignes',
    templateUrl: './engagement-juridique-lignes.component.html',
    styleUrls: ['./engagement-juridique-lignes.component.css'],
    standalone: false
})
export class EngagementJuridiqueLignesComponent implements OnChanges {

  @Input() commande: CommandeEntete;
  @Input() entete: EngagementJuridiqueEntete;
  @Input() modifiable: boolean;

  @Output() isDirty = new EventEmitter<boolean>();

  tableaux: CumulLignesEJ[];
  verrouille: boolean = true;

  TypeReferentielOrion = TypeReferentielOrion;

  readonly displayedColumns = [
    'destination',
    'operation',
    'serviceGestionnaire',
    'elementAxeComplementaire',
    'evenement',
    'typeTva',
    'montantHt'
  ];
  readonly displayedColumnsFooter = [
    'boutonLignesCommande',
    'totalLibelle',
    'totalMontantHt'
  ];

  constructor(private commandeService: CommandeService,
              public dialog: MatDialog,
              private messageTool: MessageTool,
              private fb: UntypedFormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commande'] && this.commande) {
      this.loadLignesEJ();
    }

    // S'il s'agit d'un rechargement de l'engagement juridique (suite à l'envoi vers Orion par exemple)
    if (changes['entete'] && !changes['entete'].firstChange && changes['entete'].previousValue) {
      this.loadLignesEJ();
    }

    if (changes['modifiable']) {
      if (this.modifiable) {
        this.displayedColumns.push('actions');
        this.displayedColumnsFooter.push('resultat');
      }
    }
  }

  private loadLignesEJ() {
    this.commandeService.getEngagementJuridiqueLignes(this.commande.idCommandeEntete, null).subscribe(data => {
      this.initTableauxLignesEJ(data);
    }, error => {
      this.messageTool.sendError(error);
    });
  }

  private initTableauxLignesEJ(lignes: EngagementJuridiqueLigne[]): void {
    const map = new Map<number, CumulLignesEJ>();

    for (const ligne of lignes) {
      const key = ligne.cumul.idEngagementJuridiqueCumul;

      // Si nécessaire initialisation du tableau correspondant à la clé
      if (!map.has(key)) {
        map.set(key, new CumulLignesEJ());
        map.get(key).cumul = ligne.cumul;
        map.get(key).totalHt = 0;
      }

      map.get(key).totalHt = +(map.get(key).totalHt + ligne.montantHt).toFixed(2);
      map.get(key).lignes.push(ligne);
      map.get(key).dataSource.data.push(ligne);
    }

    this.tableaux = [];

    map.forEach(((tableau) => {
      this.initFormArrayTableau(tableau)
      this.tableaux.push(tableau);
    }));

    // Ordonnancement des tableaux selon le numéro du cumul
    this.tableaux.sort((c1, c2) => c1.cumul.numeroCumul - c2.cumul.numeroCumul);
  }

  private initFormArrayTableau(tableau: CumulLignesEJ): void {
    const formArray = this.fb.array([]);

    for (const ligne of tableau.lignes) {
      formArray.push(this.ligneToFormGroup(ligne));
    }

    tableau.form = this.fb.group({});
    tableau.form.setControl('lignes', formArray);

    this.updateFormControlsTableau(tableau);
  }

  private ligneToFormGroup(ligne: EngagementJuridiqueLigne): UntypedFormGroup {
    const group = this.fb.group({
      destination:              this.fb.control(ligne.destination, Validators.required),
      operation:                this.fb.control(ligne.operation),
      serviceGestionnaire:      this.fb.control(ligne.serviceGestionnaire, Validators.required),
      elementAxeComplementaire: this.fb.control(ligne.elementAxeComplementaire),
      evenement:                this.fb.control(ligne.evenement),
      typeTva:                  this.fb.control(ligne.typeTva, Validators.required),
      montantHt:                this.fb.control(ligne.montantHt, [Validators.required, Validators.min(0)]),
    });

    group.valueChanges.subscribe(() => {
      this.isDirty.emit(this.atLeastOneFormIsDirty());
    });

    return group;
  }

  private atLeastOneFormIsDirty(): boolean {
    let dirty: boolean = false;
    for (let tableau of this.tableaux) {
      if (tableau.form.dirty) {
        dirty = true;
      }
    }
    return dirty;
  }

  private updateFormControlsTableau(tableau: CumulLignesEJ) {
    if (this.modifiable) {
      tableau.form.enable();
    } else {
      tableau.form.disable();
    }
  }

  /* ********************* */
  /* Modification de ligne */
  /* ********************* */

  ajouterLigne(tableau: CumulLignesEJ, ligne: EngagementJuridiqueLigne) {
    const newLigne = new EngagementJuridiqueLigne();
    newLigne.cumul                    = tableau.cumul;
    newLigne.destination              = ligne.destination;
    newLigne.operation                = ligne.operation;
    newLigne.serviceGestionnaire      = ligne.serviceGestionnaire;
    newLigne.elementAxeComplementaire = ligne.elementAxeComplementaire;
    newLigne.evenement                = ligne.evenement;
    newLigne.typeTva                  = ligne.typeTva;
    newLigne.montantHt                = 0;

    this.commandeService.postEngagementJuridiqueLigne(this.commande.idCommandeEntete, newLigne).subscribe(data => {
      newLigne.idEngagementJuridiqueLigne = data.idEngagementJuridiqueLigne;
      tableau.lignes.push(newLigne);
      tableau.dataSource.data.push(newLigne);
      (tableau.form.get('lignes') as UntypedFormArray).push(this.ligneToFormGroup(newLigne));
      tableau.dataSource._updateChangeSubscription();

      this.messageTool.sendSuccess(`La ligne a été ajoutée avec succès`, ClearMessages.TRUE);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  updateLigneT(tableau: CumulLignesEJ, index: number) {
    const ligneCtrl = (tableau.form.get('lignes') as UntypedFormArray).controls[index] as UntypedFormControl;

    if (ligneCtrl.valid) {
      const ligne = tableau.lignes[index];
      ligne.destination = ligneCtrl.get('destination').value;
      ligne.operation = ligneCtrl.get('operation').value;
      ligne.serviceGestionnaire = ligneCtrl.get('serviceGestionnaire').value;
      ligne.elementAxeComplementaire = ligneCtrl.get('elementAxeComplementaire').value;
      ligne.evenement = ligneCtrl.get('evenement').value;
      ligne.typeTva = ligneCtrl.get('typeTva').value;
      ligne.montantHt = ligneCtrl.get('montantHt').value;

      this.commandeService.putEngagementJuridiqueLigne(this.commande.idCommandeEntete, ligne).subscribe(() => {
        ligneCtrl.markAsPristine();
        this.isDirty.emit(this.atLeastOneFormIsDirty());

        this.updateTotalTableau(tableau);

        this.messageTool.sendSuccess(`La ligne a été modifiée avec succès`, ClearMessages.TRUE);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  supprimerLigne(tableau: CumulLignesEJ, index: number) {
    const ligne = tableau.lignes[index];

    if (tableau.lignes.length > 1) {
      this.commandeService.deleteEngagementJuridiqueLigne(this.commande.idCommandeEntete, ligne.idEngagementJuridiqueLigne).subscribe(() => {
        // Suppression de la ligne du tableau
        tableau.lignes.splice(index, 1);
        tableau.dataSource.data.splice(index, 1);
        const formArray: UntypedFormArray = tableau.form.get('lignes') as UntypedFormArray;
        formArray.controls[index].markAsPristine(); // La ligne doit être marquée comme "pristine" sinon le formulaire peut rester "dirty"
        formArray.removeAt(index);
        tableau.dataSource._updateChangeSubscription();

        this.isDirty.emit(this.atLeastOneFormIsDirty());

        this.updateTotalTableau(tableau);

        this.messageTool.sendSuccess(`La ligne a été supprimée avec succès`, ClearMessages.TRUE);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  updateTotalTableau(tableau: CumulLignesEJ) {
    let totalHt: number = 0;
    for (const ctrl of (tableau.form.get('lignes') as UntypedFormArray).controls) {
      totalHt += ctrl.get('montantHt').value;
    }
    tableau.totalHt = +totalHt.toFixed(2);
  }

  hasErrorT(form: UntypedFormGroup, index: number, controlName: string, errorCode: string): boolean {
    const ligneCtrl = (form.get('lignes') as UntypedFormArray).controls[index];
    return ligneCtrl.get(controlName).hasError(errorCode);
  }

  openDialogLignesCumul(tableau: CumulLignesEJ) {
    const search = new CommandeLigneSearch();
    search.idEngagementJuridiqueCumul = tableau.cumul.idEngagementJuridiqueCumul;
    search.fields = 'article.articleAchat,article.codeArticleAchat,article.designationAchat,quantite,valeurHt';

    this.commandeService.getListeLignes(this.commande.idCommandeEntete, search).subscribe(data => {
      let dialogConfig = new MatDialogConfig<DialogDataLignesCommandeCumul>();
      dialogConfig.data = new DialogDataLignesCommandeCumul();
      dialogConfig.data.numeroCumul = tableau.cumul.numeroCumul;
      dialogConfig.data.lignes = data;
      dialogConfig.minWidth = '400px';

      this.dialog.open(DialogLignesCommandeCumulComponent, dialogConfig);

    }, error => {
      this.messageTool.sendError(error);
    });
  }
}

export class CumulLignesEJ {
  cumul: EngagementJuridiqueCumul;
  totalHt: number = 0;

  lignes: EngagementJuridiqueLigne[] = [];
  dataSource = new MatTableDataSource<EngagementJuridiqueLigne>([]);
  form: UntypedFormGroup;
}
