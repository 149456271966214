import {ArticleFilter} from '../epona-ui/article-filter';
import {NumberComparisonOperator} from '../../commons/enum/NumberComparisonOperator';

export class ArticleSearch {

  idCrous: number;
  utilisation: string;

  q: string;
  codeGroupe: string;
  idSousGroupe: number;
  codeDepartement: number;
  codeFamille: number;
  codeSousFamille: number;
  avecDlc: boolean;
  estActifVente: boolean;
  estActifAchat: boolean;
  colisage: number;
  delaiReceptionDlc: number;
  commandableExterne: number;
  stockGere: boolean;
  stockGereOperationnellement: boolean;
  codeMarcheOrion: string;
  operateurPrixHt: NumberComparisonOperator;
  prixHt: number;
  origineAchat: string;

  inclureInformationsMarche: boolean;

  max: number;
  fields: string;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;

  public static fabric(filter: ArticleFilter) : ArticleSearch {
    const search = new ArticleSearch();

    search.q                           = filter.rechercheTextuelle;
    search.codeGroupe                  = filter.groupe      ? filter.groupe.code : null;
    search.idSousGroupe                = filter.sousGroupe  ? filter.sousGroupe.idSousGroupeArticles : null;
    search.codeDepartement             = filter.departement ? filter.departement.codeDepartement : null;
    search.codeFamille                 = filter.famille     ? filter.famille.codeFamille : null;
    search.codeSousFamille             = filter.sousFamille ? filter.sousFamille.codeSousFamille : null;
    search.avecDlc                     = filter.articleDlc;
    search.estActifVente               = filter.actifVente;
    search.delaiReceptionDlc           = filter.delaiReceptionDlc;
    search.colisage                    = filter.colisage;
    search.commandableExterne          = filter.commandableExterne;
    search.stockGere                   = filter.stockGere;
    search.stockGereOperationnellement = filter.stockGereOperationnellement;
    search.codeMarcheOrion             = filter.marche ? filter.marche.codeMarcheOrion : null;
    search.operateurPrixHt             = filter.comparaisonPrixHt ? filter.comparaisonPrixHt.operator : null;
    search.prixHt                      = filter.comparaisonPrixHt ? filter.comparaisonPrixHt.value : null;
    search.origineAchat                = filter.origineAchat;

    return search;
  }
}
