import { Injectable } from '@angular/core';
import {CodeDroit} from "../../commons/constants/CodeDroit";
import { HttpClient } from "@angular/common/http";
import {MessageTool} from "../../commons/MessageTool";
import {HttpService} from "../http.service";
import {UserService} from "../user.service";
import {Observable} from "rxjs";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";
import {PanierSearch} from "../../model/epona-api/panier-search";
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {environment} from "../../../environments/environment";
import {PanierEntetePOST} from "../../model/epona-api/panier-entete-post";
import {PanierLigne} from "../../model/epona-api/panier-ligne";
import {PanierLignePUT} from "../../model/epona-api/panier-ligne-put";
import {PanierEntetePUT} from "../../model/epona-api/panier-entete-put";
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {HttpDataWithPagination} from '../../model/http-data-with-pagination';

@Injectable({
  providedIn: 'root'
})
export class PanierService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService,
              private userService: UserService) {
    this.baseUrl = this.env.eponaApiUrl + '/paniers';
  }

  /* ******************************* */
  /* Droits de l'utilisateur courant */
  /* ******************************* */

  droitConsultation(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.PANIERS_CONSULTATION);
  }
  droitSaisie(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.PANIERS_SAISIE);
  }
  droitModification(): boolean {
    return this.droitSaisie();
  }

  /* ******************* */
  /* Gestion des entêtes */
  /* ******************* */

  getListeEntetesWithPagination(search: PanierSearch): Observable<HttpDataWithPagination<PanierEntete>> {
    return this.httpService.getListeWithPagination<PanierEntete>(this.baseUrl, search);
  }

  getEntete(idEntete: number, fields: string): Observable<PanierEntete> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<PanierEntete>(this.baseUrl + '/' + idEntete, {params});
  }

  deletePanier(idPanier: number) {
    return this.httpClient.delete(this.baseUrl + '/' + idPanier);
  }

  postEntete(entete: PanierEntete): Observable<PanierEntete> {
    return this.httpClient.post<PanierEntete>(this.baseUrl, new PanierEntetePOST(entete));
  }

  putEntete(entete: PanierEntete): Observable<any> {
    return this.httpClient.put<PanierEntete>(this.baseUrl + '/' + entete.idPanierEntete, new PanierEntetePUT(entete));
  }

  postGenerationCommandes(entete: PanierEntete, fields: string = null): Observable<CommandeEntete[]> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    const url = this.baseUrl + '/' + entete.idPanierEntete + '/generation-commandes';
    return this.httpClient.post<CommandeEntete[]>(url, null, {params});
  }

  /* ******************* */
  /* Gestion des lignes */
  /* ****************** */

  getListeLignes(idPanier: number, fields: string) {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<Array<PanierLigne>>(this.baseUrl + '/' + idPanier + '/lignes', {params});
  }

    urlRedirect(idPanierEntete: number) {
        return this.env.eponaApiUrl + '/punchout/redirect?idPanier=' + idPanierEntete
    }

  putLigne(idEntete: number, ligne: PanierLigne): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + idEntete + '/lignes/'
      + ligne.idPanierLigne, new PanierLignePUT(ligne));
  }

  deleteLigne(idPanierEntete: number, idPanierLigne: number) {
    return this.httpClient.delete(this.baseUrl + '/' + idPanierEntete + '/lignes/' + idPanierLigne);
  }

  /* ****************** */
  /* Gestion des totaux */
  /* ****************** */

  getTotauxTva(idEntete: number, fields: string): Observable<TotauxTva[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<TotauxTva[]>(this.baseUrl + '/' + idEntete + '/totaux-tva', {params});
  }

  getTotaux(idEntete: number, fields: string): Observable<Totaux[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<Totaux[]>(this.baseUrl + '/' + idEntete + '/totaux', {params});
  }

  /* ****************** */
  /*      Commandes     */
  /* ****************** */

  getCommandes(idEntete: number, fields: string): Observable<CommandeEntete[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<CommandeEntete[]>(this.baseUrl + '/' + idEntete + '/commandes', {params});
  }

}
