<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span>Lots pour la ligne de ce bordereau</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="annuler()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="ligne-modifiee">
    <table class="mat-mdc-table mat-elevation-z8" aria-label="Rappel de la ligne en cours de modification">
      <thead>
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-cell-epona" scope="col">Article</th>
        <th class="mat-mdc-cell-epona" scope="col" *ngIf="data.ligne.article.articleDlc">DLC</th>
        <th class="mat-mdc-cell-epona quantite" scope="col">{{ data.params.editQuantiteLabel }}</th>
      </tr>
      </thead>

      <tbody>
      <tr class="mat-mdc-row">
        <td class="mat-mdc-cell-epona">{{ data.ligne.article | designation }}</td>
        <td class="mat-mdc-cell-epona" *ngIf="data.ligne.article.articleDlc">{{ data.ligne.dlc | dateFormat }}</td>
        <td class="mat-mdc-cell-epona quantite">{{ data.ligne.quantite }}</td>
      </tr>
      </tbody>
    </table>
  </div>


  <form [formGroup]="form">
    <div class="lots">
      <table *ngIf="form.controls['listeLots']" mat-table
             [dataSource]="dataSource"
             multiTemplateDataRows
             formArrayName="listeLots"
             aria-label="Liste des lots">

        <ng-container matColumnDef="numeroLot">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['numeroLot'].tooltip">{{ columns['numeroLot'].label }}</th>
          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <input matInput
                     type="text"
                     (keyup)="disableOtherControls(index)"
                     (blur)="updateLot(index)"
                     maxlength="255"
                     formControlName="numeroLot">
              <mat-error *ngIf="hasErrorDetails(index, 'numeroLot', 'required')">Numéro de lot obligatoire</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'numeroLot', 'lot-existant')">Ce lot existe déjà</mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantite">
          <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="columns['quantite'].tooltip">{{ columns['quantite'].label }}</th>
          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
              <input matInput
                     type="number"
                     (keyup)="disableOtherControls(index)"
                     (change)="disableOtherControls(index)"
                     (blur)="updateLot(index)"
                     formControlName="quantite">

              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'required')">Quantité obligatoire</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'min')">Quantité négative interdite</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'not_zero')">Quantité 0 interdite</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'nbMaxDecimals')">Maximum 4 décimales</mat-error>
              <mat-error *ngIf="hasErrorDetails(index, 'quantite', 'quantite-error')">Quantité totale des lots plus grande que quantité réceptionnée</mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Actions</th>
          <td mat-cell *matCellDef="let index = dataIndex" [formGroupName]="index">
            <button type="button"
                    mat-button
                    (click)="removeLot(index)"
                    [disabled]="buttonsDisabled"
                    matTooltip="Supprimer ce lot">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </td>

          <td colspan="3" mat-footer-cell *matFooterCellDef>
            <button type="button"
                    mat-button
                    (click)="addNewLot()"
                    [disabled]="buttonsDisabled"
                    matTooltip="Ajouter un lot">
              <fa-icon icon="plus"></fa-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="['numeroLot', 'quantite', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['numeroLot', 'quantite', 'actions'];"></tr>

        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef colspan="4">Liste vide</td>
        </ng-container>


        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>

        <tr mat-footer-row *matFooterRowDef="['actions'];"></tr>
      </table>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="close()">
    Fermer
  </button>
</mat-dialog-actions>

