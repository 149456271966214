import {Directive, ElementRef} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../constants/Const";

@Directive({
    selector: '[epoHighlightIfNotEmpty]',
    standalone: false
})
export class HighlightIfNotEmptyDirective {

  readonly CLASS_NAME = 'not-empty';

  constructor(private element: ElementRef,
              private matFormFieldCtrl: MatFormFieldControl<any>
  ) {
    // À chaque changement d'état du MatFormFieldControl vérification s'il est vide ou non
    this.matFormFieldCtrl.stateChanges.subscribe(() => {
      // Element HTML sur lequel sera ajouté ou retiré la classe
      const matFormFieldElement: HTMLElement = this.element.nativeElement.closest('mat-form-field');

      if (matFormFieldElement) {
        if (matFormFieldCtrl.empty || this.element.nativeElement.tagName == 'MAT-SELECT' && matFormFieldCtrl.value === Const.DEFAULT) {
          matFormFieldElement.classList.remove(this.CLASS_NAME);
        } else {
          matFormFieldElement.classList.add(this.CLASS_NAME);
        }
      }
    });
  }
}
