import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {EntreeSortieEntete} from '../../model/epona-api/EntreeSortieEntete';
import {MatSort, Sort} from '@angular/material/sort';
import {EntreeSortieService} from '../../services/epona/entree-sortie.service';
import {EntreeSortieFilter} from '../../model/epona-ui/EntreeSortieFilter';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {EntreeSortieParametrage} from '../../model/epona-ui/EntreeSortieParametrage';
import {Lieu} from '../../model/epona-api/Lieu';
import {FiltreEntreesSortiesComponent} from '../filtre-entrees-sorties/filtre-entrees-sorties.component';
import {Tools} from '../../commons/Tools';
import {EntreeSortieQueryParams} from '../../model/epona-ui/EntreeSortieQueryParams';
import {Format} from '../../commons/constants/Format';
import {DatePipe} from '@angular/common';
import {DisplayedColumnsTools, TableColumn} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {FilterTools} from '../../commons/FilterTools';
import {CodeStatutES} from '../../commons/constants/CodeStatutES';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {EntreeSortieExportService} from '../../services/epona/entree-sortie-export.service';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {HttpService} from "../../services/http.service";

@Component({
    selector: 'epona-liste-entrees-sorties',
    templateUrl: './liste-entrees-sorties.component.html',
    styleUrls: ['./liste-entrees-sorties.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Éléments') }
    ],
    standalone: false
})
export class ListeEntreesSortiesComponent implements OnInit, IPaginatedComponent<EntreeSortieEntete> {

  private readonly DEFAULT_PAGE_SIZE = 10;
  private readonly DEFAULT_ORDER_BY = 'idEntreeSortieEntete';
  private readonly DEFAULT_ORDER_DIR = 'desc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;

  lieuCourant: Lieu;
  filter: EntreeSortieFilter;
  droitSaisie: boolean;
  droitModification: boolean; // modification = saisie ou validation

  // Paramétrages spécifiques à la catégorie d'entrée/sortie
  params = new EntreeSortieParametrage();

  // Données de la mat-table (des colonnes seront retirées selon le type de mouvement)
  dataSource = new MatTableDataSource<EntreeSortieEntete>([]);

  @ViewChild(FiltreEntreesSortiesComponent)
  filterComponent: FiltreEntreesSortiesComponent;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  COLUMNS: {[key: string]: TableColumn} = {
  };
  COLUMNS_STORE_CODE = null;

  displayedColumns: string[] = [];

  constructor(private entreeSortieService: EntreeSortieService,
              private exportService: EntreeSortieExportService,
              private httpService: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              private dateFormat: DatePipe) {

    if (this.route.snapshot.data.params) {
      this.params = this.route.snapshot.data.params;
    }

    // Initialisation des informations sur les colonnes
    this.COLUMNS = this.params.listColumns;
    this.COLUMNS_STORE_CODE = this.params.listeColumnsStoreCode;
  }

  ngOnInit() {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des entrées/sorties
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;

      // Lancement de la recherche
      this.rechercher();
    });

    this.droitSaisie       = this.entreeSortieService.droitSaisie(this.params);
    this.droitModification = this.entreeSortieService.droitModification(this.params);
  }

  // Initialisation des filtres à partie des paramètres de l'URL
  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre d'entrées/sorties
    this.filter = new EntreeSortieFilter();
    this.filter.idEntreeSortie     = params.get('idEntreeSortie') ? +params.get('idEntreeSortie') : null;
    this.filter.typeMouvement      = FilterTools.buildTypeMouvementFromURL(params);
    this.filter.valide             = params.get('valide') ? Tools.stringToBoolean(params.get('valide')) : null;
    this.filter.codesStatut        = params.getAll('codesStatut');
    this.filter.dateMin            = params.get('dateMin') ? new Date(params.get('dateMin')) : null;
    this.filter.codeBL             = params.get('codeBL');
    this.filter.lieuEvenement      = params.get('lieuEvenement');
    this.filter.dateEvenement      = params.get('dateEvenement') ? new Date(params.get('dateEvenement')) : null;
    this.filter.referenceEvenement = params.get('referenceEvenement');
    this.filter.utilisateur        = params.get('utilisateur');
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre d'entrées/sorties
    this.filter = new EntreeSortieFilter();
    if (this.params.commonFieldsBLDisplayed) {
      this.filter.codesStatut = [CodeStatutES.NON_VALIDE, CodeStatutES.VALIDE_ATTENTE_MAJ_EJ, CodeStatutES.VALIDE_ATTENTE_ENVOI_SF];
    } else {
      this.filter.valide = false;
    }
  }

  rechercher() {
    const search = this.buildSearchFromFilters();
    search.fields = 'idEntreeSortieEntete,' +
      'lieu.nomRu,' +
      'lieu.nomUd,' +
      'lieuDestination.nomRu,' +
      'lieuDestination.nomUd,' +
      'lieuEvenement,' +
      'dateEvenement,' +
      'referenceEvenement,' +
      'typeMouvement,' +
      'dateBordereauLivraison,' +
      'codeBordereauLivraison,' +
      'dateCreation,' +
      'dateValidation,' +
      'serviceFaitExiste,' +
      'codeEtapeOrion,' +
      'entreeSortieEnteteOrigine.lieu.nomRu,' +
      'entreeSortieEnteteOrigine.lieu.nomUd,' +
      'commande.fournisseur.nom,' +
      'commande.sousLotZg.codeMarcheOrion,' +
      'commande.sousLotZg.numeroSousLot,' +
      'commande.idCommandeEntete,' +
      'commande.numeroEj,' +
      'commande.externe';
    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.entreeSortieService.getListeEntetesWithPagination(search).subscribe(this.httpService.handlePaginatedSubscribe(this));
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private buildSearchFromFilters(): EntreeSortieSearch {
    const search = new EntreeSortieSearch();

    search.setIdsLieu(this.lieuCourant);

    search.idEntreeSortie     = this.filter.idEntreeSortie;
    search.codesTypeMouvement = this.filter.typeMouvement ? [this.filter.typeMouvement.codeTypeMouvement] : this.params.commonTypeMouvementList;
    search.dateMin            = this.filter.dateMin ? this.filter.dateMin : null;
    search.codeBL             = this.filter.codeBL;
    search.lieuEvenement      = this.filter.lieuEvenement;
    search.dateEvenement      = this.filter.dateEvenement ? this.filter.dateEvenement : null;
    search.referenceEvenement = this.filter.referenceEvenement;
    search.utilisateur        = this.filter.utilisateur;
    search.valide             = this.filter.valide;
    search.codesStatut        = this.filter.codesStatut;

    return search;
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche d'entrées/sorties pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: EntreeSortieFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate([this.params.commonListPath], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): EntreeSortieQueryParams {
    const params = new EntreeSortieQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.idEntreeSortie     = this.filter.idEntreeSortie;
    params.codeTypeMouvement  = this.filter.typeMouvement ? this.filter.typeMouvement.codeTypeMouvement : null;
    params.dateMin            = this.dateFormat.transform(this.filter.dateMin, Format.FORMAT_DATE_URL_REST);
    params.codeBL             = this.filter.codeBL;
    params.lieuEvenement      = this.filter.lieuEvenement;
    params.dateEvenement      = this.dateFormat.transform(this.filter.dateEvenement, Format.FORMAT_DATE_URL_REST);
    params.referenceEvenement = this.filter.referenceEvenement;
    params.utilisateur        = this.filter.utilisateur;
    params.valide             = this.filter.valide;
    params.codesStatut        = this.filter.codesStatut;

    return params;
  }

  estBLSurCommandeExterne(entete: EntreeSortieEntete): boolean {
    return entete.commande && entete.commande.externe === true;
  }

  downloadPdf(entete: EntreeSortieEntete): void {
    this.entreeSortieService.downloadPdf(entete);
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.rechercher();
  }


  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.rechercher();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListeEntreesSorties() {
    const search = this.buildSearchFromFilters();
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;


    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportEntreesSorties(search);
  }
}
