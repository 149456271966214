import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class InventaireSearch extends DTOWithLieu {
  idInventaire: number;
  utilisateur: string;
  cloture: boolean;
  libelle: string;
  annee: number;
  mois: number;
  codeGroupeArticles: string;
  idSousGroupeArticles: number;
  fields: string;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;
}
