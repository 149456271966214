import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Article} from '../../../model/epona-api/Article';
import {Observable, Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MessageTool} from '../../MessageTool';
import {FocusMonitor} from '@angular/cdk/a11y';
import {ArticleSearch} from '../../../model/epona-api/ArticleSearch';
import {map, startWith} from 'rxjs/operators';
import {Tools} from '../../Tools';
import {MatAutocompleteActivatedEvent} from '@angular/material/autocomplete';
import {ArticleService} from '../../../services/epona/article.service';
import {StockCompactSearch} from '../../../model/epona-api/StockCompactSearch';
import {Lieu} from '../../../model/epona-api/Lieu';
import {StockService} from '../../../services/epona/stock.service';
import {DesignationArticlePipe} from '../../pipes/designation-article.pipe';
import {UtilisationArticle} from '../../constants/UtilisationArticle';
import {Groupe} from "../../../model/epona-api/Groupe";
import {SousGroupe} from "../../../model/epona-api/SousGroupe";

@Component({
    selector: 'epona-autocomplete-article',
    templateUrl: './autocomplete-article.component.html',
    styleUrls: ['./autocomplete-article.component.css'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: AutocompleteArticleComponent
        }
    ],
    standalone: false
})
export class AutocompleteArticleComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor, MatFormFieldControl<Article> {
  @ViewChild('article') articleElement: ElementRef;

  articleCtrl: UntypedFormControl;

  listeArticles: Array<Article> = [];
  filteredArticles: Observable<Article[]>;

  @Input() utilisation: string;
  @Input() stockGere: boolean;
  @Input() groupeArticles: Groupe;
  @Input() sousGroupeArticles: SousGroupe;
  @Input() listeIdArticleExclus: number[] = [];
  @Input() enStockUniquement: boolean = false;
  @Input() lieu: Lieu = null;

  // MatFormFieldControl
  stateChanges = new Subject<void>();
  focused: boolean = false;
  // Ajout d'une classe qui permettra de faciliter l'application des styles
  controlType: string = 'autocomplete-article';
  propagateChange = (_: any) => { };
  onTouched = () => {};

  static nextId = 0;
  @HostBinding()
  id = `autocomplete-article-${AutocompleteArticleComponent.nextId++}`;

  @Input()
  set focus(value: boolean) {
    if (this.articleElement) {
      if (value) {
        this.articleElement.nativeElement.focus();
      } else {
        this.articleElement.nativeElement.blur();
      }
    }
  }

  @Input()
  get value(): Article {
    return this.articleCtrl.value;
  }
  set value(value: Article) {
    this.articleCtrl.setValue(value);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  // Affichage du label en position flottant lorsque le champ a le focus
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;


  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.articleCtrl.disable() : this.articleCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty(): boolean {
    return !this.articleCtrl.value;
  }

  // Affichage du champ en erreur et permet de gérer le mat-error
  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  constructor(private articleService: ArticleService,
              private stockService: StockService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl,
              private designationPipe: DesignationArticlePipe) {

    // Affichage du champ avec un soulignement si le champ a le focus
    // origin values : mouse, keyboard, null, ...
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.articleCtrl = new UntypedFormControl(null);
  }

  ngOnInit() {
    this.getListeArticles();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enStockUniquement'] && !changes['enStockUniquement'].firstChange) {
      this.getListeArticles()
    }
  }

  getListeArticles() {
    if (this.enStockUniquement && this.lieu !== null) {
      const search = new StockCompactSearch();
      search.enStock = true;
      search.idLieu = this.lieu.idLieu;
      search.fields = '' +
        'article.idArticle,' +
        'article.idCrous,' +
        'article.articleAchat,' +
        'article.articleVente,' +
        'article.codeArticleAchat,' +
        'article.codeArticleVente,' +
        'article.designationAchat,' +
        'article.designationVente,' +
        'article.articleDlc,' +
        'article.uniteExploitation.abreviation,' +
        'quantite';

      this.stockService.getListeStocksCompactsComplete(search).subscribe(data => {
        this.listeArticles = data.map(stock => stock.article);
        this.prepareListeArticles();
      }, err => {
        this.messageTool.sendError(err);
      });

    } else {
      const search = new ArticleSearch();
      search.idCrous = +localStorage.getItem('idCrous');
      if (this.utilisation === UtilisationArticle.VENTE) {
        search.estActifVente = true;
      } else if (this.utilisation === UtilisationArticle.ACHAT) {
        search.estActifAchat = true;
      }
      search.utilisation = this.utilisation;
      search.stockGereOperationnellement = this.stockGere;
      search.codeGroupe = this.groupeArticles ? this.groupeArticles.code : null;
      search.idSousGroupe = this.sousGroupeArticles ? this.sousGroupeArticles.idSousGroupeArticles : null;
      search.fields = '' +
        'idArticle,' +
        'idCrous,' +
        'articleAchat,' +
        'articleVente,' +
        'codeArticleAchat,' +
        'codeArticleVente,' +
        'designationAchat,' +
        'designationVente,' +
        'articleDlc,' +
        'uniteExploitation.abreviation';

      this.articleService.getListeArticlesComplete(search).subscribe(data => {
        this.listeArticles = data;
        this.prepareListeArticles();
      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private prepareListeArticles(): void {
    if (this.listeIdArticleExclus && this.listeIdArticleExclus.length > 0) {
      // Conservation uniquement des articles non exclus
      this.listeArticles = this.listeArticles.filter((article: Article) =>
        !this.listeIdArticleExclus.find(idArticle => idArticle === article.idArticle)
      );
    }

    this.tri(this.listeArticles);

    // Autocomplétion
    this.filteredArticles = this.articleCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => !value || typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterArticle(name) : this.listeArticles.slice())
      );
  }

  private filterArticle(name: string): Article[] {
    // Le filtre ne tient pas compte des accents
    const filterValue = Tools.removeDiacritics(name.toLowerCase());
    const filterWords = filterValue.split(' ');

    let listeArticlesFilter: Array<Article> = this.listeArticles.filter(article => {
      for (let word of filterWords) {
        if (!this.articleContientMot(article, word)) {
          return false;
        }
      }
      return true;
    });

    this.tri(listeArticlesFilter);

    return listeArticlesFilter;
  }

  articleContientMot(article: Article, word: string): boolean {
    return article.designationAchat && Tools.removeDiacritics(article.designationAchat).toLowerCase().indexOf(word) >= 0
      || article.designationVente && Tools.removeDiacritics(article.designationVente).toLowerCase().indexOf(word) >= 0
      || article.codeArticleVente && article.codeArticleVente.toString().indexOf(word) === 0
      || article.codeArticleAchat && article.codeArticleAchat.toLowerCase().indexOf(word) === 0
      ;
  }

  // Tri d'un tableau d'articles par désignation de l'article
  tri(listeArticles: Array<Article>) {
    try {
      listeArticles.sort((a, b) => {
        const designationA = a.designationAchat ? a.designationAchat : a.designationVente;
        const designationB = b.designationAchat ? b.designationAchat : b.designationVente;
        return designationA.localeCompare(designationB);
      });
    } catch (e) {
      console.error(e);
    }
  }

  //ControlValueAccessor
  registerOnChange(fn: any): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.articleCtrl.setValue(obj);
  }

  optionSelected() {
    this.propagateChange(this.articleCtrl.value);
  }

  optionActivated(activatedEvent: MatAutocompleteActivatedEvent) {
    // Procédure qui est appélee lorsqu'une option est sélectionnée par le clavier
    if (activatedEvent && activatedEvent.option) {
      this.articleCtrl.setValue(activatedEvent.option.value);
      this.propagateChange(this.articleCtrl.value);
    }
  }

  // Touche relâchée : l'article vaut null car seul la sélection d'un article doit retourner un article
  // Envoi que si ce n'est pas un objet car l'événement touche relâchée est aussi appelé lors de la sélection d'une option par le clavier
  keyup() {
    if (!(this.articleCtrl.value instanceof Object)) {
      this.propagateChange(null);
    }
  }

  // Utilisation d'une arrow function afin de pouvoir appeler this
  displayArticle = (article?: Article): string | undefined => {
    if (article) {
      const designation = this.designationPipe.transform(article, this.utilisation);
      return designation ? designation : ''+article.idArticle
    } else {
      return undefined
    }
  };


  //MatFormFieldControl
  onContainerClick(event: MouseEvent): void {
  }

  // Pour la propriété aria-describedby
  setDescribedByIds(ids: string[]): void {
  }
}
