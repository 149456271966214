import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'truncate',
    standalone: false
})
// Pour éviter d'utiliser le pipe slice dans le template avec une condition à rallonge
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 30, ellipsis: string = '...') {
    return value && value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
