import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'epona-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css'],
    standalone: false
})
export class AccueilComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
