import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {ConfirmDialogData} from "./confirm-dialog-data";

@Component({
    selector: 'epona-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.css'],
    standalone: false
})
export class DialogConfirmComponent implements OnInit {
  subject!: Subject<boolean>;

  constructor(private dialogRef: MatDialogRef<DialogConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

  ngOnInit() {
    if (this.data.width) {
      this.dialogRef.updateSize(this.data.width);
    } else {
      // La boite de dialogue a toujours la même taille. Cela évite de la définir dans le composant qui appelle la boite de dialogue.
      this.dialogRef.updateSize("400px");
    }

  }

  // Utile pour afficher la popup lorsqu'on quitte une page et qu'il y a eu des modifications
  action(value: boolean) {
    this.dialogRef.close();
    this.subject.next(value);
    this.subject.complete();
  }
}
