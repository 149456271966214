import { Pipe, PipeTransform } from '@angular/core';
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";

@Pipe({
  name: 'etapeServiceFait',
  standalone: false
})
export class EtapeServiceFaitPipe implements PipeTransform {

  transform(bl: EntreeSortieEntete): string {
    return EtapeServiceFaitPipe.buildLibelle(bl);
  }

  static buildLibelle(bl: EntreeSortieEntete): string {
    if (!bl) {
      return '';
    }
    switch (bl.codeEtapeOrion) {
      case 'MAJ_EJ': return 'Attente MAJ EJ';
      case 'ENV_SF': return 'Attente envoi SF';
      case 'SF_OK':  return  'SF envoyé';
      case 'SF_SUPP':  return  'SF supprimé';
      case 'NO_SF':  return  'SF inutile';
      default: return ''; // BL non validé
    }
  }
}
