import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {LieuSearch} from '../../model/epona-api/LieuSearch';

@Injectable({
  providedIn: 'root'
})
export class LieuExportService {
  private baseUrl = environment.eponaApiUrl + '/lieux';

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getExportLieux(search: LieuSearch): void {
    const params = this.httpService.buildParams(search);
    let url = this.baseUrl + '/export/xlsx' + '?' + params.toString();
    this.httpService.downloadFile(url, undefined, 'liste-lieux.xlsx');
  }
}
