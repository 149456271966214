import {Pipe} from '@angular/core';
import {SousLotZg} from "../../model/epona-api/SousLotZg";
import {LotMarcheOrionPipe} from "./LotMarcheOrionPipe";

@Pipe({
    name: 'lotMarcheOrionType',
    standalone: false
})
export class LotMarcheOrionTypePipe extends LotMarcheOrionPipe {
  transform(sousLot: SousLotZg): string {
    if (sousLot) {
      return super.transform(sousLot) + ' (' + sousLot.typeSousLot + ')';
    } else {
      return '';
    }
  }
}
