import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Const} from '../constants/Const';
import {UniteGestion} from '../../model/UniteGestion';
import {RestaurantUniversitaire} from '../../model/RestaurantUniversitaire';
import {UniteDistribution} from '../../model/UniteDistribution';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {VemService} from '../../services/vem.service';
import {UserService} from '../../services/user.service';
import {Subject, Subscription} from 'rxjs';
import {Lieu} from '../../model/epona-api/Lieu';
import {MessageTool} from "../MessageTool";
import {Arborescence} from "../../model/Arborescence";
import {CacheService} from "../../services/cache.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
    selector: 'epona-lieu-filter',
    templateUrl: './lieu-filter.component.html',
    styleUrls: ['./lieu-filter.component.css'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
                floatLabel: 'always',
                subscriptSizing: 'dynamic'
            }
        }
    ],
    standalone: false
})
export class LieuFilterComponent implements OnInit, OnChanges, OnDestroy {
  // Constante utilisée dans le HTML du component
  DEFAULT: number = Const.DEFAULT;

  private readonly CACHE_ARBORESCENCE_KEY = 'arborescence';

  listeUG: Array<UniteGestion>;
  listeRU: Array<RestaurantUniversitaire>;
  listeUD: Array<UniteDistribution>;
  lieuSelectionne: Lieu;
  @Input() lieu: Lieu;
  @Input() desactiveLieu = false;
  @Input() choixRestreints = true;  // Choix restreints aux RU autorisés
  @Input() ugLabel = 'UG';
  @Input() ruLabel = 'RU';
  @Input() udLabel = 'UD';
  @Input() ugDefault = 'Toutes';
  @Input() ruDefault = 'Tous';
  @Input() udDefault = 'Toutes';

  @Output() readonly lieuSubmitted = new EventEmitter<Lieu>();

  lieuForm: UntypedFormGroup;

  userEventsSubscription: Subscription;

  loadLieuxCompleted = new Subject<boolean>();

  constructor(private vemServices: VemService,
              private fb: UntypedFormBuilder,
              private userService: UserService,
              private cacheService: CacheService,
              private messageTool: MessageTool) {
  }

  ngOnInit() {
    this.userEventsSubscription = this.userService.userEvents.subscribe(res => {
      if (res !== null) { // Si res est null c'est que l'utilisateur n'est plus connecté
        this.initLieuForm();
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.lieu) {
      this.lieu = new Lieu();
      this.lieu.idCrous = +localStorage.getItem('idCrous');
    }

    // Mise à jour du lieu uniquement si la propriété a changé
    if (changes['lieu']) {
      this.updateFormFull();
    }

    let change = changes['desactiveLieu'];

    // On désactive le lieu uniquement si la valeur a changé et qu'elle est à true
    if (change && !change.firstChange) {
      if (this.desactiveLieu) {
        this.lieuForm.disable();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.userEventsSubscription) {
      this.userEventsSubscription.unsubscribe();
    }
  }

  /**
   * Méthode permettant d'initialiser le formulaire
   */
  initLieuForm() {
    // Initialisation du FormGroup
    this.lieuForm = this.fb.group({
      idCrousLieu: this.fb.control(this.lieu.idCrous),
      idUgLieu: this.fb.control({
        value: LieuFilterComponent.ifEmpty(this.lieu.idUg, this.DEFAULT)
      }),
      idRuLieu: this.fb.control( {
        value: LieuFilterComponent.ifEmpty(this.lieu.idRu, this.DEFAULT),
        disabled: !this.lieu.idRu
      }),
      idUdLieu: this.fb.control( {
        value: LieuFilterComponent.ifEmpty(this.lieu.idUd, this.DEFAULT),
        disabled: !this.lieu.idUd
      }),
    });

    if (this.desactiveLieu) {
      this.lieuForm.disable();
    }

    // Si l'arborescence est en cache
    if (this.cacheService.has(this.CACHE_ARBORESCENCE_KEY)) {
      // Ilitialisation des listes depuis le cache
      this.initListesFromCache();
      this.loadLieuxCompleted.next(true);

    // Sinon, chargement de l'arborescence via l'API-VEM, mise en cache et initialisation des listes
    } else {
      this.vemServices.getArborescence().subscribe(data => {
        this.cacheService.set(this.CACHE_ARBORESCENCE_KEY, data);
        this.initListesFromCache();
        this.loadLieuxCompleted.next(true);

      }, err => {
        this.messageTool.sendError(err);
        this.listeUG = [];
      });
    }
  }

  private initListesFromCache() {
    this.listeUG = this.initListeLieux(this.cacheService.get(this.CACHE_ARBORESCENCE_KEY));
    this.listeUG.sort((a, b) => (a.nom ? a.nom : '').localeCompare(b.nom ? b.nom : ''));

    this.updateListeRU();
    this.updateListeUD();
  }

  private initListeLieux(arborescence:Arborescence): UniteGestion[] {
    if (this.choixRestreints) {
      let listeUgSecured: Array<UniteGestion> = [];
      for (let ug of arborescence.listeUg) {
        const ugSecured = new UniteGestion();
        ugSecured.nom = ug.nom;
        ugSecured.listeRu = [];
        ugSecured.idUg = ug.idUg;
        if (ug.listeRu) {
          for (let ru of ug.listeRu) {
            // Si le RU fait partie des RU autorises
            if (this.userService.mapRuAutorises[ug.idUg] && this.userService.mapRuAutorises[ug.idUg].includes(ru.idRu)) {
              ugSecured.listeRu.push(ru);
            }
          }
        }
        if (ugSecured.listeRu.length > 0) {
          listeUgSecured.push(ugSecured)
        }
      }
      return listeUgSecured;
    } else {
      return arborescence.listeUg;
    }
  }


  private updateFormFull() {
    // si l'arborescence des lieux n'a pas été chargée, il faut attendre qu'elle soit chargée pour mettre à jour les listes
    if (!this.listeUG) {
      this.loadLieuxCompleted.subscribe(res => {
        if (res) {
          this.updateForm();
        }
      })

    } else {
      this.updateForm();
    }
  }

  private updateForm() {
    this.initLieuSelectionne();
    this.updateFormControls();
    this.updateListeRU();
    this.updateListeUD()
  }

  private initLieuSelectionne() {
    this.lieuSelectionne = new Lieu();
    this.lieuSelectionne.idCrous = this.lieu.idCrous;

    const ug = this.listeUG.find(ug => ug.idUg === this.lieu.idUg);

    // L'UG du lieu en Input existe
    if (ug) {
      this.lieuSelectionne.idUg = this.lieu.idUg;
      const ru = ug.listeRu ? ug.listeRu.find(ru => ru.idRu === this.lieu.idRu) : null;

      // Le RU du lieu en Input existe
      if (ru) {
        this.lieuSelectionne.idRu = this.lieu.idRu;
        const ud = ru.listeUd ? ru.listeUd.find(ud => ud.idUd === this.lieu.idUd) : null;

        // L'UD du lieu en Input existe
        if (ud) {
          this.lieuSelectionne.idUd = this.lieu.idUd;

        // L'UD du lieu en Input n'existe pas
        } else {
          this.lieuSelectionne.idUd = null;
        }

      // Le RU du lieu en Input n'existe pas
      } else {
        this.lieuSelectionne.idRu = null;
        this.lieuSelectionne.idUd = null;
      }

    // L'UG du lieu en Input n'existe pas
    } else {
      this.lieuSelectionne.idUg = null;
      this.lieuSelectionne.idRu = null;
      this.lieuSelectionne.idUd = null;
    }
  }

  private updateFormControls() {
    this.lieuForm.get('idUgLieu').setValue(LieuFilterComponent.ifEmpty(this.lieu.idUg, this.DEFAULT));
    this.lieuForm.get('idRuLieu').setValue(LieuFilterComponent.ifEmpty(this.lieu.idRu, this.DEFAULT));
    this.lieuForm.get('idUdLieu').setValue(LieuFilterComponent.ifEmpty(this.lieu.idUd, this.DEFAULT));
  }

  private static ifEmpty(value: number, def: number): number {
    return value === undefined || value === null ? def : value;
  }

  selectUG() {
    this.lieuForm.get('idUdLieu').disable();
    this.lieuForm.get('idRuLieu').setValue(this.DEFAULT);
    this.lieuForm.get('idUdLieu').setValue(this.DEFAULT);

    this.lieuSelectionne.idRu = null;
    this.lieuSelectionne.idUd = null;

    this.updateListeRU();
    // Envoi d'un événement aux composants parents qui contient le lieu
    this.lieuSelectionne.idUg = this.lieuForm.get('idUgLieu').value !== this.DEFAULT ? this.lieuForm.get('idUgLieu').value : null;
    this.lieuSubmitted.emit(this.lieuSelectionne);
  }

  updateListeRU() {
      this.listeRU = [];
      this.listeUD = [];
      for (const ug of this.listeUG) {
        if (ug.idUg === this.lieuForm.get('idUgLieu').value) {
          this.listeRU = ug.listeRu;
          if (this.listeRU) {
            this.listeRU.sort((a, b) => (a.nom ? a.nom : '').localeCompare(b.nom ? b.nom : ''));
          }
        }
      }
      if (this.desactiveLieu || this.lieuForm.get('idUgLieu').value === this.DEFAULT) {
        this.lieuForm.get('idRuLieu').disable();
      } else {
        this.lieuForm.get('idRuLieu').enable();
      }
  }

  selectRU() {
    this.lieuForm.get('idUdLieu').setValue(this.DEFAULT);

    this.lieuSelectionne.idUd = null;

    this.updateListeUD();

    // Envoi d'un événement aux composants parents qui contient le lieu
    this.lieuSelectionne.idRu = this.lieuForm.get('idRuLieu').value !== this.DEFAULT ? this.lieuForm.get('idRuLieu').value : null;
    this.lieuSubmitted.emit(this.lieuSelectionne);
  }

  updateListeUD() {
    this.listeUD = [];
    for (const ug of this.listeUG) {
      if (ug.idUg === this.lieuForm.get('idUgLieu').value) {
        if (ug.listeRu) {
          for (const ru of ug.listeRu) {
            if (ru.idRu === this.lieuForm.get('idRuLieu').value) {
              this.listeUD = ru.listeUd;
              if (this.listeUD) {
                this.listeUD.sort((a, b) => (a.nom ? a.nom : '').localeCompare(b.nom ? b.nom : ''));
              }
            }
          }
        }
      }
    }

    if (this.desactiveLieu || this.lieuForm.get('idRuLieu').value === this.DEFAULT) {
      this.lieuForm.get('idUdLieu').disable();
    } else {
      this.lieuForm.get('idUdLieu').enable();
    }
  }

  selectUD() {
    this.lieuSelectionne.idUd = this.lieuForm.get('idUdLieu').value !== this.DEFAULT ? this.lieuForm.get('idUdLieu').value : null;

    // Envoi d'un événement aux composants parents qui contient le lieu
    this.lieuSubmitted.emit(this.lieuSelectionne);
  }

  // Validators.required ne peut être utilisé car Const.DEFAULT peut prendre n'import quelle valeur
  private static lieuRequired (control: UntypedFormControl) {
      const isEmpty = control.value === Const.DEFAULT;
      const result = {'required': isEmpty ? true : null};
      return isEmpty ? result : null;
  }
}
