import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataCommandes} from "../../model/epona-ui/DialogDataCommandes";

@Component({
    selector: 'app-dialog-commandes-generees',
    templateUrl: './dialog-commandes-generees.component.html',
    styleUrls: ['./dialog-commandes-generees.component.css'],
    standalone: false
})
export class DialogCommandesGenereesComponent implements OnInit {

  constructor(private dialog: MatDialogRef<DialogCommandesGenereesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataCommandes) {

  }

  ngOnInit(): void {
  }

  close() {
    this.dialog.close();
  }
}
