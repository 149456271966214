import { Component, OnInit } from '@angular/core';
import {HttploaderService} from './httploader.service';
import {Subject, Subscription} from 'rxjs';

@Component({
    selector: 'app-httploader',
    templateUrl: './httploader.component.html',
    styleUrls: ['./httploader.component.css'],
    standalone: false
})
export class HttploaderComponent implements OnInit{

  readonly color = 'primary';
  readonly mode = 'indeterminate';
  readonly value = 50;
  eventsSubscription: Subscription;
  isLoading: boolean;

  constructor(private loaderService: HttploaderService){  }

  ngOnInit(): void {
    this.eventsSubscription = this.loaderService.isLoading.subscribe(res => {
      this.isLoading = res;
    });
  }
}
