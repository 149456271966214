import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {PanierService} from "../../services/epona/panier.service";
import {MessageTool} from "../../commons/MessageTool";
import {forkJoin, Observable} from "rxjs";

@Component({
    selector: 'epona-panier-totaux',
    templateUrl: './panier-totaux.component.html',
    styleUrls: ['./panier-totaux.component.css'],
    standalone: false
})
export class PanierTotauxComponent implements OnChanges {

  @Input() entete: PanierEntete;

  mapTotauxSetParSousLot: Map<number, TotauxSet>;

  constructor(private panierService: PanierService,
              private messageService: MessageTool) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entete'] && this.entete) {
      forkJoin([this.buildGetListeTotauxTva(), this.buildGetListeTotaux()]).subscribe({
        next: data => {
          this.buildMapTotauxTva(data[0], data[1]);
        },
        error: err => {
          this.mapTotauxSetParSousLot = null;
          this.messageService.sendError(err);
        }
      });
    }
  }

  private buildGetListeTotauxTva(): Observable<TotauxTva[]> {
    const fields = 'sousLot.idSousLotZg,sousLot.codeMarcheOrion,sousLot.numeroSousLot,sousLot.typeSousLot,tauxTva,sousTotalHt,sousTotalTva,sousTotalTtc';
    return this.panierService.getTotauxTva(this.entete.idPanierEntete, fields);
  }

  private buildGetListeTotaux(): Observable<Totaux[]> {
    const fields = 'sousLot.idSousLotZg,sousLot.codeMarcheOrion,sousLot.numeroSousLot,sousLot.typeSousLot,tauxTva,totalHt,totalTva,totalTtc';
    return this.panierService.getTotaux(this.entete.idPanierEntete, fields);
  }

  private buildMapTotauxTva(listeTotauxTva: TotauxTva[], listeTotaux: Totaux[]): void {
    if (listeTotauxTva && listeTotauxTva.length > 0) {
      this.mapTotauxSetParSousLot = new Map<number, TotauxSet>();
      for (let totauxTva of listeTotauxTva) {
        const idSousLot = totauxTva.sousLot.idSousLotZg;
        if (!this.mapTotauxSetParSousLot.has(idSousLot)) {
          const totauxSet = new TotauxSet();
          totauxSet.totaux = listeTotaux.find(totaux => totaux.sousLot.idSousLotZg === idSousLot);
          this.mapTotauxSetParSousLot.set(idSousLot, totauxSet);
        }
        this.mapTotauxSetParSousLot.get(idSousLot).listeTotauxTva.push(totauxTva);
      }
    }
  }
}

export class TotauxSet {
  listeTotauxTva: Array<TotauxTva> = [];
  totaux: Totaux;
}
