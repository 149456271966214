import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {StockCompactSearch} from '../../model/epona-api/StockCompactSearch';

@Injectable({
  providedIn: 'root'
})
export class StockExportService {
  private baseUrl = environment.eponaApiUrl + '/stocks-compacts';

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getExportStocksCompacts(search: StockCompactSearch): void {
    const params = this.httpService.buildParams(search);
    var url = this.baseUrl + '/export/xlsx' + '?' + params.toString();
    this.httpService.downloadFile(url, undefined, 'liste-stocks-compacts.xlsx');
  }
}
