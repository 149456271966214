import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CommandeEntete} from '../../model/epona-api/CommandeEntete';
import {CommandeLigne} from '../../model/epona-api/CommandeLigne';
import {CommandeSearch} from '../../model/epona-api/CommandeSearch';
import {InformationLieuArticle} from '../../model/InformationLieuArticle';
import {PropositionCommandeSearch} from '../../model/epona-api/PropositionCommandeSearch';
import {CommandeLignePOST} from '../../model/epona-api/CommandeLignePOST';
import {CommandeLignePUT} from '../../model/epona-api/CommandeLignePUT';
import {HttpService} from "../http.service";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {CommandeCourriel} from "../../model/epona-api/CommandeCourriel";
import {CommandeCourrielDTO} from "../../model/epona-api/CommandeCourrielDTO";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {CommandeChangementEtat} from "../../model/epona-api/CommandeChangementEtat";
import {CommandeEntetePOST} from "../../model/epona-api/CommandeEntetePOST";
import {CommandeEntetePUT} from "../../model/epona-api/CommandeEntetePUT";
import {CommandeEntetePATCH} from "../../model/epona-api/CommandeEntetePATCH";
import {UserService} from "../user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {EngagementJuridiqueLigne} from "../../model/epona-api/EngagementJuridiqueLigne";
import {EngagementJuridiqueEntetePUT} from "../../model/epona-api/EngagementJuridiqueEntetePUT";
import {EngagementJuridiqueLigneDTO} from "../../model/epona-api/EngagementJuridiqueLigneDTO";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {TotauxTva} from "../../model/epona-api/TotauxTva";
import {Totaux} from "../../model/epona-api/Totaux";
import {FinalisationReception} from "../../model/epona-api/finalisation-reception";
import {OrionSuivi} from "../../model/epona-api/OrionSuivi";
import {HttpDataWithPagination} from '../../model/http-data-with-pagination';

@Injectable()
export class CommandeService {

  private env = environment;
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService,
              private userService: UserService) {
    this.baseUrl = this.env.eponaApiUrl + '/commandes';
  }

  estBrouillon(commande: CommandeEntete): boolean {
    return commande && commande.etatCommande && commande.etatCommande.codeEtatCommande === CodeEtatCommande.BROUILLON;
  }

  estEnPreparationEJ(commande: CommandeEntete): boolean {
    return commande && commande.etatCommande && commande.etatCommande.codeEtatCommande === CodeEtatCommande.PREPARATION_EJ;
  }

  estEnAttenteRetourOrion(commande: CommandeEntete): boolean {
    return commande && commande.etatCommande && commande.etatCommande.codeEtatCommande === CodeEtatCommande.EN_ATTENTE_RETOUR_ORION;
  }

  estPartiellementModifiable(commande: CommandeEntete): boolean {
    return this.estEnPreparationEJ(commande) || this.estEnAttenteRetourOrion(commande);
  }

  estRefuseeParOrion(commande: CommandeEntete): boolean {
    return commande && commande.etatCommande && commande.etatCommande.codeEtatCommande === CodeEtatCommande.REFUSEE_ORION;
  }

  estEnPreparation(commande: CommandeEntete): boolean {
    return commande && commande.etatCommande && commande.etatCommande.codeEtatCommande === CodeEtatCommande.EN_PREPARATION;
  }

  /* ******************* */
  /* Gestion des entêtes */
  /* ******************* */

  getListeEntetesWithPagination(search: CommandeSearch): Observable<HttpDataWithPagination<CommandeEntete>> {
    return this.httpService.getListeWithPagination<CommandeEntete>(this.baseUrl, search);
  }

  getListeEntetesComplete(search: CommandeSearch): Observable<CommandeEntete[]> {
    return this.httpService.getListeComplete<CommandeEntete>(this.baseUrl, search);
  }

  getEntete(idEntete: number, fields: string): Observable<CommandeEntete> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<CommandeEntete>(this.baseUrl + '/' + idEntete, {params});
  }

  postEntete(entete: CommandeEntete): Observable<CommandeEntete> {
    return this.httpClient.post<CommandeEntete>(this.baseUrl, new CommandeEntetePOST(entete));
  }

  putEntete(entete: CommandeEntete): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + entete.idCommandeEntete, new CommandeEntetePUT(entete));
  }

  patchEntete(entete: CommandeEntete): Observable<any> {
    return this.httpClient.patch<any>(this.baseUrl + '/' + entete.idCommandeEntete, new CommandeEntetePATCH(entete));
  }

  deleteCommande(idCommande: number) : Observable<any> {
    return this.httpClient.delete(this.baseUrl + '/' + idCommande);
  }

  /* ******************* */
  /* Gestion des lignes */
  /* ****************** */

  getListeLignes(idEntete: number, search: CommandeLigneSearch): Observable<CommandeLigne[]> {
    const params = this.httpService.buildParams(search);
    return this.httpClient.get<CommandeLigne[]>(this.baseUrl + '/' + idEntete + '/lignes', {params});
  }

  postLigne(idEntete: number, ligne: CommandeLigne): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/lignes', new CommandeLignePOST(ligne));
  }

  putLigne(idEntete: number, ligne: CommandeLigne): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + idEntete + '/lignes/'
      + ligne.idCommandeLigne, new CommandeLignePUT(ligne));
  }

  deleteLigne(idEntete: number, idLigne: number): Observable<any> {
    return this.httpClient.delete(this.baseUrl + '/' + idEntete + '/lignes/' + idLigne);
  }

  /* ******************* */
  /* Gestion du courriel */
  /* ******************* */

  getCourriel(idEntete: number, fields: string): Observable<CommandeCourriel> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<CommandeCourriel>(this.baseUrl + '/' + idEntete + '/courriel', {params});
  }

  putCourriel(idEntete: number, courriel: CommandeCourriel): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + idEntete + '/courriel', new CommandeCourrielDTO(courriel));
  }

  postNouvelEnvoiCourriel(idEntete: number): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/courriel/nouvel-envoi', null);
  }

  downloadPdf(commande: CommandeEntete): void {
    const url = this.baseUrl + '/' + commande.idCommandeEntete + '/fichiers/pdf';
    const defaultFilename = this.defaultFilenamePdf(commande);
    this.httpService.downloadFile(url, 'application/pdf', defaultFilename);
  }

  defaultFilenamePdf(commande: CommandeEntete): string {
    return `Commande_${this.numeroCommande(commande)}.pdf`;
  }

  downloadMail(commande: CommandeEntete): void {
    const url = this.baseUrl + '/' + commande.idCommandeEntete + '/fichiers/mail';
    const defaultFilename =  `Courriel_commande_${this.numeroCommande(commande)}.eml`;
    this.httpService.downloadFile(url, 'application/octet-stream', defaultFilename);
  }

  numeroCommande(commande: CommandeEntete): string {
    return commande.numeroEj ? commande.numeroEj : ''+commande.idCommandeEntete;
  }


  /* ****************** */
  /* Gestion des totaux */
  /* ****************** */

  getTotauxTva(idEntete: number, fields: string): Observable<TotauxTva[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<TotauxTva[]>(this.baseUrl + '/' + idEntete + '/totaux-tva', {params});
  }

  getTotaux(idEntete: number, fields: string): Observable<Totaux> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<Totaux>(this.baseUrl + '/' + idEntete + '/totaux', {params});
  }

  /* ************************************ */
  /* Gestion des propositions de commande */
  /* ************************************ */

  getPropositionsCommandes(search: PropositionCommandeSearch): Observable<InformationLieuArticle[]> {
    const params = this.httpService.buildParams(search);
    const url = this.env.eponaApiUrl + '/propositions-commandes';
    return this.httpClient.get<InformationLieuArticle[]>(url, {params});
  }

  /* *********************************** */
  /* Gestion des bordereaux de livraison */
  /* *********************************** */

  getListeEntreeSortieFromCommande(idCommande: number) : Observable<EntreeSortieEntete[]> {
    return this.httpClient.get<EntreeSortieEntete[]>(this.baseUrl + '/' + idCommande + '/bordereaux-livraison');
  }

  /* ****************************** */
  /* Gestion des changements d'état */
  /* ****************************** */

  getListeChangementsEtatFromCommande(idCommande: number, fields: string): Observable<CommandeChangementEtat[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<CommandeChangementEtat[]>(this.baseUrl + '/' + idCommande + '/changements-etat', {params});
  }

  postFinalisationCommande(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/finalisation-commande', null);
  }

  postAnnulationFinalisationCommande(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/annulation-finalisation-commande', null);
  }

  postEnvoiEngagementJuridique(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/envoi-engagement-juridique', null);
  }

  postRegenerationEngagementJuridique(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/regeneration-engagement-juridique', null);
  }

  postEnvoiMagasin(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/envoi-magasin', null);
  }

  postFinalisationPreparation(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/finalisation-preparation', null);
  }

  postRefusMagasin(idEntete: number): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/refus-magasin', null);
  }

  postFinalisationReception(idEntete: number, dto: FinalisationReception): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/actions/finalisation-reception', dto);
  }

  /* *********** */
  /* Duplication */
  /* *********** */

  postDuplication(idEntete: number): Observable<CommandeEntete> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/duplication', null);
  }

  /* ******************************* */
  /* Droits de l'utilisateur courant */
  /* ******************************* */

  droitConsultation(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.COMMANDES_CONSULTATION);
  }
  droitSaisie(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.COMMANDES_SAISIE);
  }
  droitPreparation(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.COMMANDES_PREPARATION);
  }
  droitModification(): boolean {
    const user = this.userService.utilisateurCourant;
    return user.possedeDroit(CodeDroit.COMMANDES_SAISIE)
        || user.possedeDroit(CodeDroit.COMMANDES_ENVOI_MAGASIN)
        || user.possedeDroit(CodeDroit.COMMANDES_PREPARATION)
        || user.possedeDroit(CodeDroit.COMMANDES_FINALISATION_COMMANDE)
    ;
  }
  droitConsultationLivraison(): boolean {
    return this.userService.utilisateurCourant.possedeDroit(CodeDroit.LIVRAISONS_CONSULTATION);
  }

  /* ********************************** */
  /* Gestion des engagements juridiques */
  /* ********************************** */

  getEngagementJuridiqueEntete(idCommande: number, fields: string): Observable<EngagementJuridiqueEntete> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<EngagementJuridiqueEntete>(this.baseUrl + '/' + idCommande + '/engagement-juridique', {params});
  }

  putEngagementJuridiqueEntete(ej: EngagementJuridiqueEntete, commande: CommandeEntete): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + commande.idCommandeEntete + '/engagement-juridique', new EngagementJuridiqueEntetePUT(ej));
  }


  getEngagementJuridiqueLignes(idCommande: number, fields: string): Observable<EngagementJuridiqueLigne[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<EngagementJuridiqueLigne[]>(this.baseUrl + '/' + idCommande + '/engagement-juridique/lignes', {params});
  }

  postEngagementJuridiqueLigne(idEntete: number, ligne: EngagementJuridiqueLigne): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/' + idEntete + '/engagement-juridique/lignes', new EngagementJuridiqueLigneDTO(ligne));
  }

  putEngagementJuridiqueLigne(idEntete: number, ligne: EngagementJuridiqueLigne): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + idEntete + '/engagement-juridique/lignes/'
      + ligne.idEngagementJuridiqueLigne, new EngagementJuridiqueLigneDTO(ligne));
  }

  deleteEngagementJuridiqueLigne(idEntete: number, idLigne: number): Observable<any> {
    return this.httpClient.delete(this.baseUrl + '/' + idEntete + '/engagement-juridique/lignes/' + idLigne);
  }


  getEngagementJuridiqueSuivis(idCommande: number, fields: string): Observable<OrionSuivi[]> {
    const params = this.httpService.buildParams({fields: fields});
    return this.httpClient.get<OrionSuivi[]>(this.baseUrl + '/' + idCommande + '/engagement-juridique/suivis', {params});
  }

  buildUrlFichierSuivi(idCommande: number, idSuivi: number): string {
    return this.baseUrl + '/' + idCommande + '/engagement-juridique/suivis/' + idSuivi + '/fichier';
  }
}
