import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {PanierSearch} from '../../model/epona-api/panier-search';

@Injectable({
  providedIn: 'root'
})
export class PanierExportService {
  private baseUrl = environment.eponaApiUrl + '/paniers';

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getExportPaniers(search: PanierSearch): void {
    const params = this.httpService.buildParams(search);
    var url = this.baseUrl + '/export/xlsx' + '?' + params.toString();
    this.httpService.downloadFile(url, undefined, 'liste-paniers.xlsx');
  }
}
