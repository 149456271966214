import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {MessageTool} from "../../commons/MessageTool";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {Lieu} from "../../model/epona-api/Lieu";
import {Router} from "@angular/router";
import {Fields} from "../../commons/constants/Fields";

@Component({
    selector: 'epona-dialog-consultation-bl',
    templateUrl: './dialog-consultation-bl.component.html',
    styleUrls: ['./dialog-consultation-bl.component.css'],
    standalone: false
})
export class DialogConsultationBlComponent implements OnInit {
  readonly BORDEREAUX_LIVRAISON = EntreeSortieParametrage.BORDEREAUX_LIVRAISON;

  entete: EntreeSortieEntete;
  lieuCourant: Lieu;
  lignes: Array<EntreeSortieLigne>;

  constructor(private entreeSortieService: EntreeSortieService,
              private dialogRef: MatDialogRef<DialogConsultationBlComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EntreeSortieEntete,
              private messageTool: MessageTool,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getEntete();
    this.getLignes();
  }

  getEntete() {
    this.entreeSortieService.getEntete(this.data.idEntreeSortieEntete, Fields.ENTREE_SORTIE_ENTETE).subscribe(data => {
      this.entete = data;
      this.lieuCourant = data.lieu;

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  getLignes() {
    this.entreeSortieService.getListeLignes(this.data.idEntreeSortieEntete, Fields.ENTREE_SORTIE_LIGNES).subscribe(data => {
      this.lignes = data
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  close() {
    this.dialogRef.close();
  }

  modifier() {
    this.router.navigate(['/bordereau-livraison', this.data.idEntreeSortieEntete]);
    this.dialog.closeAll();
  }
}
