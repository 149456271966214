import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, NgControl, UntypedFormControl} from "@angular/forms";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {LieuBns} from "../../../model/bns-api/lieu-bns";
import {BnsService} from "../../../services/bns/bns.service";
import {BatimentBns} from "../../../model/bns-api/batiment-bns";
import {LieuBatimentSearch} from "../../../model/bns-api/lieu-batiment-search";

@Component({
    selector: 'epona-select-lieu-bns',
    templateUrl: './select-lieu-bns.component.html',
    styleUrls: ['./select-lieu-bns.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectLieuBnsComponent }],
    standalone: false
})
export class SelectLieuBnsComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<LieuBns> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() afficherInactifs: boolean = false;
  @Output() readonly valueChanged = new EventEmitter<LieuBns>();

  public readonly DEFAULT = Const.DEFAULT;

  formCtrl: UntypedFormControl;
  liste: LieuBns[];
  listeActifs: LieuBns[];
  listeInactifs: LieuBns[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnsService: BnsService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: LieuBns): void {
    this.formCtrl.setValue(obj ? obj.idLieu : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): LieuBns {
    return this.getCurrentItem();
  }
  set value(value: LieuBns) {
    this.formCtrl.setValue(value ? value.idLieu : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  private _batiment: BatimentBns = null;
  @Input()
  get batiment(): BatimentBns {
    return this._batiment;
  }
  set batiment(value: BatimentBns) {
    this._batiment = value;
    this.disabled = !value;
    this.loadListe();
  }

  getCurrentItem(): LieuBns {
    return this.liste && this.liste.find(item => item.idLieu === this.formCtrl.value);
  }

  private loadListe() {
    // La liste des lieux dépend du bâtiment sélectionné
    if (this._batiment) {
      // Chargement des lieux depuis la BNS
      const search = new LieuBatimentSearch();
      search.codeUsage = 'STO';
      search.fields = 'idLieu,nom,actif';
      this.bnsService.getListeCompleteLieuxFromBatiments(this._batiment.idBatiment, search).subscribe(data => {
        // Tri et stockage de la liste chargée
        this.liste = data;
        this.listeActifs   = data.filter(lieuBns => lieuBns.actif !== false);
        this.listeInactifs = data.filter(lieuBns => lieuBns.actif === false);
        this.tri(this.listeActifs);
        this.tri(this.listeInactifs);

        // Si le lieu sélectionné n'existe pas dans la nouvelle liste, il est vidé
        if (!this.getCurrentItem()) {
          this.clearCurrentItem();
        }

      }, err => {
        this.messageTool.sendError(err);
      });

    // Si aucun bâtiment n'est sélectionné alors la liste des lieux est vide et aucun lieu n'est sélectionné
    } else {
      this.liste = [];
      this.clearCurrentItem();
    }
  }

  private clearCurrentItem() {
    this.formCtrl.setValue(Const.DEFAULT);
    this.selectionChanged();
  }

  tri(liste: Array<LieuBns>) {
    liste.sort(LieuBns.sort);
  }
}
