<epona-select-lieu [lieu]="lieuCourant"
                   [livraisonUniquement]="true"
                   lieuLabel="Lieu de livraison"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>Commandes</h1>

<div class="crous-recherche">
  <epona-filtre-commandes [filter]="filter"
                          class="crous-recherche-filtre"
                          (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-commandes>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Liste des commandes</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <a routerLink="/creation-commande" matTooltip="Créer une nouvelle commande" *ngIf="droitSaisie">
          <button type="button" mat-raised-button color="primary"><fa-icon icon="plus"></fa-icon> Créer</button>
        </a>

        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeCommandes()"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             aria-label="Liste de commandes" (matSortChange)="onSortChanged($event)">

        <ng-container matColumnDef="idCommandeEntete">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['idCommandeEntete'].tooltip">{{ COLUMNS['idCommandeEntete'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.idCommandeEntete}}</td>
        </ng-container>

        <ng-container matColumnDef="numeroEj">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['numeroEj'].tooltip">{{ COLUMNS['numeroEj'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.numeroEj}}</td>
        </ng-container>

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDemandeur?.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieuDemandeur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lieuDemandeur'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDemandeur?.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="externe">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['externe'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.externe ? 'Externe' : 'Interne'}}</td>
        </ng-container>

        <ng-container matColumnDef="etatCommande">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['etatCommande'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.etatCommande.libelle}}</td>
        </ng-container>

        <ng-container matColumnDef="commandableExterne">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['commandableExterne'].tooltip">{{ COLUMNS['commandableExterne'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.externe">
              <span class="erreur" *ngIf="element.nbLignesCommandableNon > 0">
                Non
              </span>
              <span class="avertissement" *ngIf="element.nbLignesCommandableNon === 0 && element.nbLignesCommandableNonDefini > 0">
                En attente
              </span>
              <span *ngIf="element.nbLignesCommandableNon === 0 && element.nbLignesCommandableNonDefini === 0">
                Oui
              </span>

              <aide icon="error_outline" class="aide-contextuelle erreur" *ngIf="element.nbLignesCommandableNon === 1">
                1 article de cette commande ne doit pas être commandé
              </aide>
              <aide icon="error_outline" class="aide-contextuelle erreur" *ngIf="element.nbLignesCommandableNon > 1">
                {{ element.nbLignesCommandableNon }} articles de cette commande ne doivent pas être commandés
              </aide>

              <aide icon="hourglass_empty" class="aide-contextuelle avertissement" *ngIf="element.nbLignesCommandableNonDefini === 1">
                1 article de cette commande doit encore être paramétré par une personne habilitée
              </aide>
              <aide icon="hourglass_empty" class="aide-contextuelle avertissement" *ngIf="element.nbLignesCommandableNonDefini > 1">
                {{ element.nbLignesCommandableNonDefini }} articles de cette commande doivent encore être paramétrés par une personne habilitée
              </aide>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="dateCommande">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateCommande'].tooltip">{{ COLUMNS['dateCommande'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateCommande | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="dateLivraisonSouhaitee">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateLivraisonSouhaitee'].tooltip">{{ COLUMNS['dateLivraisonSouhaitee'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateLivraisonSouhaitee | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="lotMarche">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lotMarche'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ element.sousLotZg | lotMarcheOrion }}</td>
        </ng-container>

        <ng-container matColumnDef="fournisseur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['fournisseur'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.fournisseur?.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="informationsLivraison">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['informationsLivraison'].tooltip" mat-sort-header>{{ COLUMNS['informationsLivraison'].label }}</th>
          <td mat-cell class="long-text" *matCellDef="let element">{{ element.informationsLivraison }}</td>
        </ng-container>

        <ng-container matColumnDef="instructionsCommande">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" [matTooltip]="COLUMNS['instructionsCommande'].tooltip" mat-sort-header>{{ COLUMNS['instructionsCommande'].label }}</th>
          <td mat-cell class="long-text" *matCellDef="let element">{{ element.instructionsCommande }}</td>
        </ng-container>

        <ng-container matColumnDef="montantHt">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['montantHt'].label }}</th>
          <td mat-cell class="pre montant" *matCellDef="let element">{{ element.montantHt | decimal2 }}</td>
        </ng-container>

        <ng-container matColumnDef="montantTtc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['montantTtc'].label }}</th>
          <td mat-cell class="pre montant" *matCellDef="let element">{{ element.montantTtc | decimal2 }}</td>
        </ng-container>

        <ng-container matColumnDef="utilisateur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['utilisateur'].label }}</th>
          <td mat-cell class="pre" *matCellDef="let element">{{ element.utilisateurModification ? element.utilisateurModification : element.utilisateurCreation }}</td>
        </ng-container>

        <ng-container matColumnDef="pdf">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['pdf'].label }}</th>
          <td mat-cell class="pre" *matCellDef="let element">
            <a matSuffix href="#" (click)="$event.preventDefault();downloadPdf(element)" *ngIf="element.externe">
              <mat-icon>picture_as_pdf</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/commande', element.idCommandeEntete]" matTooltip="Modifier la commande" *ngIf="droitModification">
              <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
            </a>
            <a [routerLink]="['/commande', element.idCommandeEntete]" matTooltip="Consulter la commande" *ngIf="!droitModification">
              <button type="button" mat-button><fa-icon icon="folder-open"></fa-icon></button>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
