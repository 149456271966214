<button class="handle"
        (click)="changerAffichage()"
        matTooltip="{{ enteteAffichee ? 'Masquer' : 'Afficher' }} l'entête"
        *ngIf="entete">
  <fa-icon icon="chevron-left" *ngIf="enteteAffichee"></fa-icon>
  <fa-icon icon="chevron-right" *ngIf="!enteteAffichee"></fa-icon>
</button>

<mat-card [ngClass]="{'masquee': !enteteAffichee}">
  <mat-card-header>
    <mat-card-title>Informations</mat-card-title>
  </mat-card-header>

  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="commandeEnteteForm">
    <div class="mat-form-field-row" *ngIf="!estCreation()">
      <mat-form-field *ngIf="!entete.numeroEj">
        <mat-label>Identifiant interne</mat-label>

        <input matInput
               formControlName="idCommandeEntete"
        />
      </mat-form-field>

      <mat-form-field *ngIf="entete.numeroEj">
        <mat-label>Numéro d'EJ</mat-label>

        <input matInput
               formControlName="numeroEj"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>État</mat-label>
        <input matInput type="text" [value]="entete.etatCommande?.libelle" disabled />
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field *ngIf="commandeEnteteForm.get('externe').value !== null" class="date-picker">
        <mat-label>Date de livraison souhaitée</mat-label>

        <input matInput
               [matDatepicker]="picker"
               formControlName="dateLivraisonSouhaitee"
               [required]="true"
               [min]="dateMin"
               (click)="picker.open()"
               (focus)="picker.open()"
               autocomplete="off"
               [errorStateMatcher]="estBrouillon() ? errorStateMatcher : null">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="commandeEnteteForm.get('dateLivraisonSouhaitee').hasError('required')">Date de livraison obligatoire</mat-error>
        <mat-error *ngIf="commandeEnteteForm.get('dateLivraisonSouhaitee').hasError('matDatepickerMin')">Date de livraison non valide</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="commandeEnteteForm.get('externe').value !== null">
        <mat-label>Heure de livraison souhaitée</mat-label>

        <input matInput
               type="time"
               formControlName="heureLivraisonSouhaitee"
               min="00:00"
               max="23:59"
               [required]="true"
        />
        <mat-error *ngIf="commandeEnteteForm.get('heureLivraisonSouhaitee').hasError('required')">Heure de livraison obligatoire</mat-error>
      </mat-form-field>
    </div>

    <epona-select-lieu [lieu]="entete?.lieuStockageApresReception"
                       (lieuSubmitted)="onLieuLieuStockageApresReceptionSubmitted($event)"
                       [stockerLieuSelectionne]="false"
                       [required]="false"
                       [disabled]="!estBrouillon()"
                       [choixRestreints]="true"
                       use="form"
                       batimentLabel="Bâtiment de stockage"
                       lieuLabel="Lieu de stockage"
                       batimentDefault=">> Idem bâtiment de livraison <<"
                       lieuDefault=">> Idem lieu de livraison <<"
                       subscriptSizing="fixed"
                       [displayHelpTooltip]="false"
                       *ngIf="!estCreation()">
    </epona-select-lieu>

    <div class="mat-form-field-row" *ngIf="estExterne() && !estCreation()">
      <mat-form-field>
        <mat-label>Fournisseur</mat-label>
        <input matInput
               [value]="entete.fournisseur?.nom"
               matTooltip="{{ entete.fournisseur?.nom }}"
               disabled />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Lot-marché Orion</mat-label>
        <input matInput
               value="{{ entete.sousLotZg | lotMarcheOrionType }}"
               matTooltip="{{ entete.sousLotZg | lotMarcheOrionType }}"
               disabled />
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field *ngIf="estExterne() && !estCreation()">
        <mat-label>Code-client chez le fournisseur</mat-label>
        <input matInput
               formControlName="codeClientDemandeur"
               [required]="estPartiellementModifiable()"
               maxlength="50"
        />
        <mat-error *ngIf="commandeEnteteForm.get('codeClientDemandeur').hasError('required')">Code-client obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="estExterne() && !estCreation() && entete.sousLotZg?.fraisPort">
        <mat-label>Frais de port prévisionnels</mat-label>
        <input matInput type="number"
               formControlName="fraisPortPrevisionnels"
               [max]="entete.sousLotZg.montantMaxFraisPort"
               min="0"
               step="0.01"
        />
        <aide matSuffix *ngIf="entete.sousLotZg.detailFraisPort">
          <div class="long-text">{{entete.sousLotZg.detailFraisPort}}</div>
        </aide>
        <mat-error *ngIf="commandeEnteteForm.get('fraisPortPrevisionnels').hasError('nbMaxDecimals')">Maximum 2 décimales</mat-error>
        <mat-error *ngIf="commandeEnteteForm.get('fraisPortPrevisionnels').hasError('max')">Montant maximum&nbsp;: {{ entete.sousLotZg.montantMaxFraisPort | decimal2 }}&nbsp;€</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="!estCreation()">
      <mat-label>Instructions de commande</mat-label>

      <textarea matInput cdkTextareaAutosize
                formControlName="instructionsCommande"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="512">
      </textarea>
      <mat-hint>Ce champ ne doit <strong>pas</strong> contenir l'adresse de livraison</mat-hint>
      <aide matSuffix>L'adresse de livraison de la commande est celle du «&nbsp;Lieu de livraison&nbsp;» sélectionné en haut de cette fiche, elle ne doit pas être saisie dans ce champ.</aide>
    </mat-form-field>

    <mat-form-field *ngIf="!estCreation()">
      <mat-label>Informations de livraison</mat-label>

      <textarea matInput cdkTextareaAutosize
                formControlName="informationsLivraison"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="512">
      </textarea>
      <mat-hint>Ce champ ne doit <strong>pas</strong> contenir l'adresse de livraison</mat-hint>
      <aide matSuffix>L'adresse de livraison de la commande est celle du «&nbsp;Lieu de livraison&nbsp;» sélectionné en haut de cette fiche, elle ne doit pas être saisie dans ce champ.</aide>
    </mat-form-field>

    <epona-datatable-link-bl label= "Bordereaux de livraison"
                             [listeBL]="listeBordereauxLivraisonLies"
                             *ngIf="listeBordereauxLivraisonLies?.length > 0"></epona-datatable-link-bl>

    <div class="form-actions">
      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogCourriel()"
              *ngIf="!estCreation() && estExterne() && !estRefuseeParOrion()">
        <fa-icon icon="envelope-open"></fa-icon> Consulter courriel
      </button>

      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogEngagementJuridique()"
              *ngIf="!estCreation() && estExterne() && !estBrouillon()">
        <fa-icon icon="envelope-open"></fa-icon> Consulter EJ
      </button>
    </div>

    <div class="form-actions">
      <button type="button"
              mat-raised-button color="primary"
              (click)="execBouton(bouton)"
              [disabled]="isDisabledBouton(bouton)"
              [matTooltip]="bouton.toolTip(limiteBoutons)"
              *ngFor="let bouton of listeBoutons.slice(0, 2)">
        <span [class]="bouton.icone"></span> {{ bouton.libelleTruncate(limiteBoutons) }}
      </button>

      <div *ngIf="listeBoutons.length > 2">
        <button type="button"
                mat-raised-button color="primary"
                [matMenuTriggerFor]="menu">
          <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button type="button"
                  mat-menu-item
                  (click)="execBouton(bouton)"
                  [disabled]="isDisabledBouton(bouton)"
                  *ngFor="let bouton of listeBoutons.slice(2, listeBoutons.length)">
            <fa-icon [icon]="bouton.icone"></fa-icon> {{ bouton.typeBouton }}
          </button>
        </mat-menu>
      </div>
    </div>
  </form>

  <mat-expansion-panel #metaDataPanel *ngIf="!estCreation()" >
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
        les informations supplémentaires
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field *ngIf="entete.idPanierEntete">
      <mat-label>Numéro interne du panier d'origine</mat-label>
      <input matInput
             value="{{ entete.idPanierEntete}}"
             disabled />
    </mat-form-field>

    <epona-meta-data type="creation"
                     [date]="entete.dateCreation"
                     [utilisateur]="entete.utilisateurCreation"
                     *ngIf="entete?.dateCreation">
    </epona-meta-data>

    <epona-meta-data type="modification"
                     [date]="entete.dateModification"
                     [utilisateur]="entete.utilisateurModification"
                     *ngIf="entete?.dateModification">
    </epona-meta-data>

    <ng-template matExpansionPanelContent>
      <epona-commande-changements-etat [commande]="entete">
      </epona-commande-changements-etat>
    </ng-template>
  </mat-expansion-panel>
</mat-card>
