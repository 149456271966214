import {Pipe} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: 'decimal2',
    standalone: false
})
export class Decimal2Pipe extends DecimalPipe {
  transform(value): any {
    return super.transform(value, '1.2-2');
  }
}
