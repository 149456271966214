import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {MessageTool} from '../../commons/MessageTool';
import {Article} from '../../model/epona-api/Article';
import {Observable} from 'rxjs';
import {ArticleSearch} from '../../model/epona-api/ArticleSearch';
import {ArticlePUT} from '../../model/epona-api/ArticlePUT';
import {ArticlePOST} from '../../model/epona-api/ArticlePOST';
import {OnlineService} from '../online.service';
import {liveQuery} from 'dexie';
import {db} from '../database';
import {tap} from 'rxjs/operators';
import {HttpDataWithPagination} from '../../model/http-data-with-pagination';

@Injectable()
export class ArticleService {

  private env = environment;
  private readonly baseUrl: string;

  private isOnline = true;

  constructor(private httpClient: HttpClient,
              private messageTool: MessageTool,
              private httpService: HttpService,
              private onlineService: OnlineService) {
    this.baseUrl = this.env.eponaApiUrl + '/articles';
    onlineService.isOnline.subscribe(r => this.isOnline=r);
  }

  /* ******************** */
  /* Gestion des articles */
  /* ******************** */

  getListeArticlesWithPagination(search: ArticleSearch): Observable<HttpDataWithPagination<Article>> {
    return this.httpService.getListeWithPagination<Article>(this.baseUrl, search);
  }

  getListeArticlesComplete(search: ArticleSearch, saveInDb: boolean = false): Observable<Article[]> {
    if (this.isOnline) {
      return this.httpService.getListeComplete<Article>(this.baseUrl, search).pipe(
        tap(articles => {
          if (saveInDb) {
            db.articles.clear();
            db.articles.bulkAdd(articles).then(r => {
            }).catch(e => {
              console.error('Impossible d\'insérer les articles dans la BDD locale');
              console.error(e);
            });
          }
        })
      );
    } else {
      // @ts-ignore
      return liveQuery<Article[]>(() => {
        if (search.codeGroupe || search.idSousGroupe) {
          const equalityCriteria: { [p: string]: any } = {};
          if (search.codeGroupe) {
            equalityCriteria['sousGroupe.groupe.code'] = search.codeGroupe;
          }
          if (search.idSousGroupe) {
            equalityCriteria['sousGroupe.idSousGroupeArticles'] = search.idSousGroupe;
          }
          return db.articles.where(equalityCriteria).toArray();
        } else {
          return db.articles.toArray();
        }
      });
    }
  }

  getArticle(idArticle: number, fields: string): Observable<Article> {
    const params = this.httpService.buildParams({
      fields: fields
    });

    return this.httpClient.get<Article>(this.baseUrl + '/' + idArticle, {params});
  }

  postArticle(article: Article, utilisation: string): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl, new ArticlePOST(article, utilisation));
  }

  putArticle(article: Article, utilisation: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/' + article.idArticle, new ArticlePUT(article, utilisation));
  }
}
