<h1>Fournisseurs</h1>

<div class="crous-recherche">
  <epona-fournisseurs-filter
                          [filter]="filter"
                            class="crous-recherche-filtre"
                            (filterSubmitted)="onFilterSubmitted($event)"></epona-fournisseurs-filter>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Liste des fournisseurs</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeFournisseurs()"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                      [storeCode]="COLUMNS_STORE_CODE"
                                      [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>

      <table mat-table [dataSource]="dataSource"
             cnousTableExporter [columns]="COLUMNS"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             (matSortChange)="onSortChanged($event)"
             aria-label="Liste de fournisseurs">

        <ng-container matColumnDef="nom">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['nom'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.nom}}</td>
        </ng-container>

        <ng-container matColumnDef="siret">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['siret'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.siret}}</td>
        </ng-container>

        <ng-container matColumnDef="mail">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['mail'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.mail}}</td>
        </ng-container>

        <ng-container matColumnDef="codeClient">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['codeClient'].tooltip">{{ COLUMNS['codeClient'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.codeClient }}</td>
        </ng-container>

        <ng-container matColumnDef="telephone">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['telephone'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.telephone }}</td>
        </ng-container>

        <ng-container matColumnDef="ville">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['ville'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.adresseVille }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/fournisseur', element.idFournisseur]" matTooltip="Modifier le fournisseur">
              <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
            </a>
          </td>
        </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
