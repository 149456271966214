import {Departement} from "../Departement";
import {Famille} from "../Famille";
import {SousFamille} from "../SousFamille";
import {NumberComparison} from "./NumberComparison";
import {Marche} from "../epona-api/Marche";
import {Groupe} from "../epona-api/Groupe";
import {SousGroupe} from "../epona-api/SousGroupe";

export class ArticleFilter {
  rechercheTextuelle: string;
  groupe: Groupe;
  sousGroupe: SousGroupe;
  departement: Departement;
  famille: Famille;
  sousFamille: SousFamille;
  actifVente: boolean;
  colisage: number;
  articleDlc: boolean;
  delaiReceptionDlc: number;
  commandableExterne: number; // peut prendre les valeurs null, -1, 0 et 1
  stockGere: boolean;
  stockGereOperationnellement: boolean;
  marche: Marche;
  comparaisonPrixHt: NumberComparison;
  origineAchat: string;
}
