import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Lieu} from "../../model/epona-api/Lieu";
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {MessageTool} from "../../commons/MessageTool";
import {PanierService} from "../../services/epona/panier.service";
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {PanierLigne} from "../../model/epona-api/panier-ligne";
import {CommandeService} from "../../services/epona/commande.service";
import {CommandeEntete} from "../../model/epona-api/CommandeEntete";

@Component({
    selector: 'epona-panier-entete',
    templateUrl: './panier-entete.component.html',
    styleUrls: ['./panier-entete.component.css'],
    standalone: false
})
export class PanierEnteteComponent implements OnChanges {

  @Input() lieuCourant: Lieu;
  @Input() entete: PanierEntete;
  @Input() lignes: PanierLigne[];
  @Output() readonly enteteSubmitted = new EventEmitter<PanierEntete>();
  @Output() readonly suppressionSubmitted = new EventEmitter<boolean>();
  @Output() readonly generationCommandeSubmitted = new EventEmitter<boolean>();

  enteteAffichee: boolean = true;
  panierEnteteForm: UntypedFormGroup;
  listeCommandes: CommandeEntete[] = [];

  // Permet d'accèder au FormGroup du DOM et de récupérer notamment la propriété submitted
  // Utile pour contrôler s'il y a eu des modidications lors d'un changement de page
  @ViewChild(FormGroupDirective)
  form: FormGroupDirective;

  constructor(private fb: UntypedFormBuilder,
              private panierService: PanierService,
              private commandeService: CommandeService,
              private messageTool: MessageTool,
              public dialog: MatDialog) {
    this.panierEnteteForm =  fb.group({
      idPanierEntete:        fb.control({value:'', disabled:true}),
      fournisseur:           fb.control(null, Validators.required),
      commentaire:           fb.control('')
    });
  }


  loadCommandes() {
    const FIELDS_COMMANDES = 'idCommandeEntete,dateCommande,numeroEj';
    this.panierService.getCommandes(this.entete.idPanierEntete, FIELDS_COMMANDES).subscribe(data => {
      this.listeCommandes = data;
    });
  }

  changerAffichage(): void {
    this.enteteAffichee = !this.enteteAffichee;
  }

  estCreation() {
    return this.entete === undefined;
  }

  droitSaisiePaniers(): boolean {
    return this.panierService.droitSaisie();
  }

  droitSaisieCommandes(): boolean {
    return this.commandeService.droitSaisie();
  }

  isInvalidForm() {
    return this.panierEnteteForm.invalid || !this.lieuCourant || !this.lieuCourant.idLieu;
  }

  onSubmit() {
    if (!this.droitSaisiePaniers()) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else {
      const panierEntete = new PanierEntete();
      panierEntete.lieuDemandeur = this.lieuCourant;
      panierEntete.fournisseur = this.panierEnteteForm.get('fournisseur').value;
      panierEntete.commentaire = this.panierEnteteForm.get('commentaire').value;
      this.enteteSubmitted.emit(panierEntete);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entete']) {
      this.initForm();
    }
  }

  initForm() {
    if (this.entete) {
      // Mise à jour des champs avec les informations récupérées
      this.panierEnteteForm.get('idPanierEntete').setValue(this.entete.idPanierEntete);
      this.panierEnteteForm.get('fournisseur').setValue(this.entete.fournisseur);
      this.panierEnteteForm.get('commentaire').setValue(this.entete.commentaire);
      this.loadCommandes();
    }
  }

  openDialogSuppression(): void {
    // Si le panier est supprimable, ouverture de la fenêtre de confirmation
    if (this.entete.extra.deletable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Suppression du panier",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "Confirmez-vous la suppression de ce panier ?"
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut supprimer le panier
          this.suppressionSubmitted.emit(true);
        }
      });

    // Sinon, affichage des erreurs
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.deletable.details);
    }
  }

  openDialogGenerationCommandes(): void {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.form.dirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de ce panier avant de générer les commandes';
      this.messageTool.sendWarning(message);

    // Sinon ouverture de la fenêtre de confirmation
    } else if (this.entete.extra.commandesGenerables.status) {

      let dialogConfig = new MatDialogConfig();
      dialogConfig.minWidth = '550px';
      dialogConfig.data = {
        title: "Génération des commandes",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: this.buildBodyConfirmationGenerationCommandes()
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut générer les commandes
          this.generationCommandeSubmitted.emit(true);
        }
      });

    // Sinon, affichage des erreurs
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.commandesGenerables.details);
    }
  }

  private

  private buildBodyConfirmationGenerationCommandes(): string {
    const listeSousLots: string[] = [];
    let nbArticlesErreurs: number = 0;

    if (this.lignes) {
      for (const ligne of this.lignes) {
        if (ligne.article && ligne.article.actifAchat && ligne.sousLotZg) {
          const codeSousLot = ligne.sousLotZg.codeMarcheOrion + '.' + ligne.sousLotZg.numeroSousLot;
          if (!listeSousLots.find(code => code === codeSousLot)) {
            listeSousLots.push(codeSousLot);
          }
        } else {
          nbArticlesErreurs++;
        }
      }
    }

    const nbArticlesNonRecents = this.lignes.filter(ligne => ligne.estPrixRecent === false).length;

    let body: string = '';
    if (nbArticlesErreurs > 0) {
      body += `<p class="alert alert-danger">${PanierEnteteComponent.buildMessageErreurs(nbArticlesErreurs)}</p>`;
    }
    if (nbArticlesNonRecents > 0) {
      body += `<p class="alert alert-warning">${PanierEnteteComponent.buildMessageNonRecents(nbArticlesNonRecents)}</p>`;
    }
    body += '<p class="question">Confirmez-vous la génération des commandes correspondant à ce panier&nbsp;?</p>';
    body += `<p>${PanierEnteteComponent.buildMessageCommandes(listeSousLots)}</p>`;

    return body;
  }

  private static buildMessageCommandes(listeSousLots: string[]): string {
    if (listeSousLots.length === 0) {
      return 'Aucune commande ne sera générée';
    } else if (listeSousLots.length === 1) {
      return '1 commande sera générée';
    } else {
      return `${listeSousLots.length} commandes seront générées`;
    }
  }

  private static buildMessageErreurs(nbArticlesErreur: number): string {
    if (nbArticlesErreur === 0) {
      return null;
    } else if (nbArticlesErreur === 1) {
      return '1 article en erreur ne pourra être inclus automatiquement dans une commande et devra être ajouté manuellement (voir colonne «&nbsp;Référence&nbsp;»)';
    } else {
      return `${nbArticlesErreur} articles en erreur ne pourront être inclus automatiquement dans une commande et devront être ajoutés manuellement (voir colonne «&nbsp;Référence&nbsp;»)`;
    }
  }

  public static buildMessageNonRecents(nbArticlesNonRecents: number): string {
    if (nbArticlesNonRecents === 0) {
      return null;
    } else if (nbArticlesNonRecents === 1) {
      return '1 article a un prix possiblement obsolète (voir colonne «&nbsp;PU HT&nbsp;»)';
    } else {
      return `${nbArticlesNonRecents} articles ont un prix possiblement obsolète (voir colonne «&nbsp;PU HT&nbsp;»)`;
    }
  }
}
