import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {BatimentBns} from "../../../model/bns-api/batiment-bns";
import {BnsService} from "../../../services/bns/bns.service";
import {BatimentSearch} from "../../../model/bns-api/batiment-search";
import {LocalStorageHelper} from "../../../helpers/local-storage-helper";

@Component({
    selector: 'epona-select-batiment-bns',
    templateUrl: './select-batiment-bns.component.html',
    styleUrls: ['./select-batiment-bns.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectBatimentBnsComponent }],
    standalone: false
})
export class SelectBatimentBnsComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<BatimentBns> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() afficherInactifs: boolean = false;
  @Output() readonly valueChanged = new EventEmitter<BatimentBns>();

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'batiments-bns';

  formCtrl: FormControl<number>;
  liste: BatimentBns[];
  listeActifs: BatimentBns[];
  listeInactifs: BatimentBns[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnsService: BnsService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new FormControl<number>(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: BatimentBns): void {
    this.formCtrl.setValue(obj ? obj.idBatiment : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): BatimentBns {
    return this.getCurrentItem();
  }
  set value(value: BatimentBns) {
    this.formCtrl.setValue(value ? value.idBatiment : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): BatimentBns {
    return this.liste && this.liste.find(item => item.idBatiment === this.formCtrl.value)
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

    // Sinon récupération de la liste via l'API
    } else {
      const search = new BatimentSearch();
      search.idEtablissement = LocalStorageHelper.getIdEtablissement();
      search.batimentsAutorisesUniquement = true;
      search.fields = 'idBatiment,nom,actif';

      this.bnsService.getListeCompleteBatiments(search).subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.eclaterParActifEtTrier();
  }

  private eclaterParActifEtTrier(): void {
    this.listeActifs   = this.liste.filter(batiment => batiment.actif !== false);
    this.listeInactifs = this.liste.filter(batiment => batiment.actif === false);

    this.tri(this.listeActifs);
    this.tri(this.listeInactifs);
  }

  tri(liste: Array<BatimentBns>) {
    liste.sort(BatimentBns.sort);
  }
}
