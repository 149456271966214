import {InventaireEntete} from "./InventaireEntete";

export class InventaireEntetePut {

  libelle: string;
  commentaire: string;

  constructor(inventaireEntete: InventaireEntete) {
    this.libelle = inventaireEntete.libelle;
    this.commentaire = inventaireEntete.commentaire;
  }
}
