import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {InventaireSearch} from '../../model/epona-api/InventaireSearch';
import {environment} from '../../../environments/environment';
import {InventaireEntete} from '../../model/epona-api/InventaireEntete';
import {InventaireLigne} from '../../model/epona-api/InventaireLigne';
import {Article} from '../../model/epona-api/Article';
import {map, tap} from 'rxjs/operators';
import {InventaireLignePOST} from '../../model/epona-api/InventaireLignePOST';
import {InventaireLignePUT} from '../../model/epona-api/InventaireLignePUT';
import {HttpService} from "../http.service";
import {ArticleDTO} from "../../model/epona-api/ArticleDTO";
import {OnlineService} from "../online.service";
import {db} from "../database";
import {liveQuery} from "dexie";
import {InventaireUtilisateurDexie} from "../../model/epona-api/inventaire-utilisateur-dexie";
import {InventaireComponent} from "../../inventaires/inventaire/inventaire.component";
import {MessageTool} from "../../commons/MessageTool";
import {HttpDataWithPagination} from '../../model/http-data-with-pagination';
import {InventaireEntetePut} from "../../model/epona-api/inventaire-entete-put";
import {InventaireEntetePost} from "../../model/epona-api/inventaire-entete-post";

@Injectable()
export class InventaireService {

  private env = environment;

  private isOnline = true;

  constructor(private httpClient: HttpClient,
              private httpService: HttpService,
              private onlineService: OnlineService,
              private messageTool: MessageTool) {
    onlineService.isOnline.subscribe(r => this.isOnline = r);
  }

  getListeInventairesWithPagination(inventaireSearch: InventaireSearch): Observable<HttpDataWithPagination<InventaireEntete>> {
    if (this.isOnline) {
      return this.httpService.getListeWithPagination<InventaireEntete>(this.env.eponaApiUrl + '/inventaires', inventaireSearch).pipe(
        tap(response => {


          // on filtre pour ne conserver dans la BDD que les inventaires non cloturés
          response.data.filter(res => !res.utilisateurCloture).forEach((inventaire) => {
            db.inventaires.where({idInventaireEntete: inventaire.idInventaireEntete }).delete().then( e=> {
              this.getInventaireEntete(inventaire.idInventaireEntete, InventaireComponent.FIELDS_ENTETE).subscribe();
            });

            db.inventaireLignes.where({'inventaire.idInventaireEntete':inventaire.idInventaireEntete}).delete()
              .then(r => {
                this.getListeLignesInventaire(inventaire.idInventaireEntete, InventaireComponent.FIELDS_LIGNES).subscribe();
              })
              .catch(e => console.error(e));
          });
        })
      );
    } else {
      // @ts-ignore
      return liveQuery<HttpDataWithPagination<InventaireEntete>>(() => {
        return db.inventaires.toArray().then(array => {
          let response = new HttpDataWithPagination<InventaireEntete>();
          response.data = array;
          return response;
        });
      });
    }

  }

  getInventaireEntete(idInventaire: number, fields: string): Observable<InventaireEntete> {
    if (this.isOnline) {
      const params = this.httpService.buildParams({
        fields: fields
      });
      return this.httpClient.get<InventaireEntete>(this.env.eponaApiUrl + '/inventaires/' + idInventaire, {params}).pipe(
        tap(inv => {
              db.inventaires.where({idInventaireEntete: inv.idInventaireEntete }).delete().then( e=> {
                db.inventaires.add({
                  dateCreation: inv.dateCreation,
                  dateModification: inv.dateModification,
                  extra: inv.extra,
                  utilisateurModification: inv.utilisateurModification,
                  idInventaireEntete: inv.idInventaireEntete,
                  libelle: inv.libelle,
                  commentaire: inv.commentaire,
                  utilisateur: inv.utilisateur,
                  dateCloture: inv.dateCloture,
                  utilisateurCloture: inv.utilisateurCloture,
                  lieu: inv.lieu,
                  groupeArticles: inv.groupeArticles,
                  sousGroupeArticles: inv.sousGroupeArticles
                }).catch(e => console.error(e));
              }).catch(e => console.error(e));
        })
      );
    } else {
      return  from(liveQuery<InventaireEntete>(() => db.inventaires.get(idInventaire)));
    }
  }

  postInventaireEntete(inventaireEntete: InventaireEntete): Observable<InventaireEntete> {
    return this.httpClient.post<InventaireEntete>(this.env.eponaApiUrl + '/inventaires', new InventaireEntetePost(inventaireEntete)).pipe(
      tap(r => this.getInventaireEntete(r.idInventaireEntete, InventaireComponent.FIELDS_ENTETE).subscribe())
    );
  }

  putInventaireEntete(inventaireEntete: InventaireEntete): Observable<any> {
    return this.httpClient.put<any>(this.env.eponaApiUrl + '/inventaires/' + inventaireEntete.idInventaireEntete, new InventaireEntetePut(inventaireEntete));
  }

  postClotureInventaire(idInventaire: number): Observable<any> {
    return this.httpClient.post<any>(this.env.eponaApiUrl + '/inventaires/' + idInventaire + '/cloture', '');
  }

  getListeLignesInventaire(idInventaire: number, fields: string): Observable<InventaireLigne[]> {
    if (this.isOnline) {
      const params = this.httpService.buildParams({
        fields: fields
      });
      // On transforme la date au format Date afin que lors de l'ajout d'une ligne, le tri sur la date se fasse correctement
      // https://stackoverflow.com/questions/51122117/deserialise-dates-properly-in-angular-5
      return this.httpClient.get<InventaireLigne[]>(this.env.eponaApiUrl + '/inventaires/' + idInventaire + '/lignes', {params})
        .pipe(map(res => res.map(inventaireLigne => this.formatDate(inventaireLigne))),
          tap(invs => {
            db.inventaireLignes.where('inventaire.idInventaireEntete').equals(idInventaire).delete();
            invs.forEach(r => {
                db.inventaireLignes.add(<InventaireLigne>{
                  idInventaireLigne: r.idInventaireLigne,
                  inventaire: {idInventaireEntete: idInventaire},
                  article: r.article,
                  dlc: r.dlc,
                  quantiteStockTheorique: r.quantiteStockTheorique,
                  pmpHtTheorique: r.pmpHtTheorique,
                  pmpTtcTheorique: r.pmpTtcTheorique,
                  quantiteStockConstatee: r.quantiteStockConstatee,
                  pmpHtConstate: r.pmpHtConstate,
                  pmpTtcConstate: r.pmpTtcConstate,
                  commentaire: r.commentaire,
                }).catch(e => console.error(e));
              });
            })
        );
    } else {
      return  from(liveQuery<InventaireLigne[]>(() => db.inventaireLignes.where('inventaire.idInventaireEntete').equals(idInventaire).toArray()));
    }
  }

  private formatDate(inventaireLigne) {
    inventaireLigne.dlc = inventaireLigne.dlc === undefined ? null : new Date(inventaireLigne.dlc);

    return inventaireLigne;
  }

  postInventaireArticles(idInventaire: number, listeArticles: Array<Article>): Observable<any> {
    if (this.isOnline) {
      return this.httpClient.post<any>(this.env.eponaApiUrl + '/inventaires/' + idInventaire + '/articles', listeArticles.map(article => new ArticleDTO(article))).pipe(
        tap(r => this.getListeLignesInventaire(idInventaire, InventaireComponent.FIELDS_LIGNES).subscribe()));
    } else {
        const articleForm = listeArticles[0];
        return from(db.articles.get(articleForm.idArticle).then( art => {
            db.inventaireLignes.add({
                // @ts-ignore
                inventaire: {idInventaireEntete: idInventaire},
              // @ts-ignore
                article: art,
                quantiteStockTheorique: 0,
                sync: 0
              },
            ).catch(e => console.error(e));
        }));
    }
  }

  postInventaireLignes(idInventaire: number, inventaireLigne: InventaireLigne): Observable<any> {
    return this.httpClient.post<any>(this.env.eponaApiUrl + '/inventaires/' + idInventaire + '/lignes',
      new InventaireLignePOST(inventaireLigne));
  }

  putInventaireLignes(idInventaire: number, inventaireLigne: InventaireLigne): Observable<any> {
    if (this.isOnline) {
      return this.httpClient.put<any>(this.env.eponaApiUrl + '/inventaires/' + idInventaire + '/lignes/'
        + inventaireLigne.idInventaireLigne, new InventaireLignePUT(inventaireLigne)).pipe(tap(r => this.getListeLignesInventaire(idInventaire, InventaireComponent.FIELDS_LIGNES).subscribe()));
    } else {
        return from(db.inventaireLignes.where({'inventaire.idInventaireEntete':idInventaire,'article.idArticle':inventaireLigne.article.idArticle }).first().then(res => {
          res.quantiteStockConstatee = inventaireLigne.quantiteStockConstatee;
          res.pmpHtConstate = inventaireLigne.pmpHtConstate;
          res.pmpTtcConstate = inventaireLigne.pmpTtcConstate;
          res.commentaire = inventaireLigne.commentaire;
          res.sync = 0;
          db.inventaireLignes.put(res, res.id);
        }).catch(e => console.error(e)));
    }
  }

  getListeUtilisateursInventaires(idLieu: number): Observable<string[]> {
    const params = this.httpService.buildParams({idLieu});
    if (this.isOnline) {
      return this.httpClient.get<string[]>(this.env.eponaApiUrl + '/inventaires/utilisateurs', {params}).pipe(
        tap(users => {
          db.inventaireUtilisateur.clear().catch(e => console.error(e));
          users.forEach((u) => {
            db.inventaireUtilisateur.add({ eppn: u }).catch(e => console.error(e));
          });
        })
      );
    } else {
      // @ts-ignore
      return from(liveQuery<InventaireUtilisateurDexie[]>(() => db.inventaireUtilisateur.toArray().catch(e => console.error(e)))).pipe(
        map(res => {
          const tab = [];
          if (res && res.length)
          tab.push(res[0].eppn);
          return tab;})
      );
    }
  }

  deleteInventaire(idInventaire: number) : Observable<any> {
    return this.httpClient.delete(this.env.eponaApiUrl + '/inventaires/' + idInventaire);
  }

  postLigne(inventaireLigne: InventaireLigne[]) {
    const tabInventaireLignePOST : InventaireLignePOST[] = [];
    for (const l of inventaireLigne) {
      tabInventaireLignePOST.push(new InventaireLignePOST(l));
    }
    return this.httpClient.post<any>(this.env.eponaApiUrl + '/inventaires/lignes', tabInventaireLignePOST);
  }


  sync() {
    if (this.isOnline) {
      db.inventaireLignes.where('sync').equals(0).toArray().then(il => {
        if (!il) {
          this.messageTool.sendSuccess('Impossible de lire les lignes');
          return;
        }
        if (il.length == 0) {
          return;
        }
        this.postLigne(il).subscribe(res => {
          this.messageTool.sendSuccess('Les lignes d\'inventaire ont été synchronisées');
          this.updateSync(il);
        });
      }).catch(e => console.error(e));
    }
  }

  private updateSync(il: Array<InventaireLigne>) {
      const idEntetes = [...new Set(il.map(objet => objet.inventaire.idInventaireEntete))];
      for (const inv of idEntetes) {
        this.getListeLignesInventaire(inv, InventaireComponent.FIELDS_LIGNES).subscribe();
      }
  }
}
