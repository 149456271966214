import { Pipe, PipeTransform } from '@angular/core';
import {Article} from "../../model/epona-api/Article";
import {UtilisationArticle} from "../constants/UtilisationArticle";
import {ArticleTools} from "../ArticleTools";

@Pipe({
    name: 'designation',
    standalone: false
})
export class DesignationArticlePipe implements PipeTransform {

  transform(article: Article, utilisation: string = UtilisationArticle.ACHAT, strict: boolean = false): string {
    return ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, article, utilisation, strict);
  }
}
