import {InventaireEntete} from "./InventaireEntete";

export class InventaireEnteteDto {

  idInventaireEntete: number

  constructor(inventaire: InventaireEntete) {
    this.idInventaireEntete = inventaire.idInventaireEntete;
  }
}
