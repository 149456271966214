import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogErrorsData} from "../../model/epona-ui/DialogErrorsData";

@Component({
    selector: 'app-dialog-errors',
    templateUrl: './dialog-errors.component.html',
    styleUrls: ['./dialog-errors.component.css'],
    standalone: false
})
export class DialogErrorsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogErrorsData) { }
}
