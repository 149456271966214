import {Lieu} from './Lieu';
import {LieuFilter} from "../epona-ui/lieu-filter";

export class LieuSearch extends Lieu {

  lieuxAutorisesUniquement: boolean;
  parametrePourOrion: boolean;

  fields: string;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;

  public static fabric(filter: LieuFilter) : LieuSearch {
    const search = new LieuSearch();

    search.masque              = filter.masque;
    search.priseEnCompteVentes = filter.priseEnCompteVentes;
    search.lieuLivraison       = filter.lieuLivraison;
    search.parametrePourOrion  = filter.parametrePourOrion;

    return search;
  }
}
