import {Component, Inject} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {TypeAjoutArticles} from "../../commons/enum/TypeAjoutArticles";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DialogDataAjoutArticlesCommande} from "../../model/epona-ui/DialogDataAjoutArticlesCommande";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CommandeService} from "../../services/epona/commande.service";
import {Article} from "../../model/epona-api/Article";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {PropositionCommandeSearch} from "../../model/epona-api/PropositionCommandeSearch";
import {InformationLieuArticle} from "../../model/InformationLieuArticle";
import {EMPTY, from, Observable} from "rxjs";
import {concatMap, defaultIfEmpty, last} from "rxjs/operators";
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {ArticleMarche} from "../../model/epona-api/ArticleMarche";
import {DialogDataArticleAutreLot} from "../../model/epona-ui/DialogDataArticleAutreLot";
import {DialogArticleAutreLotComponent} from "../dialog-article-autre-lot/dialog-article-autre-lot.component";
import {DesignationArticlePipe} from "../../commons/pipes/designation-article.pipe";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";

@Component({
    selector: 'app-dialog-ajout-articles-commande',
    templateUrl: './dialog-ajout-articles-commande.component.html',
    styleUrls: ['./dialog-ajout-articles-commande.component.css'],
    standalone: false
})
export class DialogAjoutArticlesCommandeComponent {
  typeArticleCtrl: UntypedFormControl;
  articleCtrl: UntypedFormControl;

  nbAjouts = 0;
  nbModifications = 0;
  focusFieldArticle: boolean = false;
  typeAjoutArticles = TypeAjoutArticles;
  listeIdArticleDejaPresents: number[];
  UA = UtilisationArticle;

  constructor(public dialogRef: MatDialogRef<DialogAjoutArticlesCommandeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataAjoutArticlesCommande,
              private messageTool: MessageTool,
              private commandeService: CommandeService,
              public dialog: MatDialog,
              private designationPipe: DesignationArticlePipe) {

    this.listeIdArticleDejaPresents = data.lignes.map<number>(ligne => ligne.article.idArticle);

    this.typeArticleCtrl = new UntypedFormControl(this.estBrouillon() && this.estInterne() ? this.typeAjoutArticles.PROPOSITION_REAPPRO : this.typeAjoutArticles.ARTICLE);
    this.articleCtrl = new UntypedFormControl({
      value: '',
      disabled: this.estBrouillon() && this.estInterne()
    }, Validators.required);
  }

  estBrouillon(): boolean {
    return this.data.entete.etatCommande.codeEtatCommande === CodeEtatCommande.BROUILLON;
  }

  estExterne(): boolean {
    return this.data.entete.externe === true;
  }
  estInterne(): boolean {
    return this.data.entete.externe === false;
  }

  label(): string {
    if (this.estExterne() && this.data.entete.sousLotZg) {
      return `Article du fournisseur ${this.data.entete.fournisseur.nom}`;
    } else {
      return 'Article';
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.typeArticleCtrl.value === this.typeAjoutArticles.PROPOSITION_REAPPRO) {
      this.ajoutPreparationReappro();
    } else {
      this.ajoutArticle();
    }
  }

  disableBoutonAjouter() {
    return this.typeArticleCtrl.value === this.typeAjoutArticles.ARTICLE && !this.articleCtrl.valid;
  }

  changeStateFieldArticle() {
    if (this.typeArticleCtrl.value === this.typeAjoutArticles.ARTICLE) {
      this.articleCtrl.enable();
      this.focusFieldArticle = true;

    } else {
      this.articleCtrl.disable();
      this.focusFieldArticle = false;
    }
  }

  enableFieldArticle() {
    if(this.articleCtrl.disabled) {
      this.articleCtrl.enable();
      this.typeArticleCtrl.setValue(TypeAjoutArticles.ARTICLE);
      this.focusFieldArticle = true;
    }
  }

  private ajoutArticle() {
    const article = new Article();
    if (this.estExterne()) {
      article.idArticle = this.articleCtrl.value.idArticle;
      article.sousLotZg = this.articleCtrl.value.sousLotZg;
    } else {
      article.idArticle = this.articleCtrl.value.idArticle;
    }

    const ligne = new CommandeLigne();
    ligne.article = article;

    this.commandeService.postLigne(this.data.entete.idCommandeEntete, ligne).subscribe(() => {
      // Stockage de l'article ajouté
      this.data.listeArticles = new Array<Article>();
      this.data.listeArticles.push(article);

      this.dialogRef.close();

      const designation = this.designationPipe.transform(this.articleCtrl.value, this.data.utilisation);
      this.messageTool.sendSuccess(`L'article ${designation} a été ajouté avec succès`, ClearMessages.TRUE);

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private ajoutPreparationReappro() {
    // Paramètres de la recherche de propositions de commande
    const search = new PropositionCommandeSearch();
    const date: Date = this.data.entete.dateLivraisonSouhaitee ? new Date(this.data.entete.dateLivraisonSouhaitee) : new Date();
    search.date = date.toISOString().substr(0,10);
    search.idLieu = this.data.entete.lieuDemandeur.idLieu;
    search.fields = 'article.idArticle,stockMini,quantite';

    this.commandeService.getPropositionsCommandes(search).subscribe( data => {
      this.ajoutLignesFromPropositions(data).subscribe(() => {
        // Stockage des articles ajoutés
        this.stockArticleFromPropositions(data);

        this.sendSuccessArticles();
        this.dialogRef.close();

      }, err => {
        this.messageTool.sendError(err);
      });

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private ajoutLignesFromPropositions(informationLieuArticleList: InformationLieuArticle[]): Observable<any> {
    return from(informationLieuArticleList).pipe(
      concatMap(informationLieuArticle => {
        // Récupération de la ligne existant éventuellement pour l'article
        let commandeLigne = this.data.lignes.find(ligne => ligne.article.idArticle === informationLieuArticle.article.idArticle);

        // Si la ligne existe déjà pour l'article'
        if (commandeLigne) {
          // Si la quantité est différente la ligne est modifiée (PUT)
          if (commandeLigne.quantite != informationLieuArticle.quantite) {
            commandeLigne.quantite = informationLieuArticle.quantite;
            this.nbModifications++;
            return this.commandeService.putLigne(this.data.entete.idCommandeEntete, commandeLigne);

            // Sinon un observable vide est tout de même retourné afin que le traitement puisse passer au suivant
          } else {
            return EMPTY;
          }

          // sinon la ligne est créée (POST)
        } else {
          commandeLigne = new CommandeLigne();
          commandeLigne.article = informationLieuArticle.article;
          commandeLigne.quantite = informationLieuArticle.quantite;
          this.nbAjouts++;
          return this.commandeService.postLigne(this.data.entete.idCommandeEntete, commandeLigne);
        }
      }), defaultIfEmpty('defaultValue'), last()
    )
  }

  private sendSuccessArticles() {
    if (this.nbAjouts > 0) {
      let message: string;
      if (this.nbAjouts === 1) {
        message = '1 article a été ajouté à la commande';
      } else {
        message = `${this.nbAjouts} articles ont été ajoutés à la commande`;
      }
      this.messageTool.sendSuccess(message, ClearMessages.TRUE);
    }

    if (this.nbModifications > 0) {
      let message: string;
      if (this.nbModifications === 1) {
        message = '1 article a été modifié';
      } else {
        message = `${this.nbModifications} articles ont été modifiés`;
      }
      this.messageTool.sendSuccess(message, ClearMessages.TRUE);
    }

    if (this.nbAjouts === 0 && this.nbModifications === 0) {
      this.messageTool.sendSuccess('Aucune modification n\'a été effectuée');
    }
  }

  private stockArticleFromPropositions(informationLieuArticleList: Array<InformationLieuArticle>) {
    this.data.listeArticles = new Array<Article>();

    for (const informationLieuArticle of informationLieuArticleList){
      this.data.listeArticles.push(informationLieuArticle.article);
    }
  }

  public handleArticleSelection = (articleMarche: ArticleMarche): boolean => {
    if (!this.data.entete.sousLotZg || this.data.entete.sousLotZg.idSousLotZg === articleMarche.sousLotZg.idSousLotZg ) {
      return true;
    } else {
      this.openDialogArticleAutreLot(articleMarche);
      return false;
    }
  }

  private openDialogArticleAutreLot(articleMarche: ArticleMarche): void {
    let dialogConfig = new MatDialogConfig<DialogDataArticleAutreLot>();
    dialogConfig.width = '500px'
    dialogConfig.data = new DialogDataArticleAutreLot();
    dialogConfig.data.articleMarche = articleMarche;
    dialogConfig.data.commande = this.data.entete;

    this.dialog.open(DialogArticleAutreLotComponent, dialogConfig);
  }
}
