<mat-card class="card-width tdb-matcard">
  <mat-card-header class="card-title">
    <mat-card-title>Inventaires</mat-card-title>

    <a aria-label="Liste des inventaires"
       title="Liste des inventaires"
       class="envoi-link"
       routerLink="/liste-inventaires">
      <mat-icon>open_in_new</mat-icon>
    </a>
  </mat-card-header>

  <table mat-table class="mat-elevation-z8 tdb-table" [dataSource]="dataSource"
         matSort matSortActive="batiment" matSortDirection="asc" matSortDisableClear="true"
         aria-label="Dernier inventaire clôturé et inventaire en cours par lieu">

    <ng-container matColumnDef="batiment">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Bâtiment</th>

      <td mat-cell *matCellDef="let element">{{element.lieu?.nomRu}}</td>
    </ng-container>

    <ng-container matColumnDef="lieu">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Lieu</th>

      <td mat-cell *matCellDef="let element">{{element.lieu?.nomUd}}</td>
    </ng-container>

    <ng-container matColumnDef="dateDernierInventaireValide">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>Dernier complet clôturé</th>

      <td mat-cell *matCellDef="let data">
        <span *ngIf="data.dernierInventaireValide?.idInventaireEntete == null">aucun</span>
        <a *ngIf="data.dernierInventaireValide?.idInventaireEntete != null"
           [routerLink]="['/inventaire', data.dernierInventaireValide.idInventaireEntete]">{{data.dernierInventaireValide?.dateCloture | dateFormat}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbInventairesNonValides">
      <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>En cours</th>

      <td mat-cell *matCellDef="let data">
        <div [hidden]="data.nbInventairesNonValides > 0"> - </div>
        <a [routerLink]="['/liste-inventaires']"
           [queryParams]="{idLieu: data.lieu.idLieu, cloture: false}"
           [hidden]="data.nbInventairesNonValides === 0">{{ data.nbInventairesNonValides }}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </table>

  <mat-paginator class="tdb-table" #paginator [pageSize]="3" hidePageSize="true"></mat-paginator>
</mat-card>
