import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatPaginator} from '@angular/material/paginator';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {MatSort} from "@angular/material/sort";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {TypeMouvement} from "../../model/TypeMouvement";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {SortHelper} from "../../helpers/sort-helper";
import {CodeStatutES} from "../../commons/constants/CodeStatutES";

export class Sortie {
  lieu: Lieu;
  nombreNonValide: number;
  libelle: string;
  codeTypeMouvement: string;
}

@Component({
    selector: 'epona-card-sorties-non-validee',
    templateUrl: './card-sorties-non-validee.component.html',
    styleUrls: ['./card-sorties-non-validee.component.css'],
    standalone: false
})
export class CardSortiesNonValideeComponent implements AfterViewInit, OnChanges {
  displayedColumns: string[] = ['batiment', 'lieu', 'typeMouvement', 'nombreNonValide'];

  listeSorties: Array<Sortie>;
  dataSource = new MatTableDataSource<NbSortiesNonValideesParLieuTypeHTML>([]);

  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  test = [];

  constructor(private tdbService: TableauBordService) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lieuCourant']) {
      this.rempliListeSorties();
    }
  }

  rempliListeSorties() {
    // commencer par l'initialisation de la liste
    this.listeSorties = new Array<Sortie>();
    this.dataSource.data = [];

    const entreeSortieSearch = new EntreeSortieSearch();
    entreeSortieSearch.idCrous       = this.lieuCourant.idCrous;
    entreeSortieSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    entreeSortieSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    entreeSortieSearch.idRu          = this.lieuCourant.idRu;
    entreeSortieSearch.idUd          = this.lieuCourant.idUd;
    entreeSortieSearch.codesStatut   = [CodeStatutES.NON_VALIDE];

    this.tdbService.getEntreesSorties(entreeSortieSearch).subscribe(data => {
      const liste: NbSortiesNonValideesParLieuTypeHTML[] = [];
      for (const dataUnit of data) {
        for (const detailUnit of dataUnit.detail) {
          if (detailUnit.typeMouvement.codeTypeMouvement != CodeTypeMouvement.APPROVISIONNEMENT) {
            const item = new NbSortiesNonValideesParLieuTypeHTML();
            item.lieu = dataUnit.lieu;
            item.type = detailUnit.typeMouvement;
            item.nombre = detailUnit.nombreNonValide;
            liste.push(item);
          }
        }
      }
      this.dataSource.data = liste;
    });
  }

  getRouterLink(element: NbSortiesNonValideesParLieuTypeHTML): string {
    return EntreeSortieParametrage.getParametrageFromType(element.type).commonListPath
  }
  getQueryParams(element: NbSortiesNonValideesParLieuTypeHTML) {
    return {
      idLieu: element.lieu.idLieu,
      codeTypeMouvement: element.type.codeTypeMouvement,
      valide: false
    };
  }

  sortingDataAccessor(data: NbSortiesNonValideesParLieuTypeHTML, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'batiment'        : return SortHelper.stringToString(data.lieu?.nomRu)
      case 'lieu'            : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'typeMouvement'   : return data.type?.codeTypeMouvement;
      case 'nombreNonValide' : return data.nombre;
    }
  }
}

export class NbSortiesNonValideesParLieuTypeHTML {
  lieu: Lieu;
  type: TypeMouvement;
  nombre: number;
}
