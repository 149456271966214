import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";
import {MatTableDataSource} from "@angular/material/table";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {ServiceFaitLigne} from "../../model/epona-api/ServiceFaitLigne";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {MessageTool} from "../../commons/MessageTool";
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
    selector: 'epona-service-fait-lignes',
    templateUrl: './service-fait-lignes.component.html',
    styleUrls: ['./service-fait-lignes.component.css'],
    standalone: false
})
export class ServiceFaitLignesComponent implements OnInit, OnChanges {

  @Input() livraison: EntreeSortieEntete;
  @Input() entete: ServiceFaitEntete;
  @Input() modifiable: boolean;

  @Output() isDirty = new EventEmitter<boolean>();

  dataSource = new MatTableDataSource<ServiceFaitLigne>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    numeroLigneEj:            new TableColumn({label: 'Ligne EJ', hiddable: false, tooltip: 'Numéro de ligne d\'EJ', exportFormat: 'integer'}),
    familleMarches:           new TableColumn({label: 'Famille de marchés'}),
    compteComptable:          new TableColumn({label: 'CC', tooltip: 'Compte comptable'}),
    natureBudgetaire:         new TableColumn({label: 'Nat. budg.', tooltip: 'Nature budgétaire'}),
    tauxTva:                  new TableColumn({label: 'Taux TVA', exportFormat: 'decimal'}),
    destination:              new TableColumn({label: 'Destination', default: false}),
    operation:                new TableColumn({label: 'Opération',   default: false}),
    serviceGestionnaire:      new TableColumn({label: 'Serv. gest.', default: false, tooltip: 'Service gestionnaire'}),
    elementAxeComplementaire: new TableColumn({label: 'Axe compl.',  default: false, tooltip: 'Élément d\'axe complémentaire'}),
    evenement:                new TableColumn({label: 'Événement',   default: false}),
    typeTva:                  new TableColumn({label: 'Type TVA',    default: false, tooltip: 'Type de TVA'}),
    montantHt:                new TableColumn({label: 'HT',  hiddable: false, tooltip: 'Montant HT', exportFormat: 'decimal'}),
    montantTva:               new TableColumn({label: 'TVA', hiddable: false, tooltip: 'Montant TVA', exportFormat: 'decimal'}),
    montantTtc:               new TableColumn({label: 'TTC', hiddable: false, tooltip: 'Montant TTC', exportFormat: 'decimal'})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_SERVICE_FAIT;

  private readonly FIELDS = '' +
    'ligneEj.numeroLigne,' +
    'ligneEj.cumul.familleMarches,' +
    'ligneEj.cumul.compteComptable,' +
    'ligneEj.cumul.natureBudgetaire,' +
    'ligneEj.cumul.tva,' +
    'ligneEj.destination,' +
    'ligneEj.operation,' +
    'ligneEj.serviceGestionnaire,' +
    'ligneEj.elementAxeComplementaire,' +
    'ligneEj.evenement,' +
    'ligneEj.typeTva,' +
    'montantHt,' +
    'montantTva' +
    '';

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  totalMontantHt: number = 0;
  totalMontantTva: number = 0;

  constructor(private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool) { }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ServiceFaitLignesComponent.sortingDataAccessor;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['livraison'] && this.livraison) {
      this.loadLignesSF();
    }

    // S'il s'agit d'un rechargement du service fait (suite à l'envoi vers Orion par exemple)
    if (changes['entete'] && !changes['entete'].firstChange && changes['entete'].previousValue) {
      this.loadLignesSF();
    }
  }

  private loadLignesSF() {
    this.entreeSortieService.getServiceFaitLignes(this.livraison.idEntreeSortieEntete, this.FIELDS).subscribe(data => {
      this.dataSource.data = data;
      for (let ligneSF of data) {
        this.totalMontantHt  += ligneSF.montantHt;
        this.totalMontantTva += ligneSF.montantTva;
      }
      this.setDisplayedColumns();
    }, error => {
      this.messageTool.sendError(error);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private static sortingDataAccessor(data: ServiceFaitLigne, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'numeroLigneEj':            return data.ligneEj.numeroLigne;
      case 'familleMarches':           return SortHelper.stringToString(data.ligneEj.cumul.familleMarches.libelle);
      case 'compteComptable':          return data.ligneEj.cumul.compteComptable.numero;
      case 'natureBudgetaire':         return data.ligneEj.cumul.natureBudgetaire.code;
      case 'tauxTva':                  return data.ligneEj.cumul.tva.taux;
      case 'destination':              return SortHelper.stringToString(data.ligneEj.destination.libelle);
      case 'operation':                return SortHelper.stringToString(data.ligneEj.operation?.libelle);
      case 'serviceGestionnaire':      return SortHelper.stringToString(data.ligneEj.serviceGestionnaire.libelle);
      case 'elementAxeComplementaire': return SortHelper.stringToString(data.ligneEj.elementAxeComplementaire?.libelle);
      case 'evenement':                return SortHelper.stringToString(data.ligneEj.evenement?.libelle);
      case 'typeTva':                  return SortHelper.stringToString(data.ligneEj.typeTva.libelle);
      case 'montantHt':                return data.montantHt;
      case 'montantTva':               return data.montantTva;
      case 'montantTtc':               return data.montantHt + data.montantTva;
    }
  }
}
