import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataStocks} from "../../model/epona-ui/DialogDataStocks";
import {StockService} from "../../services/epona/stock.service";
import {MessageTool} from "../../commons/MessageTool";
import {StockSearch} from "../../model/epona-api/StockSearch";
import {Stock} from "../../model/epona-api/Stock";

@Component({
    selector: 'app-dialog-detail-dlc',
    templateUrl: './dialog-detail-dlc.component.html',
    styleUrls: ['./dialog-detail-dlc.component.css'],
    standalone: false
})
export class DialogDetailDlcComponent implements OnInit {

  dataSource = new MatTableDataSource<Stock>([]);
  displayedColumns: string[] = ['dlc', 'quantite', 'valeurHt', 'valeurTtc'];

  constructor(public dialog: MatDialogRef<DialogDetailDlcComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataStocks,
              private stockService: StockService,
              private messageTool: MessageTool) {
  }

  ngOnInit(): void {
    const fields = 'dlc,quantite,pmpHt,pmpTtc';

    const search = new StockSearch();
    search.idLieu = this.data.stockCompact.lieu.idLieu;
    search.idArticle = this.data.stockCompact.article.idArticle;
    search.fields = fields;

    this.stockService.getListeStocks(search).subscribe((data) => {
      // Ordonnancement des lignes selon la DLC (DLC vide en dernière)
      data.sort((l1, l2) => {
        return !l1.dlc || l1.dlc > l2.dlc ? 1 : -1;
      });

      this.dataSource.data = data;

    }, err => {
      this.messageTool.sendError(err);
    });
  }
}
