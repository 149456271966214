import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {CodeEtatCommande} from '../../commons/constants/CodeEtatCommande';
import {CommandeSearch} from '../../model/epona-api/CommandeSearch';
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";

export class CommandesNonFermees {
  lieu: Lieu;
  nbBrouillon: number = 0;
  nbEnPreparationEJ: number = 0;
  nbEnAttenteRetourOrion: number = 0;
  nbEnPreparationFournisseur: number = 0;
  nbEnReception: number = 0;
}

@Component({
    selector: 'epona-card-commandes',
    templateUrl: './card-commandes.component.html',
    styleUrls: ['./card-commandes.component.css'],
    standalone: false
})
export class CardCommandesComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = ['batiment', 'lieu', 'nbBrouillon', 'nbEnPreparationEJ', 'nbEnAttenteRetourOrion', 'nbEnPreparationFournisseur', 'nbEnReception'];

  dataSource = new MatTableDataSource<CommandesNonFermees>([]);

  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  CodeEtatCommande = CodeEtatCommande;

  constructor(private tdbService: TableauBordService) {
  }

  ngOnInit(): void {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingdataAccessor;
  }

  ngAfterViewInit(): void {
   this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lieuCourant']) {
      this.rempliListeCommandesATraiter();
    }
  }

  rempliListeCommandesATraiter() {
    const listeCommandesNonFermees = new Array<CommandesNonFermees>();

    const commandeSearch = new CommandeSearch();
    commandeSearch.idCrous       = this.lieuCourant.idCrous;
    commandeSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    commandeSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    commandeSearch.idRu          = this.lieuCourant.idRu;
    commandeSearch.idUd          = this.lieuCourant.idUd;

    this.tdbService.getCommandesParEtatLieu(commandeSearch).subscribe(data => {
      for (const dataUnit of data) {
        const commandesNonFermees = new CommandesNonFermees();
        if (dataUnit.detail) {
          for (const commande of dataUnit.detail) {
            switch (commande.etatCommande.codeEtatCommande) {
              case CodeEtatCommande.BROUILLON :
                commandesNonFermees.nbBrouillon = commande.nombre;
                break;
              case CodeEtatCommande.PREPARATION_EJ :
                commandesNonFermees.nbEnPreparationEJ = commande.nombre;
                break;
              case CodeEtatCommande.EN_ATTENTE_RETOUR_ORION :
                commandesNonFermees.nbEnAttenteRetourOrion = commande.nombre;
                break;
              case CodeEtatCommande.EN_PREPARATION :
                commandesNonFermees.nbEnPreparationFournisseur = commande.nombre;
                break;
              case CodeEtatCommande.EN_RECEPTION :
                commandesNonFermees.nbEnReception = commande.nombre;
                break;
              default:
            }
          }
          if (commandesNonFermees.nbBrouillon > 0
            || commandesNonFermees.nbEnPreparationEJ > 0
            || commandesNonFermees.nbEnAttenteRetourOrion > 0
            || commandesNonFermees.nbEnPreparationFournisseur > 0
            || commandesNonFermees.nbEnReception > 0) {
            commandesNonFermees.lieu = dataUnit.lieu;
            listeCommandesNonFermees.push(commandesNonFermees);
          }
        }
      }
       this.dataSource.data = listeCommandesNonFermees;
    });
  }

  sortingdataAccessor(data: CommandesNonFermees, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'batiment'                  : return SortHelper.stringToString(data.lieu?.nomRu)
      case 'lieu'                      : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'nbBrouillon'               : return data.nbBrouillon;
      case 'nbEnPreparationEJ'         : return data.nbEnPreparationEJ;
      case 'nbEnAttenteRetourOrion'    : return data.nbEnAttenteRetourOrion;
      case 'nbEnPreparationFournisseur': return data.nbEnPreparationFournisseur;
      case 'nbEnReception'             : return data.nbEnReception;
      default                          : return data[sortHeaderId];
    }
  }
}
