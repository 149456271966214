import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {TypeMouvement} from "../TypeMouvement";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";

export class EntreeSortieParametrage extends Object {

  // Map permettant de récupérer le paramétrage correspondant à un codeTypeMouvement
  private static mapTypeToParametrage: Record<string, EntreeSortieParametrage> = {};

  static readonly BORDEREAUX_LIVRAISON = new EntreeSortieParametrage({
    commonTypeMouvementList:  [CodeTypeMouvement.APPROVISIONNEMENT],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      true,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     false,
    commonListPath:               '/liste-bordereaux-livraison',
    commonCreationPath:           null,
    commonEditPath:               '/bordereau-livraison',
    commonLabel:                  'BL',
    commonDroitConsultation:      CodeDroit.LIVRAISONS_CONSULTATION,
    commonDroitSaisie:            CodeDroit.LIVRAISONS_SAISIE,
    commonDroitValidation:        CodeDroit.LIVRAISONS_VALIDATION,

    filterTypeMouvementLabel: '',
    filterFiltersBLDisplayed: true,
    filterDateMinLabel:       'Créés depuis le',
    filterValideLabel:        'Validés',
    filterValideDefaultLabel: 'Tous',

    listTitle:                 'Bordereaux de livraison',
    listListTitle:             'Liste des bordereaux de livraison',
    listCreateButtonDisplayed: false,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de livraison',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',                tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment de livraison'}),
      lieu:                   new TableColumn({label: 'Lieu de livraison'}),
      commande:               new TableColumn({label: 'Commande',           default: false}),
      fournisseur:            new TableColumn({label: 'Fournisseur / Origine'}),
      lotMarche:              new TableColumn({label: 'Lot-marché',         default: false}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateBordereauLivraison: new TableColumn({label: 'Date BL', exportFormat: 'date'}),
      codeBordereauLivraison: new TableColumn({label: 'Code-BL'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      etapeOrion:             new TableColumn({label: 'Étape Orion',        tooltip: 'Étape de l\'envoi du service fait vers Orion'}),
      pdf:                    new TableColumn({label: 'PDF',                default: false, export: false}),
      actions:                new TableColumn({label: 'Actions',            export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_LIVRAISONS,
    listeExportFileName:       'liste-bordereaux-livraison',
    listEditButtonTooltip:     'Modifier ce bordereau de livraison',
    listViewButtonTooltip:     'Consulter ce bordereau de livraison',

    editCreationTitle:              'Création d\'un bordereau de livraison',
    editModificationTitle:          'Modification d\'un bordereau de livraison',
    editConsultationTitle:          'Consultation d\'un bordereau de livraison',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      false,
    editArticlesEnStockOnly:        false,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:              new TableColumn({label: 'Sélection',           hiddable: false, export: false}),
      codeArticle:           new TableColumn({label: 'Code'}),
      referenceFournisseur:  new TableColumn({label: 'Référence',                                                     tooltip: 'Référence-fournisseur'}),
      designation:           new TableColumn({label: 'Désignation',         hiddable: false}),
      dlc:                   new TableColumn({label: 'DLC',                                  exportFormat: 'date'}),
      quantiteCommandee:     new TableColumn({label: 'Qtt. commandée',                       exportFormat: 'decimal'}),
      quantiteReceptionnee:  new TableColumn({label: 'Qtt. déjà récept.',   default: false,  exportFormat: 'decimal', tooltip: 'Quantité déjà réceptionnée (BL validés uniquement)'}),
      quantiteAReceptionner: new TableColumn({label: 'Qtt. à réceptionner', default: false,  exportFormat: 'decimal', tooltip: 'Quantité restant à réceptionner'}),
      quantiteAttendue:      new TableColumn({label: 'Qtt. envoyée',                         exportFormat: 'decimal', tooltip: 'Quantité envoyée'}),
      quantite:              new TableColumn({label: 'Qtt. livrée',                          exportFormat: 'decimal', tooltip: 'Quantité livrée'}),
      uniteExploitation:     new TableColumn({label: 'Unité'}),
      prixHt:                new TableColumn({label: 'PU HT',                                exportFormat: 'decimal', tooltip: 'Prix unitaire HT'}),
      montantHt:             new TableColumn({label: 'Mtt. HT',                              exportFormat: 'decimal', tooltip: 'Montant HT'}),
      tauxTva:               new TableColumn({label: 'Taux TVA',                             exportFormat: 'decimal', tooltip: 'Taux de TVA'}),
      montantTtc:            new TableColumn({label: 'Mtt. TTC',                             exportFormat: 'decimal', tooltip: 'Montant TTC'}),
      ecart:                 new TableColumn({label: 'Écart',                                exportFormat: 'decimal'}),
      ecartDetaille:         new TableColumn({label: 'Écart détaillé',                       exportFormat: 'decimal'}),
      corrections:           new TableColumn({label: 'Correction',          hiddable: false, export: false}),
      gestionLots:           new TableColumn({label: 'Lots',                                 export: false,           tooltip: 'Gestion des lots'}),
      fdnc:                  new TableColumn({label: 'FDNC',                                 export: false})
    },
    editColumnsStoreCode:           null, // Dépend du type de BL (externe, interne, transfert)
    editExportFileName:             'lignes-bordereau-livraison',
    editQuantitesPositives:         true,
    editQuantiteLabel:              'Quantité réceptionnée',
    editQuantiteZeroInterdite:      false,
    editFdncDisplayed:              true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de livraison avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de livraison ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de livraison ?',
    editCreationSuccessMsg:         'Le bordereau de livraison a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de livraison a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de livraison a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de livraison a été supprimé avec succès'
  });


  static readonly TRANSFERTS = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.TRANSFERT],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     true,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     false,
    commonListPath:               '/liste-bordereaux-transfert',
    commonCreationPath:           '/creation-bordereau-transfert',
    commonEditPath:               '/bordereau-transfert',
    commonLabel:                  'Transfert',
    commonDroitConsultation:      CodeDroit.GESTION_TRANSFERTS,
    commonDroitSaisie:            CodeDroit.GESTION_TRANSFERTS,
    commonDroitValidation:        CodeDroit.GESTION_TRANSFERTS,

    filterTypeMouvementLabel:     '',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Bordereaux de transfert',
    listListTitle:             'Liste des bordereaux de transfert',
    listCreateButtonDisplayed: true,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de transfert',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment d\'origine'}),
      lieu:                   new TableColumn({label: 'Lieu d\'origine'}),
      batimentDestination:    new TableColumn({label: 'Bâtiment destination'}),
      lieuDestination:        new TableColumn({label: 'Lieu destination'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_TRANSFERTS,
    listeExportFileName:       'liste-bordereaux-transfert',
    listEditButtonTooltip:     'Modifier ce bordereau de transfert',
    listViewButtonTooltip:     'Consulter ce bordereau de transfert',

    editCreationTitle:              'Création d\'un bordereau de transfert',
    editModificationTitle:          'Modification d\'un bordereau de transfert',
    editConsultationTitle:          'Consultation d\'un bordereau de transfert',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    true,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        true,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',   hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation', hiddable: false}),
      quantiteEnStock:   new TableColumn({label: 'Quantité en stock', exportFormat: 'decimal'}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantite:          new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      corrections:       new TableColumn({label: 'Correction',  hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_TRANSFERTS,
    editExportFileName:             'lignes-bordereau-transfert',
    editQuantitesPositives:         false,
    editQuantiteLabel:              'Quantité',
    editQuantiteZeroInterdite:      true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de transfert avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de transfert ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de transfert ?',
    editCreationSuccessMsg:         'Le bordereau de transfert a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de transfert a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de transfert a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de transfert a été supprimé avec succès'
  });


  static readonly SORTIES_EVENEMENTIELLES = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.SORTIE_EVENEMENTIELLE],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     true,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     true,
    commonListPath:               '/liste-bordereaux-sortie',
    commonCreationPath:           '/creation-bordereau-sortie',
    commonEditPath:               '/bordereau-sortie',
    commonLabel:                  'Sortie',
    commonDroitConsultation:      CodeDroit.GESTION_TRANSFERTS,
    commonDroitSaisie:            CodeDroit.GESTION_TRANSFERTS,
    commonDroitValidation:        CodeDroit.GESTION_TRANSFERTS,

    filterTypeMouvementLabel:     '',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Bordereaux de sortie évènementielle',
    listListTitle:             'Liste des bordereaux de sortie évènementielle',
    listCreateButtonDisplayed: true,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de sortie évènementielle',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',              tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment d\'origine'}),
      lieu:                   new TableColumn({label: 'Lieu d\'origine'}),
      lieuEvenement:          new TableColumn({label: 'Lieu évènement'}),
      dateEvenement:          new TableColumn({label: 'Date évènement', exportFormat: 'date'}),
      referenceEvenement:     new TableColumn({label: 'Référence'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',          export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_SORTIES_EVEN,
    listeExportFileName:       'liste-bordereaux-sortie-evenementielle',
    listEditButtonTooltip:     'Modifier ce bordereau de sortie évènementielle',
    listViewButtonTooltip:     'Consulter ce bordereau de sortie évènementielle',

    editCreationTitle:              'Création d\'un bordereau de sortie évènementielle',
    editModificationTitle:          'Modification d\'un bordereau de sortie évènementielle',
    editConsultationTitle:          'Consultation d\'un bordereau de sortie évènementielle',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      true,
    editDateEvenementRequired:      true,
    editChampsEvenementDisabled:    false,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        true,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',   hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation', hiddable: false}),
      quantiteEnStock:   new TableColumn({label: 'Quantité en stock', exportFormat: 'decimal'}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantite:          new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      corrections:       new TableColumn({label: 'Correction',  hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_SORTIES_EVEN,
    editExportFileName:             'lignes-bordereau-sortie-evenementielle',
    editQuantitesPositives:         false,
    editQuantiteLabel:              'Quantité',
    editQuantiteZeroInterdite:      true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de sortie évènementielle avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de sortie évènementielle ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de sortie évènementielle ?',
    editCreationSuccessMsg:         'Le bordereau de sortie évènementielle a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de sortie évènementielle a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de sortie évènementielle a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de sortie évènementielle a été supprimé avec succès'
  });


  static readonly RETOURS_SORTIES = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.RETOUR_SORTIE],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    true,
    commonFieldsEvtDisplayed:     true,
    commonListPath:               '/liste-retours-sorties',
    commonCreationPath:           null,
    commonEditPath:               '/retour-sortie',
    commonLabel:                  'Retour de sortie',
    commonDroitConsultation:      CodeDroit.GESTION_TRANSFERTS,
    commonDroitSaisie:            CodeDroit.GESTION_TRANSFERTS,
    commonDroitValidation:        CodeDroit.GESTION_TRANSFERTS,

    filterTypeMouvementLabel:     '',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Retours de sortie',
    listListTitle:             'Liste des retours de sortie',
    listCreateButtonDisplayed: false,
    listCreateButtonTooltip:   '',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment'}),
      lieu:                   new TableColumn({label: 'Lieu de stockage'}),
      lieuEvenement:          new TableColumn({label: 'Lieu évènement'}),
      dateEvenement:          new TableColumn({label: 'Date évènement', exportFormat: 'date'}),
      referenceEvenement:     new TableColumn({label: 'Référence'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_RETOURS_SOR,
    listeExportFileName:       'liste-retours-sortie',
    listEditButtonTooltip:     'Modifier ce retour de sortie',
    listViewButtonTooltip:     'Consulter ce retour de sortie',

    editCreationTitle:              '',
    editModificationTitle:          'Retour de sortie',
    editConsultationTitle:          'Retour de sortie',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        true,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     false,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',          hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation',        hiddable: false}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantiteSortie:    new TableColumn({label: 'Quantité sortie', exportFormat: 'decimal'}),
      quantite:          new TableColumn({label: 'Quantité retournée', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      corrections:       new TableColumn({label: 'Correction',         hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_RETOURS_SOR,
    editExportFileName:             'lignes-retour-sortie',
    editQuantitesPositives:         true,
    editQuantiteLabel:              'Quantité retournée',
    editQuantiteZeroInterdite:      false,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce retour de sortie avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce retour de sortie ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce retour de sortie ?',
    editCreationSuccessMsg:         '',
    editModificationSuccessMsg:     'Le retour de sortie a été modifié avec succès',
    editValidationSuccessMsg:       'Le retour de sortie a été validé avec succès',
    editSuppressionSuccessMsg:      'Le retour de sortie a été supprimé avec succès'
  });


  static readonly PERTES = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.PERIMES, CodeTypeMouvement.CASSE, CodeTypeMouvement.VOL, CodeTypeMouvement.JETES],
    commonTypeMouvementDisplayed: true,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     false,
    commonListPath:               '/liste-pertes',
    commonCreationPath:           '/creation-pertes',
    commonEditPath:               '/pertes',
    commonLabel:                  'Pertes',
    commonDroitConsultation:      CodeDroit.GESTION_PERTES,
    commonDroitSaisie:            CodeDroit.GESTION_PERTES,
    commonDroitValidation:        CodeDroit.GESTION_PERTES,

    filterTypeMouvementLabel:     'Type de perte',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Pertes',
    listListTitle:             'Liste des bordereaux de pertes',
    listCreateButtonDisplayed: true,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de pertes',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment'}),
      lieu:                   new TableColumn({label: 'Lieu de stockage'}),
      typeMouvement:          new TableColumn({label: 'Type'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_PERTES,
    listeExportFileName:       'liste-bordereaux-pertes',
    listEditButtonTooltip:     'Modifier ce bordereau de pertes',
    listViewButtonTooltip:     'Consulter ce bordereau de pertes',

    editCreationTitle:              'Création d\'un bordereau de pertes',
    editModificationTitle:          'Modification d\'un bordereau de pertes',
    editConsultationTitle:          'Consultation d\'un bordereau de pertes',
    editTypeMouvementLabel:         'Type de perte',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        true,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',   hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation', hiddable: false}),
      quantiteEnStock:   new TableColumn({label: 'Quantité en stock', exportFormat: 'decimal'}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantite:          new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      corrections:       new TableColumn({label: 'Correction',  hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_PERTES,
    editExportFileName:             'lignes-bordereau-pertes',
    editQuantitesPositives:         false,
    editQuantiteLabel:              'Quantité',
    editQuantiteZeroInterdite:      true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de pertes avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de pertes ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de pertes ?',
    editCreationSuccessMsg:         'Le bordereau de pertes a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de pertes a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de pertes a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de pertes a été supprimé avec succès'
  });


  static readonly CONSOMMATIONS_UTILISATIONS = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.CONSOMMATION_UTILISATION],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     false,
    commonListPath:               '/liste-consommations-utilisations',
    commonCreationPath:           '/creation-consommations-utilisations',
    commonEditPath:               '/consommations-utilisations',
    commonLabel:                  'Conso./utilisation',
    commonDroitConsultation:      CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS,
    commonDroitSaisie:            CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS,
    commonDroitValidation:        CodeDroit.GESTION_CONSOMMATIONS_UTILISATIONS,

    filterTypeMouvementLabel:     '',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Consommations/utilisations',
    listListTitle:             'Liste des bordereaux de consommations/utilisations',
    listCreateButtonDisplayed: true,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de consommations/utilisations',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment'}),
      lieu:                   new TableColumn({label: 'Lieu de stockage'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_CONSO_UTIL,
    listeExportFileName:       'liste-bordereaux-consommations-utilisations',
    listEditButtonTooltip:     'Modifier ce bordereau de consommations/utilisations',
    listViewButtonTooltip:     'Consulter ce bordereau de consommations/utilisations',

    editCreationTitle:              'Création d\'un bordereau de consommations/utilisations',
    editModificationTitle:          'Modification d\'un bordereau de consommations/utilisations',
    editConsultationTitle:          'Consultation d\'un bordereau de consommations/utilisations',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        true,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',   hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation', hiddable: false}),
      quantiteEnStock:   new TableColumn({label: 'Quantité en stock', exportFormat: 'decimal'}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantite:          new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      corrections:       new TableColumn({label: 'Correction',  hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_CONSO_UTIL,
    editExportFileName:             'lignes-bordereau-consommations-utilisations',
    editQuantitesPositives:         false,
    editQuantiteLabel:              'Quantité',
    editQuantiteZeroInterdite:      true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de consommations/utilisations avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de consommations/utilisations ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de consommations/utilisations ?',
    editCreationSuccessMsg:         'Le bordereau de consommations/utilisations a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de consommations/utilisations a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de consommations/utilisations a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de consommations/utilisations a été supprimé avec succès'
  });


  static readonly REINTEGRATION = new EntreeSortieParametrage({
    commonTypeMouvementList:      [CodeTypeMouvement.REINTEGRATION],
    commonTypeMouvementDisplayed: false,
    commonFieldsBLDisplayed:      false,
    commonFieldsTRFDisplayed:     false,
    commonFieldsSORDisplayed:     false,
    commonFieldsRSORDisplayed:    false,
    commonFieldsEvtDisplayed:     false,
    commonListPath:               '/liste-reintegrations',
    commonCreationPath:           '/creation-reintegrations',
    commonEditPath:               '/reintegrations',
    commonLabel:                  'Réintégration',
    commonDroitConsultation:      CodeDroit.GESTION_REINTEGRATIONS,
    commonDroitSaisie:            CodeDroit.GESTION_REINTEGRATIONS,
    commonDroitValidation:        CodeDroit.GESTION_REINTEGRATIONS,

    filterTypeMouvementLabel:     '',
    filterDateMinLabel:           'Créés depuis le',
    filterValideLabel:            'Validés',
    filterValideDefaultLabel:     'Tous',

    listTitle:                 'Réintégrations',
    listListTitle:             'Liste des bordereaux de réintégrations',
    listCreateButtonDisplayed: true,
    listCreateButtonTooltip:   'Créer un nouveau bordereau de réintégrations',
    listColumns: {
      idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
      batiment:               new TableColumn({label: 'Bâtiment'}),
      lieu:                   new TableColumn({label: 'Lieu de stockage'}),
      dateCreation:           new TableColumn({label: 'Création', exportFormat: 'date'}),
      dateValidation:         new TableColumn({label: 'Validation', exportFormat: 'datetime'}),
      actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
    },
    listeColumnsStoreCode:     CodeStockageColonnes.ES_REINTEG,
    listeExportFileName:       'liste-bordereaux-reintegrations',
    listEditButtonTooltip:     'Modifier ce bordereau de réintégrations',
    listViewButtonTooltip:     'Consulter ce bordereau de réintégrations',

    editCreationTitle:              'Création d\'un bordereau de réintégrations',
    editModificationTitle:          'Modification d\'un bordereau de réintégrations',
    editConsultationTitle:          'Consultation d\'un bordereau de réintégrations',
    editTypeMouvementLabel:         '',
    editLieuDestinationRequired:    false,
    editLieuEvenementRequired:      false,
    editDateEvenementRequired:      false,
    editChampsEvenementDisabled:    true,
    editArticlesStockGereOnly:      true,
    editArticlesEnStockOnly:        false,
    editDeleteButtonDisplayed:      true,
    editActionButtonsDisplayed:     true,
    editColumns: {
      checkBox:          new TableColumn({label: 'Sélection',   hiddable: false, export: false}),
      codeArticle:       new TableColumn({label: 'Code'}),
      designation:       new TableColumn({label: 'Désignation', hiddable: false}),
      dlc:               new TableColumn({label: 'DLC', exportFormat: 'date'}),
      quantite:          new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
      uniteExploitation: new TableColumn({label: 'Unité'}),
      prixHt:            new TableColumn({label: 'PU HT',                      tooltip: 'Prix unitaire HT', exportFormat: 'decimal'}),
      montantHt:         new TableColumn({label: 'Mtt. HT',                    tooltip: 'Montant HT', exportFormat: 'decimal'}),
      tauxTva:           new TableColumn({label: 'Taux TVA',                   tooltip: 'Taux de TVA', exportFormat: 'decimal'}),
      prixTtc:           new TableColumn({label: 'PU TTC',                     tooltip: 'Prix unitaire TTC', exportFormat: 'decimal'}),
      montantTtc:        new TableColumn({label: 'Mtt. TTC',                   tooltip: 'Montant TTC', exportFormat: 'decimal'}),
      corrections:       new TableColumn({label: 'Correction',  hiddable: false, export: false})
    },
    editColumnsStoreCode:           CodeStockageColonnes.LIGNES_ES_REINTEG,
    editExportFileName:             'lignes-bordereau-reintegrations',
    editQuantitesPositives:         true,
    editQuantiteLabel:              'Quantité',
    editQuantiteZeroInterdite:      true,
    editPrixSaisis:                 true,
    editEnregistrAvtValidationMsg:  'Merci d\'enregistrer les modifications effectuées dans ce bordereau de réintégrations avant de le valider',
    editValidationConfirmationMsg:  'Êtes-vous sûr de vouloir valider ce bordereau de réintégrations ?',
    editSuppressionConfirmationMsg: 'Êtes-vous sûr de vouloir supprimer ce bordereau de réintégrations ?',
    editCreationSuccessMsg:         'Le bordereau de réintégrations a été enregistré avec succès',
    editModificationSuccessMsg:     'Le bordereau de réintégrations a été modifié avec succès',
    editValidationSuccessMsg:       'Le bordereau de réintégrations a été validé avec succès',
    editSuppressionSuccessMsg:      'Le bordereau de réintégrations a été supprimé avec succès'
  });


  // Valeurs par défaut
  commonTypeMouvementList: string[] = null; // null signifie que tous les types de mouvement peuvent être gérés
  commonTypeMouvementDisplayed = true;
  commonFieldsBLDisplayed      = true;
  commonFieldsTRFDisplayed     = true;
  commonFieldsSORDisplayed     = true;
  commonFieldsRSORDisplayed    = true;
  commonFieldsEvtDisplayed     = true;   // Champs 'évènementiels' (communs à SOR et RSOR)
  commonListPath               = '/liste-entrees-sorties';
  commonCreationPath           = '/creation-entree-sortie';
  commonEditPath               = '/entree-sortie';
  commonLabel                  = 'Entrée / sortie';
  commonDroitConsultation      = null;
  commonDroitSaisie            = null;
  commonDroitValidation        = null;

  filterTypeMouvementLabel      = 'Type d\'entrées/sorties';
  filterDateMinLabel            = 'Créées depuis le';
  filterValideLabel             = 'Validées';
  filterValideDefaultLabel      = 'Toutes';

  listTitle                 = 'Entrées/sorties';
  listListTitle             = 'Liste des entrées/sorties';
  listCreateButtonDisplayed = true;
  listCreateButtonTooltip   = 'Créer un nouveau bordereau d\'entrées/sorties';
  listColumns: {[key: string]: TableColumn} = {
    idEntreeSortieEntete:   new TableColumn({label: 'Id.',               tooltip: 'Identifiant interne', hiddable: false}),
    batiment:               new TableColumn({label: 'Bâtiment'}),
    lieu:                   new TableColumn({label: 'Lieu'}),
    batimentDestination:    new TableColumn({label: 'Bâtiment destination'}),
    lieuDestination:        new TableColumn({label: 'Lieu destination'}),
    lieuEvenement:          new TableColumn({label: 'Lieu évènement'}),
    dateEvenement:          new TableColumn({label: 'Date évènement'}),
    referenceEvenement:     new TableColumn({label: 'Référence'}),
    typeMouvement:          new TableColumn({label: 'Type'}),
    commande:               new TableColumn({label: 'Commande'}),
    fournisseur:            new TableColumn({label: 'Fournisseur'}),
    lotMarche:              new TableColumn({label: 'Lot-marché'}),
    dateCreation:           new TableColumn({label: 'Création'}),
    dateBordereauLivraison: new TableColumn({label: 'Date BL'}),
    codeBordereauLivraison: new TableColumn({label: 'Code-BL'}),
    dateValidation:         new TableColumn({label: 'Validation'}),
    etapeOrion:             new TableColumn({label: 'Étape Orion',       tooltip: 'Étape de l\'envoi du service fait vers Orion'}),
    pdf:                    new TableColumn({label: 'PDF'}),
    actions:                new TableColumn({label: 'Actions',           export: false, hiddable: false})
  };
  listeColumnsStoreCode     = null;
  listeExportFileName       = 'liste-entrees-sorties';
  listEditButtonTooltip     = 'Modifier cette entrée/sortie';
  listViewButtonTooltip     = 'Consulter cette entrée/sortie';

  editCreationTitle              = 'Création d\'une entrée/sortie';
  editModificationTitle          = 'Modification d\'une entrée/sortie';
  editConsultationTitle          = 'Consultation d\'une entrée/sortie';
  editTypeMouvementLabel         = 'Type d\'entrées/sorties';
  editLieuDestinationRequired    = false;
  editLieuEvenementRequired      = false;
  editDateEvenementRequired      = false;
  editChampsEvenementDisabled    = true;
  editArticlesStockGereOnly      = true;
  editArticlesEnStockOnly        = true;
  editDeleteButtonDisplayed      = true;
  editActionButtonsDisplayed     = true;
  editColumns: {[key: string]: TableColumn} = {
    checkBox:              new TableColumn({label: 'Sélection',                  hiddable: false, export: false}),
    codeArticle:           new TableColumn({label: 'Code'}),
    referenceFournisseur:  new TableColumn({label: 'Référence',                  tooltip: 'Référence-fournisseur'}),
    designation:           new TableColumn({label: 'Désignation',                hiddable: false}),
    dlc:                   new TableColumn({label: 'DLC'}),
    quantiteEnStock:       new TableColumn({label: 'Quantité en stock'}),
    quantiteSortie:        new TableColumn({label: 'Quantité sortie'}),
    quantiteCommandee:     new TableColumn({label: 'Quantité commandée'}),
    quantiteReceptionnee:  new TableColumn({label: 'Quantité déjà réceptionnée', tooltip: 'Quantité déjà réceptionnée (BL validés uniquement)'}),
    quantiteAReceptionner: new TableColumn({label: 'Quantité à réceptionner'}),
    quantiteAttendue:      new TableColumn({label: 'Quantité envoyée'}),
    quantite:              new TableColumn({label: 'Quantité'}),
    uniteExploitation:     new TableColumn({label: 'Unité'}),
    prixHt:                new TableColumn({label: 'PU HT',                      tooltip: 'Prix unitaire HT'}),
    montantHt:             new TableColumn({label: 'Mtt. HT',                    tooltip: 'Montant HT'}),
    tauxTva:               new TableColumn({label: 'Taux TVA',                   tooltip: 'Taux de TVA'}),
    prixTtc:               new TableColumn({label: 'PU TTC',                     tooltip: 'Prix unitaire TTC'}),
    montantTtc:            new TableColumn({label: 'Mtt. TTC',                   tooltip: 'Montant TTC'}),
    ecart:                 new TableColumn({label: 'Écart'}),
    ecartDetaille:         new TableColumn({label: 'Écart détaillé'}),
    corrections:           new TableColumn({label: 'Correction',                 hiddable: false, export: false}),
    gestionLots:           new TableColumn({label: 'Lots',                       tooltip: 'Gestion des lots'}),
    fdnc:                  new TableColumn({label: 'FDNC',                       export: false})
  };
  editColumnsStoreCode           = null;
  editExportFileName             = 'lignes-entree-sortie';
  editQuantitesPositives         = true;
  editQuantiteLabel              = 'Quantité';
  editQuantiteZeroInterdite      = false;
  editPrixSaisis                 = false;
  editFdncDisplayed              = false;
  editEnregistrAvtValidationMsg  = 'Merci d\'enregistrer les modifications effectuées dans ce bordereau d\'entrées/sorties avant de le valider';
  editValidationConfirmationMsg  = 'Êtes-vous sûr de vouloir valider ce bordereau d\'entrées/sorties ?';
  editSuppressionConfirmationMsg = 'Êtes-vous sûr de vouloir supprimer ce bordereau d\'entrées/sorties ?';
  editCreationSuccessMsg         = 'Le bordereau d\'entrées/sorties a été enregistré avec succès';
  editModificationSuccessMsg     = 'Le bordereau d\'entrées/sorties a été modifié avec succès';
  editValidationSuccessMsg       = 'Le bordereau d\'entrées/sorties a été validé avec succès';
  editSuppressionSuccessMsg      = 'Le bordereau d\'entrées/sorties a été supprimé avec succès';

  constructor(params?: Object) {
    super();

    if (params) {
      for (const prop in params) {
        if (params.hasOwnProperty(prop) && this.hasOwnProperty(prop)) {
          this[prop] = params[prop];
        }
      }
    }

    // Construction de mapTypeToParametrage au fur et à mesure des instanciations
    if (this.commonTypeMouvementList) {
      for (const typeMouvement of this.commonTypeMouvementList) {
        EntreeSortieParametrage.mapTypeToParametrage[typeMouvement] = this;
      }
    }
  }

  static getParametrageFromType(type: TypeMouvement): EntreeSortieParametrage {
    return EntreeSortieParametrage.mapTypeToParametrage[type.codeTypeMouvement];
  }
}
