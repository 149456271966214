import {Component, OnInit, ViewChild} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {PanierFilter} from '../../model/epona-ui/panier-filter';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PanierQueryParams} from '../../model/epona-ui/panier-query-params';
import {MatTableDataSource} from '@angular/material/table';
import {DisplayedColumnsTools, TableColumn} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {Tools} from '../../commons/Tools';
import {PanierService} from '../../services/epona/panier.service';
import {MatSort, Sort} from '@angular/material/sort';
import {PanierSearch} from '../../model/epona-api/panier-search';
import {PanierEntete} from '../../model/epona-api/PanierEntete';
import {FiltrePaniersComponent} from '../filtre-paniers/filtre-paniers.component';
import {FilterTools} from '../../commons/FilterTools';
import {MatDialog} from '@angular/material/dialog';
import {DialogRevisionTarifaireComponent} from '../../revisions-tarifaires/dialog-revision-tarifaire/dialog-revision-tarifaire.component';
import {CnousTableExporterDirective} from '../../commons/directives/cnous-table-exporter.directive';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {PanierExportService} from '../../services/epona/panier-export.service';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {HttpService} from "../../services/http.service";

@Component({
    selector: 'app-liste-paniers',
    templateUrl: './liste-paniers.component.html',
    styleUrls: ['./liste-paniers.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Paniers') }
    ],
    standalone: false
})
export class ListePaniersComponent implements OnInit, IPaginatedComponent<PanierEntete> {

  private readonly DEFAULT_PAGE_SIZE = 10;
  private readonly DEFAULT_ORDER_BY = 'idPanierEntete';
  private readonly DEFAULT_ORDER_DIR = 'desc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;


  dataSource = new MatTableDataSource<PanierEntete>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    idPanierEntete:      new TableColumn({label: 'Id.',            tooltip: 'Identifiant interne', hiddable: false, exportFormat: 'integer'}),
    batiment:            new TableColumn({label: 'Bâtiment'}),
    lieuDemandeur:       new TableColumn({label: 'Lieu de livraison'}),
    fournisseur:         new TableColumn({label: 'Fournisseur'}),
    dateCreation:        new TableColumn({label: 'Date création', default: false, exportFormat: 'date'}),
    utilisateurCreation: new TableColumn({label: 'Créé par',      default: false}),
    nbCommandes:         new TableColumn({label: 'Nb. commandes', default: false, exportFormat: 'integer'}),
    actions:             new TableColumn({label: 'Actions',       export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.PANIERS;

  displayedColumns: string[] = [];

  droitModification: boolean;
  lieuCourant: Lieu;

  @ViewChild(FiltrePaniersComponent)
  filterComponent: FiltrePaniersComponent;

  @ViewChild('tableauPaniers') tableauPaniers: CnousTableExporterDirective;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  filter: PanierFilter;
  droitSaisie: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private panierService: PanierService,
              private exportService: PanierExportService,
              public dialog: MatDialog,
              private httpService: HttpService) { }

  ngOnInit(): void {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des commandes
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;

      // Lancement de la recherche
      this.recherchePaniers();
    });


    this.droitSaisie = this.panierService.droitSaisie();
    this.droitModification = this.panierService.droitModification();

  }


  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de commandes
    this.filter = new PanierFilter();
    this.filter.numeroPanier = params.get('numeroPanier') ? params.get('numeroPanier') : null;
    this.filter.utilisateur  = params.get('utilisateur') ? params.get('utilisateur') : null;
    this.filter.fournisseur  = FilterTools.buildFournisseurFromURL(params);
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de commandes
    this.filter = new PanierFilter();
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: PanierFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-paniers'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): PanierQueryParams {
    const params = new PanierQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.numeroPanier = this.filter.numeroPanier;
    params.utilisateur = this.filter.utilisateur;
    params.idFournisseur  = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur : null;

    return params;
  }

  private recherchePaniers() {
    const search = this.buildSearchFromFilters();
    search.fields = '' +
      'idPanierEntete,' +
      'dateCreation,' +
      'utilisateurCreation,' +
      'lieuDemandeur.nomRu,' +
      'lieuDemandeur.nomUd,' +
      'fournisseur.nom,' +
      'nbCommandes';
    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.panierService.getListeEntetesWithPagination(search).subscribe(this.httpService.handlePaginatedSubscribe(this));
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private buildSearchFromFilters(): PanierSearch {
    const search = new PanierSearch();

    search.setIdsLieu(this.lieuCourant);

    search.utilisateur  = this.filter.utilisateur;
    search.numeroPanier  = this.filter.numeroPanier;
    search.idFournisseur   = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur: null;

    return search;
  }

  openProchainesRevisionsTarifaires() {
    this.dialog.open(DialogRevisionTarifaireComponent);
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.recherchePaniers();
  }


  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.recherchePaniers();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListePaniers() {
    const search = this.buildSearchFromFilters();
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;


    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportPaniers(search);
  }


}
