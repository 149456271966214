
<epona-select-lieu [lieu]="lieuCourant"
                   [lieuLabel]="params.commonFieldsBLDisplayed ? 'Lieu de livraison' : 'Lieu de stockage'"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>{{params.listTitle}}</h1>

<div class="crous-recherche">
  <epona-filtre-entrees-sorties [filter]="filter"
                                 class="crous-recherche-filtre"
                                [params]="params"
                                (filterSubmitted)="onFilterSubmitted($event)">
  </epona-filtre-entrees-sorties>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{params.listListTitle}}</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <a routerLink="{{params.commonCreationPath}}" matTooltip="{{params.listCreateButtonTooltip}}" *ngIf="params.listCreateButtonDisplayed && droitSaisie">
          <button type="button" mat-raised-button color="primary"><fa-icon icon="plus"></fa-icon> Créer</button>
        </a>

        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeEntreesSorties()"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                      [storeCode]="COLUMNS_STORE_CODE"
                                      [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             (matSortChange)="onSortChanged($event)"
             aria-label="Liste d'entrées/sorties">

        <ng-container matColumnDef="idEntreeSortieEntete">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['idEntreeSortieEntete'].tooltip">{{ COLUMNS['idEntreeSortieEntete'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.idEntreeSortieEntete}}</td>
        </ng-container>

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['batiment'].tooltip">{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu?.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieu">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lieu'].tooltip">{{ COLUMNS['lieu'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu?.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="batimentDestination">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['batimentDestination'].tooltip">{{ COLUMNS['batimentDestination'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDestination?.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieuDestination">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lieuDestination'].tooltip">{{ COLUMNS['lieuDestination'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuDestination?.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="lieuEvenement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lieuEvenement'].tooltip">{{ COLUMNS['lieuEvenement'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieuEvenement}}</td>
        </ng-container>

        <ng-container matColumnDef="dateEvenement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateEvenement'].tooltip">{{ COLUMNS['dateEvenement'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateEvenement | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="referenceEvenement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['referenceEvenement'].tooltip">{{ COLUMNS['referenceEvenement'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.referenceEvenement}}</td>
        </ng-container>

        <ng-container matColumnDef="typeMouvement">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['typeMouvement'].tooltip">{{ COLUMNS['typeMouvement'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.typeMouvement.libelle}}</td>
        </ng-container>

        <ng-container matColumnDef="commande">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['commande'].tooltip">{{ COLUMNS['commande'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/commande', element.commande.idCommandeEntete]" *ngIf="element.commande">
              {{ element.commande | commande }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="fournisseur">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['fournisseur'].tooltip">{{ COLUMNS['fournisseur'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.entreeSortieEnteteOrigine">{{ element.entreeSortieEnteteOrigine.lieu?.nom }}</span>
            <span *ngIf="!element.entreeSortieEnteteOrigine">{{ element.commande?.fournisseur?.nom }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lotMarche">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['lotMarche'].tooltip">{{ COLUMNS['lotMarche'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.entreeSortieEnteteOrigine">(BL interne)</span>
            <span *ngIf="!element.entreeSortieEnteteOrigine">{{ element.commande?.sousLotZg | lotMarcheOrion }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="dateCreation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateCreation'].tooltip">{{ COLUMNS['dateCreation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateCreation | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="dateBordereauLivraison">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateBordereauLivraison'].tooltip">{{ COLUMNS['dateBordereauLivraison'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateBordereauLivraison | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="codeBordereauLivraison">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['codeBordereauLivraison'].tooltip">{{ COLUMNS['codeBordereauLivraison'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.codeBordereauLivraison}}</td>
        </ng-container>

        <ng-container matColumnDef="dateValidation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['dateValidation'].tooltip">{{ COLUMNS['dateValidation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateValidation | dateTimeFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="etapeOrion">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['etapeOrion'].tooltip">{{ COLUMNS['etapeOrion'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.entreeSortieEnteteOrigine">(<abbr matTooltip="Non pertinent dans le cas d'un BL interne">NP</abbr>)</span>
            <span *ngIf="!element.entreeSortieEnteteOrigine">{{ element | etapeServiceFait }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="pdf">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['pdf'].label }}</th>
          <td mat-cell class="pre" *matCellDef="let element">
            <a matSuffix href="#" (click)="$event.preventDefault();downloadPdf(element)" *ngIf="estBLSurCommandeExterne(element)">
              <mat-icon>picture_as_pdf</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="[params.commonEditPath, element.idEntreeSortieEntete]"
               matTooltip="{{params.listEditButtonTooltip}}"
               *ngIf="droitModification">
              <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
            </a>
            <a [routerLink]="[params.commonEditPath, element.idEntreeSortieEntete]"
               matTooltip="{{params.listViewButtonTooltip}}"
               *ngIf="!droitModification">
              <button type="button" mat-button><fa-icon icon="folder-open"></fa-icon></button>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
