import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {CacheService} from "../../../services/cache.service";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Marche} from "../../../model/epona-api/Marche";
import {EponaService} from "../../../services/epona/epona.service";
import {SousLotZgSearch} from "../../../model/epona-api/SousLotZgSearch";

@Component({
    selector: 'epona-select-marche',
    templateUrl: './select-marche.component.html',
    styleUrls: ['./select-marche.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectMarcheComponent }],
    standalone: false
})
export class SelectMarcheComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Marche> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() afficherFuturs: boolean = true;
  @Input() afficherPasses: boolean = true;
  @Output() readonly valueChanged = new EventEmitter<Marche>();

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'marches';
  private static loadInProgress: boolean = false;
  private static listLoaded = new Subject<Marche[]>();

  formCtrl: UntypedFormControl;
  liste: Marche[];
  listePresents: Marche[] = [];
  listeFuturs: Marche[] = [];
  listePasses: Marche[] = [];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private eponaService: EponaService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: Marche): void {
    this.formCtrl.setValue(obj ? obj.codeMarcheOrion : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Marche {
    return this.getCurrentItem();
  }
  set value(value: Marche) {
    this.formCtrl.setValue(value ? value.codeMarcheOrion : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): Marche {
    return this.liste.find(item => item.codeMarcheOrion === this.formCtrl.value)
  }

  private loadListe(): void {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

    // Sinon, si le chargement est en cours, attente de la fin du chargement
    } else if (SelectMarcheComponent.loadInProgress) {
      SelectMarcheComponent.listLoaded.subscribe(list => {
        this.setAndSortListeFromCache();
        SelectMarcheComponent.listLoaded.unsubscribe();
      });

    // Sinon récupération de la liste via l'API
    } else {
      SelectMarcheComponent.loadInProgress = true;

      const search = new SousLotZgSearch();
      search.idCrous = +localStorage.getItem('idCrous');
      search.fields = 'codeMarcheOrion,dateDebut,dateFin';

      this.eponaService.getListeSousLotsZg(search).subscribe(data => {
        this.liste = [];
        for (const sousLot of data) {
          const marcheExistant = this.liste.find(marche => marche.codeMarcheOrion === sousLot.codeMarcheOrion);
          if (marcheExistant) {
            const dateDebut = new Date(sousLot.dateDebut);
            const dateFin = new Date(sousLot.dateFin);
            // La date de début du marché est la date minimum des sous-lots du marché
            if (dateDebut < marcheExistant.dateDebut) {
              marcheExistant.dateDebut = dateDebut;
            }
            // La date de fin du marché est la date maximim des sous-lots du marché
            if (dateFin > marcheExistant.dateFin) {
              marcheExistant.dateFin = dateFin;
            }
          } else {
            this.liste.push(new Marche(sousLot));
          }
        }
        this.cacheService.set(this.CACHE_KEY, this.liste);
        SelectMarcheComponent.loadInProgress = false;
        SelectMarcheComponent.listLoaded.next(this.liste);
        this.eclaterParTypeValiditeEtTrier();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.eclaterParTypeValiditeEtTrier();
  }

  private eclaterParTypeValiditeEtTrier(): void {
    this.listePresents = [];
    this.listeFuturs = [];
    this.listePasses = [];

    const now = Date.now();

    for (const marche of this.liste) {
      if (marche.dateFin.getTime() < now) {
        if (this.afficherPasses) {
          this.listePasses.push(marche);
        }
      } else if (marche.dateDebut.getTime() > now) {
        if (this.afficherFuturs) {
          this.listeFuturs.push(marche);
        }
      } else {
        this.listePresents.push(marche);
      }
    }

    this.tri(this.listePresents);
    this.tri(this.listeFuturs);
    this.tri(this.listePasses);
  }

  tri(liste: Array<Marche>) {
    liste.sort((a, b) => {
      return a.codeMarcheOrion.localeCompare(b.codeMarcheOrion);
    });
  }
}
