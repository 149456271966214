import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataHistoriqueCorrectionsEntreeSortie} from "../../model/epona-ui/DialogDataHistoriqueCorrectionsEntreeSortie";
import {MatTableDataSource} from "@angular/material/table";
import {EntreeSortieLigne} from "../../model/epona-api/EntreeSortieLigne";

@Component({
    selector: 'app-dialog-historique-corrections',
    templateUrl: './dialog-historique-corrections.component.html',
    styleUrls: ['./dialog-historique-corrections.component.css'],
    standalone: false
})
export class DialogHistoriqueCorrectionsComponent implements OnInit {

  dataSource = new MatTableDataSource<EntreeSortieLigne>([]);
  displayedColumns: string[] = ['action', 'date', 'utilisateur', 'designation', 'dlc', 'quantite'];

  constructor(public dialog: MatDialogRef<DialogHistoriqueCorrectionsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataHistoriqueCorrectionsEntreeSortie) {

    this.sortLignes();
    this.dataSource.data = data.lignes;
  }

  ngOnInit(): void {
  }

  private sortLignes() {
    // Ordonnancement des lignes selon l'identifiant
    this.data.lignes.sort((l1, l2) => {
      return l1.idEntreeSortieLigne < l2.idEntreeSortieLigne ? -1 : 1;
    });
  }
}
