import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ParametrageLieuFournisseur} from "../../model/epona-api/parametrage-lieu-fournisseur";
import {ParametrageLieuFournisseurService} from "../../services/epona/parametrage-lieu-fournisseur.service";
import {Fournisseur} from "../../model/epona-api/Fournisseur";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogParametrageLieuFournisseurComponent} from "../dialog-ajout-parametrage-lieu-fournisseur/dialog-parametrage-lieu-fournisseur.component";
import {DialogueDataParametrageLieuFournisseur} from "../../model/epona-ui/dialogue-data-parametrage-lieu-fournisseur";
import {Lieu} from "../../model/epona-api/Lieu";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {MessageTool} from "../../commons/MessageTool";
import {MatSort} from "@angular/material/sort";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
    selector: 'epona-fournisseur-parametrage-local',
    templateUrl: './fournisseur-parametrage-local.component.html',
    styleUrls: ['./fournisseur-parametrage-local.component.css'],
    standalone: false
})
export class FournisseurParametrageLocalComponent implements OnInit, OnChanges {

  @Input()
  fournisseur : Fournisseur;

  dataSource = new MatTableDataSource<ParametrageLieuFournisseur>([]);
  possedeDroitParametrageLocal = false;
  displayedColumns: string[] = ['batiment', 'lieu', 'codeClient', 'mail', 'actions'];
  @ViewChild(MatSort, {static: true})
  sort: MatSort;


  constructor(private parametrageLieuFournisseurService: ParametrageLieuFournisseurService,
              private dialog: MatDialog,
              private messageTool: MessageTool,
              private userService: UserService) { }

  ngOnInit(): void {
    this.possedeDroitParametrageLocal = this.userService.utilisateurCourant.possedeDroit(CodeDroit.GESTION_FOURNISSEURS_LOCALE);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fournisseur'] && this.fournisseur) {
      this.loadData();
    }
  }

  ajoutParametrage() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.data = new DialogueDataParametrageLieuFournisseur();
    dialogConfig.data.fournisseur = this.fournisseur;
    dialogConfig.data.parametrageLieuFournisseur = new ParametrageLieuFournisseur();
    const lieu = new Lieu();
    lieu.initWithLocalStorage();
    dialogConfig.data.lieu =lieu;

    const dialogRef = this.dialog.open(DialogParametrageLieuFournisseurComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if(dialogConfig.data) {
        this.loadData();
      }
    });
  }

  modifieParametrage(parametrageLieuFournisseur: ParametrageLieuFournisseur) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.data = new DialogueDataParametrageLieuFournisseur();
    dialogConfig.data.fournisseur = this.fournisseur;
    dialogConfig.data.parametrageLieuFournisseur = parametrageLieuFournisseur;
    dialogConfig.data.lieu = parametrageLieuFournisseur.lieu;


    const dialogRef = this.dialog.open(DialogParametrageLieuFournisseurComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      if(dialogConfig.data) {
        this.loadData();
      }
    });
    return false;
  }

  supprimeParametrage(parametrageLieuFournisseur: ParametrageLieuFournisseur) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: "Êtes-vous sûr de vouloir supprimer ce paramétrage ?"};

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.parametrageLieuFournisseurService.deleteParametrageLieuFournisseur(parametrageLieuFournisseur.idParametrageLieuFournisseur).subscribe(res => {
          this.loadData();
        }, err=> {
          this.messageTool.sendError(err);
        });
      }
    });
    return false;
  }

  loadData(){
    this.parametrageLieuFournisseurService.getListeParametragesLieuFournisseur(this.fournisseur.idFournisseur, "idParametrageLieuFournisseur,lieu,codeClient,mail").subscribe(res => {
      this.dataSource.data = res;
    });
  }

  /* ************** */
  /*       Tri      */
  /* ************** */

  sortingDataAccessor(data: ParametrageLieuFournisseur, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'lieu'       : return SortHelper.stringToString(data.lieu.nomUd);
      case 'batiment'   : return SortHelper.stringToString(data.lieu.nomRu);
      case 'codeClient' : return SortHelper.stringToString(data.codeClient);
      case 'mail'       : return SortHelper.stringToString(data.mail);
      default           : return data[sortHeaderId];
    }
  }
}
