import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FournisseurFilter} from '../../model/epona-ui/fournisseur-filter';
import {FournisseurQueryParams} from '../../model/epona-ui/fournisseur-query-params';
import {MatTableDataSource} from '@angular/material/table';
import {Tools} from '../../commons/Tools';
import {FournisseurService} from '../../services/epona/fournisseur.service';
import {FournisseurSearch} from '../../model/epona-api/FournisseurSearch';
import {MatSort, Sort} from '@angular/material/sort';
import {DisplayedColumnsTools, TableColumn} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {FilterTools} from '../../commons/FilterTools';
import {FournisseurExportService} from '../../services/epona/fournisseur-export.service';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {Fournisseur} from "../../model/epona-api/Fournisseur";
import {HttpService} from "../../services/http.service";

@Component({
    selector: 'app-liste-fournisseurs',
    templateUrl: './liste-fournisseurs.component.html',
    styleUrls: ['./liste-fournisseurs.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Fournisseurs') }
    ],
    standalone: false
})
export class ListeFournisseursComponent implements OnInit, IPaginatedComponent<Fournisseur> {

  private readonly DEFAULT_PAGE_SIZE = 10;
  private readonly DEFAULT_ORDER_BY = 'nom';
  private readonly DEFAULT_ORDER_DIR = 'asc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;

  dataSource = new MatTableDataSource([]);

  filter: FournisseurFilter;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  readonly COLUMNS: {[key: string]: TableColumn} = {
    nom:        new TableColumn({label: 'Nom',      hiddable: false}),
    siret:      new TableColumn({label: 'Siret'}),
    mail:       new TableColumn({label: 'Email'}),
    codeClient: new TableColumn({label: 'Code-client', tooltip: 'Code-client du Crous chez le fournisseur'}),
    telephone:  new TableColumn({label: 'Téléphone',   default: false}),
    ville:      new TableColumn({label: 'Ville',       default: false}),
    actions:    new TableColumn({label: 'Actions',     export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.FOURNISSEURS;

  displayedColumns: string[] = [];

  constructor(private route: ActivatedRoute,
              private fournisseurService: FournisseurService,
              private exportService: FournisseurExportService,
              private httpService: HttpService,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.filter = new FournisseurFilter();
      }
      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;
      this.rechercher();
    });

  }

  private initFiltersFromURL(params: ParamMap) {
    this.filter = new FournisseurFilter();
    this.filter.nom = params.get('nom');
    this.filter.siret = params.get('siret');
    this.filter.marche = FilterTools.buildMarcheFromURL(params);
  }

  rechercher() {
    const search = FournisseurSearch.fabric(localStorage.getItem('idCrous'), this.filter,"idFournisseur,siret,nom,mail,telephone,adresseVille,codeClient");

    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.fournisseurService.getListeFournisseursWithPagination(search).subscribe(this.httpService.handlePaginatedSubscribe(this));
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onFilterSubmitted(filter: FournisseurFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['liste-fournisseurs'], {queryParams: FournisseurQueryParams.fabric(this.filter)});
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.rechercher();
  }


  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.rechercher();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListeFournisseurs() {
    const search = FournisseurSearch.fabric(localStorage.getItem('idCrous'), this.filter,"idFournisseur,siret,nom,mail,telephone,adresseVille,codeClient");
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;

    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportFourniseurs(search);
  }
}
