import {Pipe} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: 'decimal4',
    standalone: false
})
export class Decimal4Pipe extends DecimalPipe {
  transform(value): any {
    return super.transform(value, '1.4-4');
  }
}
