<button class="handle"
        (click)="changerAffichage()"
        matTooltip="{{ enteteAffichee ? 'Masquer' : 'Afficher' }} l'entête"
        *ngIf="entete">
  <fa-icon icon="chevron-left" *ngIf="enteteAffichee"></fa-icon>
  <fa-icon icon="chevron-right" *ngIf="!enteteAffichee"></fa-icon>
</button>

<mat-card [ngClass]="{'masquee': !enteteAffichee}">
  <mat-card-header>
    <mat-card-title>Informations</mat-card-title>
  </mat-card-header>

  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="enteteForm">
    <div class="mat-form-field-row">
      <mat-form-field *ngIf="!isCreation()">
        <mat-label>Identifiant interne</mat-label>
        <input matInput formControlName="idEntreeSortieEntete">
      </mat-form-field>

      <mat-form-field *ngIf="!isCreation()">
        <mat-label>État</mat-label>
        <input matInput value="Validé"     disabled="disabled" *ngIf="entete.validee" />
        <input matInput value="Non validé" disabled="disabled" *ngIf="!entete.validee" />
        <mat-icon matSuffix class="succes"        *ngIf="entete.validee">check</mat-icon>
        <mat-icon matSuffix class="avertissement" *ngIf="!entete.validee">warning</mat-icon>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row" *ngIf="entete && (entete.serviceFaitExiste || entete.dateEnvoiSf)">
      <mat-form-field *ngIf="!isCreation()">
        <mat-label>Numéro SF</mat-label>
        <input matInput formControlName="numeroSf">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Étape service fait (Orion)</mat-label>
        <input matInput [value]="entete | etapeServiceFait" disabled="disabled" />
        <mat-icon matSuffix                       *ngIf="!entete.serviceFaitExiste">delete</mat-icon>
        <mat-icon matSuffix class="avertissement" *ngIf="entete.codeEtapeOrion === 'MAJ_EJ'">sync</mat-icon>
        <mat-icon matSuffix class="avertissement" *ngIf="entete.codeEtapeOrion === 'ENV_SF'">send</mat-icon>
        <mat-icon matSuffix class="succes"        *ngIf="entete.codeEtapeOrion === 'SF_OK'">check</mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="params.commonTypeMouvementDisplayed">
      <mat-label>{{params.editTypeMouvementLabel}}</mat-label>

      <epona-select-type-mouvement formControlName="typeMouvement"
                                   [values]="params.commonTypeMouvementList"
                                   [required]="true">
      </epona-select-type-mouvement>
    </mat-form-field>

    <epona-select-lieu [lieu]="entete?.lieuStockageApresValidation"
                       (lieuSubmitted)="onLieuStockageApresValidationSubmitted($event)"
                       [stockerLieuSelectionne]="false"
                       [required]="false"
                       [disabled]="enteteForm.disabled"
                       [choixRestreints]="true"
                       use="form"
                       batimentLabel="Bâtiment de stockage"
                       lieuLabel="Lieu de stockage"
                       batimentDefault=">> Idem bâtiment de livraison <<"
                       lieuDefault=">> Idem lieu de livraison <<"
                       [displayHelpTooltip]="false"
                       subscriptSizing="fixed"
                       *ngIf="params.commonFieldsBLDisplayed">
    </epona-select-lieu>

    <epona-select-lieu [lieu]="entete?.lieuDestination"
                       (lieuSubmitted)="onLieuDestinationSubmitted($event)"
                       [stockerLieuSelectionne]="false"
                       [required]="true"
                       [disabled]="enteteForm.disabled"
                       [choixRestreints]="false"
                       use="form"
                       batimentLabel="Bâtiment de destination"
                       lieuLabel="Lieu de destination"
                       batimentDefault="- Aucun -"
                       lieuDefault="- Aucun -"
                       [displayHelpTooltip]="false"
                       subscriptSizing="fixed"
                       *ngIf="params.commonFieldsTRFDisplayed">
    </epona-select-lieu>

    <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
      <mat-label>Lieu de l'évènement</mat-label>

      <input matInput
             type="text"
             formControlName="lieuEvenement"
             maxlength="255"
             [required]="params.editLieuEvenementRequired">
    </mat-form-field>

    <div class="mat-form-field-row">
      <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
        <mat-label>Date de l'évènement</mat-label>

        <input matInput
               [matDatepicker]="pickerDateEvenement"
               formControlName="dateEvenement"
               [required]="params.editDateEvenementRequired"
               (click)="pickerDateEvenement.open()"
               (focus)="pickerDateEvenement.open()"
               autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="pickerDateEvenement"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateEvenement></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="params.commonFieldsEvtDisplayed">
        <mat-label>Référence de l'évènement</mat-label>

        <input matInput
               type="text"
               formControlName="referenceEvenement"
               maxlength="255">
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field *ngIf="params.commonFieldsBLDisplayed">
        <mat-label>Code-BL</mat-label>
        <input matInput
               type="text"
               formControlName="codeBordereauLivraison"
               maxlength="255">
      </mat-form-field>

      <mat-form-field *ngIf="params.commonFieldsBLDisplayed">
        <mat-label>Date du BL</mat-label>
        <input matInput
               [matDatepicker]="pickerDateBordereauLivraison"
               formControlName="dateBordereauLivraison"
               [required]="false"
               (click)="pickerDateBordereauLivraison.open()"
               (focus)="pickerDateBordereauLivraison.open()"
               autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="pickerDateBordereauLivraison"></mat-datepicker-toggle>
        <mat-datepicker #pickerDateBordereauLivraison></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row" *ngIf="params.commonFieldsBLDisplayed">
      <mat-form-field>
        <mat-label>Température conforme</mat-label>

        <select-boolean formControlName="temperatureConforme"></select-boolean>
      </mat-form-field>
    </div>

    <epona-link-resource label="Commande d'origine"
                         value="No. {{ entete.commande.numeroEj ? entete.commande.numeroEj : entete.commande.idCommandeEntete }} {{ entete.commande.fournisseur ? '(' + entete.commande.fournisseur.nom + ')' : '' }}"
                         [routerLink]="['/commande', entete.commande.idCommandeEntete]"
                         tooltip="Consulter la commande {{ libelleCommande(entete.commande) }}"
                         [showLink]="!modeConsultation && droitConsultationCommande"
                         *ngIf="params.commonFieldsBLDisplayed && entete?.commande"></epona-link-resource>

    <epona-link-resource label="Bordereau de transfert d'origine"
                         value="No. {{entete.entreeSortieEnteteOrigine.idEntreeSortieEntete}}"
                         [routerLink]="['/bordereau-transfert', entete.entreeSortieEnteteOrigine.idEntreeSortieEntete]"
                         tooltip="Consulter le bordereau de transfert no. {{entete.entreeSortieEnteteOrigine.idEntreeSortieEntete}} (depuis {{entete.entreeSortieEnteteOrigine.lieu.nom}})"
                         *ngIf="params.commonFieldsBLDisplayed && entete?.entreeSortieEnteteOrigine"></epona-link-resource>

    <epona-link-resource label="Bordereau de sortie d'origine"
                         value="No. {{entete.entreeSortieEnteteOrigine.idEntreeSortieEntete}}"
                         [routerLink]="['/bordereau-sortie', entete.entreeSortieEnteteOrigine.idEntreeSortieEntete]"
                         tooltip="Consulter le bordereau de sortie no. {{entete.entreeSortieEnteteOrigine.idEntreeSortieEntete}}"
                         *ngIf="params.commonFieldsRSORDisplayed && entete?.entreeSortieEnteteOrigine"></epona-link-resource>

    <mat-form-field>
      <mat-label>Commentaire</mat-label>

      <textarea matInput cdkTextareaAutosize
                formControlName="commentaire"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="500">
      </textarea>

      <mat-error *ngIf="enteteForm.get('commentaire').hasError('required')">Commentaire obligatoire</mat-error>
    </mat-form-field>


    <epona-link-resource label="Bordereau de livraison"
                         value="No. {{entreeSortieLiee.idEntreeSortieEntete}}"
                         [routerLink]="['/bordereau-livraison', entreeSortieLiee.idEntreeSortieEntete]"
                         tooltip="Consulter le bordereau de livraison no. {{entreeSortieLiee.idEntreeSortieEntete}} (à {{entreeSortieLiee.lieu.nom}})"
                         *ngIf="params.commonFieldsTRFDisplayed  && entreeSortieLiee?.idEntreeSortieEntete"></epona-link-resource>

    <epona-link-resource label="Retour de sortie"
                         value="No. {{entreeSortieLiee.idEntreeSortieEntete}}"
                         [routerLink]="['/retour-sortie', entreeSortieLiee.idEntreeSortieEntete]"
                         tooltip="Consulter le retour de sortie no. {{entreeSortieLiee.idEntreeSortieEntete}}"
                         *ngIf="params.commonFieldsSORDisplayed  && entreeSortieLiee?.idEntreeSortieEntete"></epona-link-resource>

    <div class="form-actions">
      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogGestionDocuments()"
              [matTooltip]="documentsModifiables ? 'Gérer les documents joints…' : 'Voir les documents joints…'"
              *ngIf="afficherBoutonDocuments">
        <fa-icon icon="folder-open"></fa-icon> {{ documentsModifiables ? 'Gérer documents…' : 'Voir documents…' }}
      </button>

      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogServiceFait()"
              [matTooltip]="serviceFaitModifiable ? 'Saisir ou envoyer le Service Fait…' : 'Voir le Service Fait…'"
              *ngIf="afficherBoutonServiceFait">
        <fa-icon icon="envelope-open"></fa-icon> {{ serviceFaitModifiable ? 'Saisir Service Fait…' : 'Voir Service Fait…' }}
      </button>
    </div>

    <div class="form-actions" *ngIf="!modeConsultation">
      <button type="submit"
              mat-raised-button color="primary"
              [disabled]="isInvalidForm() || !isEditable()">
        <fa-icon icon="save"></fa-icon> Enregistrer
      </button>

      <button type="button"
              mat-raised-button color="primary"
              (click)="openDialogValidation()" [disabled]="!isEditable() || isModificationLigneEnCours()" *ngIf="!isCreation()">

        <fa-icon icon="check"></fa-icon> Valider…
      </button>

      <button type="button"
              mat-raised-button color="primary"
              [matMenuTriggerFor]="menu"
              *ngIf="params.editDeleteButtonDisplayed && !isCreation()">
        <mat-icon>more_horiz</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button type="button"
                mat-menu-item
                (click)="openDialogSuppression()"
                [disabled]="!isEditable()">
          <fa-icon icon="trash"></fa-icon> Supprimer…
        </button>
      </mat-menu>
    </div>
  </form>

  <mat-expansion-panel #metaDataPanel *ngIf="!isCreation()" >
    <mat-expansion-panel-header>
      <mat-panel-description>
        {{ metaDataPanel.expanded ? 'Masquer' : 'Afficher' }}
        les informations supplémentaires
      </mat-panel-description>
    </mat-expansion-panel-header>

    <epona-meta-data type="creation"
                     [date]="entete.dateCreation"
                     [utilisateur]="entete.utilisateurCreation"
                     *ngIf="entete?.dateCreation">
    </epona-meta-data>

    <epona-meta-data type="modification"
                     [date]="entete.dateModification"
                     [utilisateur]="entete.utilisateurModification"
                     *ngIf="entete?.dateModification">
    </epona-meta-data>

    <epona-meta-data type="validation"
                     [date]="entete.dateValidation"
                     [utilisateur]="entete.utilisateurValidation"
                     *ngIf="entete?.dateValidation">
    </epona-meta-data>

    <epona-meta-data type="majEj"
                     [date]="entete.dateMiseAJourEj"
                     [utilisateur]="entete.utilisateurMiseAJourEj"
                     *ngIf="entete?.dateMiseAJourEj">
    </epona-meta-data>

    <epona-meta-data type="envoiSf"
                     [date]="entete.dateEnvoiSf"
                     [utilisateur]="entete.utilisateurEnvoiSf"
                     *ngIf="entete?.dateEnvoiSf && afficherBoutonServiceFait">
    </epona-meta-data>

    <epona-meta-data type="suppressionSf"
                     [date]="entete.dateEnvoiSf"
                     [utilisateur]="entete.utilisateurEnvoiSf"
                     *ngIf="entete?.dateEnvoiSf && !afficherBoutonServiceFait">
    </epona-meta-data>
  </mat-expansion-panel>
</mat-card>
