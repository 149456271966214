import {Component, OnInit, ViewChild} from '@angular/core';
import {InventaireFilter} from '../../model/epona-ui/InventaireFilter';
import {InventaireSearch} from '../../model/epona-api/InventaireSearch';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {InventaireService} from '../../services/epona/inventaire.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Lieu} from '../../model/epona-api/Lieu';
import {Tools} from '../../commons/Tools';
import {InventaireQueryParams} from '../../model/epona-ui/InventaireQueryParams';
import {InventairesFilterComponent} from '../inventaires-filter/inventaires-filter.component';
import {DisplayedColumnsTools, TableColumn} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {OnlineService} from '../../services/online.service';
import {InventaireEntete} from '../../model/epona-api/InventaireEntete';
import {InventaireExportService} from '../../services/epona/inventaire-export.service';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {HttpService} from "../../services/http.service";
import {FilterTools} from "../../commons/FilterTools";

@Component({
    selector: 'app-liste-inventaires',
    templateUrl: './liste-inventaires.component.html',
    styleUrls: ['./liste-inventaires.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Inventaires') }
    ],
    standalone: false
})
export class ListeInventairesComponent implements OnInit, IPaginatedComponent<InventaireEntete> {

  private readonly DEFAULT_PAGE_SIZE = 10;
  private readonly DEFAULT_ORDER_BY = 'idInventaireEntete';
  private readonly DEFAULT_ORDER_DIR = 'desc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;

  dataSource = new MatTableDataSource<InventaireEntete>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    idInventaireEntete: new TableColumn({label: 'No.',          hiddable: false, exportFormat: 'integer'}),
    batiment:           new TableColumn({label: 'Bâtiment'}),
    lieu:               new TableColumn({label: 'Lieu'}),
    groupeArticles:     new TableColumn({label: 'Groupe'}),
    sousGroupeArticles: new TableColumn({label: 'Sous-groupe'}),
    libelle:            new TableColumn({label: 'Libellé',      hiddable: false}),
    dateCloture:        new TableColumn({label: 'Date clôture', exportFormat: 'datetime'}),
    utilisateurCloture: new TableColumn({label: 'Clôturé par'}),
    actions:            new TableColumn({label: 'Actions',      export: false, hiddable: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.INVENTAIRES;

  displayedColumns: string[] = [];

  @ViewChild(InventairesFilterComponent)
  filterComponent: InventairesFilterComponent;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  lieuCourant: Lieu;
  filter: InventaireFilter;

  constructor(private inventaireService: InventaireService,
              private exportService: InventaireExportService,
              private httpService: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              public onlineService: OnlineService) {
  }

  ngOnInit() {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis la liste des ivnentaires
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;

      // Lancement de la recherche
      this.rechercher();
    });
  }

  // Initialisation des filtres à partie des paramètres de l'URL
  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre d'inventaires
    this.filter = new InventaireFilter();
    this.filter.annee = params.get('annee') ? +params.get('annee') : null;
    this.filter.mois = params.get('mois') ? +params.get('mois') : null;
    this.filter.cloture = params.get('cloture') ? Tools.stringToBoolean(params.get('cloture')) : null;
    this.filter.utilisateur = params.get('utilisateur');
    this.filter.libelle = params.get('libelle');
    this.filter.idInventaire = params.get('idInventaire') ? +params.get('idInventaire') : null;
    this.filter.groupeArticles     = FilterTools.buildGroupeArticlesFromURL(params);
    this.filter.sousGroupeArticles = FilterTools.buildSousGroupeArticlesFromURL(params);
    // TODO
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre d'inventaires
    this.filter = new InventaireFilter();
    this.filter.cloture = false;
  }

  rechercher() {
    const search = this.buildSearchFromFilters();
    search.fields = 'idInventaireEntete,lieu.nomRu,lieu.nomUd,groupeArticles.libelle,sousGroupeArticles.libelle,libelle,dateCloture,utilisateurCloture';
    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.inventaireService.getListeInventairesWithPagination(search).subscribe(this.httpService.handlePaginatedSubscribe(this));
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  private buildSearchFromFilters(): InventaireSearch {
    const search = new InventaireSearch();

    search.setIdsLieu(this.lieuCourant);

    search.idInventaire = this.filter.idInventaire;
    search.libelle = this.filter.libelle;
    search.utilisateur = this.filter.utilisateur;
    search.cloture = this.filter.cloture;
    search.annee = this.filter.annee;
    search.mois = this.filter.mois;
    search.codeGroupeArticles = this.filter.groupeArticles?.code;
    search.idSousGroupeArticles = this.filter.sousGroupeArticles?.idSousGroupeArticles;

    return search;
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche d'inventaire pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: InventaireFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
      this.router.navigate(['liste-inventaires'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): InventaireQueryParams {
    const params = new InventaireQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.idInventaire = this.filter.idInventaire;
    params.libelle      = this.filter.libelle;
    params.utilisateur  = this.filter.utilisateur;
    params.cloture      = this.filter.cloture;
    params.annee        = this.filter.annee;
    params.mois         = this.filter.mois;
    params.codeGroupe   = this.filter.groupeArticles?.code;
    params.idSousGroupe = this.filter.sousGroupeArticles?.idSousGroupeArticles;

    return params;
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.rechercher();
  }


  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.rechercher();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListeInventaires() {
    const search = this.buildSearchFromFilters();
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;


    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportInventaires(search);
  }



}
