import {Pipe} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: 'decimal1',
    standalone: false
})
export class Decimal1Pipe extends DecimalPipe {
  transform(value): any {
    return super.transform(value, '1.1-1');
  }
}
