import {Component, OnInit, ViewChild} from '@angular/core';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {CnousTableExporterDirective} from '../../commons/directives/cnous-table-exporter.directive';
import {UserService} from '../../services/user.service';
import {MessageTool} from '../../commons/MessageTool';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ObjectHelper} from '../../helpers/object-helper';
import {DisplayedColumnsTools} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {FiltreFdnc} from '../../model/epona-ui/filtre-fdnc';
import {TableauFdncComponent} from '../tableau-fdnc/tableau-fdnc.component';
import {FdncService} from '../../services/epona/fdnc.service';
import {FdncEponaSearch} from '../../model/epona-api/fdnc-epona-search';
import {FdncBnaSearch} from '../../model/bna-api/fdnc-bna-search';
import {BnaFdncService} from '../../services/bna/bna-fdnc.service';
import {Marche} from '../../model/epona-api/Marche';
import {Fournisseur} from '../../model/epona-api/Fournisseur';
import {Article} from '../../model/epona-api/Article';
import {Lieu} from '../../model/epona-api/Lieu';
import {FiltrePaniersComponent} from '../../paniers/filtre-paniers/filtre-paniers.component';
import {FiltreFdncComponent} from '../filtre-fdnc/filtre-fdnc.component';
import {FdncQueryParams} from '../../model/epona-ui/fdnc-query-params';
import {forkJoin, Observable} from 'rxjs';
import {FdncEponaBna} from '../../model/epona-api/fdnc-epona-bna';
import {CommandeService} from '../../services/epona/commande.service';
import {CommandeSearch} from '../../model/epona-api/CommandeSearch';
import {CodeArticlePipe} from '../../commons/pipes/code-article.pipe';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {EntreeSortieService} from '../../services/epona/entree-sortie.service';
import {CommandeEntete} from '../../model/epona-api/CommandeEntete';
import {StatutFdnc} from "../../model/bna-api/statut-fdnc";
import {EtapeFdnc} from "../../model/bna-api/etape-fdnc";
import {IdStatutFdnc} from "../../commons/constants/IdStatutFdnc";
import {IdEtapeFdnc} from "../../commons/constants/IdEtapeFdnc";
import {Fdnc} from "../../model/epona-api/fdnc";
import {FdncBna} from "../../model/bna-api/fdnc-bna";
import {ContexteFdnc} from '../../model/bna-api/contexte-fdnc';

@Component({
    selector: 'epona-recherche-fdnc',
    templateUrl: './recherche-fdnc.component.html',
    styleUrls: ['./recherche-fdnc.component.css'],
    standalone: false
})
export class RechercheFdncComponent implements OnInit {

  filtre: FiltreFdnc = new FiltreFdnc();
  listeFDNC: Array<FdncEponaBna> = [];
  lieuCourant: Lieu;

  FDNC_EPONA_FIELDS: string = "" +
    "idFdnc," +
    "commandeEntete.idCommandeEntete," +
    "commandeEntete.numeroEj," +
    "commandeEntete.lieuDemandeur.nomRu," +
    "commandeEntete.lieuDemandeur.nomUd," +
    "commandeEntete.fournisseur.nom," +
    "commandeEntete.sousLotZg.codeMarcheOrion," +
    "commandeLigne.article.codeArticleAchat," +
    "commandeLigne.article.designationAchat," +
    "commandeLigne.reference," +
    "entreeSortieEntete.idEntreeSortieEntete," +
    "entreeSortieEntete.codeBordereauLivraison," +
    "entreeSortieLigne.article.codeArticleAchat," +
    "entreeSortieLigne.article.designationAchat," +
    "entreeSortieLigne.referenceFournisseur," +
    "emise," +
    "dateEmission," +
    "utilisateurEmission," +
    "idContexte," +
    "dateConstat," +
    "numeroLotProduit," +
    "observationsNonConformite," +
    "observationsTraitement," +
    "tempsTraitement," +
    "utilisateurCreation," +
    "dateCreation," +
    "utilisateurModification," +
    "dateModification," +
    "listeTraitementsNonConformite," +
    "listeTypesNonConformite";

  FDNC_BNA_FIELDS: string = "" +
    "applicationOrigine.code," +
    "idFdnc," +
    "code," +
    "numeroFdnc," +
    "articleLotMarche.article.codeArticle," +
    "articleLotMarche.article.denomination," +
    "articleLotMarche.referenceArticleFournisseur," +
    "numeroLotProduit," +
    "emission," +
    "declarant," +
    "contexte," +
    "dateConstat," +
    "dateEmission," +
    "nomDeclarantLegal," +
    "dateDerniereModification," +
    "gravite," +
    "statut," +
    "etapeEnCours," +
    "numeroEjCommande," +
    "numeroBlFournisseur";

  readonly COLUMNS = TableauFdncComponent.COLUMNS;
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.FDNC;

  colonnesAffichees: string[] = [];

  // Exporter inclus dans le composant TableauFdnc
  cnousTableExporter!: CnousTableExporterDirective;

  @ViewChild(TableauFdncComponent)
  set element(element: TableauFdncComponent) {
    this.cnousTableExporter = element.cnousTableExporter;
  }

  @ViewChild(FiltreFdncComponent)
  filterComponent: FiltrePaniersComponent;

  constructor(private fdncService: FdncService,
              private bnaFdncService: BnaFdncService,
              private commandeService: CommandeService,
              private entreeSortieService: EntreeSortieService,
              private codeArticlePipe: CodeArticlePipe,
              private userService: UserService,
              private messageService: MessageTool,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMap pour que le ngOnInit soit rappelé
    //  si une recherche est lancée depuis la page elle-même
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      // Initialisation des filtres
      // Si aucune valeur n'est passée en paramètre alors le filtre est initialisé avec les valeurs par défaut
      if (paramMap.keys.length === 0) {
        this.initDefaultFilter();
      } else {
        this.initFilterFromURL(paramMap);
      }

      // Lancement de la recherche
      this.loadData()
    });
  }

  private initFilterFromURL(paramMap: ParamMap) {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(paramMap);

    this.filtre.fournisseur   = this.initFournisseurFromUrl(paramMap);
    this.filtre.marche        = this.initMarcheFromUrl(paramMap);
    this.filtre.article       = this.initArticleFromUrl(paramMap);
    this.filtre.gravite       = ObjectHelper.stringToBooleanOrNull(paramMap.get('gravite'));
    this.filtre.statuts       = RechercheFdncComponent.buildIdsStatutFromURL(paramMap);
    this.filtre.etapesEnCours = RechercheFdncComponent.buildIdsEtapeEnCoursFromURL(paramMap);
  }

  initFournisseurFromUrl(paramMap: ParamMap): Fournisseur | null {
    if (paramMap.get('idFournisseur')) {
      const fournisseur = new Fournisseur();
      fournisseur.idFournisseur = ObjectHelper.stringToNumberOrNull(paramMap.get('idFournisseur'));
      return fournisseur;
    }
    return null;
  }

  initMarcheFromUrl(paramMap: ParamMap): Marche | null {
    if (paramMap.get('codeMarcheOrion')) {
      const marche = new Marche();
      marche.codeMarcheOrion = paramMap.get('codeMarcheOrion');
      return marche;
    }
    return null;
  }

  initArticleFromUrl(paramMap: ParamMap): Article | null {
    if (paramMap.get('idArticle')) {
      const article = new Article();
      article.idArticle = ObjectHelper.stringToNumberOrNull(paramMap.get('idArticle'));
      return article;
    }
    return null;
  }

  private static buildIdsStatutFromURL(params: ParamMap): StatutFdnc[] {
    if (params.get('idsStatut')) {
      return params.getAll('idsStatut').filter(idStatut => !isNaN(+idStatut)).map(idStatut => new StatutFdnc(+idStatut));
    } else {
      return null;
    }
  }

  private static buildIdsEtapeEnCoursFromURL(params: ParamMap): EtapeFdnc[] {
    if (params.get('idsEtapeEnCours')) {
      return params.getAll('idsEtapeEnCours').filter(idEtape => !isNaN(+idEtape)).map(idEtape => new EtapeFdnc(+idEtape));
    } else {
      return null;
    }
  }

  private initDefaultFilter() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.filtre = new FiltreFdnc();
    this.filtre.statuts = [
      new StatutFdnc(IdStatutFdnc.NON_EMISE),
      new StatutFdnc(IdStatutFdnc.EN_COURS)
    ];
    this.filtre.etapesEnCours = [
    ];
  }

  private loadData(): void {
    const listeObservables: Observable<any[]>[] = [];

    // Appel à la Épona si aucune gravité n'est sélectionnée,
    //  qu'aucun statut n'est sélectionné ou que le statut "Non émise" est sélectionné
    //  et qu'aucune étape n'est sélectionnée ou que l'étape "En cours de rédaction dans Épona" est sélectionnée
    const appelEpona = this.filtre.gravite === null
      && (this.filtre.statuts === null
       || this.filtre.statuts.length === 0
       || this.filtre.statuts.some(statut => statut.idStatutFdnc === IdStatutFdnc.NON_EMISE))
      && (this.filtre.etapesEnCours === null
       || this.filtre.etapesEnCours.length === 0
       || this.filtre.etapesEnCours.some(etape => etape.idEtapeFdnc === IdEtapeFdnc.EN_COURS_DE_REDACTION_DANS_EPONA));


    // Appel à la BNA si aucun statut n'est sélectionné ou au moins un statut autre que "Non émise" est sélectionné
    //  et qu'aucune étape n'est sélectionnée ou qu'au moins une étape autre que "En cours de rédaction dans Épona" est sélectionnée
    const appelBna = (this.filtre.statuts === null
                   || this.filtre.statuts.length === 0
                   || this.filtre.statuts.filter(statut => statut.idStatutFdnc !== IdStatutFdnc.NON_EMISE).length > 0)
                  && (this.filtre.etapesEnCours === null
                   || this.filtre.etapesEnCours.length === 0
                   || this.filtre.etapesEnCours.filter(etape => etape.idEtapeFdnc !== IdEtapeFdnc.EN_COURS_DE_REDACTION_DANS_EPONA).length > 0);

    if (appelEpona) {
      const eponaSearch = new FdncEponaSearch();
      eponaSearch.setIdsLieu(this.lieuCourant);
      eponaSearch.emise           = false;
      eponaSearch.idFournisseur   = this.filtre.fournisseur?.idFournisseur;
      eponaSearch.codeMarcheOrion = this.filtre.marche?.codeMarcheOrion;
      eponaSearch.idArticle       = this.filtre.article?.idArticle;
      eponaSearch.fields          = this.FDNC_EPONA_FIELDS;
      listeObservables.push(this.fdncService.getListeFdnc(eponaSearch));
    }

    if (appelBna) {
      const bnaSearch = new FdncBnaSearch();
      bnaSearch.idEtablissementBns = this.lieuCourant.idCrous;
      bnaSearch.codeOrigine        = 'EPO';
      bnaSearch.idBatimentBns      = this.lieuCourant.idBatimentBns;
      bnaSearch.idLieuBns          = this.lieuCourant.idLieuBns;
      bnaSearch.codeArticle        = this.filtre.article?.codeArticleAchat;
      bnaSearch.gravite            = this.filtre.gravite === true ? 1 : this.filtre.gravite === false ? 0 : null;
      if (this.filtre.statuts) {
        const statutsBna = this.filtre.statuts?.filter(statut => statut.idStatutFdnc !== IdStatutFdnc.NON_EMISE);
        if (statutsBna.length == 1) {
          bnaSearch.idStatut = statutsBna[0].idStatutFdnc;
        }
      }
      bnaSearch.idsEtapeEnCours    = this.filtre.etapesEnCours?.filter(etape => etape.idEtapeFdnc !== IdEtapeFdnc.EN_COURS_DE_REDACTION_DANS_EPONA).map(etape => etape.idEtapeFdnc);
      bnaSearch.fields             = this.FDNC_BNA_FIELDS;
      listeObservables.push(this.bnaFdncService.getListeFdnc(bnaSearch));
    }

    if (appelBna || appelEpona) {
      forkJoin(listeObservables).subscribe({
        next: data => {
          this.listeFDNC = [];
          const listeFdncApi = [].concat(...data);
          this.buildFdncEponaBnaFromEpona(listeFdncApi.filter(f => f.dateCreation)).forEach(f => this.listeFDNC.push(f));
          this.buildFdncEponaBnaFromBna(listeFdncApi.filter(f => f.applicationOrigine)).forEach(f => this.listeFDNC.push(f));
          this.setColonnesAffichees();
        },
        error: err => {
          this.messageService.sendError(err);
        }
      });
    } else {
      this.listeFDNC = [];
    }
  }

  buildFdncEponaBnaFromEpona(data: Array<Fdnc>): Array<FdncEponaBna> {
    return data.map(f => {
      let fdnc: FdncEponaBna = new  FdncEponaBna();
      fdnc.idFdnc                   = f.idFdnc;
      fdnc.commande                 = f.commandeEntete;
      fdnc.batiment                 = fdnc.commande.lieuDemandeur.nomRu;
      fdnc.lieu                     = fdnc.commande.lieuDemandeur.nomUd;
      fdnc.bl                       = f.entreeSortieEntete;
      const article = this.fdncService.extractArticle(f);
      fdnc.codeArticle              = article?.codeArticleAchat;
      fdnc.designationArticle       = article?.designationAchat;
      fdnc.referenceFournisseur     = this.fdncService.extractReference(f);
      fdnc.numeroLotProduit         = f.numeroLotProduit;
      fdnc.emission                 = f.dateEmission;
      fdnc.declarant                = f.utilisateurEmission;
      let contexteFdnc= new ContexteFdnc();
      contexteFdnc.idContexteFdnc = f.idContexte;
      fdnc.contexte                 = contexteFdnc;
      fdnc.dateConstat              = f.dateConstat;
      fdnc.dateDerniereModification = this.getDateDerniereModification(f);
      fdnc.gravite                  = null;
      fdnc.statut                   = StatutFdnc.STATUT_EPONA;
      fdnc.etapeEnCours             = EtapeFdnc.ETAPE_EPONA;
      return fdnc;
    });
  }

  buildFdncEponaBnaFromBna(data: Array<FdncBna>): Array<FdncEponaBna> {
    return data.map(f => {
      let fdnc: FdncEponaBna = new  FdncEponaBna();
      fdnc.idFdnc                   = f.numeroFdnc;
      fdnc.codeBna                  = f.code;
      this.commandeService.getListeEntetesComplete(this.buildCommandeSearch(f)).subscribe(data => {
        fdnc.commande = data[0];
        fdnc.batiment = fdnc.commande.lieuDemandeur.nomRu;
        fdnc.lieu     = fdnc.commande.lieuDemandeur.nomUd;

        this.entreeSortieService.getListeEntetesComplete(this.buildBLSearch(f, fdnc.commande)).subscribe(data => {
          fdnc.bl = data[0];
        }) ;
      })
      fdnc.codeArticle              = f.articleLotMarche?.article.codeArticle;
      fdnc.designationArticle       = f.articleLotMarche?.article.denomination;
      fdnc.referenceFournisseur     = f.articleLotMarche?.referenceArticleFournisseur;
      fdnc.numeroLotProduit         = f.numeroLotProduit;
      fdnc.emission                 = f.dateEmission;
      fdnc.declarant                = f.nomDeclarantLegal;
      fdnc.contexte                 = f.contexte;
      fdnc.dateConstat              = f.dateConstat;
      fdnc.dateDerniereModification = this.getDateDerniereModification(f);
      fdnc.gravite                  = f.gravite === 0 ? 'Mineure' : 'Majeure';
      fdnc.statut                   = f.statut;
      fdnc.etapeEnCours             = f.etapeEnCours;
      return fdnc;
    })
  }

  buildCommandeSearch(f) {
    const search = new CommandeSearch();
    search.setIdsLieu(this.lieuCourant);
    search.numeroCommande = f.numeroEjCommande;
    search.fields = '' +
      'idCommandeEntete,' +
      'numeroEj,' +
      'lieuDemandeur.nomRu,' +
      'lieuDemandeur.nomUd,' +
      'fournisseur.nom,' +
      "sousLotZg.codeMarcheOrion" +
      '';
    return search;
  }

  buildBLSearch(f, c:CommandeEntete): EntreeSortieSearch {
    const search = new EntreeSortieSearch();
    search.setIdsLieu(this.lieuCourant);
    search.numeroCommande = f.numeroEjCommande;
    search.idCommande = c.idCommandeEntete;
    search.codeBL = f.numeroBlFournisseur;
    search.fields = 'idEntreeSortieEntete,codeBordereauLivraison';
    return search;
  }

  getDateDerniereModification(fdnc): Date {
    if (fdnc.dateCreation) {
      return fdnc.dateModification ? fdnc.dateModification : fdnc.dateCreation;
    } else if (fdnc.dateEmission) {
      return fdnc.dateDernierEchange ? fdnc.dateDernierEchange : fdnc.dateEmission;
    }
    return null;
  }

  private getQueryParams(): FdncQueryParams {
    const params = new FdncQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.idFournisseur   = this.filtre.fournisseur?.idFournisseur;
    params.codeMarcheOrion = this.filtre.marche?.codeMarcheOrion;
    params.idArticle       = this.filtre.article?.idArticle;
    params.gravite         = this.filtre.gravite;
    params.idsStatut       = this.filtre.statuts?.map(s => s.idStatutFdnc);
    params.idsEtapeEnCours = this.filtre.etapesEnCours?.map(e => e.idEtapeFdnc);

    return params;
  }

  setColonnesAffichees() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.colonnesAffichees.length === 0) {
      this.colonnesAffichees = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, TableauFdncComponent.COLUMNS);
    }
  }

  onFilterSubmitted(filter: FiltreFdnc) {
    this.filtre = filter;
    this.redirect();
  }

  private redirect() {
    // le paramètre 'r' est ajouté aux paramètres du filtre
    //  pour s'assurer qu'au moins un paramètre sera présent (cf. ngOnInit())
    const params = {...{r: 1}, ...this.getQueryParams()};
    this.router.navigate(['liste-fdnc'], {queryParams: params});
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }
}
