import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from "@angular/forms";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {OptionBooleen} from "../../enum/OptionBooleen";
import {SelectItem} from "./SelectItem";

@Component({
    selector: 'select-boolean',
    templateUrl: './select-boolean.component.html',
    styleUrls: ['./select-boolean.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectBooleanComponent }],
    standalone: false
})
export class SelectBooleanComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<boolean> {

  public readonly DEFAULT = Const.DEFAULT;

  @Input() defaultLabel: string;
  @Input() trueLabel: string;
  @Input() falseLabel: string;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  formCtrl: UntypedFormControl;
  liste: SelectItem[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: boolean): void {
    this.formCtrl.setValue(this.setCurrentItem(obj));
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit();
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): boolean {
    return this.getCurrentItem();
  }
  set value(value: boolean) {
    this.formCtrl.setValue(this.setCurrentItem(value));
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): boolean {
    if (this.formCtrl.value === OptionBooleen.OUI) {
      return true;
    } else if (this.formCtrl.value === OptionBooleen.NON) {
      return false;
    } else {
      return null;
    }
  }

  setCurrentItem(bool: boolean) {
    if (bool === true) {
      return OptionBooleen.OUI;
    } else if (bool === false) {
      return OptionBooleen.NON;
    } else {
      return Const.DEFAULT;
    }
  }

  private loadListe() {
    this.liste = [
      new SelectItem(Const.DEFAULT, this.defaultLabel),
      new SelectItem(OptionBooleen.OUI, this.trueLabel ? this.trueLabel : OptionBooleen.OUI),
      new SelectItem(OptionBooleen.NON, this.falseLabel ? this.falseLabel : OptionBooleen.NON),
    ];
  }
}
