<input #article type="text" matInput [formControl]="articleCtrl" [matAutocomplete]="auto" (keyup)="keyup()">

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayArticle" (optionSelected)="optionSelected()"
                  (optionActivated)="optionActivated($event)" panelWidth="auto">
  <ng-container *ngIf="listeArticlesAffiches.length === 0">
    <mat-option [disabled]="true">Aucun résultat</mat-option>
  </ng-container>

  <ng-container *ngIf="listeArticlesAffiches.length >= nbMaxResults">
    <mat-option [disabled]="true">Trop de résultats, affiner la recherche</mat-option>
  </ng-container>

  <ng-container *ngIf="listeArticlesAffiches.length > 0 && listeArticlesAffiches.length < nbMaxResults">
    <mat-option *ngFor="let article of listeArticlesAffiches" [value]="article" class="option-avancee">
      <span class="libelle-principal">
        <span class="designation">{{ article.designationAchat }}</span>
      </span>

      <span class="libelle-secondaire">
        <span class="reference">
          <span class="libelle">Référence</span>
          <span class="valeur">
            {{ article.reference }}
          </span>
        </span>

        <span class="prixHt">
          <span class="libelle">Prix HT</span>
          <span class="valeur">
            {{ article.prixHt | currency:'EUR':'symbol':'1.2-4' }}
          </span>
        </span>

        <span class="lot-marche"
              [ngClass]="{'lots-marches-differents': article.sousLotZg.idSousLotZg !== sousLotZgReference.idSousLotZg}"
              *ngIf="!sousLotZg && sousLotZgReference">
          <span class="libelle">Lot-marché</span>
          <span class="valeur">
            {{ article.sousLotZg.codeMarcheOrion }}.{{ article.sousLotZg.numeroSousLot }}
          </span>
        </span>
      </span>

      <span class="libelle-secondaire" *ngIf="!sousLotZg && !fournisseur">
        <span class="zone-geographique" *ngIf="multiZG">
          <abbr class="libelle" title="Zone géographique">ZG</abbr>
          <span class="valeur">
            {{ article.sousLotZg.zoneGeographique ? article.sousLotZg.zoneGeographique.libelle : 'Toute zone' }}
          </span>
        </span>

        <span class="fournisseur">
          <span class="libelle">Fournisseur</span>
          <span class="valeur">
            {{ article.sousLotZg.fournisseur.nom }}
          </span>
        </span>

        <span class="lot-marche">
          <span class="libelle">Lot-marché</span>
          <span class="valeur">
            {{ article.sousLotZg.codeMarcheOrion }}.{{ article.sousLotZg.numeroSousLot }}
          </span>
        </span>
      </span>
    </mat-option>
  </ng-container>
</mat-autocomplete>
