import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import {ParametrageLieuArticle} from "../../model/epona-api/ParametrageLieuArticle";

@Component({
    selector: 'epona-ajout-article-modal',
    templateUrl: './ajout-article-modal.component.html',
    styleUrls: ['./ajout-article-modal.component.css'],
    standalone: false
})
export class AjoutArticleModalComponent implements OnInit {

  form : UntypedFormGroup;

  constructor(private dialog: MatDialogRef<AjoutArticleModalComponent>,
              private fb: UntypedFormBuilder) {

    this.form = fb.group({
      article :   fb.control(null, Validators.required),
      stockMini : fb.control('', Validators.min(1))
    })
  }

  ngOnInit() {
  }

  annuler() {
    this.dialog.close();
  }

  save() {
    const parametrageLieuArticle = new ParametrageLieuArticle();
    parametrageLieuArticle.article   = this.form.get('article').value;
    parametrageLieuArticle.stockMini = this.form.get('stockMini').value;
    return parametrageLieuArticle;
  }

  disableBoutonAjouter() {
    return !this.form.valid;
  }
}
