import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'epona-link-resource',
    templateUrl: './link-resource.component.html',
    styleUrls: ['./link-resource.component.css'],
    standalone: false
})
export class LinkResourceComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() routerLink: any[];
  @Input() tooltip: string;
  @Input() showLink: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}
