import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataCommentaireInventaire} from "../../model/epona-ui/DialogDataCommentaireInventaire";
import {DateFormatPipe} from "../../commons/pipes/DateFormatPipe";
import {InventaireService} from "../../services/epona/inventaire.service";
import {MessageTool} from "../../commons/MessageTool";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {Article} from "../../model/epona-api/Article";
import {ArticleTools} from "../../commons/ArticleTools";

@Component({
    selector: 'app-dialog-commentaire-inventaire',
    templateUrl: '../../commons/dialog-comment/dialog-comment.component.html',
    styleUrls: ['../../commons/dialog-comment/dialog-comment.component.css'],
    standalone: false
})
export class DialogCommentaireInventaireComponent implements OnInit {
  commentaireCtrl: UntypedFormControl;
  article: Article;
  dlc: string;
  readonly fieldName = 'Commentaire';              // toujours 'Commentaire' dans le cas d'un inventaire mais nécessaire pour le template (commun avec les commandes)
  readonly utilisation = UtilisationArticle.ACHAT; // toujours 'achat' dans le cas d'un inventaire mais nécessaire pour le template (commun avec les commandes)

  constructor(public dialog: MatDialogRef<DialogCommentaireInventaireComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataCommentaireInventaire,
              private dateFormat: DateFormatPipe,
              private inventaireService: InventaireService,
              private messageTool: MessageTool) {

    this.commentaireCtrl = new UntypedFormControl('');
  }

  ngOnInit(): void {
    this.commentaireCtrl.setValue(this.data.ligne.commentaire);
    this.article = this.data.ligne.article;
    this.dlc = `DLC : ${this.data.ligne.dlc ? this.dateFormat.transform(this.data.ligne.dlc) : 'non renseignée'}`;
  }

  close() {
    this.dialog.close();
  }

  save() {
    if (this.commentaireCtrl.value !== this.data.ligne.commentaire && this.commentaireCtrl.value !== undefined) {
      this.data.ligne.commentaire = this.commentaireCtrl.value;

      this.inventaireService.putInventaireLignes(this.data.entete.idInventaireEntete, this.data.ligne).subscribe(() => {
        const designation = ArticleTools.getDesignation(this.article);
        this.messageTool.sendSuccess(`La ligne de l'article ${ designation }
          pour la DLC ${this.data.ligne.dlc ? this.dateFormat.transform(this.data.ligne.dlc) : 'non renseignée'} a été mise à jour avec succès`);

        this.data.ligneUpdated = this.data.ligne;

        this.dialog.close();

      }, err => {
        this.messageTool.sendError(err);
      });
    } else {
      this.dialog.close();
    }
  }
}
