import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VemService } from '../services/vem.service';
import {UserService} from '../services/user.service';
import {Lieu} from "../model/epona-api/Lieu";
import {VentesFilter} from "../model/epona-ui/VentesFilter";
import {MessageTool} from "../commons/MessageTool";
import {Vente} from "../model/epona-api/Vente";
import {DatePipe} from "@angular/common";
import {Format} from "../commons/constants/Format";
import {Const} from "../commons/constants/Const";
import {MatPaginator} from "@angular/material/paginator";
import {SortHelper} from "../helpers/sort-helper";

@Component({
    selector: 'epona-ventes-temps-reel',
    templateUrl: './ventes-temps-reel.component.html',
    styleUrls: ['./ventes-temps-reel.component.css'],
    standalone: false
})
export class VentesTempsReelComponent implements OnInit, AfterViewInit {
  DEFAULT: number = Const.DEFAULT;

  form: UntypedFormGroup;
  dataSource = new MatTableDataSource<Vente>([]);

  private lieuFilter: Lieu;

  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly today = new Date();
  readonly yesterday = VentesTempsReelComponent.buildYesterday();

  constructor(private userService: UserService,
              private vemService: VemService,
              private toastr: ToastrService,
              private fb: UntypedFormBuilder,
              private messageTool: MessageTool,
              private dateFormat: DatePipe) {
  }

  displayedColumns = ['ru', 'ud', 'caisse', 'montantTTC', 'nombreTickets'];
  @ViewChild(MatSort, {static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'ru': return SortHelper.stringToString(item.caisse.ud.ru.nom);
        case 'ud': return SortHelper.stringToString(item.caisse.ud.nom);
        case 'caisse': return SortHelper.stringToString(item.caisse.nom);
        default: return item[property];
      }
    };

    this.dataSource.sort = this.sort;

    this.form = this.fb.group({
      journee: [this.today, Validators.required],
      heureDebut: ['00:00', Validators.required],
    });

    this.lieuCourant = new Lieu();
    this.lieuCourant.idCrous = +localStorage.getItem('idCrous');
    this.lieuFilter = this.lieuCourant;

    this.getVentes();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onSubmit() {
    this.getVentes();
  }

  getVentes() {
    const venteFilter = new VentesFilter();
    venteFilter.idCrous = this.lieuFilter.idCrous;
    venteFilter.idUg = this.lieuFilter.idUg === this.DEFAULT ? null : this.lieuFilter.idUg;
    venteFilter.idRu = this.lieuFilter.idRu === this.DEFAULT ? null : this.lieuFilter.idRu;
    venteFilter.idUd = this.lieuFilter.idUd === this.DEFAULT ? null : this.lieuFilter.idUd;
    venteFilter.date = this.dateFormat.transform(this.form.get('journee').value, Format.FORMAT_DATE_URL_REST);
    venteFilter.heureDebut = this.form.get('heureDebut').value;
    venteFilter.fields = 'caisse.ud.ru.ug.idUg,' +
      'caisse.ud.ru.ug.nom,caisse.ud.ru.idRu,caisse.ud.ru.nom,caisse.ud.idUd,caisse.ud.nom,caisse.idCaisse,' +
      'caisse.nom,montantTTC,nombreTickets';

    this.vemService.getVentes(venteFilter).subscribe(data => {
      this.dataSource.data = data;
    }, err => {
      this.messageTool.sendError(err);
    });
  }

  getLieuSubmitted(lieu: Lieu) {
    this.lieuFilter.idCrous = lieu.idCrous;
    this.lieuFilter.idUg = lieu.idUg;
    this.lieuFilter.idRu = lieu.idRu;
    this.lieuFilter.idUd = lieu.idUd;
  }

  static buildYesterday(): Date {
    let dateToday = new Date();
    dateToday.setDate(dateToday.getDate() - 1);
    return dateToday;
  }
}
