import {Pipe, PipeTransform} from '@angular/core';
import {SousGroupe} from "../../model/epona-api/SousGroupe";
import {FamilleMarches} from "../../model/epona-api/FamilleMarches";

@Pipe({
    name: 'sousGroupe',
    standalone: false
})
export class SousGroupePipe implements PipeTransform {
  transform(sousGroupe: SousGroupe): string {
    return SousGroupePipe.buildLibelle(sousGroupe);
  }

  static buildLibelle(sousGroupe: SousGroupe): string {
    if (sousGroupe) {
      return sousGroupe.groupe.libelle
        + ' > ' + sousGroupe.libelle;
    } else {
      return '';
    }
  }
}
