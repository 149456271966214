{
  "name": "epona-ui",
  "version": "1.23.0",
  "scripts": {
    "build:gitlab-prod": "ng build --aot -c production --output-path=prod --output-hashing=all",
    "build:gitlab-ppd": "ng build --aot -c ppd --output-path=ppd --output-hashing=all",
    "build:gitlab-int": "ng build --aot -c int --output-path=int --output-hashing=all",
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.7.15",
    "@angular/animations": "^19.0.3",
    "@angular/cdk": "^19.0.2",
    "@angular/common": "^19.0.3",
    "@angular/compiler": "^19.0.3",
    "@angular/core": "^19.0.3",
    "@angular/forms": "^19.0.3",
    "@angular/material": "^19.0.2",
    "@angular/platform-browser": "^19.0.3",
    "@angular/platform-browser-dynamic": "^19.0.3",
    "@angular/router": "^19.0.3",
    "@angular/service-worker": "^19.0.3",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-svg-core": "~1.2.36",
    "@fortawesome/free-solid-svg-icons": "^5.15.4",
    "dexie": "^3.2.2",
    "jwt-decode": "^2.2.0",
    "ng2-pdf-viewer": "^10.4.0",
    "ngx-cookie-service": "19.0.0",
    "ngx-filesaver": "^15.0.1",
    "ngx-toastr": "^16.0.1",
    "normalize.css": "^8.0.1",
    "rxjs": "^6.6.3",
    "tslib": "^2.0.0",
    "xlsx": "^0.18.5",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.3",
    "@angular/cli": "^19.0.3",
    "@angular/compiler-cli": "^19.0.3",
    "@angular/language-service": "^19.0.3",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "^2.0.8",
    "@types/node": "^12.11.1",
    "codelyzer": "^6.0.0",
    "jasmine-core": "~3.6.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "protractor": "~7.0.0",
    "ts-node": "~7.0.0",
    "tslint": "~6.1.0",
    "typescript": "~5.6.3"
  }
}
