import {StockSearch} from "./StockSearch";

export class StockCompactSearch extends StockSearch {
  stockFaible: boolean;
  nombreJoursAvantDlc: number;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;

}
