import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {FournisseurFilter} from "../../model/epona-ui/fournisseur-filter";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
    selector: 'epona-fournisseurs-filter',
    templateUrl: './fournisseurs-filter.component.html',
    styleUrls: ['./fournisseurs-filter.component.css'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                floatLabel: 'always',
                subscriptSizing: 'dynamic'
            }
        }
    ],
    standalone: false
})
export class FournisseursFilterComponent implements OnChanges {

  @Input() filter: FournisseurFilter;

  @Output() readonly filterSubmitted = new EventEmitter<FournisseurFilter>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      marche: null,
      nom: null,
      siret: null
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.controls['marche'].setValue(this.filter.marche);
    this.form.controls['nom'].setValue(this.filter.nom);
    this.form.controls['siret'].setValue(this.filter.siret);
  }

  onSubmit() {
    this.filter.marche = this.form.controls['marche'].value;
    this.filter.nom    = this.form.controls['nom'].value;
    this.filter.siret  = this.form.controls['siret'].value;
    this.filterSubmitted.emit(this.filter);
  }
}
