import {Component, Input} from '@angular/core';
import {TypeMetaData} from "../../enum/TypeMetaData";

@Component({
    selector: 'epona-meta-data',
    templateUrl: './meta-data.component.html',
    styleUrls: ['./meta-data.component.css'],
    standalone: false
})
export class MetaDataComponent {

  @Input() type: TypeMetaData;
  @Input() date: Date;
  @Input() utilisateur: string;
  @Input() commentaire: string;

  constructor() { }

  label(type: TypeMetaData): string {
    switch (type) {
      case TypeMetaData.CREATION:                   return 'Création / brouillon';
      case TypeMetaData.MODIFICATION:               return 'Dernière modification';
      case TypeMetaData.VALIDATION:                 return 'Validation';
      case TypeMetaData.MAJ_EJ:                     return 'Mise à jour de l\'EJ';
      case TypeMetaData.ENVOI_SF:                   return 'Envoi du SF vers Orion';
      case TypeMetaData.SUPPRESSION_SF:             return 'Suppression du SF';

      case TypeMetaData.PREPARATION_EJ:             return 'Préparation de l\'EJ';
      case TypeMetaData.ATTENTE_RETOUR_ORION:       return 'Envoi de l\'EJ vers Orion';
      case TypeMetaData.REFUS_ORION:                return 'Rejet de l\'EJ par Orion';
      case TypeMetaData.EN_PREPARATION_FOURNISSEUR: return 'Envoi de la commande au fournisseur';
      case TypeMetaData.EN_PREPARATION_MAGASIN:     return 'Envoi de la commande au magasin';
      case TypeMetaData.REFUS_MAGASIN:              return 'Refus par le magasin';
      case TypeMetaData.EN_ACHEMINEMENT:            return 'En acheminement depuis le magasin';
      case TypeMetaData.EN_RECEPTION:               return 'Début de la réception';
      case TypeMetaData.RECEPTIONNEE:               return 'Finalisation de la réception';

      case TypeMetaData.CLOTURE:                    return 'Clôture';

      case TypeMetaData.EMISSION:                   return 'Émission'
    }
  }

  icon(type: TypeMetaData): string {
    switch (type) {
      case TypeMetaData.CREATION:                   return 'add_box';
      case TypeMetaData.MODIFICATION:               return 'save';
      case TypeMetaData.VALIDATION:                 return 'check_box';
      case TypeMetaData.MAJ_EJ:                     return 'refresh';
      case TypeMetaData.ENVOI_SF:                   return 'send';
      case TypeMetaData.SUPPRESSION_SF:             return 'delete';
      case TypeMetaData.PREPARATION_EJ:             return 'credit_score';
      case TypeMetaData.ATTENTE_RETOUR_ORION:       return 'pending';
      case TypeMetaData.REFUS_ORION:                return 'highlight_off';
      case TypeMetaData.EN_PREPARATION_FOURNISSEUR: return 'send';
      case TypeMetaData.EN_PREPARATION_MAGASIN:     return 'send';
      case TypeMetaData.REFUS_MAGASIN:              return 'highlight_off';
      case TypeMetaData.EN_ACHEMINEMENT:            return 'local_shipping';
      case TypeMetaData.EN_RECEPTION:               return 'save_alt';
      case TypeMetaData.RECEPTIONNEE:               return 'playlist_add_check';
      case TypeMetaData.CLOTURE:                    return 'lock';
      case TypeMetaData.EMISSION:                   return 'send';
    }
  }
}
