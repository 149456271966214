import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
  FormControl
} from '@angular/forms';
import {InventaireEntete} from '../../model/epona-api/InventaireEntete';
import {Lieu} from '../../model/epona-api/Lieu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {DialogConfirmComponent} from '../../commons/dialog-confirm/dialog-confirm.component';
import {MessageTool} from "../../commons/MessageTool";
import {OnlineService} from "../../services/online.service";
import {Groupe} from "../../model/epona-api/Groupe";
import {SousGroupe} from "../../model/epona-api/SousGroupe";

@Component({
    selector: 'epona-inventaire-entete',
    templateUrl: './inventaire-entete.component.html',
    styleUrls: ['./inventaire-entete.component.css'],
    standalone: false
})
export class InventaireEnteteComponent implements OnInit, OnChanges {
  @Input() lieuCourant: Lieu;
  @Input() inventaireEntete: InventaireEntete;

  @Output() readonly enteteSubmitted = new EventEmitter<InventaireEntete>();
  @Output() readonly clotureSubmitted = new EventEmitter<boolean>();
  @Output() readonly suppressionSubmitted = new EventEmitter<boolean>();

  inventaireEnteteForm: UntypedFormGroup;

  idInventaireEnteteCtrl: UntypedFormControl;
  groupeArticlesCtrl: FormControl<Groupe>;
  sousGroupeArticlesCtrl: FormControl<SousGroupe>;
  libelleCtrl: UntypedFormControl;
  commentaireCtrl: UntypedFormControl;

  // Permet d'accèder au FormGroup du DOM et de récupérer notamment la propriété submitted
  // Utile pour contrôler s'il y a eu des modidications lors d'un changement de page
  @ViewChild(FormGroupDirective)
  form: FormGroupDirective;

  constructor(private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private messageTool: MessageTool,
              public onlineService: OnlineService) {

    this.idInventaireEnteteCtrl = fb.control('');
    this.groupeArticlesCtrl     = fb.control(null);
    this.sousGroupeArticlesCtrl = fb.control(null);
    this.libelleCtrl = fb.control('', Validators.required);
    this.commentaireCtrl = fb.control('');

    this.inventaireEnteteForm = fb.group({
      idEnteteInventaire: this.idInventaireEnteteCtrl,
      groupeArticles:     this.groupeArticlesCtrl,
      sousGroupeArticles: this.sousGroupeArticlesCtrl,
      libelle:            this.libelleCtrl,
      commentaire:        this.commentaireCtrl
    });
  }

  changeFormState(online: boolean){
    if (!online) {
      this.inventaireEnteteForm && this.inventaireEnteteForm.disable();
    } else {
      this.disableFields();
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inventaireEntete']) {
      this.initForm();
    }
  }

  initForm() {
    if (this.inventaireEntete) {
      // Mise à jour des champs avec les informations récupérées
      this.idInventaireEnteteCtrl.setValue(this.inventaireEntete.idInventaireEntete);
      this.groupeArticlesCtrl.setValue(this.inventaireEntete.groupeArticles);
      this.sousGroupeArticlesCtrl.setValue(this.inventaireEntete.sousGroupeArticles);
      this.libelleCtrl.setValue(this.inventaireEntete.libelle);
      this.commentaireCtrl.setValue(this.inventaireEntete.commentaire);

      this.disableFields();

      this.lieuCourant = this.inventaireEntete.lieu;
    }
    this.onlineService.isOnline.subscribe(r => this.changeFormState(r));
  }

  isCreation(): boolean {
    return !this.inventaireEntete;
  }

  isEditable() {
    return this.inventaireEntete ? this.inventaireEntete.extra.editable.status : true;
  }

  disableFields(): void {
    if (!this.inventaireEntete || !this.inventaireEnteteForm) {
      return;
    }

    // Inventaire modifiable => activation des champs (sauf l'id de l'inventaire)
    if (this.inventaireEntete.extra.editable.status) {
      this.inventaireEnteteForm.enable();

      this.inventaireEnteteForm.get('idEnteteInventaire').disable();

      if (this.inventaireEntete.idInventaireEntete) {
        this.inventaireEnteteForm.get('groupeArticles').disable();
        this.inventaireEnteteForm.get('sousGroupeArticles').disable();
      }

    // Inventaire non modifiable => désactivation de tous les champs
    } else {
      this.inventaireEnteteForm.disable();
    }
  }

  isInvalidForm() {
    return this.inventaireEnteteForm.invalid || !this.lieuCourant || !this.lieuCourant.idLieu;
  }

  onSubmit() {
    const inventaireEntete = new InventaireEntete();
    inventaireEntete.lieu = this.lieuCourant;
    inventaireEntete.groupeArticles     = this.inventaireEnteteForm.value.groupeArticles;
    inventaireEntete.sousGroupeArticles = this.inventaireEnteteForm.value.sousGroupeArticles;
    inventaireEntete.libelle            = this.inventaireEnteteForm.value.libelle;
    inventaireEntete.commentaire        = this.inventaireEnteteForm.value.commentaire;

    this.enteteSubmitted.emit(inventaireEntete);
  }

  openDialogCloture(): void {
    let message = "<p>Êtes-vous sûr de vouloir clôturer cet inventaire&nbsp;?</p>";

    if (this.inventaireEnteteForm.dirty) {
      message += "<p class='alert alert-warning'>Attention&nbsp;: certaines modifications n'ont pas été enregistrées.</p>";
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de clôture",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: message
    };

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Envoi au composant parent que l'on veut clôturer l'inventaire
        this.clotureSubmitted.emit(true);
      }
    });
  }

  openDialogSuppression(): void {
    // Si l'inventaire est supprimable, ouverture de la fenêtre de confirmation
    if (this.inventaireEntete.extra.deletable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de suppression",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "Êtes-vous sûr de vouloir supprimer cet inventaire ?"
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut supprimer l'inventaire
          this.suppressionSubmitted.emit(true);
        }
      });
    } else {
      for (const detail of this.inventaireEntete.extra.deletable.details) {
        this.messageTool.sendErrorMessage(detail.description);
      }
    }
  }
}
