import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DialogDataEngagementJuridique} from "../../model/epona-ui/DialogDataEngagementJuridique";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {CommandeService} from "../../services/epona/commande.service";
import {EngagementJuridiqueEntete} from "../../model/epona-api/EngagementJuridiqueEntete";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {DialogErrorsComponent} from "../../commons/dialog-errors/dialog-errors.component";
import {DialogErrorsData} from "../../model/epona-ui/DialogErrorsData";
import {PanierEnteteComponent} from "../../paniers/panier-entete/panier-entete.component";

@Component({
    selector: 'epona-dialog-engagement-juridique',
    templateUrl: './dialog-engagement-juridique.component.html',
    styleUrls: ['./dialog-engagement-juridique.component.css'],
    standalone: false
})
export class DialogEngagementJuridiqueComponent implements OnInit {

  engagementJuridique: EngagementJuridiqueEntete;

  private formEnteteDirty: boolean = false;
  private formLignesDirty: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogEngagementJuridiqueComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataEngagementJuridique,
              public dialog: MatDialog,
              private messageTool: MessageTool,
              private commandeService: CommandeService) { }

  ngOnInit(): void {
    this.loadEJ();
  }

  private loadEJ(): void {
    if (this.data.commande) {
      this.commandeService.getEngagementJuridiqueEntete(this.data.commande.idCommandeEntete, null).subscribe(data => {
        this.engagementJuridique = data;
      }, error => {
        this.messageTool.sendError(error);
      });
    }
  }

  close() {
    if (this.formEnteteDirty || this.formLignesDirty) {
      let message = "<p>Certaines modifications n'ont pas été enregistrées, confirmez-vous la fermeture de cette fenêtre ?</p>";

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de fermeture",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: message
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  openDialogEnvoiEJ() {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.formEnteteDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de cet engagement juridique avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);

    // Si le formulaire des lignes contient des modifications non enregistrées
    } else if (this.formLignesDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées sur les lignes de cet engagement juridique avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);

    // Sinon ouverture de la fenêtre de confirmation
    } else {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation d'envoi vers Orion",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: this.buildBodyConfirmationEnvoiEJ()
      };

      const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRefConf.afterClosed().subscribe(result => {
        if (result) {
          this.commandeService.postEnvoiEngagementJuridique(this.data.commande.idCommandeEntete).subscribe(() => {
            this.messageTool.sendSuccess(`L'engagement juridique a été envoyé vers Orion avec succès`, ClearMessages.TRUE);
            this.dialogRef.close(true);

          }, err => {
            // Rechargement de l'engagement juridique
            //  car des lignes peuvent avoir été fusionnées ou supprimées
            this.loadEJ();

            // Les erreurs Orion sont affichées différemment
            if (this.messageTool.areStandardErrors(err)
              && err.error.find(error => error.error_code === 'ORION_ERREUR_FONCTIONNELLE')) {

              console.error(err);

              let dialogConfigErr = new MatDialogConfig<DialogErrorsData>();
              dialogConfigErr.data = new DialogErrorsData();
              dialogConfigErr.data.title = 'Erreurs retournées par Orion';
              dialogConfigErr.data.text = 'Erreurs retournées par Orion au moment de l\'envoi de l\'engagement juridique :';
              dialogConfigErr.data.errors = (err.error as any[]).map(error => {
                error.error_description = error.error_description.replace('Erreur fonctionnelle retournée par Orion : ', '');
                return error;
              });
              this.dialog.open(DialogErrorsComponent, dialogConfigErr);

            } else {
              this.messageTool.sendError(err);
            }
          });
        }
      });
    }
  }

  private buildBodyConfirmationEnvoiEJ(): string {
    const nbArticlesNonRecents = this.data.lignesCommande.filter(ligne => ligne.estPrixRecent === false).length;

    let body: string = '';
    if (nbArticlesNonRecents > 0) {
      body += `<p class="alert alert-warning">${PanierEnteteComponent.buildMessageNonRecents(nbArticlesNonRecents)}</p>`;
    }
    body += '<p class="question">Confirmez-vous l\'envoi de cet engagement juridique vers Orion&nbsp;?</p>';
    body += '<p>Une fois dans Orion, l\'engagement juridique devra y être approuvé ou rejeté par une personne habilitée.</p>';
    if (this.data.commande.envoiEdi) {
      body += '<p>En cas d\'approbation, la commande sera transmise automatiquement en ' +
        '<abbr title="Échange de données informatisé">EDI</abbr> au fournisseur par Épona ' +
        'et un courriel de notification sera adressé en interne.</p>';
    } else {
      body += '<p>En cas d\'approbation, la commande sera envoyée automatiquement par courriel au fournisseur par Épona.</p>';
    }

    return body;
  }

  openDialogRegenerationEJ() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de regénération",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: '<p class="question">Confirmez-vous la regénération de cet engagement juridique&nbsp;?</p>' +
        '<p>Les modifications effectuées sur ses lignes seront perdues.</p>'
    };

    const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.commandeService.postRegenerationEngagementJuridique(this.data.commande.idCommandeEntete).subscribe(() => {
          this.loadEJ();
          this.messageTool.sendSuccess(`L'engagement juridique a été regénéré`, ClearMessages.TRUE);

        }, err => {
          this.messageTool.sendError(err);
        });
      }
    });
  }

  onFormEnteteIsDirty(dirty: boolean): void {
    this.formEnteteDirty = dirty;
  }
  onFormLignesIsDirty(dirty: boolean): void {
    this.formLignesDirty = dirty;
  }

  onCloseDialog(close: boolean): void {
    if (close) {
      this.close();
    }
  }
}
