import {
  Component, EventEmitter,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {Observable} from "rxjs";
import {DocumentJoint} from "../../model/epona-api/DocumentJoint";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {SelectionModel} from "@angular/cdk/collections";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {
  DialogAddDocumentsComponent,
  DialogDataAddDocument
} from "../dialog-add-documents/dialog-add-documents.component";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {
  DialogSeeDocumentsComponent,
  DialogDataSeeDocument
} from "../dialog-see-documents/dialog-see-documents.component";
import * as FileSaver from 'file-saver';
import {Const} from '../../commons/constants/Const';

@Component({
    selector: 'epona-tableau-documents',
    templateUrl: './tableau-documents.component.html',
    styleUrls: ['./tableau-documents.component.css'],
    standalone: false
})
export class TableauDocumentsComponent implements OnInit {

  @Input() loadListeDocumentsFn: () => Observable<DocumentJoint[]>;
  @Input() loadDocumentFn: (idDocument: number) => Observable<DocumentJoint>;
  @Input() downloadFileFn: (idDocument: number) => Observable<any>;
  @Input() deleteDocumentFn: (idDocument: number) => Observable<any>;
  @Input() postDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;
  @Input() putDocumentFn: (document: DocumentJoint, file: File) => Observable<any>;
  @Input() droitSaisie: boolean = false;
  @Input() modifiable: boolean = false;
  @Input() nbDocumentsMax: number = undefined;

  @Output() listeDocumentsModifiee = new EventEmitter<boolean>();

  listeDocuments: DocumentJoint[] = [];
  dataSource = new MatTableDataSource<DocumentJoint>();

  colonnesAffichees: string[] = ['libelle', 'visualisation', 'telechargement', 'modification', 'suppression'];

  // Définition des colonnes
  readonly columns: { [key: string]: TableColumn } = {
    libelle       : new TableColumn({ label: 'Document' }),
    visualisation : new TableColumn({ label: '' }),
    telechargement: new TableColumn({ label: '' }),
    modification  : new TableColumn({ label: '' }),
    suppression   : new TableColumn({ label: '' }),
  };

  selection = new SelectionModel<DocumentJoint>(true, []);

  @ViewChild('table')
  table: MatTable<any>;

  constructor(public dialog: MatDialog,
              private messageService: MessageTool) {
  }

  ngOnInit(){
    this.loadDocuments();
  }

  private loadDocuments(): void {
    this.loadListeDocumentsFn().subscribe({
      next: (data) => {
        this.listeDocuments = data;
        this.dataSource.data = data;
      }
    })
  }

  /* ******************************** */
  /* Ajout / modification de document */
  /* ******************************** */

  private handleDocument(document: DocumentJoint, type: 'save'|'view'): void {
    this.downloadFileFn(document.idDocument).subscribe({
      next: (data) => {
        const blob = new Blob([data],{type: document.contentType});
        if (type === 'save') {
          FileSaver.saveAs(blob, document.nomFichier, { autoBom: false });
        } else {
          this.openDialogViewDocument(blob, document.nomFichier);
        }
      },
      error: (err) => {
        this.messageService.sendError(err);
      }
    });
  }

  downloadFile(document: DocumentJoint): void {
    this.handleDocument(document, 'save');
  }

  viewFile(document: DocumentJoint): void {
    this.handleDocument(document, 'view');
  }

  private openDialogViewDocument(blob: Blob, filename: string): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = 'auto';
    dialogConfig.data = new DialogDataSeeDocument();
    dialogConfig.data.file = blob;
    dialogConfig.data.filename = filename;

    this.dialog.open(DialogSeeDocumentsComponent, dialogConfig);
  }

  private openDialogGestionDocument(document: DocumentJoint): void {
    let dialogConfig = new MatDialogConfig<DialogDataAddDocument>();
    dialogConfig.width = 'auto';
    dialogConfig.position= {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement de fichier
    dialogConfig.data = new DialogDataAddDocument();
    if (document) {
      dialogConfig.data.document = document;
      dialogConfig.data.putDocumentFn = this.putDocumentFn;
    } else {
      dialogConfig.data.document = null;
      dialogConfig.data.postDocumentFn = this.postDocumentFn;
    }

    const dialogRef = this.dialog.open(DialogAddDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loadDocuments();
        this.listeDocumentsModifiee.emit(true);
      }
    });
  }

  openDialogAjoutDocument(): void {
    this.openDialogGestionDocument(null);
  }

  openDialogModificationDocument(document: DocumentJoint): void {
    this.openDialogGestionDocument(document);
  }

  /* *********************** */
  /* Suppression de document */
  /* *********************** */

  openDialogSuppressionDocument(document: DocumentJoint): void {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Suppression d'un document",
      yesLabel: "Supprimer",
      noLabel: "Annuler",
      body: "Confirmez-vous la suppression de ce document ?"};

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteDocumentFn(document.idDocument).subscribe({
          next: () => {
            this.messageService.sendSuccess("Le document a été supprimé avec succès", ClearMessages.TRUE);
            this.loadDocuments();
            this.listeDocumentsModifiee.emit(true);
          },
          error: err => {
            this.messageService.sendError(err);
          }
        });
      }
    });
  }
}


