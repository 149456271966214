import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {Lieu} from '../../model/epona-api/Lieu';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {MatPaginator} from '@angular/material/paginator';
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {MatSort} from "@angular/material/sort";
import {TypeMouvement} from "../../model/TypeMouvement";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {Format} from "../../commons/constants/Format";
import {DatePipe} from "@angular/common";
import {CodeStatutES} from "../../commons/constants/CodeStatutES";

export class NbEntreesSortiesParType {
  nombreValide: number;
  typeMouvement: TypeMouvement;
  dateMin : Date;
}

@Component({
    selector: 'epona-card-entrees-sorties-validee',
    templateUrl: './card-entrees-sorties-validee.component.html',
    styleUrls: ['./card-entrees-sorties-validee.component.css'],
    standalone: false
})
export class CardEntreesSortiesValideeComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = ['typeMouvement', 'nombreValide'];

  listeNbESParType: Array<NbEntreesSortiesParType>;
  dataSource = new MatTableDataSource<NbEntreesSortiesParType>([]);

  dateCtrl : UntypedFormControl;

  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private fb: UntypedFormBuilder,
              private tdbService: TableauBordService,
              private dateFormat: DatePipe) {
    this.dateCtrl = fb.control(new Date());
  }

  ngOnInit(): void {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingdataAccessor;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lieuCourant']) {
      this.rempliListeTypes();
    }
  }

  rempliListeTypes() {
    // commencer par l'initialisation de la liste
    this.listeNbESParType = new Array<NbEntreesSortiesParType>();
    this.dataSource.data = [];

    const entreeSortieSearch = new EntreeSortieSearch();
    entreeSortieSearch.idCrous       = this.lieuCourant.idCrous;
    entreeSortieSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    entreeSortieSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    entreeSortieSearch.idRu          = this.lieuCourant.idRu;
    entreeSortieSearch.idUd          = this.lieuCourant.idUd;
    entreeSortieSearch.codesStatut   = [CodeStatutES.VALIDE];
    entreeSortieSearch.dateMinValidation = this.dateCtrl.value;

    this.tdbService.getEntreesSorties(entreeSortieSearch).subscribe(data => {
      for (const dataUnit of data) {
        if (dataUnit.detail){
          for (const detail of dataUnit.detail) {
            let nbESParType = this.listeNbESParType.find(entity => entity.typeMouvement.codeTypeMouvement === detail.typeMouvement.codeTypeMouvement);
            if (nbESParType) {
              nbESParType.nombreValide += detail.nombreValide;
            } else {
              nbESParType = new NbEntreesSortiesParType();
              nbESParType.nombreValide = detail.nombreValide;
              nbESParType.typeMouvement = detail.typeMouvement;
              this.listeNbESParType.push(nbESParType);
            }
          }
        }
      }
      this.dataSource.data = this.listeNbESParType;
    });
  }

  getRouterLink(element: NbEntreesSortiesParType): string {
    return EntreeSortieParametrage.getParametrageFromType(element.typeMouvement).commonListPath
  }
  getQueryParams(element: NbEntreesSortiesParType) {
    return {
      idCrous: this.lieuCourant.idCrous,
      idRu: this.lieuCourant.idRu,
      idUd: this.lieuCourant.idUd,
      codeTypeMouvement: element.typeMouvement.codeTypeMouvement,
      valide: true,
      dateMin: this.dateFormat.transform(this.dateCtrl.value, Format.FORMAT_DATE_URL_REST)
    };
  }

  sortingdataAccessor(data: NbEntreesSortiesParType, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'typeMouvement': return data.typeMouvement?.codeTypeMouvement;
      case 'nombreValide' : return data.nombreValide;
      default             : return data[sortHeaderId];
    }
  }
}
