import {InventaireLigne} from "./InventaireLigne";
import {ArticleDTO} from "./ArticleDTO";
import {InventaireLignePUT} from "./InventaireLignePUT";
import {InventaireEnteteDto} from "./inventaire-entete-dto";

export class InventaireLignePOST extends InventaireLignePUT{

  inventaire: InventaireEnteteDto;
  article: ArticleDTO;
  dlc: Date;

  constructor(inventaireLigne: InventaireLigne) {
    super(inventaireLigne);
    this.inventaire = inventaireLigne.inventaire ? new InventaireEnteteDto(inventaireLigne.inventaire) : null;
    this.dlc = inventaireLigne.dlc;
    this.article = new ArticleDTO(inventaireLigne.article);
  }
}
