import {Component, Inject, OnInit} from '@angular/core';
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {DialogDataServiceFait} from "../../model/epona-ui/DialogDataServiceFait";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {DialogErrorsData} from "../../model/epona-ui/DialogErrorsData";
import {DialogErrorsComponent} from "../../commons/dialog-errors/dialog-errors.component";
import {CrousService} from "../../services/epona/crous.service";
import {Decimal2Pipe} from "../../commons/pipes/Decimal2Pipe";

@Component({
    selector: 'epona-dialog-service-fait',
    templateUrl: './dialog-service-fait.component.html',
    styleUrls: ['./dialog-service-fait.component.css'],
    standalone: false
})
export class DialogServiceFaitComponent implements OnInit {

  serviceFait: ServiceFaitEntete;
  afficherBoutonMajEj: boolean = false;
  ejMisAJour: boolean = false;

  private formEnteteDirty: boolean = false;
  private formLignesDirty: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogServiceFaitComponent, boolean>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataServiceFait,
              public dialog: MatDialog,
              private messageTool: MessageTool,
              private entreeSortieService: EntreeSortieService,
              private crousService: CrousService,
              private decimal2Pipe: Decimal2Pipe) { }

  ngOnInit(): void {
    this.loadSF();
  }

  private loadSF(): void {
    if (this.data.livraison) {
      this.entreeSortieService.getServiceFaitEntete(this.data.livraison.idEntreeSortieEntete, null).subscribe(serviceFaitEntete => {
        this.serviceFait = serviceFaitEntete;
        this.crousService.getCurrentCrous('modificationLignesEJAutorisee').subscribe({
          next: crous => {
            this.afficherBoutonMajEj = crous.modificationLignesEJAutorisee;
          }
        });
      }, error => {
        this.messageTool.sendError(error);
      });
    }
  }

  close() {
    if (this.formEnteteDirty || this.formLignesDirty) {
      let message = "<p>Certaines modifications n'ont pas été enregistrées, confirmez-vous la fermeture de cette fenêtre ?</p>";

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Confirmation de fermeture",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: message
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(this.ejMisAJour);
        }
      });
    } else {
      this.dialogRef.close(this.ejMisAJour);
    }
  }

  private isAnyFormDirty(): boolean {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.formEnteteDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de ce service fait avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);
      return true;
    }

    // Si le formulaire des lignes contient des modifications non enregistrées
    if (this.formLignesDirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées sur les lignes de ce service fait avant de l\'envoyer vers Orion';
      this.messageTool.sendWarning(message);
      return true;
    }

    return false;
  }

  openDialogMiseAJourEJ() {
    // Si au moins un formulaire contient des modifications non enregistrées
    if (this.isAnyFormDirty()) {
      return;
    }

    // Ouverture de la fenêtre de confirmation
    const dialogConfig = new MatDialogConfig();
    let message = '<p class="question">Confirmez-vous la mise à jour de l\'engagement juridique dans Orion&nbsp;?</p>';
    message += `<p>Un complément de ${this.decimal2Pipe.transform(this.serviceFait.montantAActualiser)}&nbsp;€ HT est nécessaire.</p>`;
    dialogConfig.data = {
      title: "Confirmation de mise à jour",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: message
    };

    const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.entreeSortieService.postMiseAJourEngagementJuridique(this.data.livraison.idEntreeSortieEntete).subscribe((sfEntete) => {
          this.messageTool.sendSuccess(`L'engagement juridique a été mis à jour dans Orion avec succès`, ClearMessages.TRUE);
          this.serviceFait.codeEtapeOrion = sfEntete.codeEtapeOrion;
          this.ejMisAJour = true;

        }, err => {
          const text = 'Erreurs retournées par Orion au moment de la mise à jour de l\'engagement juridique :';
          this.traitementErreurs(err, text);
        });
      }
    });
  }

  openDialogEnvoiSF() {
    // Si au moins un formulaire contient des modifications non enregistrées
    if (this.isAnyFormDirty()) {
      return;
    }

    // Ouverture de la fenêtre de confirmation
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation d'envoi",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: this.buildMessageConfirmation()
    };

    const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.entreeSortieService.postEnvoiServiceFait(this.data.livraison.idEntreeSortieEntete).subscribe(() => {
          this.messageTool.sendSuccess(`La constatation de service fait a été envoyée vers Orion avec succès`, ClearMessages.TRUE);
          this.dialogRef.close(true);

        }, err => {
          const text = 'Erreurs retournées par Orion au moment de l\'envoi de la constatation de service fait :';
          this.traitementErreurs(err, text);
        });
      }
    });
  }

  private buildMessageConfirmation(): string {
    let message = '<p class="question">Confirmez-vous l\'envoi de cette constation de service fait vers Orion&nbsp;?</p>';
    if (this.data.livraison.nbDocuments === 0) {
      message += '<p class="alert alert-warning">Aucun document n\'a été joint au bordereau de livraison ' +
        'donc cette constatation de service fait sera envoyée <strong>sans pièce jointe</strong> autre que la commande d\'origine.</p>';
    }
    return message;
  }

  private traitementErreurs(err: any, text) {
    // Les erreurs Orion sont affichées différemment
    if (this.messageTool.areStandardErrors(err)
      && err.error.find(error => error.error_code === 'ORION_ERREUR_FONCTIONNELLE')) {

      console.error(err);

      let dialogConfigErr = new MatDialogConfig<DialogErrorsData>();
      dialogConfigErr.data = new DialogErrorsData();
      dialogConfigErr.data.title = 'Erreurs retournées par Orion';
      dialogConfigErr.data.text = text;
      dialogConfigErr.data.errors = (err.error as any[]).map(error => {
        error.error_description = error.error_description.replace('Erreur fonctionnelle retournée par Orion : ', '');
        return error;
      });
      this.dialog.open(DialogErrorsComponent, dialogConfigErr);

    } else {
      this.messageTool.sendError(err);
    }
  }

  openDialogSuppressionSF() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: '<p class="question">Confirmez-vous que cette constation de service fait a déjà été saisie dans Orion&nbsp;?</p>' +
        '<p>Le service fait d\'Épona sera supprimé et ne pourra plus être envoyé vers Orion.</p>'
    };

    const dialogRefConf = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRefConf.afterClosed().subscribe(result => {
      if (result) {
        this.entreeSortieService.postSuppressionServiceFait(this.data.livraison.idEntreeSortieEntete).subscribe(() => {
          this.messageTool.sendSuccess(`La constatation de service fait a été supprimée`, ClearMessages.TRUE);
          this.dialogRef.close(true);

        }, err => {
          this.messageTool.sendError(err);
        });
      }
    });
  }

  onFormEnteteIsDirty(dirty: boolean): void {
    this.formEnteteDirty = dirty;
  }
  onFormLignesIsDirty(dirty: boolean): void {
    this.formLignesDirty = dirty;
  }
}
