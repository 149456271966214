import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {ArticleService} from '../../services/epona/article.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MessageTool} from '../../commons/MessageTool';
import {Tools} from '../../commons/Tools';
import {ArticleSearch} from '../../model/epona-api/ArticleSearch';
import {ArticleFilter} from '../../model/epona-ui/article-filter';
import {ArticleQueryParams} from '../../model/epona-ui/ArticleQueryParams';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Lieu} from '../../model/epona-api/Lieu';
import {FilterTools} from '../../commons/FilterTools';
import {Article} from '../../model/epona-api/Article';
import {UtilisationArticle} from '../../commons/constants/UtilisationArticle';
import {DisplayedColumnsTools, TableColumn,} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {SelectionModel} from '@angular/cdk/collections';
import {DialogDataModificationMultipleArticles} from '../../model/epona-ui/DialogDataModificationMultipleArticles';
import {DialogModificationMultipleComponent} from '../dialog-modification-multiple/dialog-modification-multiple.component';
import {FormTools} from '../../commons/FormTools';
import {NumberComparison} from '../../model/epona-ui/NumberComparison';
import {ArticleExportService} from '../../services/epona/article-export.service';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {HttpService} from "../../services/http.service";

@Component({
    selector: 'epona-liste-articles',
    templateUrl: './liste-articles.component.html',
    styleUrls: ['./liste-articles.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Articles') }
    ],
    standalone: false
})
export class ListeArticlesComponent implements OnInit, IPaginatedComponent<Article> {

  private readonly DEFAULT_PAGE_SIZE = 100;
  private readonly DEFAULT_ORDER_BY = 'designation';
  private readonly DEFAULT_ORDER_DIR = 'asc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;


  private readonly path: string;
  utilisation: string;
  afficherAchat: boolean = true;
  afficherVente: boolean = true;

  private readonly FIELDS = 'idArticle,' +
    'codeArticleVente,' +
    'pcb,' +
    'articleAchat,' +
    'articleVente,' +
    'actifAchat,' +
    'actifVente,' +
    'designationAchat,' +
    'designationVente,' +
    'articleDlc,' +
    'delaiReceptionDlc,' +
    'origineAchat,' +
    'codeArticleAchat,' +
    'sousFamille.libelle,' +
    'sousFamille.famille.libelle,' +
    'sousFamille.famille.departement.libelle,' +

    'familleMarches.idFamilleMarches,' +
    'familleMarches.code,' +
    'familleMarches.libelle,' +
    'familleMarches.categorieMarches.code,' +
    'familleMarches.categorieMarches.libelle,' +
    'sousGroupe.idSousGroupeArticles,' +
    'sousGroupe.code,' +
    'sousGroupe.libelle,' +
    'sousGroupe.groupe.code,' +
    'sousGroupe.groupe.libelle,' +
    'commandableExterne,' +
    'stockGere,' +
    'stockGereOperationnellement,' +
    'commentaireParametrage,' +
    'listeInformationsMarche.codeMarcheOrion,' +
    'listeInformationsMarche.numeroSousLot,' +
    'listeInformationsMarche.typeSousLot,' +
    'listeInformationsMarche.reference,' +
    'listeInformationsMarche.commentaireMarche,' +
    'listeInformationsMarche.prixHt' +
    '';

  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel<Article>(true, []);

  filter: ArticleFilter;
  lieuCourant: Lieu;

  COLUMNS: {[key: string]: TableColumn} = {};
  COLUMNS_STORE_CODE = null;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private route: ActivatedRoute,
              private articleService: ArticleService,
              private exportService: ArticleExportService,
              private httpService: HttpService,
              public messageTool: MessageTool,
              public dialog: MatDialog,
              private router: Router) {

    this.path = this.route.snapshot.url[0].path;

    if (this.route.snapshot.data.utilisation) {
      this.utilisation = this.route.snapshot.data.utilisation;
      this.afficherAchat = !this.utilisation || this.utilisation === UtilisationArticle.ACHAT;
      this.afficherVente = !this.utilisation || this.utilisation === UtilisationArticle.VENTE;

      this.initInformationsColonnes();
    }
  }

  private initInformationsColonnes() {
    switch (this.utilisation) {
      case UtilisationArticle.ACHAT:
        this.COLUMNS = {
          checkBox:                    new TableColumn({label: 'Sélection',    hiddable: false, export: false}),
          familleMarches:              new TableColumn({label: 'Catégorie/famille de marchés', default: false}),
          sousGroupe:                  new TableColumn({label: 'Groupe/sous-groupe'}),
          codeArticle:                 new TableColumn({label: 'Code'}),
          designation:                 new TableColumn({label: 'Désignation' ,  hiddable: false}),
          actifAchat:                  new TableColumn({label: 'Actif'}),
          origineAchat:                new TableColumn({label: 'Origine',       default: false}),
          commandableExterne:          new TableColumn({label: 'Commandable',   tooltip: 'Peut être commandé à un fournisseur'}),
          stockGereComptablement:      new TableColumn({label: 'Stock compta.', tooltip: 'Stock géré comptablement'}),
          stockGereOperationnellement: new TableColumn({label: 'Stock opé.',    tooltip: 'Stock géré opérationnellement'}),
          lotMarche:                   new TableColumn({label: 'Lot-marché',    default: false}),
          typeSousLot:                 new TableColumn({label: 'Type',          tooltip: 'Type de lot-marché', default: false}),
          referenceFournisseur:        new TableColumn({label: 'Référence',     tooltip: 'Référence-fournisseur', default: false}),
          prixHt:                      new TableColumn({label: 'Prix HT',       default: false}),
          actions:                     new TableColumn({label: 'Actions',       hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES_ACHAT;
        break;

      case UtilisationArticle.VENTE:
        this.COLUMNS = {
          checkBox:          new TableColumn({label: 'Sélection',    hiddable: false, export: false}),
          sousFamille:       new TableColumn({label: 'Département/famille/sous-famille'}),
          codeArticle:       new TableColumn({label: 'Code', exportFormat: 'integer'}),
          designation:       new TableColumn({label: 'Désignation' , hiddable: false}),
          actifVente:        new TableColumn({label: 'Actif'}),
          colisage:          new TableColumn({label: 'Colisage',     tooltip: 'Colisage interne', exportFormat: 'decimal'}),
          articleDlc:        new TableColumn({label: 'Avec DLC'}),
          delaiReceptionDlc: new TableColumn({label: 'Durée conso.', tooltip: 'Durée de consommation après réception', exportFormat: 'integer'}),
          actions:           new TableColumn({label: 'Actions',      hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES_VENTE;
        break;

      default:
        this.COLUMNS = {
          checkBox:                    new TableColumn({label: 'Sélection',     hiddable: false, export: false}),
          sousFamille:                 new TableColumn({label: 'Département/famille/sous-famille'}),
          codeArticle:                 new TableColumn({label: 'Code', exportFormat: 'date'}),
          designation:                 new TableColumn({label: 'Désignation' ,  hiddable: false}),

          actifAchat:                  new TableColumn({label: 'Actif achat'}),
          familleMarches:              new TableColumn({label: 'Catégorie/famille de marchés'}),
          sousGroupe:                  new TableColumn({label: 'Groupe/sous-groupe'}),
          origineAchat:                new TableColumn({label: 'Origine',       default: false}),
          commandableExterne:          new TableColumn({label: 'Commandable',   tooltip: 'Peut être commandé à un fournisseur'}),
          stockGereComptablement:      new TableColumn({label: 'Stock compta.', tooltip: 'Stock géré comptablement'}),
          stockGereOperationnellement: new TableColumn({label: 'Stock opé.',    tooltip: 'Stock géré opérationnellement'}),
          lotMarche:                   new TableColumn({label: 'Lot-marché',    default: false}),
          typeSousLot:                 new TableColumn({label: 'Type',          tooltip: 'Type de lot-marché', default: false}),
          referenceFournisseur:        new TableColumn({label: 'Référence',     tooltip: 'Référence-fournisseur', default: false}),
          prixHt:                      new TableColumn({label: 'Prix HT',       default: false, exportFormat: 'decimal'}),

          actifVente:                  new TableColumn({label: 'Actif vente'}),
          colisage:                    new TableColumn({label: 'Colisage'}),
          articleDlc:                  new TableColumn({label: 'Avec DLC'}),
          delaiReceptionDlc:           new TableColumn({label: 'Durée conso.',  tooltip: 'Durée de consommation après réception'}),
          actions:                     new TableColumn({label: 'Actions',       hiddable: false, export: false})
        };
        this.COLUMNS_STORE_CODE = CodeStockageColonnes.ARTICLES;
    }
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.filter = new ArticleFilter();
        if (this.afficherAchat) {
          this.filter.commandableExterne = -1;  // uniquement les articles dont le statut commandable n'est pas défini
        }
        if (this.afficherVente) {
          this.filter.actifVente = true;        // uniquement les articles actifs
        }
      }

      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;
      this.rechercher();
    });
  }

  private initFiltersFromURL(params: ParamMap) {
    this.filter = new ArticleFilter();
    this.filter.rechercheTextuelle          = params.get('q');
    this.filter.groupe                      = FilterTools.buildGroupeArticlesFromURL(params);
    this.filter.sousGroupe                  = FilterTools.buildSousGroupeArticlesFromURL(params);
    this.filter.departement                 = FilterTools.buildDepartementFromURL(params);
    this.filter.famille                     = FilterTools.buildFamilleFromURL(params);
    this.filter.sousFamille                 = FilterTools.buildSousFamilleFromURL(params);
    this.filter.actifVente                  = params.get('actifVente') ? Tools.stringToBoolean(params.get('actifVente')) : null;
    this.filter.colisage                    = params.get('colisage') ? +params.get('colisage') : null;
    this.filter.articleDlc                  = params.get('articleDlc') ? Tools.stringToBoolean(params.get('articleDlc')) : null;
    this.filter.delaiReceptionDlc           = params.get('delaiReceptionDlc') ? +params.get('delaiReceptionDlc') : null;
    this.filter.commandableExterne          = params.get('commandableExterne') ? +params.get('commandableExterne') : null;
    this.filter.stockGere                   = params.get('stockGere') ? Tools.stringToBoolean(params.get('stockGere')) : null;
    this.filter.stockGereOperationnellement = params.get('stockGereOperationnellement') ? Tools.stringToBoolean(params.get('stockGereOperationnellement')) : null;
    this.filter.marche                      = FilterTools.buildMarcheFromURL(params);
    this.filter.comparaisonPrixHt           = params.get('prixHt') ? new NumberComparison(params.get('operateurPrixHt'), +params.get('prixHt')) : null;
    this.filter.origineAchat                = params.get('origineAchat');
  }

  private rechercher(idArticle?: number) {
    this.messageTool.clearAllMessages();

    const search = ArticleSearch.fabric(this.filter);
    search.idCrous = +localStorage.getItem('idCrous');
    search.inclureInformationsMarche = this.utilisation === UtilisationArticle.ACHAT;
    search.utilisation = this.utilisation;
    search.fields = this.FIELDS;
    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.articleService.getListeArticlesWithPagination(search).subscribe(response => {
      this.httpService.handlePaginatedResponse(this, response);
      this.selection.clear();

      if (idArticle) {
        const article = this.dataSource.data.find(art => art.idArticle === idArticle);
        if (article) {
          FormTools.markAsSuccess(article);
        }
      }

    }, err => {
      this.dataSource.data = [];
      this.messageTool.sendError(err);
    });
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onFilterSubmitted(filter : ArticleFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate([this.path], {queryParams: ArticleQueryParams.fabric(this.filter)});
  }

  openDialogAjoutParametrage(): void {
    this.messageTool.clearAllMessages();

    const search = new ArticleSearch();
    search.idCrous = +localStorage.getItem('idCrous');
    search.utilisation = UtilisationArticle.VENTE;
    search.fields = 'codeArticleVente,idArticle';

    this.articleService.getListeArticlesWithPagination(search).subscribe((response) => {
      let dialogConfig = new MatDialogConfig<DialogDataModificationMultipleArticles>();
      dialogConfig.data = new DialogDataModificationMultipleArticles();
      dialogConfig.data.listeArticles = [];
      dialogConfig.data.listeArticlesExistants = response.data;
      dialogConfig.data.utilisation = UtilisationArticle.VENTE;

      const dialogRef = this.dialog.open(DialogModificationMultipleComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(idArticle => {
        if (idArticle) {
          this.rechercher(idArticle);
        }
      });

    }, err => {
      this.messageTool.sendErrorMessage('Erreur lors de la récupération des articles existants');
      console.error(err);
    });
  }

  openDialogModificationUnique(article: Article): void {
    this.openDialogModification([article]);
  }

  openDialogModificationMultiple(): void {
    this.openDialogModification(this.selection.selected);
  }

  private openDialogModification(listeArticles: Article[]): void {
    this.messageTool.clearAllMessages();

    let dialogConfig = new MatDialogConfig<DialogDataModificationMultipleArticles>();
    dialogConfig.data = new DialogDataModificationMultipleArticles();
    dialogConfig.data.listeArticles = listeArticles;
    dialogConfig.data.utilisation = this.utilisation;
    dialogConfig.data.pageSelectionneeEntierement = listeArticles.length === this.pageSize;
    dialogConfig.minWidth = '560px';

    const dialogRef = this.dialog.open(DialogModificationMultipleComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.dataSource._updateChangeSubscription();
        FormTools.markAsSuccess(listeArticles);
      }
    });
  }

  /* ******************** */
  /* Sélection des lignes */
  /* ******************** */

  // Si le nombre d'éléments sélectionnés correspond au nombre total de lignes
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Sélectionne toutes les lignes si elles ne sont pas sélectionnées sinon déselectionne toutes les lignes
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.rechercher();
  }


  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.rechercher();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListeArticles() {
    const search = ArticleSearch.fabric(this.filter);
    search.idCrous = +localStorage.getItem('idCrous');
    search.inclureInformationsMarche = this.utilisation === UtilisationArticle.ACHAT;
    search.utilisation = this.utilisation;
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;


    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportArticles(search);
  }
}
