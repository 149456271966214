import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {TypeReferentielOrion} from "../../commons/constants/TypeReferentielOrion";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {ServiceFaitEntete} from "../../model/epona-api/ServiceFaitEntete";

@Component({
    selector: 'epona-service-fait-entete',
    templateUrl: './service-fait-entete.component.html',
    styleUrls: ['./service-fait-entete.component.css'],
    standalone: false
})
export class ServiceFaitEnteteComponent implements OnChanges {

  @Input() livraison: EntreeSortieEntete;
  @Input() entete: ServiceFaitEntete;
  @Input() modifiable: boolean;
  @Input() envoyable: boolean;

  @Output() isDirty = new EventEmitter<boolean>();

  form: UntypedFormGroup;

  TypeReferentielOrion = TypeReferentielOrion;

  constructor(private fb: UntypedFormBuilder,
              private entreeSortieService: EntreeSortieService,
              public dialog: MatDialog,
              private messageTool: MessageTool) {

    this.form = fb.group({
      serviceConstatation: fb.control(null),
      dateServiceFait:     fb.control(null),
      libelle:             fb.control(null, Validators.required),
      observation:         fb.control(null),
    });

    this.updateFormControls();

    this.form.valueChanges.subscribe(() => {
      this.isDirty.emit(this.form.dirty);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entete'] && this.entete) {
      this.form.get('serviceConstatation').setValue(this.entete.serviceConstatation);
      this.form.get('dateServiceFait').setValue(this.entete.dateServiceFait);
      this.form.get('libelle').setValue(this.entete.libelle);
      this.form.get('observation').setValue(this.entete.observation);
    }

    if (changes['modifiable']) {
      this.updateFormControls();
    }
  }

  private updateFormControls() {
    this.form.disable();

    if (this.modifiable) {
      this.form.get('serviceConstatation').enable();
      this.form.get('libelle').enable();
      this.form.get('observation').enable();
    }
  }

  onSubmit() {
    this.entete.serviceConstatation = this.form.get('serviceConstatation').value;
    this.entete.libelle             = this.form.get('libelle').value;
    this.entete.observation         = this.form.get('observation').value;

    this.entreeSortieService.putServiceFaitEntete(this.entete, this.livraison).subscribe(() => {
      this.messageTool.sendSuccess('La constatation de service fait a été mise à jour avec succès', ClearMessages.TRUE);
      this.form.markAsPristine();
      this.isDirty.emit(false);

    }, err => {
      this.messageTool.sendError(err);
    });
  }
}
