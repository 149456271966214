import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl, UntypedFormControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {Const} from '../../constants/Const';
import {CacheService} from '../../../services/cache.service';
import {MessageTool} from '../../MessageTool';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {StatutFdnc} from '../../../model/bna-api/statut-fdnc';
import {BnaFdncService} from '../../../services/bna/bna-fdnc.service';

@Component({
    selector: 'epona-select-statut-fdnc',
    templateUrl: './select-statut-fdnc.component.html',
    styleUrls: ['./select-statut-fdnc.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectStatutFdncComponent }],
    standalone: false
})
export class SelectStatutFdncComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<StatutFdnc|StatutFdnc[]>{

  @Input() defaultLabel: string = '- Aucun -';
  @Input() multiple: boolean = false;

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'statuts-fdnc';

  formCtrl: UntypedFormControl;
  liste: StatutFdnc[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnaFdncService: BnaFdncService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl();
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: StatutFdnc|StatutFdnc[]): void {
    this.formCtrl.setValue(this.statutFdncToNumber(obj));
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): StatutFdnc|StatutFdnc[] {
    return this.getCurrentItem();
  }
  set value(value: StatutFdnc|StatutFdnc[]) {
    this.formCtrl.setValue(this.statutFdncToNumber(value));
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT
      || this.formCtrl.value && (this.formCtrl.value as number[]).length === 0;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): StatutFdnc|StatutFdnc[] {
    if (this.formCtrl.value) {
      if (typeof this.formCtrl.value === 'number') {
        return this.liste.find(item => item.idStatutFdnc === this.formCtrl.value);
      } else {
        return this.liste.filter(item => (this.formCtrl.value as number[]).includes(item.idStatutFdnc));
      }
    } else {
      return null;
    }
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

      // Sinon récupération de la liste via l'API
    } else {
      this.bnaFdncService.getListeStatutsFdnc().subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, [StatutFdnc.STATUT_EPONA].concat(data));
        this.setAndSortListeFromCache();

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = this.cacheService.get(this.CACHE_KEY);
    this.tri(this.liste);
  }

  tri(liste: Array<StatutFdnc>) {
    liste.sort((a, b) => {
      return a.idStatutFdnc < b.idStatutFdnc ? -1 : a.idStatutFdnc === b.idStatutFdnc ? 0 : 1;
    });
  }

  private statutFdncToNumber(obj: StatutFdnc|StatutFdnc[]): number|number[] {
    if (obj) {
      if (obj.constructor.name === 'StatutFdnc') {
        return (obj as StatutFdnc).idStatutFdnc;
      } else {
        return (obj as StatutFdnc[]).map(statut => statut.idStatutFdnc);
      }
    } else if (this.multiple) {
      return [];
    } else {
      return Const.DEFAULT;
    }
  }
}
