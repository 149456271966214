<div mat-dialog-title>
  <mat-toolbar color="primary" role="toolbar">
    <span>Détail par DLC</span>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="dialog.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <div class="stock-affiche">
    <table class="mat-mdc-table mat-elevation-z8" aria-label="Rappel du stock de l'article en cours de consultation">
      <thead>
      <tr class="mat-mdc-header-row">
        <th class="mat-mdc-cell-epona" scope="col">Lieu</th>
        <th class="mat-mdc-cell-epona" scope="col">Code</th>
        <th class="mat-mdc-cell-epona" scope="col">Désignation</th>
        <th class="mat-mdc-cell-epona quantite" scope="col">Stock</th>
        <th class="mat-mdc-cell-epona quantite" scope="col">Valeur HT</th>
        <th class="mat-mdc-cell-epona quantite" scope="col">Valeur TTC</th>
      </tr>
      </thead>

      <tbody>
      <tr class="mat-mdc-row">
        <td class="mat-mdc-cell-epona">{{ data.stockCompact.lieu.nom }}</td>
        <td class="mat-mdc-cell-epona">{{ data.stockCompact.article | codeArticle }}</td>
        <td class="mat-mdc-cell-epona">{{ data.stockCompact.article | designation }}</td>
        <td class="mat-mdc-cell-epona quantite">{{ data.stockCompact.quantite }}</td>
        <td class="mat-mdc-cell-epona quantite">{{ data.stockCompact.pmpHt  * data.stockCompact.quantite | decimal2 }}</td>
        <td class="mat-mdc-cell-epona quantite">{{ data.stockCompact.pmpTtc * data.stockCompact.quantite | decimal2 }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="detail-dlc">
    <table mat-table [dataSource]="dataSource" aria-label="Détail du stock d'un article par DLC">
      <ng-container matColumnDef="dlc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">DLC</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.dlc">{{ element.dlc | dateFormat }}</span>
          <span *ngIf="!element.dlc">inconnue</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="quantite">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
        <td mat-cell *matCellDef="let element">
          {{ element.quantite }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valeurHt">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">Valeur HT</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pmpHt  * element.quantite | decimal2 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="valeurTtc">
        <th class="title-container quantite" mat-header-cell *matHeaderCellDef scope="col">Valeur TTC</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pmpTtc  * element.quantite | decimal2 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="dialog.close()">Fermer</button>
</mat-dialog-actions>
