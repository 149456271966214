// Centralisation des codes permettant de sauvegarder les colonnes sélectionnées dans le localStorage
// Si dans une version de nouvelles colonnes sont ajoutées ou supprimées au tableau
//  alors incrémenter le dernier chiffre afin de rendre l'ancienne sauvegarde obsolète
export class CodeStockageColonnes {
  public static ARTICLES       = 'col-articles-1';
  public static ARTICLES_ACHAT = 'col-articles-achat-3';
  public static ARTICLES_VENTE = 'col-articles-vente-1';
  public static COMMANDES      = 'col-commandes-3';
  public static LOT_MARCHES    = 'col-lot-marche-1';
  public static PANIERS        = 'col-paniers-2';
  public static FOURNISSEURS   = 'col-fournisseurs-1';
  public static INVENTAIRES    = 'col-inventaires-4';
  public static LIEUX          = 'col-lieux-2';
  public static LIEUX_ARTICLES = 'col-lieux-articles-1';
  public static STOCKS         = 'col-stocks-4';
  public static FDNC           = 'col-fdnc-2';

  public static ES_CONSO_UTIL   = 'col-conso-util-2';
  public static ES_LIVRAISONS   = 'col-livraisons-3';
  public static ES_PERTES       = 'col-pertes-2';
  public static ES_REINTEG      = 'col-reinteg-2';
  public static ES_RETOURS_SOR  = 'col-retours-sor-2';
  public static ES_SORTIES_EVEN = 'col-sorties-even-2';
  public static ES_TRANSFERTS   = 'col-transferts-2';

  public static LIGNES_INVENTAIRE = 'col-l-inventaires-4';

  public static LIGNES_ES_BL_EXT       = 'col-l-livraisons-ext-3';
  public static LIGNES_ES_BL_INT       = 'col-l-livraisons-int-3';
  public static LIGNES_ES_BL_TRF       = 'col-l-livraisons-trf-3';
  public static LIGNES_ES_CONSO_UTIL   = 'col-l-conso-util-2';
  public static LIGNES_ES_PERTES       = 'col-l-pertes-2';
  public static LIGNES_ES_REINTEG      = 'col-l-reinteg-2';
  public static LIGNES_ES_RETOURS_SOR  = 'col-l-retours-sor-2';
  public static LIGNES_ES_SORTIES_EVEN = 'col-l-sorties-even-2';
  public static LIGNES_ES_TRANSFERTS   = 'col-l-transferts-2';

  public static LIGNES_PANIER   = 'col-l-paniers-2';

  public static LIGNES_SERVICE_FAIT   = 'col-l-sf-1';
}
