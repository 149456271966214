import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http.service';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';

@Injectable({
  providedIn: 'root'
})
export class EntreeSortieExportService {
  private baseUrl = environment.eponaApiUrl + '/entrees-sorties';

  constructor(private httpClient: HttpClient,
              private httpService: HttpService) { }

  getExportEntreesSorties(search: EntreeSortieSearch): void {
    const params = this.httpService.buildParams(search);
    let url = this.baseUrl + '/export/xlsx' + '?' + params.toString();
    this.httpService.downloadFile(url, undefined, 'liste-entrees-sorties.xlsx');
  }
}
