/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import {Component, HostListener} from '@angular/core';
import {FormGroupDirective} from "@angular/forms";

@Component({
    template: '',
    standalone: false
})
export class CanDeactivateComponent {
  // FormGroupDirective permettant d'accéder au formulaire et de contrôler s'il a été soumis
  formGroupDirective!: FormGroupDirective;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =true;
    }
  }

  canDeactivate(): boolean {
    return !this.formGroupDirective.dirty;
  }

  resetFormStates() {
    this.formGroupDirective.control.markAsPristine();
    this.formGroupDirective.control.markAsUntouched();
  }
}
