import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PanierEntete} from "../../model/epona-api/PanierEntete";
import {PanierLigne} from "../../model/epona-api/panier-ligne";
import {PanierService} from "../../services/epona/panier.service";
import {MatTableDataSource} from "@angular/material/table";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../commons/CustomValidators";
import {SelectionModel} from "@angular/cdk/collections";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DialogConfirmComponent} from "../../commons/dialog-confirm/dialog-confirm.component";
import {FormTools} from "../../commons/FormTools";
import {ClearMessages, MessageTool} from "../../commons/MessageTool";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {forkJoin, Observable} from "rxjs";

@Component({
    selector: 'epona-panier-lignes',
    templateUrl: './panier-lignes.component.html',
    styleUrls: ['./panier-lignes.component.css'],
    standalone: false
})
export class PanierLignesComponent  implements OnInit, OnChanges {

  dataSource = new MatTableDataSource<PanierLigne>([]);
  @Input() entete: PanierEntete;
  @Input() lignes: Array<PanierLigne>;
  droitSaisie: boolean;
  form: UntypedFormGroup;
  selection = new SelectionModel<PanierLigne>(true, []);
  displayedColumns: string[] = [];
  @Output() readonly debutModification = new EventEmitter<void>();
  @Output() readonly finModification = new EventEmitter<void>();
  @Output() readonly ligneUpdated = new EventEmitter<PanierLigne>();
  @Output() readonly lignesDeleted = new EventEmitter<Array<PanierLigne>>();


  readonly COLUMNS: {[key: string]: TableColumn} = {
    checkBox:           new TableColumn({label: 'Sélection', hiddable: false, export: false}),
    codeArticle:        new TableColumn({label: 'Référence', tooltip: 'Référence de l\'article chez le fournisseur', hiddable: false}),
    description:        new TableColumn({label: 'Désignation fournisseur'}),
    colisage:           new TableColumn({label: 'Colisage', exportFormat: 'decimal'}),
    quantite:           new TableColumn({label: 'Quantité', exportFormat: 'decimal'}),
    conditionnement:    new TableColumn({label: 'Cond.', tooltip: 'Conditionnement'}),
    prixHT:             new TableColumn({label: 'PU HT', exportFormat: 'decimal'}),
    tauxTva :           new TableColumn({label: 'TVA'}),
    prixTTC:            new TableColumn({label: 'PU TTC',                                           default: false, exportFormat: 'decimal'}),
    descriptionLongue:  new TableColumn({label: 'Description',                                      default: false}),
    delaiLivraison:     new TableColumn({label: 'Délai liv.', tooltip:'Délai de livraison',         default: false}),
    lotMarcheOrion:     new TableColumn({label: 'Lot-marché'}),
    typeLotMarche:      new TableColumn({label: 'Type lot', tooltip: 'Type de lot : BPU ou CAT'}),
    codeArticleAchat:   new TableColumn({label: 'Code-article', tooltip: 'Code-article à l\'achat', default: false}),
    designationBna:     new TableColumn({label: 'Désignation BNA',                                  default: false}),
    colisageBna:        new TableColumn({label: 'Colisage BNA',                                     default: false, exportFormat: 'decimal'}),
    conditionnementBna: new TableColumn({label: 'Cond. BNA', tooltip: 'Conditionnement BNA',        default: false}),
    prixHtBna:          new TableColumn({label: 'PU HT BNA',                                        default: false, exportFormat: 'decimal'}),
    dateMajPrix:        new TableColumn({label: 'Date prix',                                        default: false, exportFormat: 'datetime'}),
    erreur:             new TableColumn({label: 'Erreur',                                           default: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.LIGNES_PANIER;

  constructor(private messageTool: MessageTool,
              private panierService: PanierService, private fb: UntypedFormBuilder, public dialog: MatDialog) {
    this.form = fb.group({
      lignes: fb.array([])
    });
  }

  ngOnInit() {
    this.droitSaisie      = this.panierService.droitSaisie();

    if (this.lignes) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['lignes'] && !changes['lignes'].firstChange) {
      this.init();
    }

    if (changes['entete'] && !changes['entete'].firstChange) {
      this.setDisplayedColumns(); // Les colonnes peuvent changer en cas de changement d'état de l'entête
    }
  }

  private init() {
    if (this.lignes) {
      this.initFormArray();
      this.dataSource.data = this.lignes;
      this.deselectedLignes();
    }
  }

  private initFormArray() {
    const formArray = this.fb.array([]);

    for (const ligne of this.lignes) {
      const formGroup = this.fb.group({
        quantite: this.fb.control(
          ligne.quantite,
          [Validators.min(0), CustomValidators.zeroInterdit(true)]
        )
      });

      formArray.push(formGroup);
    }

    this.form.setControl('lignes', formArray);
  }

  deselectedLignes() {
    // si pas de lignes sélectionné, on désélectionne tout
    if (!this.lignes.some(inventaireLigne => inventaireLigne.selected === true)) {
      this.selection.clear();
    }
  }

  openDialogAjoutArticles(){
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Accès au site fournisseur",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "Vous allez quitter Épona pour vous rendre sur le site du founisseur, confirmez-vous cette action ?"};

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          window.location.href = this.panierService.urlRedirect(this.entete.idPanierEntete);
        }
      });
  }

  openDialogSuppression(): void {
    if (this.selection.selected.length === 0 ) {
      return this.messageTool.sendErrorMessage("Vous devez sélectionner au moins un article");
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Confirmation de suppression",
      yesLabel: "Confirmer",
      noLabel: "Annuler",
      body: this.selection.selected.length > 1
        ? "Êtes-vous sûr de vouloir supprimer les articles sélectionnés de ce panier ?"
        : "Êtes-vous sûr de vouloir supprimer l'article sélectionné de ce panier ?"};

    const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let listeObservable: Array<Observable<any>> = [];

        for (let ligne of this.selection.selected) {
          listeObservable.push(this.panierService.deleteLigne(this.entete.idPanierEntete, ligne.idPanierLigne));
        }

        forkJoin(listeObservable).subscribe(() => {
            if (listeObservable.length > 1) {
              this.messageTool.sendSuccess("Les articles ont été supprimés avec succès du panier", ClearMessages.TRUE);
            } else {
              this.messageTool.sendSuccess("L'article a été supprimé avec succès du panier", ClearMessages.TRUE);
            }

            // Envoi de la liste des lignes supprimées au composant parent
            this.lignesDeleted.emit(this.selection.selected);
          }, err => {
            this.messageTool.sendError(err);
          }
        )
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  private setDisplayedColumns() {
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  debutUpdate(index: number, formControlName: string) {
    this.debutModification.emit();

    const formCtrl = this.getLigneCtrl(index).get(formControlName);
    // En cas d'erreur liée au webservice REST, celle-ci n'est plus mentionnée
    if (formCtrl.hasError('network') || formCtrl.hasError('5xx') || formCtrl.hasError('4xx')) {
      formCtrl.setErrors(null);
    }
  }

  finUpdate() {
    this.finModification.emit();
  }


  private getLigneCtrl(index: number): UntypedFormControl {
    return (this.form.get('lignes') as UntypedFormArray).controls[index] as UntypedFormControl;
  }

  hasError(index: number, controlName: string, errorCode: string): boolean {
    return this.getLigneCtrl(index).get(controlName).hasError(errorCode);
  }

  updateLigne(index: number, formControlName: string) {
    const ligne: PanierLigne = this.lignes[index];
    const ligneCtrl = this.getLigneCtrl(index);

    if (!ligneCtrl.valid) {
      this.messageTool.sendErrorMessage(`La ligne de l'article ${ligne.descriptionCourte} est erronée`);
      FormTools.markAsFailure(ligne);
      this.finUpdate();

    } else if (ligneCtrl.dirty) {
      ligne.quantite        = ligneCtrl.get('quantite').value;
      ligne.prixUnitaire    = ligne.quantite && ligne.prixUnitaire;

      this.panierService.putLigne(this.entete.idPanierEntete, ligne).subscribe(() => {
        const message = `La ligne de l'article ${ligne.descriptionCourte} a été mise à jour avec succès`;
        this.messageTool.sendSuccess(message, ClearMessages.TRUE);

        // Envoi au composant parent qu'une ligne a été modifiée
        this.ligneUpdated.emit(ligne);

        // Les valeurs effectivement sauvegardées sont settées dans le formulaire
        ligneCtrl.get('quantite').setValue(ligne.quantite);

        // Ligne  marquée comme n'ayant pas été touchée
        ligneCtrl.markAsPristine();

        FormTools.markAsSuccess(ligne);

      }, err => {
        ligneCtrl.get(formControlName).setErrors(FormTools.ngErrorFromHttpError(err));
        this.messageTool.sendError(err);
        FormTools.markAsFailure(ligne);

      }).add(() => {
        this.finUpdate();
      });

    } else {
      FormTools.unmark(ligne);
      this.finUpdate();
    }
  }
}
