import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {EntreeSortieSearch} from '../../model/epona-api/EntreeSortieSearch';
import {MatSort} from "@angular/material/sort";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {TdbNbEntreesSortiesParLieuType} from "../../model/epona-api/TdbNbEntreesSortiesParLieuType";
import {SortHelper} from "../../helpers/sort-helper";
import {CodeStatutES} from "../../commons/constants/CodeStatutES";

@Component({
    selector: 'epona-card-bordereaux-livraisons',
    templateUrl: './card-bordereaux-livraisons.component.html',
    styleUrls: ['./card-bordereaux-livraisons.component.css'],
    standalone: false
})
export class CardBordereauxLivraisonsComponent implements AfterViewInit, OnChanges {

  displayedColumns: string[] = ['batiment', 'lieu', 'nombreNonValides', 'nombreValidesAttenteMajEj', 'nombreValidesAttenteEnvoiSf'];
  dataSource = new MatTableDataSource<TdbNbEntreesSortiesParLieuType>([]);

  @Input() lieuCourant: Lieu;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private tdbService: TableauBordService) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lieuCourant']) {
      this.rempliListeBL();
    }
  }

  rempliListeBL() {
    // commencer par l'initialisation de la liste
    this.dataSource.data = [];

    const entreeSortieSearch = new EntreeSortieSearch();
    entreeSortieSearch.idCrous       = this.lieuCourant.idCrous;
    entreeSortieSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    entreeSortieSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    entreeSortieSearch.idRu          = this.lieuCourant.idRu;
    entreeSortieSearch.idUd          = this.lieuCourant.idUd;
    entreeSortieSearch.codeTypeMouvement = CodeTypeMouvement.APPROVISIONNEMENT;
    entreeSortieSearch.codesStatut   = [CodeStatutES.NON_VALIDE, CodeStatutES.VALIDE_ATTENTE_MAJ_EJ, CodeStatutES.VALIDE_ATTENTE_ENVOI_SF];

    this.tdbService.getEntreesSorties(entreeSortieSearch).subscribe(data => {
      this.dataSource.data = data;
    });
  }

  sortingDataAccessor(data: TdbNbEntreesSortiesParLieuType, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'batiment'                   : return SortHelper.stringToString(data.lieu?.nomRu);
      case 'lieu'                       : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'nombreNonValides'           : return data.detail[0].nombreNonValide;
      case 'nombreValidesAttenteMajEj'  : return data.detail[0].nombreValideAttenteMajEj;
      case 'nombreValidesAttenteEnvoiSf': return data.detail[0].nombreValideAttenteEnvoiSf;
      default                           : return data[sortHeaderId];
    }
  }
}
