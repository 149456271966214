import {Lieu} from './Lieu';
import {Extra} from './Extra';
import {Groupe} from "./Groupe";
import {SousGroupe} from "./SousGroupe";

export class InventaireEntete {

  idInventaireEntete: number;

  lieu: Lieu;
  groupeArticles: Groupe;
  sousGroupeArticles: SousGroupe;
  libelle: string;
  commentaire: string;

  dateCreation: Date;
  utilisateur: string;
  dateModification: Date;
  utilisateurModification: string;
  dateCloture: Date;
  utilisateurCloture: string;

  extra: Extra;
}
