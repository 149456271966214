<table class="mat-mdc-table mat-elevation-z3" aria-label="Rappel de la commande et de la livraison sur lesquelles porte la FDNC">
  <thead>
  <tr class="mat-mdc-header-row">
    <th class="mat-mdc-cell-epona" scope="col">Fournisseur</th>
    <th class="mat-mdc-cell-epona" scope="col">Commande</th>
    <th class="mat-mdc-cell-epona" scope="col" *ngIf="livraison">BL</th>
    <th class="mat-mdc-cell-epona" scope="col" *ngIf="article">Article</th>
  </tr>
  </thead>

  <tbody>
  <tr class="mat-mdc-row">
    <td class="mat-mdc-cell-epona">{{ commande?.fournisseur.nom }}</td>
    <td class="mat-mdc-cell-epona">
      <a [routerLink]="commande ? ['/commande', commande.idCommandeEntete] : null">{{ commande | commande }}</a>
    </td>
    <td class="mat-mdc-cell-epona" *ngIf="livraison">
      <a [routerLink]="livraison ? ['/bordereau-livraison', livraison.idEntreeSortieEntete] : null">{{ livraison | bordereauLivraison }}</a>
    </td>
    <td class="mat-mdc-cell-epona" *ngIf="article">
      <span [matTooltip]="article.designationAchat">{{ article.codeArticleAchat }}</span>
    </td>
  </tr>
  </tbody>
</table>
