import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NgControl, UntypedFormControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Const} from '../../constants/Const';
import {Subject} from 'rxjs';
import {CacheService} from '../../../services/cache.service';
import {MessageTool} from '../../MessageTool';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {TraitementNonConformite} from '../../../model/bna-api/traitement-non-conformite';
import {BnaFdncService} from "../../../services/bna/bna-fdnc.service";

@Component({
    selector: 'epona-select-traitement-non-conformite',
    templateUrl: './select-traitement-non-conformite.component.html',
    styleUrls: ['./select-traitement-non-conformite.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectTraitementNonConformiteComponent }],
    standalone: false
})
export class SelectTraitementNonConformiteComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<TraitementNonConformite[]> {

  @Input() defaultLabel: string = '- Aucun -';
  @Input() displayType: 'select'|'checkbox' = 'select';
  @Input() afficherInactifs: boolean = true;

  @Output() valueInitialized = new EventEmitter<TraitementNonConformite[]>();

  public readonly DEFAULT = Const.DEFAULT;
  private readonly CACHE_KEY: string = 'traitements-non-conformite';

  private static loadInProgress: boolean = false;
  private static listLoaded = new Subject<TraitementNonConformite[]>();

  formCtrl: UntypedFormControl;
  formGroup: FormGroup;
  formArray: FormArray<FormControl<boolean>>;
  liste: TraitementNonConformite[] = [];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnaFdncService: BnaFdncService,
              private cacheService: CacheService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl();
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });

    this.formArray = new FormArray<FormControl<boolean>>([]);
    this.formGroup = new FormGroup({
      'types': this.formArray
    });
    this.formGroup.valueChanges.subscribe(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: TraitementNonConformite[]): void {
    this.formCtrl.setValue(this.traitementNonConformiteToNumber(obj));

    if (SelectTraitementNonConformiteComponent.loadInProgress) {
      SelectTraitementNonConformiteComponent.listLoaded.subscribe(list => {
        this.valueInitialized.emit(this.getCurrentItem());
      })
    } else {
      if (obj) {
        for (let traitement of obj) {
          const traitementBna = this.liste.find(t => t.code === traitement.code);
          if (traitementBna) {
            traitement.libelle         = traitementBna.libelle;
            traitement.description     = traitementBna.description;
            traitement.tempsTraitement = traitementBna.tempsTraitement;
          }
        }
      }
      this.valueInitialized.emit(this.getCurrentItem());
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TraitementNonConformite[] {
    return this.getCurrentItem();
  }
  set value(value: TraitementNonConformite[]) {
    this.formCtrl.setValue(this.traitementNonConformiteToNumber(value));
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): TraitementNonConformite[] {
    if (this.displayType === 'select') {
      return this.liste ? this.liste.filter(item => (this.formCtrl.value as number[]).includes(item.code)) : [];
    } else {
      return this.formArray.controls
        .map((formCtrl:FormControl<boolean>, index: number) => formCtrl.value ? this.liste[index] : null)
        .filter(traitement => traitement !== null);
    }
  }

  private loadListe() {
    // Si la liste a déjà été chargée
    if (this.cacheService.has(this.CACHE_KEY)) {
      this.setAndSortListeFromCache();

    // Sinon, si le chargement est en cours, attente de la fin du chargement
    } else if (SelectTraitementNonConformiteComponent.loadInProgress) {
      SelectTraitementNonConformiteComponent.listLoaded.subscribe(list => {
        this.setAndSortListeFromCache();
        SelectTraitementNonConformiteComponent.listLoaded.unsubscribe();
      });

    // Sinon récupération de la liste via l'API
    } else {
      SelectTraitementNonConformiteComponent.loadInProgress = true;
      this.bnaFdncService.getListeTraitementsNonConformite().subscribe(data => {
        this.cacheService.set(this.CACHE_KEY, data);
        SelectTraitementNonConformiteComponent.loadInProgress = false;
        this.setAndSortListeFromCache();
        SelectTraitementNonConformiteComponent.listLoaded.next(data);

      }, err => {
        this.messageTool.sendError(err);
      });
    }
  }

  private setAndSortListeFromCache() {
    this.liste = (this.cacheService.get(this.CACHE_KEY) as TraitementNonConformite[]).filter(t => t.actif || this.afficherInactifs);
    this.tri(this.liste);
    this.initFormArray();
  }

  tri(liste: Array<TraitementNonConformite>) {
    liste.sort((a, b) => {
      return a.libelle.localeCompare(b.libelle);
    });
  }

  private initFormArray() {
    if (this.displayType === 'checkbox') {
      const checked = this.liste.length === 1;
      this.formArray.clear();
      for (const traitement of this.liste) {
        this.formArray.push(new FormControl<boolean>({value: checked, disabled: !traitement.actif}));
      }
      if (checked) {
        this.selectionChanged();
      }
    }
  }

  traitementNonConformiteToNumber(obj: TraitementNonConformite[]): number[] {
    if (obj) {
      return obj.map(traitement => traitement.code);
    } else {
      return [];
    }
  }
}
