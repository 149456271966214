/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import {Injectable} from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {CookieService} from 'ngx-cookie-service';
import { finalize } from "rxjs/operators";
import {appProperties} from "../app.properties";
import {environment} from "../../environments/environment";
import {HttploaderService} from '../commons/httploader/httploader.service';
import {SessionStorageHelper} from "../helpers/session-storage-helper";

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

  readonly cookieName: string = JwtInterceptorService.buildCookieName();

  constructor(private cookieService: CookieService,
              private loaderService: HttploaderService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Booléen indiquant si le loader a déjà été arrêté
    let alreadyStopped = false;

    // Affichage du loader après 300 ms
    (async () => {
      await this.delay(500);
      if (!alreadyStopped) {
        this.loaderService.show();
      }
    })();

    // Ajout éventuel du paramètre de simulation de lenteur
    const simDelayStocke = localStorage.getItem('simDelay');
    if (simDelayStocke !== null) {
      req = req.clone({setParams: {simDelay: simDelayStocke}});
    }

    let authHeader: string|null = null;
    const devToken = SessionStorageHelper.getDevToken();
    if (devToken !== null && (environment.env === 'DEV' || environment.env === 'INT')) {
      authHeader = devToken;
    } else {
      if (this.cookieService.get(this.cookieName)) {
        authHeader = 'Bearer ' + this.cookieService.get(this.cookieName);
      }
    }

    // Ajout du token, exécution de la requête et masquage du loader
    if (authHeader) {
      const clone = req.clone({ setHeaders: { Authorization: authHeader}, withCredentials:true });

      return next.handle(clone).pipe(
        finalize(() => {
          alreadyStopped = true;
          this.loaderService.hide()
        })
      );
    }

    // Exécution de la requête et masquage du loader (cas où aucun token n'a été ajouté)
    return next.handle(req).pipe(
      finalize(() => {
        alreadyStopped = true;
        this.loaderService.hide()
      })
    );
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public static buildCookieName(): string {
    const codeApplication = appProperties.codeApplication;
    const env = environment.env;
    return `bns-auth-${codeApplication}-${env}`;
  }

  public deleteCookie(): void {
    this.cookieService.delete(this.cookieName, '/', environment.domain);
  }
}
