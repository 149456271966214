<div *ngIf="lignes !== undefined">
  <div class="table-actions">
    <!-- Des articles peuvent êtres ajoutés lorsque
          la commande est à l'état brouillon
          ou la commande est interne et en préparation
          -->
    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogAjoutArticles()"
            [disabled]="!(estBrouillon() || estEnPreparationInterne())"
            *ngIf="droitSaisie || droitPreparation">
      <fa-icon icon="plus"></fa-icon> Ajouter…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="openDialogSuppression()"
            [disabled]="!estBrouillon()"
            *ngIf="droitSaisie">
      <fa-icon icon="trash"></fa-icon> Supprimer…
    </button>

    <button type="button"
            mat-raised-button color="primary"
            (click)="exporter.exportTable({nomFichier: 'lignes-commandes.xlsx'})"
            [disabled]="dataSource.data.length === 0">
      <fa-icon icon="file-excel"></fa-icon> Exporter
    </button>
  </div>

  <form [formGroup]="form" autocomplete="off">
    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows="true"
           formArrayName="lignes"
           cnousTableExporter #exporter="cnousTableExporter" [columns]="COLUMNS"
           class="mat-elevation-z8"
           aria-label="Liste des lignes d'une commande">

      <ng-container matColumnDef="checkBox">
        <th mat-header-cell *matHeaderCellDef scope="col" rowspan="2">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [disabled]="!estBrouillon()">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let data;let i = dataIndex">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(data) : null"
                        [checked]="selection.isSelected(data)"
                        [disabled]="!estBrouillon()">
          </mat-checkbox>

          <div class="update-result">
            <span class="success"><fa-icon icon="check-circle"></fa-icon></span>
            <span class="failure"><fa-icon icon="times-circle"></fa-icon></span>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="article">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col" [attr.colspan]="colspanArticle">Article</th>
      </ng-container>

      <ng-container matColumnDef="codeArticle">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col">Code</th>
        <td class="first" mat-cell *matCellDef="let data;let i = dataIndex">
          <a href="#" (click)="$event.preventDefault();openDialogArticle(data)">
            {{ data.article | codeArticle:utilisation }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Désignation</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex"
            [ngClass]="{'erreur' :        estExterne() && (data.article.commandableExterne === false),
                        'avertissement' : estExterne() && (data.article.commandableExterne === undefined || data.article.commandableExterne === null  || data.article.commandableExterne !== false && !data.article.parametragesNationauxOk)}">

          {{ data.article | designation:utilisation }}

          <aide icon="error_outline" class="aide-contextuelle" *ngIf="estExterne() && data.article.commandableExterne === false">
            <p>Le paramétrage de cet article indique qu'il ne doit pas être commandé.</p>

            <p *ngIf="data.article.commentaireParametrage">
              <strong>Commentaire&nbsp;:</strong>
              {{ data.article.commentaireParametrage }}
            </p>
          </aide>

          <aide icon="hourglass_empty" class="aide-contextuelle" *ngIf="estExterne() && (data.article.commandableExterne === undefined || data.article.commandableExterne === null)">
            Le paramétrage budgétaire de cet article doit être validé par une personne habilitée.
          </aide>

          <aide icon="hourglass_empty" class="aide-contextuelle" *ngIf="estExterne() && data.article.commandableExterne !== false && !data.article.parametrageFamilleMarchesOk">
            La famille de marchés de cet article doit être renseignée par une personne habilitée.
          </aide>

          <aide icon="hourglass_empty" class="aide-contextuelle" *ngIf="estExterne() && data.article.commandableExterne !== false && !data.article.parametrageCompteComptableOk">
            Le sous-groupe ou le compte comptable Épona de cet article doit être renseigné par une personne habilitée.
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Référence fournisseur">Référence</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.reference }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pcb">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Colisage">Colis.</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ (data.article.pcb > 0 ? data.article.pcb : data.colisage > 0 ? data.colisage : null) | decimal4 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantiteStock">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Quantité en stock">Stock</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.quantiteStock }}
        </td>
      </ng-container>


      <ng-container matColumnDef="commande">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col" [attr.colspan]="colspanCommande">Commande</th>
      </ng-container>

      <ng-container matColumnDef="quantite">
        <th class="title-container first quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
        <td class="first" mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <input matInput type="number"
                   #quantiteInput
                   (keydown)="debutUpdate(index, 'quantite')"
                   (blur)="updateLigne(index, 'quantite')"
                   (keyup.enter)="updateLigne(index, 'quantite', true)"
                   formControlName="quantite"
                   min="0" max="1000000" [step]="data.article.pcb">

            <mat-error *ngIf="hasError(index, 'quantite', 'min')">Qtté. négative interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'zeroInterdit')">Qtté. nulle interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'pcb')">Multiple de {{ data.article.pcb | number }} attendu</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', 'network')">Erreur réseau</mat-error>
            <mat-error *ngIf="hasError(index, 'quantite', '5xx')">Erreur serveur</mat-error>
          </mat-form-field>

          <!-- Version texte uniquement pour l'export -->
          <div class="cdk-visually-hidden">{{ data.quantite | decimal4 }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="conditionnement">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Conditionnement">Cdt.</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.uniteConditionnement?.abreviation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixAchatHt">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Prix unitaire HT">PU HT</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex"
            [ngClass]="{'erreur' : !data.prixAchatHt && estCataloguePunchout,
                        'avertissement' : estBrouillon() && data.estPrixRecent === false}">
          {{ data.prixAchatHt | decimal4 }}

          <aide class="aide-contextuelle" icon="warning" *ngIf="!data.prixAchatHt && estCataloguePunchout">
            Le prix de cet article a été supprimé d'Épona suite à la dernière révision tarifaire et doit être récupéré depuis le site du fournisseur via un panier.
          </aide>
          <aide class="aide-contextuelle" icon="warning" *ngIf="estBrouillon() && data.estPrixRecent === false">
            Le prix de cet article est possiblement obsolète (dernière mise à jour le {{ data.dateMajPrix | dateFormat }}).
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="tauxTva">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Taux de TVA">TVA</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.tauxTva !== undefined && data.tauxTva !== null ? ((data.tauxTva | decimal1) + ' %') : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="prixAchatTtc">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Prix unitaire TTC">PU TTC</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex" [ngClass]="{'erreur' : !data.prixAchatTtc && estCataloguePunchout}">
          {{ data.prixAchatTtc | decimal4 }}

          <aide class="aide-contextuelle" icon="warning" *ngIf="!data.prixAchatTtc && estCataloguePunchout">
            Le prix de cet article a été supprimé d'Épona suite à la dernière révision tarifaire et doit être récupéré depuis le site du fournisseur via un panier.
          </aide>
        </td>
      </ng-container>

      <ng-container matColumnDef="valeurHt">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Valeur HT</th>
        <td mat-cell *matCellDef="let data;let i = dataIndex">
          {{ data.valeurHt | decimal2 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="commentaire">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Commentaire</th>
        <td mat-cell *matCellDef="let data">
          <button type="button"
                  mat-button
                  matTooltip="Modifier le commentaire"
                  (click)="openDialogCommentaire(data)"
                  [disabled]="data.success === false"
                  *ngIf="estBrouillon() && droitSaisie && !data.commentaire"><fa-icon icon="comment-dots"></fa-icon></button>

          <a href="#"
             matTooltip="Consulter / modifier le commentaire"
             class="commentaire-ligne"
             (click)="$event.preventDefault();openDialogCommentaire(data)"
             *ngIf="estBrouillon() && droitSaisie && data.commentaire">{{ data.commentaire }}</a>

          <div class="commentaire-ligne"
                *ngIf="(!estBrouillon() || !droitSaisie) && data.commentaire">{{ data.commentaire }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="magasin">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col" colspan="2">Magasin</th>
      </ng-container>

      <ng-container matColumnDef="quantiteMagasin">
        <th class="title-container first quantite" mat-header-cell *matHeaderCellDef scope="col">Quantité</th>
        <td class="first" mat-cell *matCellDef="let data;let index = dataIndex" [formGroupName]="index">
          <mat-form-field appearance="fill" subscriptSizing="dynamic">
            <input matInput type="number"
                   #quantiteMagasinInput
                   (keydown)="debutUpdate(index, 'quantiteMagasin')"
                   (blur)="updateLigne(index, 'quantiteMagasin')"
                   (keyup.enter)="updateLigne(index, 'quantiteMagasin', true)"
                   formControlName="quantiteMagasin"
                   min="0" max="1000000" [step]="data.article.pcb">

            <mat-error *ngIf="hasError(index, 'quantiteMagasin', 'min')">Qtté. négative interdite</mat-error>
            <mat-error *ngIf="hasError(index, 'quantiteMagasin', 'rest')">Erreur réseau</mat-error>
          </mat-form-field>

          <!-- Version texte uniquement pour l'export -->
          <div class="cdk-visually-hidden">{{ data.quantiteMagasin | decimal4 }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="commentaireMagasin">
        <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">Commentaire</th>
        <td mat-cell *matCellDef="let data">
          <button type="button"
                  mat-button
                  matTooltip="Modifier le commentaire magasin"
                  (click)="openDialogCommentaireMagasin(data)"
                  [disabled]="data.success === false"
                  *ngIf="estEnPreparationInterne() && droitPreparation && !data.commentaireMagasin"><fa-icon icon="comment-dots"></fa-icon></button>

          <a href="#"
             matTooltip="Consulter / modifier le commentaire magasin"
             class="commentaire-ligne"
             (click)="$event.preventDefault();openDialogCommentaireMagasin(data)"
             *ngIf="estEnPreparationInterne() && droitPreparation && data.commentaireMagasin">{{ data.commentaireMagasin }}</a>

          <div class="commentaire-ligne"
               *ngIf="(!estEnPreparationInterne() || !droitPreparation) && data.commentaireMagasin">{{ data.commentaireMagasin }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="reception">
        <th class="title-container first" mat-header-cell *matHeaderCellDef scope="col" colspan="1">Réception</th>
      </ng-container>

      <ng-container matColumnDef="quantiteReceptionnee">
        <th class="title-container first quantite" mat-header-cell *matHeaderCellDef scope="col" matTooltip="Quantité réceptionnée">Quantité</th>
        <td class="first" mat-cell *matCellDef="let data;let i = dataIndex">
          <span [ngClass]="{'quantiteReceptionneeInsuffisante': data.quantiteReceptionnee !== data.quantite}">{{ data.quantiteReceptionnee }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedTopHeaders"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedHeaders"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'success': row.success === true, 'failure': row.success === false }"></tr>


      <!-- Ligne de pied de tableau en cas de liste vide -->
      <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedHeaders ? displayedHeaders.length : 1">Liste vide</td>
      </ng-container>

      <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
    </table>
  </form>

  <epona-commande-totaux [entete]="entete" [forceUpdate]="forceUpdateTotaux" *ngIf="estExterne()"></epona-commande-totaux>
</div>
