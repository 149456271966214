import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {PanierFilter} from "../../model/epona-ui/panier-filter";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
    selector: 'epona-filtre-paniers',
    templateUrl: './filtre-paniers.component.html',
    styleUrls: ['./filtre-paniers.component.css'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                floatLabel: 'always',
                subscriptSizing: 'dynamic'
            }
        }
    ],
    standalone: false
})
export class FiltrePaniersComponent implements OnInit {

  @Input() filter: PanierFilter;
  @Output() readonly filterSubmitted = new EventEmitter<PanierFilter>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {

    this.form = this.fb.group({
      numeroPanier: fb.control(null),
      utilisateur: fb.control(null),
      fournisseur: fb.control(null)
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('numeroPanier').setValue(this.filter.numeroPanier);
    this.form.get('utilisateur').setValue(this.filter.utilisateur);
    this.form.get('fournisseur').setValue(this.filter.fournisseur);
  }

  onSubmit() {
    const newFilter = new PanierFilter();

    newFilter.numeroPanier = this.form.get('numeroPanier').value;
    newFilter.utilisateur = this.form.get('utilisateur').value;
    newFilter.fournisseur    = this.form.get('fournisseur').value;

    this.filterSubmitted.emit(newFilter);
  }

}
