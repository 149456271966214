<epona-select-lieu [lieu]="lieuCourant"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>État des stocks</h1>

<div class="crous-recherche">
  <epona-filtre-stocks [filter]="filter"
                       class="crous-recherche-filtre"
                       (filterSubmitted)="onFilterSubmitted($event)"></epona-filtre-stocks>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Stocks</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeStocks()"
                [disabled]="dataSource.data.length === 0">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             (matSortChange)="onSortChanged($event)"
             aria-label="Liste de stocks">

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieu">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lieu'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="groupe">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['groupe'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.article.sousGroupe?.groupe.libelle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sousGroupe">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['sousGroupe'].label }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.article.sousGroupe?.libelle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="codeArticleAchat">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['codeArticleAchat'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ element.article | codeArticle:UA.ACHAT:true }}</td>
        </ng-container>

        <ng-container matColumnDef="codeArticleVente">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['codeArticleVente'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ element.article | codeArticle:UA.VENTE:true }}</td>
        </ng-container>

        <ng-container matColumnDef="designation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['designation'].label }}</th>
          <td mat-cell *matCellDef="let element">{{ element.article | designation }}</td>
        </ng-container>

        <ng-container matColumnDef="quantite">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['quantite'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.quantite | decimal4 }} </td>
        </ng-container>

        <ng-container matColumnDef="uniteExploitation">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header [matTooltip]="COLUMNS['uniteExploitation'].tooltip">{{ COLUMNS['uniteExploitation'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.article.uniteExploitation?.abreviation }} </td>
        </ng-container>

        <ng-container matColumnDef="pmpHt">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['pmpHt'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.pmpHt | decimal4 }} </td>
        </ng-container>

        <ng-container matColumnDef="pmpTtc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['pmpTtc'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.pmpTtc | decimal4 }} </td>
        </ng-container>

        <ng-container matColumnDef="valeurHt">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['valeurHt'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.pmpHt * data.quantite | decimal2 }} </td>
        </ng-container>

        <ng-container matColumnDef="valeurTtc">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['valeurTtc'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.pmpTtc * data.quantite | decimal2 }} </td>
        </ng-container>

        <ng-container matColumnDef="stockMini">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['stockMini'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.stockMini | decimal4 }} </td>
        </ng-container>

        <ng-container matColumnDef="dateDernierInventaire">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['dateDernierInventaire'].label }}</th>
          <td mat-cell *matCellDef="let data"> {{ data.dateDernierInventaire | dateTimeFormat }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <button type="button"
                    mat-button
                    (click)="openDialogDetailDlc(element)"
                    matTooltip="Détail des stocks par DLC"
                    [matTooltipDisabled]="!element.dlcMin"
                    [disabled]="!element.dlcMin">
              <fa-icon icon="calendar"></fa-icon>
            </button>

            <button type="button"
                    mat-button
                    (click)="openDialogHistoriqueMouvements(element)"
                    matTooltip="Historique des mouvements et valorisations de stocks">
              <fa-icon icon="exchange-alt"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
