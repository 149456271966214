import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogDataConfirmationEntreeSortie} from "../../model/epona-ui/DialogDataConfirmationEntreeSortie";
import {FormControl} from "@angular/forms";
import {CommandeService} from "../../services/epona/commande.service";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {MessageTool} from "../../commons/MessageTool";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {CommandeLigneSearch} from "../../model/epona-api/CommandeLigneSearch";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";

@Component({
    selector: 'epona-dialog-confirm-validation',
    templateUrl: './dialog-confirm-validation.component.html',
    styleUrls: ['./dialog-confirm-validation.component.css'],
    standalone: false
})
export class DialogConfirmValidationComponent implements OnInit {
  validationCompleteCtrl: FormControl;
  listeBLNonValides: EntreeSortieEntete[] = [];
  validationCompleteForcee: boolean = false;

  constructor(private dialog: MatDialogRef<DialogConfirmValidationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirmationEntreeSortie,
              private commandeService: CommandeService,
              private entreeSortieService: EntreeSortieService,
              private messageTool: MessageTool) {

    this.validationCompleteCtrl = new FormControl<boolean>(false);
  }

  ngOnInit(): void {
    // La boite de dialogue a toujours la même taille. Cela évite de la définir dans le composant qui appelle la boite de dialogue.
    this.dialog.updateSize("500px");

    if (this.estBLSurCommande()) {
      this.getBLNonValides();
    }
  }

  getBLNonValides() {
    // Récupération des BL de la commande du BL en cours de validation
    this.commandeService.getListeEntreeSortieFromCommande(this.data.entete.commande.idCommandeEntete).subscribe(data => {
      this.listeBLNonValides = new Array<EntreeSortieEntete>();

      // Conservation des BL non validés (hors BL en cours de validation)
      for (let bl of data) {
        if (!bl.validee && bl.idEntreeSortieEntete !== this.data.entete.idEntreeSortieEntete) {
          this.listeBLNonValides.push(bl);
        }
      }

      // S'il n'y aucun BL non validé
      if (this.listeBLNonValides.length === 0) {
        this.seraTotalementReceptionnee().subscribe({
          next: totalementReceptionnee => {
            if (totalementReceptionnee) {
              this.validationCompleteForcee = true;
              this.validationCompleteCtrl.disable();
              this.validationCompleteCtrl.setValue(true);
            } else {
              this.validationCompleteForcee = false;
              this.validationCompleteCtrl.enable();
              this.validationCompleteCtrl.setValue(false);
            }
          }
        });
      }

    }, err => {
      this.messageTool.sendError(err);
    });
  }

  private seraTotalementReceptionnee(): Observable<boolean> {
    // Récupération des lignes de la commande d'origine
    const search = new CommandeLigneSearch();
    search.fields = 'article.idArticle,quantite,quantiteReceptionnee'
    return this.commandeService.getListeLignes(this.data.entete.commande.idCommandeEntete, search).pipe(
      map<CommandeLigne[], boolean>(listeLignesCommandes => {
          for (let ligneCommande of listeLignesCommandes) {
            const ligneBL = this.data.lignes.find(l => l.article.idArticle === ligneCommande.article.idArticle);
            const quantiteEnReception = ligneBL ? ligneBL.quantite : 0;
            const quantiteReceptionnee = ligneCommande.quantiteReceptionnee + quantiteEnReception;
            if (ligneCommande.quantite !== quantiteReceptionnee) {
              return false;
            }
          }
          return true;
      })
    );
  }

  estBLSurCommande() {
    return this.data.entete.typeMouvement.codeTypeMouvement === CodeTypeMouvement.APPROVISIONNEMENT
      && this.data.entete.commande;
  }

  close() {
    this.dialog.close();
  }

  confirm() {
    this.data.validation = true;
    this.data.validationComplete = this.validationCompleteCtrl.value;
    this.close();
  }
}
