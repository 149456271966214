import {FournisseurFilter} from "../epona-ui/fournisseur-filter";

export class FournisseurSearch {


  public static fabric(idCrous: string, fournisseurFilter: FournisseurFilter, fields: string) : FournisseurSearch {

    const fournisseurSearch = new FournisseurSearch();

    fournisseurSearch.idCrous = Number.parseInt(idCrous);
    fournisseurSearch.nom = fournisseurFilter.nom;
    fournisseurSearch.siret = fournisseurFilter.siret;
    fournisseurSearch.codeMarche = fournisseurFilter.marche ? fournisseurFilter.marche.codeMarcheOrion : null;
    fournisseurSearch.hasPunchout = fournisseurFilter.hasPunchout;
    fournisseurSearch.fields = fields;

    return fournisseurSearch;
  }

  idCrous: number;
  nom: string;
  siret: string;
  codeMarche: string;
  hasPunchout: boolean;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;

  fields: string;
}
