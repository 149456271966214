import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EntreeSortieLigneLot} from "../../model/epona-api/entree-sortie-ligne-lot";
import {
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {ArticleTools} from "../../commons/ArticleTools";
import {UtilisationArticle} from "../../commons/constants/UtilisationArticle";
import {SortHelper} from "../../helpers/sort-helper";

@Component({
    selector: 'epona-tableau-lots',
    templateUrl: './tableau-lots.component.html',
    styleUrls: ['./tableau-lots.component.css'],
    standalone: false
})
export class TableauLotsComponent implements OnInit, OnChanges{

  static readonly COLUMNS: {[key: string]: TableColumn} = {
    batiment              : new TableColumn({label: 'Bâtiment'}),
    lieu                  : new TableColumn({label: 'Lieu de livraison'}),
    fournisseur           : new TableColumn({label: 'Fournisseur',                                 hiddable: false}),
    numeroBL              : new TableColumn({label: 'Numéro BL',                                   hiddable: false}),
    dateBL                : new TableColumn({label: 'Date BL',                                     hiddable: false,                 exportFormat: 'date'}),
    referenceFournisseur  : new TableColumn({label: 'Référence', tooltip: 'Référence-fournisseur',                  default: false}),
    codeAchat             : new TableColumn({label: 'Code-achat'}),
    codeVente             : new TableColumn({label: 'Code-vente',                                                   default: false, exportFormat: 'integer'}),
    designation           : new TableColumn({label: 'Désignation',                                 hiddable: false}),
    dlc                   : new TableColumn({label: 'DLC',                                                          default: false, exportFormat: 'date'}),
    numeroLot             : new TableColumn({label: 'Numéro de lot',                               hiddable: false}),
    quantite              : new TableColumn({label: 'Quantité du lot',                                                              exportFormat: 'decimal'})
  };

  @Input() listeLots: EntreeSortieLigneLot[] = [];
  @Input() colonnesAffichees: string[] = [];

  dataSource = new MatTableDataSource<EntreeSortieLigneLot>();
  columns = TableauLotsComponent.COLUMNS;
  columnsStoreCode = CodeStockageColonnes.LOT_MARCHES;

  @ViewChild(MatSort, {static: true})
  sort!: MatSort;

  private sortingDataAccessor(data: EntreeSortieLigneLot, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'batiment'              : return SortHelper.stringToString(data.entreeSortieLigne.entete.lieu.nomRu);
      case 'lieu'                  : return SortHelper.stringToString(data.entreeSortieLigne.entete.lieu.nomUd);
      case 'fournisseur'           : return SortHelper.stringToString(data.entreeSortieLigne.entete.commande.fournisseur?.nom);
      case 'dateBL'                : return SortHelper.dateToNumber(data.entreeSortieLigne.entete.dateBordereauLivraison);
      case 'numeroBL'              : return SortHelper.stringToString(data.entreeSortieLigne.entete.codeBordereauLivraison);
      case 'codeAchat'             : return SortHelper.stringToString(data.entreeSortieLigne.article.codeArticleAchat);
      case 'codeVente'             : return data.entreeSortieLigne.article.codeArticleVente;
      case 'designation'           : return SortHelper.stringToString(
        ArticleTools.getProperty(ArticleTools.PROP_DESIGNATION, data.entreeSortieLigne.article, UtilisationArticle.ACHAT, false)
      );
      case 'dlc'                   : return SortHelper.dateToNumber(data.entreeSortieLigne.dlc);
      case 'numeroLot'             : return SortHelper.stringToString(data.numeroLot);
      case 'quantite'              : return data.quantite;
      default: return 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listeLots']) {
      this.dataSource.data = this.listeLots;
    }
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }
}
