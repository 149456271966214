
import {InventaireEntete} from "./InventaireEntete";
import {InventaireEntetePut} from "./inventaire-entete-put";
import {LieuDTO} from "./LieuDTO";
import {SousGroupeDTO} from "./SousGroupeDTO";
import {GroupeDTO} from "./GroupeDTO";

export class InventaireEntetePost extends InventaireEntetePut {

  lieu: LieuDTO;
  groupeArticles: GroupeDTO;
  sousGroupeArticles: SousGroupeDTO;

  constructor(inventaire: InventaireEntete) {
    super(inventaire);
    this.lieu = new LieuDTO(inventaire.lieu);
    this.groupeArticles = inventaire.groupeArticles ? new GroupeDTO(inventaire.groupeArticles) : null;
    this.sousGroupeArticles = inventaire.sousGroupeArticles ? new SousGroupeDTO(inventaire.sousGroupeArticles) : null;
  }
}
