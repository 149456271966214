import {AccueilComponent} from './accueil/accueil.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, HAMMER_LOADER} from '@angular/platform-browser';
import {EppnFormComponent} from './identification/eppn-form/eppn-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {isDevMode, LOCALE_ID, NgModule} from '@angular/core';
import {VemService} from './services/vem.service';
import {W3Service} from './services/w3.service';
import {EponaService} from './services/epona/epona.service';
import {TopbarComponent} from './topbar/topbar.component';
import {ToastrModule} from 'ngx-toastr';
import {VentesTempsReelComponent} from './ventes-temps-reel/ventes-temps-reel.component';
import {CookieService} from 'ngx-cookie-service';
import {JwtInterceptorService} from './intercepteurs/jwt-interceptor.service';
import {HttploaderComponent} from './commons/httploader/httploader.component';
import {MycurrencyPipe} from './commons/pipes/custom.currencypipe.pipe';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ListeInventairesComponent} from './inventaires/liste-inventaires/liste-inventaires.component';
import {LieuFilterComponent} from './commons/lieu-filter/lieu-filter.component';
import {InventairesFilterComponent} from './inventaires/inventaires-filter/inventaires-filter.component';
import {InventaireEnteteComponent} from './inventaires/inventaire-entete/inventaire-entete.component';
import {DialogConfirmComponent} from './commons/dialog-confirm/dialog-confirm.component';
import {MessageTool} from './commons/MessageTool';
import {DateTimeFormatPipe} from './commons/pipes/DateTimeFormatPipe';
import {InventaireLignesComponent} from './inventaires/inventaire-lignes/inventaire-lignes.component';
import {DateFormatPipe} from './commons/pipes/DateFormatPipe';
import {LieuxArticlesComponent} from './lieux-articles/lieux-articles.component';
import {AjoutArticleModalComponent} from './lieux-articles/ajout-article-modal/ajout-article-modal.component';
import {DialogAjoutDlcComponent} from './inventaires/dialog-ajout-dlc/dialog-ajout-dlc.component';
import {InventaireService} from './services/epona/inventaire.service';
import {ListeCommandesComponent} from './commandes/liste-commandes/liste-commandes.component';
import {FiltreCommandesComponent} from './commandes/filtre-commandes/filtre-commandes.component';
import {CommandeService} from './services/epona/commande.service';
import {CommandeEnteteComponent} from './commandes/commande-entete/commande-entete.component';
import {CustomDateAdapter} from './commons/custom-date-adapter';
import {CommandeLignesComponent} from './commandes/commande-lignes/commande-lignes.component';
import {ParametrageLieuArticleService} from './services/epona/parametrage-lieu-article.service';
import {InventaireComponent} from './inventaires/inventaire/inventaire.component';
import {CommandeComponent} from './commandes/commande/commande.component';
import {EntreeSortieComponent} from './entrees-sorties/entree-sortie/entree-sortie.component';
import {FiltreEntreesSortiesComponent} from './entrees-sorties/filtre-entrees-sorties/filtre-entrees-sorties.component';
import {ListeEntreesSortiesComponent} from './entrees-sorties/liste-entrees-sorties/liste-entrees-sorties.component';
import {EntreeSortieEnteteComponent} from './entrees-sorties/entree-sortie-entete/entree-sortie-entete.component';
import {EntreeSortieLignesComponent} from './entrees-sorties/entree-sortie-lignes/entree-sortie-lignes.component';
import {SelectTypeMouvementComponent} from './commons/inputs/select-type-mouvement/select-type-mouvement.component';
import {DialogAjoutLigneComponent} from './entrees-sorties/dialog-ajout-ligne/dialog-ajout-ligne.component';
import {TruncatePipe} from './commons/pipes/TruncatePipe';
import {TableauBordComponent} from './tableau-de-bord/tableau-bord/tableau-bord.component';
import {CardCommandesComponent} from './tableau-de-bord/card-commandes/card-commandes.component';
import {CardBordereauxLivraisonsComponent} from './tableau-de-bord/card-bordereaux-livraisons/card-bordereaux-livraisons.component';
import {CardEntreesSortiesValideeComponent} from './tableau-de-bord/card-entrees-sorties-validee/card-entrees-sorties-validee.component';
import {CardStockAlertComponent} from './tableau-de-bord/card-stock-alert/card-stock-alert.component';
import {CardInventairesComponent} from './tableau-de-bord/card-inventaires/card-inventaires.component';
import {CardSortiesNonValideeComponent} from './tableau-de-bord/card-sorties-non-validees/card-sorties-non-validee.component';
import {TableauBordService} from './services/epona/tableau-bord.service';
import {AutocompleteArticleVemComponent} from './commons/inputs/autocomplete-article-vem/autocomplete-article-vem.component';
import {DialogAjoutArticlesCommandeComponent} from './commandes/dialog-ajout-articles-commande/dialog-ajout-articles-commande.component';
import {TimeFormatPipe} from './commons/pipes/TimeFormatPipe';
import {LinkResourceComponent} from './commons/inputs/link-resource/link-resource.component';
import {MetaDataComponent} from './commons/inputs/meta-data/meta-data.component';
import {DialogCommentaireCommandeComponent} from './commandes/dialog-commentaire-commande/dialog-commentaire-commande.component';
import {DialogCommentaireInventaireComponent} from './inventaires/dialog-commentaire-inventaire/dialog-commentaire-inventaire.component';
import {
  DialogHistoriqueCorrectionsComponent
} from './entrees-sorties/dialog-historique-corrections/dialog-historique-corrections.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SelectTypeDetailEcartComponent} from './commons/inputs/select-type-detail-ecart/select-type-detail-ecart.component';
import {SignedNumberPipe} from './commons/pipes/SignedNumberPipe';
import {ListeStocksComponent} from './etat-stocks/liste-stocks/liste-stocks.component';
import {FiltreStocksComponent} from './etat-stocks/filtre-stocks/filtre-stocks.component';
import {StockService} from './services/epona/stock.service';
import {SelectDepartementComponent} from './commons/inputs/select-departement/select-departement.component';
import {SelectFamilleComponent} from './commons/inputs/select-famille/select-famille.component';
import {
  DialogHistoriqueMouvementsStocksComponent
} from './etat-stocks/dialog-historique-mouvements-stocks/dialog-historique-mouvements-stocks.component';
import {DialogDetailDlcComponent} from './etat-stocks/dialog-detail-dlc/dialog-detail-dlc.component';
import {DialogConfirmValidationComponent} from './entrees-sorties/dialog-confirm-validation/dialog-confirm-validation.component';
import {DatatableLinkBlComponent} from './entrees-sorties/datatable-link-bl/datatable-link-bl.component';
import {DialogConsultationBlComponent} from './entrees-sorties/dialog-consultation-bl/dialog-consultation-bl.component';
import {SelectBooleanComponent} from './commons/inputs/select-boolean/select-boolean.component';
import {SelectFournisseurComponent} from './commons/inputs/select-fournisseur/select-fournisseur.component';
import {FournisseurService} from './services/epona/fournisseur.service';
import {ListeFournisseursComponent} from './fournisseurs/liste-fournisseurs/liste-fournisseurs.component';
import {FournisseursFilterComponent} from './fournisseurs/fournisseurs-filter/fournisseurs-filter.component';
import {
  FournisseurParametrageGlobalComponent
} from './fournisseurs/fournisseur-parametrage-global/fournisseur-parametrage-global.component';
import {FournisseurParametrageLocalComponent} from './fournisseurs/fournisseur-parametrage-local/fournisseur-parametrage-local.component';
import {FournisseurComponent} from './fournisseurs/fournisseur/fournisseur.component';
import {
  DialogParametrageLieuFournisseurComponent
} from './fournisseurs/dialog-ajout-parametrage-lieu-fournisseur/dialog-parametrage-lieu-fournisseur.component';
import {ListeLotMarcheComponent} from './fournisseurs/liste-lot-marche/liste-lot-marche.component';
import {SelectSousLotZgComponent} from './commons/inputs/select-sous-lot-zg/select-sous-lot-zg.component';
import {AutocompleteArticleMarcheComponent} from './commons/inputs/autocomplete-article-marche/autocomplete-article-marche.component';
import {
  DialogModificationCourrielCommandeComponent
} from './commandes/dialog-modification-courriel-commande/dialog-modification-courriel-commande.component';
import {SelectEtatCommandeComponent} from './commons/inputs/select-etat-commande/select-etat-commande.component';
import {Decimal4Pipe} from './commons/pipes/Decimal4Pipe';
import {Decimal1Pipe} from './commons/pipes/Decimal1Pipe';
import {CommandeTotauxComponent} from './commandes/commande-totaux/commande-totaux.component';
import {Decimal2Pipe} from './commons/pipes/Decimal2Pipe';
import {CommandeChangementsEtatComponent} from './commandes/commande-changements-etat/commande-changements-etat.component';
import {RouterModule} from '@angular/router';
import {LotMarcheOrionPipe} from './commons/pipes/LotMarcheOrionPipe';
import {LotMarcheOrionTypePipe} from './commons/pipes/LotMarcheOrionTypePipe';
import {ListeArticlesComponent} from './articles/liste-articles/liste-articles.component';
import {FiltreArticlesComponent} from './articles/filtre-articles/filtre-articles.component';
import {DialogAjoutArticleComponent} from './inventaires/dialog-ajout-article/dialog-ajout-article.component';
import {ArticleService} from './services/epona/article.service';
import {HighlightIfNotEmptyDirective} from './commons/directives/highlight-if-not-empty.directive';
import {BooleanComponent} from './commons/outputs/boolean/boolean.component';
import {SelectSousFamilleComponent} from './commons/inputs/select-sous-famille/select-sous-famille.component';
import {AutocompleteArticleComponent} from './commons/inputs/autocomplete-article/autocomplete-article.component';
import {FormDisplayedColumnsComponent} from './commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {SousFamillePipe} from './commons/pipes/SousFamillePipe';
import {CodeArticlePipe} from './commons/pipes/code-article.pipe';
import {DialogModificationMultipleComponent} from './articles/dialog-modification-multiple/dialog-modification-multiple.component';
import {ListePaniersComponent} from './paniers/liste-paniers/liste-paniers.component';
import {FiltrePaniersComponent} from './paniers/filtre-paniers/filtre-paniers.component';
import {PanierComponent} from './paniers/panier/panier.component';
import {PanierEnteteComponent} from './paniers/panier-entete/panier-entete.component';
import {PanierLignesComponent} from './paniers/panier-lignes/panier-lignes.component';
import {OrionService} from './services/epona/orion.service';
import {ReferentielOrionPipe} from './commons/pipes/ReferentielOrionPipe';
import {SelectReferentielOrionComponent} from './commons/inputs/select-referentiel-orion/select-referentiel-orion.component';
import {DialogCommandesGenereesComponent} from './paniers/dialog-commandes-generees/dialog-commandes-generees.component';
import {ListeLieuxComponent} from './lieux/liste-lieux/liste-lieux.component';
import {FiltreLieuxComponent} from './lieux/filtre-lieux/filtre-lieux.component';
import {LieuService} from './services/epona/lieu.service';
import {DialogAjoutModificationLieuComponent} from './lieux/dialog-ajout-modification-lieu/dialog-ajout-modification-lieu.component';
import {EngagementJuridiqueComponent} from './engagements-juridiques/engagement-juridique/engagement-juridique.component';
import {
  EngagementJuridiqueEnteteComponent
} from './engagements-juridiques/engagement-juridique-entete/engagement-juridique-entete.component';
import {
  EngagementJuridiqueLignesComponent
} from './engagements-juridiques/engagement-juridique-lignes/engagement-juridique-lignes.component';
import {DialogEngagementJuridiqueComponent} from './commandes/dialog-engagement-juridique/dialog-engagement-juridique.component';
import {DialogErrorsComponent} from './commons/dialog-errors/dialog-errors.component';
import {NumberComparisonComponent} from './commons/inputs/number-comparison/number-comparison.component';
import {SelectMarcheComponent} from './commons/inputs/select-marche/select-marche.component';
import {SelectLieuComponent} from './commons/inputs/select-lieu/select-lieu.component';
import {
  DialogLignesCommandeCumulComponent
} from './engagements-juridiques/dialog-lignes-commande-cumul/dialog-lignes-commande-cumul.component';
import {SelectFamilleMarchesComponent} from './commons/inputs/select-famille-marches/select-famille-marches.component';
import {FamilleMarchesPipe} from './commons/pipes/FamilleMarchesPipe';
import {SousGroupePipe} from './commons/pipes/SousGroupePipe';
import {SelectSousGroupeComponent} from './commons/inputs/select-sous-groupe/select-sous-groupe.component';
import {AideComponent} from './commons/outputs/aide/aide.component';
import {DialogServiceFaitComponent} from './entrees-sorties/dialog-service-fait/dialog-service-fait.component';
import {ServiceFaitComponent} from './services-faits/service-fait/service-fait.component';
import {ServiceFaitEnteteComponent} from './services-faits/service-fait-entete/service-fait-entete.component';
import {ServiceFaitLignesComponent} from './services-faits/service-fait-lignes/service-fait-lignes.component';
import {DialogArticleAutreLotComponent} from './commandes/dialog-article-autre-lot/dialog-article-autre-lot.component';
import {EntreeSortieTotauxComponent} from './entrees-sorties/entree-sortie-totaux/entree-sortie-totaux.component';
import {SelectSousGroupeArticlesComponent} from './commons/inputs/select-sous-groupe-articles/select-sous-groupe-articles.component';
import {BnaService} from './services/epona/bna.service';
import {SelectGroupeArticlesComponent} from './commons/inputs/select-groupe-articles/select-groupe-articles.component';
import {DesignationArticlePipe} from './commons/pipes/designation-article.pipe';
import {SelectEtablissementComponent} from './commons/inputs/select-etablissement/select-etablissement.component';
import {ChoixEtablissementComponent} from './connexion/choix-etablissement/choix-etablissement.component';
import {SelectLieuBnsComponent} from './commons/inputs/select-lieu-bns/select-lieu-bns.component';
import {SelectBatimentBnsComponent} from './commons/inputs/select-batiment-bns/select-batiment-bns.component';
import {SelectZoneGeographiqueComponent} from './commons/inputs/select-zone-geographique/select-zone-geographique.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {DialogCommentConfirmComponent} from './commons/dialog-comment-confirm/dialog-comment-confirm.component';
import {DialogGestionLotsComponent} from './entrees-sorties/dialog-gestion-lots/dialog-gestion-lots.component';
import {CommandePipe} from './commons/pipes/commande.pipe';
import {PanierTotauxComponent} from './paniers/panier-totaux/panier-totaux.component';
import {EntreeSortieRechercheLotComponent} from './entrees-sorties/entree-sortie-recherche-lot/entree-sortie-recherche-lot.component';
import {TableauLotsComponent} from './entrees-sorties/tableau-lots/tableau-lots.component';
import {FiltreLotsComponent} from './entrees-sorties/filtre-lots/filtre-lots.component';
import {CanDeactivateComponent} from './intercepteurs/can-deactivate/can-deactivate.component';
import {DatatableLinkCommandesComponent} from './entrees-sorties/datatable-link-commandes/datatable-link-commandes.component';
import {TableauDocumentsComponent} from './documents/tableau-documents/tableau-documents.component';
import {DialogAddDocumentsComponent} from './documents/dialog-add-documents/dialog-add-documents.component';
import {DialogSeeDocumentsComponent} from './documents/dialog-see-documents/dialog-see-documents.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DialogGestionDocumentsComponent} from './documents/dialog-gestion-documents/dialog-gestion-documents.component';
import {ServiceFaitPiecesJointesComponent} from './services-faits/service-fait-pieces-jointes/service-fait-pieces-jointes.component';
import {
  TableauRevisionsTarifairesComponent
} from './revisions-tarifaires/tableau-revisions-tarifaires/tableau-revisions-tarifaires.component';
import {DialogRevisionTarifaireComponent} from './revisions-tarifaires/dialog-revision-tarifaire/dialog-revision-tarifaire.component';
import {CnousTableExporterDirective} from './commons/directives/cnous-table-exporter.directive';
import {TableauFdncComponent} from './fdnc/tableau-fdnc/tableau-fdnc.component';
import {FiltreFdncComponent} from './fdnc/filtre-fdnc/filtre-fdnc.component';
import {RechercheFdncComponent} from './fdnc/recherche-fdnc/recherche-fdnc.component';
import {SelectStatutFdncComponent} from './commons/inputs/select-statut-fdnc/select-statut-fdnc.component';
import {SelectEtapeFdncComponent} from './commons/inputs/select-etape-fdnc/select-etape-fdnc.component';
import {FdncComponent} from './fdnc/fdnc/fdnc.component';
import {FicheFdncComponent} from './fdnc/fiche-fdnc/fiche-fdnc.component';
import {SelectTypeNonConformiteComponent} from './commons/inputs/select-type-non-conformite/select-type-non-conformite.component';
import {
  SelectTraitementNonConformiteComponent
} from './commons/inputs/select-traitement-non-conformite/select-traitement-non-conformite.component';
import {FdncCommandeLivraisonComponent} from './fdnc/fdnc-commande-livraison/fdnc-commande-livraison.component';
import {DialogCreationFdncComponent} from './fdnc/dialog-creation-fdnc/dialog-creation-fdnc.component';
import {TableauEchangesFdncComponent} from './fdnc/tableau-echanges-fdnc/tableau-echanges-fdnc.component';
import {DialogAjoutEchangeComponent} from './fdnc/dialog-ajout-echange/dialog-ajout-echange.component';
import {SelectTempsTraitementFdncComponent} from './commons/inputs/select-temps-traitement-fdnc/select-temps-traitement-fdnc.component';
import {SelectContexteFdncComponent} from './commons/inputs/select-contexte-fdnc/select-contexte-fdnc.component';
import {OutputTextComponent} from './commons/outputs/output-text/output-text.component';
import {TableauCommandeLivraisonComponent} from './fdnc/tableau-commande-livraison/tableau-commande-livraison.component';
import {RouterService} from './services/router.service';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {BordereauLivraisonPipe} from './commons/pipes/bordereau-livraison.pipe';
import {
  EngagementJuridiqueSuivisEnvoisComponent
} from './engagements-juridiques/engagement-juridique-suivis-envois/engagement-juridique-suivis-envois.component';
import {getFrPaginatorIntl} from "./commons/paginatorFr/fr-paginator-intl";
import {EtapeServiceFaitPipe} from "./commons/pipes/etape-service-fait.pipe";

registerLocaleData(localeFr, 'fr');


export const MY_MOMENT_FORMATS = {
  parse: {
    dateInput : 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({ declarations: [
        AppComponent,
        TopbarComponent,
        AccueilComponent,
        EppnFormComponent,
        VentesTempsReelComponent,
        HttploaderComponent,
        MycurrencyPipe,
        ListeInventairesComponent,
        LieuFilterComponent,
        InventairesFilterComponent,
        InventaireEnteteComponent,
        DialogConfirmComponent,
        DateTimeFormatPipe,
        InventaireLignesComponent,
        DateFormatPipe,
        LieuxArticlesComponent,
        AjoutArticleModalComponent,
        DialogAjoutDlcComponent,
        ListeCommandesComponent,
        FiltreCommandesComponent,
        CommandeEnteteComponent,
        CommandeLignesComponent,
        InventaireComponent,
        SelectTypeMouvementComponent,
        CommandeComponent,
        EntreeSortieComponent,
        FiltreEntreesSortiesComponent,
        ListeEntreesSortiesComponent,
        EntreeSortieEnteteComponent,
        EntreeSortieLignesComponent,
        DialogAjoutLigneComponent,
        TruncatePipe,
        TableauBordComponent,
        CardCommandesComponent,
        CardBordereauxLivraisonsComponent,
        CardEntreesSortiesValideeComponent,
        CardStockAlertComponent,
        CardInventairesComponent,
        CardSortiesNonValideeComponent,
        DialogAjoutArticleComponent,
        AutocompleteArticleVemComponent,
        DialogAjoutArticlesCommandeComponent,
        TimeFormatPipe,
        LinkResourceComponent,
        MetaDataComponent,
        DialogCommentaireCommandeComponent,
        DialogCommentaireInventaireComponent,
        DialogHistoriqueCorrectionsComponent,
        SelectTypeDetailEcartComponent,
        SignedNumberPipe,
        Decimal1Pipe,
        Decimal2Pipe,
        Decimal4Pipe,
        LotMarcheOrionPipe,
        LotMarcheOrionTypePipe,
        SousFamillePipe,
        FamilleMarchesPipe,
        SousGroupePipe,
        ReferentielOrionPipe,
        ListeStocksComponent,
        FiltreStocksComponent,
        SelectDepartementComponent,
        SelectFamilleComponent,
        DialogHistoriqueMouvementsStocksComponent,
        DialogDetailDlcComponent,
        DialogConfirmValidationComponent,
        DatatableLinkBlComponent,
        DialogConsultationBlComponent,
        SelectBooleanComponent,
        SelectFournisseurComponent,
        ListeFournisseursComponent,
        FournisseursFilterComponent,
        FournisseurParametrageGlobalComponent,
        FournisseurParametrageLocalComponent,
        FournisseurComponent,
        DialogParametrageLieuFournisseurComponent,
        ListeLotMarcheComponent,
        SelectSousLotZgComponent,
        AutocompleteArticleMarcheComponent,
        DialogModificationCourrielCommandeComponent,
        SelectEtatCommandeComponent,
        CommandeTotauxComponent,
        CommandeChangementsEtatComponent,
        ListeArticlesComponent,
        FiltreArticlesComponent,
        HighlightIfNotEmptyDirective,
        BooleanComponent,
        SelectSousFamilleComponent,
        AutocompleteArticleComponent,
        FormDisplayedColumnsComponent,
        CodeArticlePipe,
        DesignationArticlePipe,
        DialogModificationMultipleComponent,
        ListePaniersComponent,
        FiltrePaniersComponent,
        PanierComponent,
        PanierEnteteComponent,
        PanierLignesComponent,
        SelectReferentielOrionComponent,
        DialogCommandesGenereesComponent,
        ListeLieuxComponent,
        FiltreLieuxComponent,
        DialogAjoutModificationLieuComponent,
        EngagementJuridiqueComponent,
        EngagementJuridiqueEnteteComponent,
        EngagementJuridiqueLignesComponent,
        EngagementJuridiqueSuivisEnvoisComponent,
        DialogEngagementJuridiqueComponent,
        DialogErrorsComponent,
        NumberComparisonComponent,
        SelectMarcheComponent,
        SelectLieuComponent,
        DialogLignesCommandeCumulComponent,
        SelectFamilleMarchesComponent,
        SelectSousGroupeComponent,
        AideComponent,
        DialogServiceFaitComponent,
        ServiceFaitComponent,
        ServiceFaitEnteteComponent,
        ServiceFaitLignesComponent,
        DialogArticleAutreLotComponent,
        EntreeSortieTotauxComponent,
        SelectSousGroupeArticlesComponent,
        SelectGroupeArticlesComponent,
        DesignationArticlePipe,
        SelectEtablissementComponent,
        ChoixEtablissementComponent,
        SelectLieuBnsComponent,
        SelectBatimentBnsComponent,
        SelectZoneGeographiqueComponent,
        DialogCommentConfirmComponent,
        DialogGestionLotsComponent,
        CommandePipe,
        PanierTotauxComponent,
        EntreeSortieRechercheLotComponent,
        TableauLotsComponent,
        FiltreLotsComponent,
        CanDeactivateComponent,
        DatatableLinkCommandesComponent,
        TableauDocumentsComponent,
        DialogAddDocumentsComponent,
        DialogSeeDocumentsComponent,
        DialogGestionDocumentsComponent,
        ServiceFaitPiecesJointesComponent,
        TableauRevisionsTarifairesComponent,
        DialogRevisionTarifaireComponent,
        CnousTableExporterDirective,
        TableauFdncComponent,
        FiltreFdncComponent,
        RechercheFdncComponent,
        SelectStatutFdncComponent,
        SelectEtapeFdncComponent,
        FdncComponent,
        FicheFdncComponent,
        SelectTypeNonConformiteComponent,
        SelectTraitementNonConformiteComponent,
        FdncCommandeLivraisonComponent,
        DialogCreationFdncComponent,
        TableauEchangesFdncComponent,
        DialogAjoutEchangeComponent,
        SelectTempsTraitementFdncComponent,
        SelectContexteFdncComponent,
        OutputTextComponent,
        TableauCommandeLivraisonComponent,
        BordereauLivraisonPipe,
        EtapeServiceFaitPipe
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        DragDropModule,
        FormsModule,
        MatCheckboxModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatNativeDateModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        RouterModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        PdfViewerModule,
        FontAwesomeModule], providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always', appearance: 'outline' } },
        {
            provide: HAMMER_LOADER,
            useValue: () => new Promise(() => { })
        },
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: MAT_DATE_LOCALE, useValue: 'fr' },
        { provide: MAT_DATE_FORMATS, useValue: MY_MOMENT_FORMATS },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl() },
        VemService,
        EponaService,
        InventaireService,
        CommandeService,
        ParametrageLieuArticleService,
        StockService,
        TableauBordService,
        W3Service,
        FournisseurService,
        ArticleService,
        LieuService,
        OrionService,
        BnaService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptorService,
            multi: true
        },
        MessageTool,
        DateFormatPipe,
        DatePipe,
        TruncatePipe,
        TimeFormatPipe,
        SignedNumberPipe,
        Decimal1Pipe,
        Decimal2Pipe,
        Decimal4Pipe,
        LotMarcheOrionPipe,
        LotMarcheOrionTypePipe,
        SousFamillePipe,
        FamilleMarchesPipe,
        SousGroupePipe,
        CodeArticlePipe,
        DesignationArticlePipe,
        ReferentielOrionPipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  // Ne pas supprimer l'injection de RouterService
  constructor(private routerService: RouterService,
              library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
