import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";

@Component({
    selector: 'form-displayed-columns',
    templateUrl: './form-displayed-columns.component.html',
    styleUrls: ['./form-displayed-columns.component.scss'],
    standalone: false
})
export class FormDisplayedColumnsComponent implements OnChanges, AfterViewInit {

  @Input() availableColumns: {[key: string]: TableColumn} = {};

  @Input() displayedColumns: string[] = [];
  @Output() displayedColumnsChange = new EventEmitter<string[]>();

  @Input() storeCode: string|undefined;

  formColumns: FormGroup;
  availableColumnsKeys: string[] = [];

  constructor(private fb: FormBuilder) {
    this.formColumns = fb.group({
      cols: fb.control<string[]>([])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['availableColumns']) {
      if (this.availableColumns) {
        this.availableColumnsKeys = Object.keys(this.availableColumns);
      } else {
        this.availableColumnsKeys = [];
      }
    }

    if (changes['displayedColumns']) {
      this.formColumns.get('cols')!.setValue(this.displayedColumns);
    }
  }

  ngAfterViewInit(): void {
    this.formColumns.get('cols')!.valueChanges.subscribe(value => {
      if (value !== this.displayedColumns) {
        this.displayedColumnsChange.emit(value);
      }
    });
  }

  // Sauvegarde des colonnes sélectionnées à chaque changement, si storeCode est renseigné
  storeChange(change: MatSelectChange) {
    if (change.value.length > 0 && this.storeCode) {
      localStorage.setItem(this.storeCode, change.value.toString());
    }
  }
}

/**
 * Propriétés d'une colonne de tableau
 */
export class TableColumn {
  /**
   * Libellé de la colonne (entête)
   */
  label!: string;

  /**
   * Libellé long (indiqué en info-bulle)
   * @default null
   */
  tooltip!: string;

  /**
   * Indique si la colonne est affichée par défaut (sinon seulement proposée à l'affichage)
   * @default true
   */
  default: boolean | undefined = true;

  /**
   * Indique si la colonne est masquable
   * @default false
   */
  hiddable: boolean | undefined = false;

  /**
   * Indique si la colonne est exportable
   * @default true
   */
  export: boolean | undefined = true;

  exportFormat: 'integer' | 'decimal' | 'date' | 'datetime' | 'string' = 'string';

  constructor(col: TableColumnProperties) {
    this.label    = col.label;
    this.tooltip  = col.tooltip  === undefined ? ''   : col.tooltip;
    this.default  = col.default  === undefined ? true : col.default;
    this.hiddable = col.hiddable === undefined ? true : col.hiddable;
    this.export   = col.export   === undefined ? true : col.export;
    this.exportFormat = col.exportFormat === undefined ? 'string' : col.exportFormat;
  }
}

class TableColumnProperties {
  label!: string;
  tooltip?: string;
  default?: boolean;
  hiddable?: boolean;
  export?: boolean;
  exportFormat?: 'integer' | 'decimal' | 'date' | 'datetime' | 'string';
}

export class DisplayedColumnsTools {
  /**
   * Retourne les colonnes affichées initialement
   *  soit à partir de la sauvegarde
   *  soit à partir de la définition des colonnes
   *  soit à partir des colonnes par défaut générées par une méthode
   * @param storeCode Code de l'élement dans le localStorage
   * @param defaultDisplayedColumns Tableau de définition des colonnes disponibles ou Méthode à appeler pour générer les colonnes par défaut
   */
  static initDisplayedColumns(storeCode: string, defaultDisplayedColumns: {[key: string]: TableColumn} | (() => string[])): string[] {
    const code = localStorage.getItem(storeCode);
    if (code !== null) {
      return code.split(',').filter(columnCode => {
        if (typeof defaultDisplayedColumns === 'object') {
          return !!defaultDisplayedColumns[columnCode];
        } else {
          return true;
        }
      });

    } else if (typeof defaultDisplayedColumns === 'object') {
      const displayedColumns: string[] = [];
      for (let [key, value] of Object.entries(defaultDisplayedColumns)) {
        if (value.default) {
          displayedColumns.push(key);
        }
      }
      return displayedColumns;

    } else {
      return defaultDisplayedColumns();
    }
  }
}
