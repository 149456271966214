import {DTOWithLieu} from "../epona-ui/DTOWithLieu";

export class PanierSearch extends DTOWithLieu {
  numeroPanier: string;
  utilisateur:string;
  idFournisseur: number;

  pageNumber: number | null = null;
  pageSize: number | null = null;
  orderBy: string | null = null;
  orderDir: string | null = null;

  fields: string = null;
}
