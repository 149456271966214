import {Component, OnInit, ViewChild} from '@angular/core';
import {EntreeSortieLigneLot} from "../../model/epona-api/entree-sortie-ligne-lot";
import {
  DisplayedColumnsTools,
  TableColumn
} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {TableauLotsComponent} from "../tableau-lots/tableau-lots.component";
import {EntreeSortieService} from "../../services/epona/entree-sortie.service";
import {EntreeSortieLigneLotSearch} from "../../model/epona-api/entree-sortie-ligne-lot-search";
import {MessageTool} from "../../commons/MessageTool";
import {Lieu} from "../../model/epona-api/Lieu";
import {CodeStockageColonnes} from "../../commons/constants/CodeStockageColonnes";
import {FiltreLotsComponent} from "../filtre-lots/filtre-lots.component";
import {LotFilter} from "../../model/epona-ui/lot-filter";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {LotQueryParams} from "../../model/epona-ui/lot-query-params";
import {Tools} from "../../commons/Tools";
import {FilterTools} from "../../commons/FilterTools";

@Component({
    selector: 'app-entree-sortie-recherche-lot',
    templateUrl: './entree-sortie-recherche-lot.component.html',
    styleUrls: ['./entree-sortie-recherche-lot.component.css'],
    standalone: false
})
export class EntreeSortieRechercheLotComponent implements OnInit {
  lieuCourant: Lieu;
  afficherAvertissement: boolean = true;
  listeLots: EntreeSortieLigneLot[] = [];
  COLUMNS_STORE_CODE = CodeStockageColonnes.LOT_MARCHES;
  COLUMNS: {[key: string]: TableColumn};
  colonneAffichees: string[] = [];

  @ViewChild(FiltreLotsComponent)
  filterComponent: FiltreLotsComponent;

  filter: LotFilter;

  constructor(private entreSortieService: EntreeSortieService,
              private messageService: MessageTool,
              private route: ActivatedRoute,
              private router: Router) {
    this.COLUMNS = {...TableauLotsComponent.COLUMNS};
  }

  ngOnInit(): void {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
        this.rechercheLots();

      } else {
        this.initDefaultFilters();
      }
    });

    // this.loadData();
  }

  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de commandes
    this.filter = new LotFilter();

    this.filter.article     = FilterTools.buildArticleFromURL(params);
    if (this.filter.article != null) {
      this.filter.article.articleAchat = true;
      this.filter.article.designationAchat = '' + this.filter.article.idArticle;
    }
    this.filter.numeroLot   = params.get('numeroLot') ? params.get('numeroLot').split(';').join('\n') : null;
    this.filter.marche  =  FilterTools.buildMarcheFromURL(params);
    this.filter.fournisseur = FilterTools.buildFournisseurFromURL(params);
  }

  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de commandes
    this.filter = new LotFilter();
  }

  rechercheLots() {
    this.listeLots = [];

    const search = this.buildSearchFromFilter();
    search.fields = 'idEntreeSortieLigneLot,' +
      'numeroLot,' +
      'quantite,' +
      'entreeSortieLigne.idEntreSortieLigne,' +
      'entreeSortieLigne.dlc,' +
      'entreeSortieLigne.referenceFournisseur,' +
      'entreeSortieLigne.entete.idEntreeSortieEntete,' +
      'entreeSortieLigne.entete.lieu.nomRu,' +
      'entreeSortieLigne.entete.lieu.nomUd,' +
      'entreeSortieLigne.entete.commande.fournisseur.nom,' +
      'entreeSortieLigne.entete.codeBordereauLivraison,' +
      'entreeSortieLigne.entete.dateBordereauLivraison,' +
      'entreeSortieLigne.entete.commande.sousLotZ.codeMarcheOrion,' +
      'entreeSortieLigne.article.designationAchat,' +
      'entreeSortieLigne.article.designationVente,' +
      'entreeSortieLigne.article.articleAchat,' +
      'entreeSortieLigne.article.articleVente,' +
      'entreeSortieLigne.article.codeArticleAchat,' +
      'entreeSortieLigne.article.codeArticleVente';

    this.entreSortieService.getListeLots(search).subscribe({
        next: data => {
          this.listeLots = data;
          this.setColonnesAffichees();
          this.afficherAvertissement = false;
        },
        error: (err) => {
          this.messageService.sendError(err);
        }
      }
    );
  }

  private buildSearchFromFilter(): EntreeSortieLigneLotSearch {
    const search = new EntreeSortieLigneLotSearch();

    search.setIdsLieu(this.lieuCourant);

    search.idArticle     = this.filter.article?.idArticle;
    search.numeroLot     = this.filter.numeroLot?.split('\n');
    search.codeMarche    = this.filter.marche ? this.filter.marche.codeMarcheOrion : null;
    search.idFournisseur = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur : null;

    return search;
  }

  setColonnesAffichees() {
    this.colonneAffichees = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: LotFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['recherche-lots'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): LotQueryParams {
    const params = new LotQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.idArticle         = this.filter.article ? this.filter.article.idArticle : null;
    params.codeMarcheOrion   = this.filter.marche ? this.filter.marche.codeMarcheOrion : null;
    params.numeroLot         = this.filter.numeroLot;
    params.idFournisseur     = this.filter.fournisseur ? this.filter.fournisseur.idFournisseur : null;

    return params;
  }
}
