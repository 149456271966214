import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TdbNbArticlesAlerteParLieu} from '../../model/epona-api/TdbNbArticlesAlerteParLieu';
import {TableauBordService} from '../../services/epona/tableau-bord.service';
import {AlerteArticleSearch} from "../../model/epona-api/AlerteArticleSearch";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {SortHelper} from "../../helpers/sort-helper";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'epona-card-stock-alert',
    templateUrl: './card-stock-alert.component.html',
    styleUrls: ['./card-stock-alert.component.css'],
    standalone: false
})
export class CardStockAlertComponent implements OnInit, AfterViewInit, OnChanges {
  displayedColumns: string[] = ['batiment', 'lieu', 'nbArticlesStockFaible', 'nbArticlesDlcProche'];
  listeStockAlerte: TdbNbArticlesAlerteParLieu[] = [];
  dataSource = new MatTableDataSource<TdbNbArticlesAlerteParLieu>(this.listeStockAlerte);

  @Input() lieuCourant: Lieu;

  nbJoursDlcExpiree: UntypedFormControl

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  constructor(private fb: UntypedFormBuilder,
              private tdbService: TableauBordService) {
    this.nbJoursDlcExpiree = fb.control(4);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['lieuCourant']){
      this.rempliListeStocksAlertes();
    }
  }

  ngOnInit(): void {
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingdataAccessor;
  }

  rempliListeStocksAlertes() {
    const alerteArticleSearch = new AlerteArticleSearch();
    alerteArticleSearch.idCrous       = this.lieuCourant.idCrous;
    alerteArticleSearch.idBatimentBns = this.lieuCourant.idBatimentBns;
    alerteArticleSearch.idLieuBns     = this.lieuCourant.idLieuBns;
    alerteArticleSearch.idRu          = this.lieuCourant.idRu;
    alerteArticleSearch.idUd          = this.lieuCourant.idUd;
    alerteArticleSearch.nombreJours = this.nbJoursDlcExpiree.value;
    this.tdbService.getStockAlerte(alerteArticleSearch).subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  sortingdataAccessor(data: TdbNbArticlesAlerteParLieu, sortHeaderId: string): number|string {
    switch (sortHeaderId) {
      case 'batiment'              : return SortHelper.stringToString(data.lieu?.nomRu)
      case 'lieu'                  : return SortHelper.stringToString(data.lieu?.nomUd);
      case 'nbArticlesStockFaible' : return data.nbArticlesStockFaible !== 0 ? data.nbArticlesStockFaible : '-';
      case 'nbArticlesDlcProche'   : return data.nbArticlesDlcProche !== 0 ? data.nbArticlesDlcProche : '-';
      default                      : return data[sortHeaderId];
    }
  }
}
