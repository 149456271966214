import {Lieu} from './Lieu';
import {Extra} from './Extra';
import {CommandeEntete} from "./CommandeEntete";
import {TypeMouvement} from "../TypeMouvement";
import {Tva} from "./Tva";

export class EntreeSortieEntete {
  idEntreeSortieEntete: number;
  validee: boolean;
  lieu: Lieu;
  lieuStockageApresValidation: Lieu;
  typeMouvement: TypeMouvement;
  lieuDestination: Lieu;
  lieuEvenement: string;
  dateEvenement: Date;
  referenceEvenement: string;
  commande: CommandeEntete;
  entreeSortieEnteteOrigine: EntreeSortieEntete;
  codeBordereauLivraison: string;
  dateBordereauLivraison: Date;
  fraisPort: number;
  tvaFraisPort: Tva;
  temperatureConforme: boolean;
  idLogiciel: number;
  commentaire: string;

  dateCreation: Date;
  utilisateurCreation: string;
  dateModification: Date;
  utilisateurModification: string;
  dateValidation: Date;
  utilisateurValidation: string;
  dateMiseAJourEj: Date;
  utilisateurMiseAJourEj: string;
  dateEnvoiSf: Date;
  utilisateurEnvoiSf: string;

  serviceFaitExiste: boolean;
  codeEtapeOrion: 'MAJ_EJ'|'ENV_SF'|'SF_OK'|'SF_SUPP'|'NO_SF';
  numeroSf: number;
  nbDocuments: number;

  extra: Extra;
}
