import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {StockCompact} from '../../model/epona-api/StockCompact';
import {MatSort, Sort} from '@angular/material/sort';
import {Lieu} from '../../model/epona-api/Lieu';
import {FiltreStocksComponent} from '../filtre-stocks/filtre-stocks.component';
import {StockCompactFilter} from '../../model/epona-ui/StockCompactFilter';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Tools} from '../../commons/Tools';
import {StockCompactSearch} from '../../model/epona-api/StockCompactSearch';
import {StockCompactQueryParams} from '../../model/epona-ui/StockCompactQueryParams';
import {StockService} from '../../services/epona/stock.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogDataStocks} from '../../model/epona-ui/DialogDataStocks';
import {
  DialogHistoriqueMouvementsStocksComponent
} from '../dialog-historique-mouvements-stocks/dialog-historique-mouvements-stocks.component';
import {DialogDetailDlcComponent} from '../dialog-detail-dlc/dialog-detail-dlc.component';
import {FilterTools} from '../../commons/FilterTools';
import {DisplayedColumnsTools, TableColumn,} from '../../commons/inputs/form-displayed-columns/form-displayed-columns.component';
import {CodeStockageColonnes} from '../../commons/constants/CodeStockageColonnes';
import {UtilisationArticle} from '../../commons/constants/UtilisationArticle';
import {StockExportService} from '../../services/epona/stock-export.service';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {getFrPaginatorIntl} from '../../commons/paginatorFr/fr-paginator-intl';
import {IPaginatedComponent} from "../../commons/interfaces/ipaginated-component";
import {HttpService} from "../../services/http.service";

@Component({
    selector: 'app-liste-stocks',
    templateUrl: './liste-stocks.component.html',
    styleUrls: ['./liste-stocks.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: getFrPaginatorIntl('Stocks') }
    ],
    standalone: false
})
export class ListeStocksComponent implements OnInit, IPaginatedComponent<StockCompact> {

  private readonly DEFAULT_PAGE_SIZE = 10;
  private readonly DEFAULT_ORDER_BY = 'batiment';
  private readonly DEFAULT_ORDER_DIR = 'asc';

  pageIndex: number = 0;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  totalRecords: number = 0;
  orderBy: string = this.DEFAULT_ORDER_BY;
  orderDir: 'asc'|'desc'|'' = this.DEFAULT_ORDER_DIR;

  dataSource = new MatTableDataSource<StockCompact>([]);

  readonly COLUMNS: {[key: string]: TableColumn} = {
    batiment:              new TableColumn({label: 'Bâtiment'}),
    lieu:                  new TableColumn({label: 'Lieu stockage'}),
    groupe:                new TableColumn({label: 'Groupe',             default: false}),
    sousGroupe:            new TableColumn({label: 'Sous-groupe',        default: false}),
    codeArticleAchat:      new TableColumn({label: 'Code achat'}),
    codeArticleVente:      new TableColumn({label: 'Code vente',         default: false,  exportFormat: 'integer'}),
    designation:           new TableColumn({label: 'Désignation',        hiddable: false}),
    quantite:              new TableColumn({label: 'Quantité',           hiddable: false, exportFormat: 'decimal'}),
    uniteExploitation:     new TableColumn({label: 'Unité',              default: false}),
    pmpHt:                 new TableColumn({label: 'PMP HT',             default: false,  exportFormat: 'decimal'}),
    pmpTtc:                new TableColumn({label: 'PMP TTC',            default: false,  exportFormat: 'decimal'}),
    valeurHt:              new TableColumn({label: 'Valeur HT',          default: false,  exportFormat: 'decimal'}),
    valeurTtc:             new TableColumn({label: 'Valeur TTC',         default: false,  exportFormat: 'decimal'}),
    stockMini:             new TableColumn({label: 'Stock mini.',        default: false,  exportFormat: 'decimal'}),
    dateDernierInventaire: new TableColumn({label: 'Dernier inventaire', default: false,  exportFormat: 'datetime'}),
    actions:               new TableColumn({label: 'Actions',            hiddable: false, export: false})
  };
  readonly COLUMNS_STORE_CODE = CodeStockageColonnes.STOCKS;

  displayedColumns: string[] = [];

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild(FiltreStocksComponent)
  filterComponent: FiltreStocksComponent;

  lieuCourant: Lieu;
  filter: StockCompactFilter;

  UA = UtilisationArticle;

  constructor(private stockService: StockService,
              private exportService: StockExportService,
              private httpService: HttpService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
    // // override the route reuse strategy
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // };
  }

  ngOnInit(): void {
    // Récupération des paramètres de requête de l'url pour la recherche
    // Utilisation de l'observable queryParamMAp pour que le ngOnInit soit rappelé si une recherche est lancé depuis l'état des stocks
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Initialisation des filtres
      if (!Tools.isEmpty(params['params'])) {
        this.initFiltersFromURL(params);
      } else {
        this.initDefaultFilters();
      }

      // Nouvelle recherche => retour à la première page et à l'ordonnancement par défaut
      this.pageIndex = 0;
      this.orderBy  = this.DEFAULT_ORDER_BY;
      this.orderDir = this.DEFAULT_ORDER_DIR;

      // Lancement de la recherche
      this.rechercheStocksCompacts();
    });

  }

  private initFiltersFromURL(params: ParamMap) {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithURL(params);

    // Filtre de stock compact
    this.filter = new StockCompactFilter();
    this.filter.groupe                 = FilterTools.buildGroupeArticlesFromURL(params);
    this.filter.sousGroupe             = FilterTools.buildSousGroupeArticlesFromURL(params);
    this.filter.article                = FilterTools.buildArticleFromURL(params);
    this.filter.masquerQuantitesNulles = params.get('masquerQuantitesNulles') ? Tools.stringToBoolean(params.get('masquerQuantitesNulles')) : true;
    this.filter.stockFaible            = params.get('stockFaible') ? Tools.stringToBoolean(params.get('stockFaible')) : null;
    this.filter.nombreJoursAvantDlc    = params.get('nombreJoursAvantDlc') ? +params.get('nombreJoursAvantDlc') : null;
  }

  // Initialisation des filtres à partir des valeurs par défaut
  private initDefaultFilters() {
    // Filtre de lieu
    this.lieuCourant = new Lieu();
    this.lieuCourant.initWithLocalStorage();

    // Filtre de stock compact
    this.filter = new StockCompactFilter();
    this.filter.masquerQuantitesNulles = true;
  }

  private rechercheStocksCompacts() {
    const search = this.buildSearchFromFilters();
    search.fields = 'lieu,' +
      'article.idArticle,' +
      'article.articleAchat,' +
      'article.codeArticleAchat,' +
      'article.designationAchat,' +
      'article.sousGroupe.libelle,' +
      'article.sousGroupe.groupe.libelle,' +
      'article.uniteExploitation.abreviation,' +
      'article.articleVente,' +
      'article.codeArticleVente,' +
      'article.designationVente,' +
      'article.articleDlc,' +
      'quantite,' +
      'pmpHt,' +
      'pmpTtc,' +
      'stockMini,' +
      'dlcMin,' +
      'dateDernierInventaire';
    search.pageNumber = this.pageIndex + 1;
    search.orderDir = this.orderDir;
    search.orderBy = this.orderBy;
    search.pageSize = this.pageSize;

    this.stockService.getListeStocksCompactWithPagination(search).subscribe(this.httpService.handlePaginatedSubscribe(this));
  }

  private buildSearchFromFilters(): StockCompactSearch {
    const search = new StockCompactSearch();

    search.setIdsLieu(this.lieuCourant);

    search.codeGroupe             = this.filter.groupe      ? this.filter.groupe.code                     : null;
    search.idSousGroupe           = this.filter.sousGroupe  ? this.filter.sousGroupe.idSousGroupeArticles : null;
    search.idArticle              = this.filter.article     ? this.filter.article.idArticle               : null;
    search.masquerQuantitesNulles = this.filter.masquerQuantitesNulles;
    search.stockFaible            = this.filter.stockFaible;
    search.nombreJoursAvantDlc    = this.filter.nombreJoursAvantDlc;

    return search;
  }

  setDisplayedColumns() {
    // Si les colonnes affichées n'ont pas encore été définies alors elles sont initialisées
    //  soit à partir de la sauvegarde soit à partir des colonnes par défaut
    if (this.displayedColumns.length === 0) {
      this.displayedColumns = DisplayedColumnsTools.initDisplayedColumns(this.COLUMNS_STORE_CODE, this.COLUMNS);
    }
  }

  onLieuSubmitted(lieu: Lieu) {
    this.lieuCourant = lieu;

    // Soumission du formulaire de recherche de commande pour récupérer les valeurs saisies et lancer la recherche
    this.filterComponent.onSubmit();
  }

  onLieuCorrected(lieu: Lieu) {
    this.lieuCourant = lieu;
  }

  onFilterSubmitted(filter: StockCompactFilter) {
    this.filter = filter;
    this.redirect();
  }

  private redirect() {
    this.router.navigate(['etat-stocks'], {queryParams: this.getQueryParams()});
  }

  private getQueryParams(): StockCompactQueryParams {
    const params = new StockCompactQueryParams();

    params.setIdsLieu(this.lieuCourant);

    params.codeGroupe             = this.filter.groupe      ? this.filter.groupe.code                     : null;
    params.idSousGroupe           = this.filter.sousGroupe  ? this.filter.sousGroupe.idSousGroupeArticles : null;
    params.idArticle              = this.filter.article     ? this.filter.article.idArticle               : null;
    params.masquerQuantitesNulles = this.filter.masquerQuantitesNulles;
    params.stockFaible            = this.filter.stockFaible;
    params.nombreJoursAvantDlc    = this.filter.nombreJoursAvantDlc;

    return params;
  }

  /* ************************* */
  /* Historique des mouvements */
  /* ************************* */

  openDialogHistoriqueMouvements(stockCompact: StockCompact) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = new DialogDataStocks();
    dialogConfig.data.stockCompact = stockCompact;
    dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'onglet

    this.dialog.open(DialogHistoriqueMouvementsStocksComponent, dialogConfig);
  }

  /* ************** */
  /* Détail par DLC */
  /* ************** */

  openDialogDetailDlc(stockCompact: StockCompact) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = new DialogDataStocks();
    dialogConfig.data.stockCompact = stockCompact;

    this.dialog.open(DialogDetailDlcComponent, dialogConfig);
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  onSortChanged(sort: Sort) {
    this.orderBy = sort.active;
    this.orderDir = sort.direction;
    this.pageIndex = 0;

    this.rechercheStocksCompacts();
  }

  onPageChange(page: PageEvent) {
    this.pageIndex = page.pageIndex;
    this.pageSize = page.pageSize;
    this.rechercheStocksCompacts();
  }

  /* ************************* */
  /*    Export des lignes      */
  /* ************************* */

  exportListeStocks() {
    const search = this.buildSearchFromFilters();
    search.pageNumber = this.pageIndex + 1;
    search.pageSize = this.pageSize;
    search.orderBy = this.orderBy;
    search.orderDir = this.orderDir;

    const colonnesExport = this.displayedColumns.filter(colonne => this.COLUMNS[colonne].export);
    search.fields = colonnesExport.join(',');

    this.exportService.getExportStocksCompacts(search);
  }
}
