import {Component, OnInit, ViewChild} from '@angular/core';
import {ProchaineRevisionTarifaire} from "../../model/epona-api/prochaine-revision-tarifaire";
import {MatTableDataSource} from "@angular/material/table";
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {SortHelper} from "../../helpers/sort-helper";
import {MatSort} from "@angular/material/sort";
import {ProchaineRevisionTarifaireSearch} from "../../model/epona-api/prochaine-revision-tarifaire-search";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";
import {RevisionTarifaireService} from "../../services/epona/revision-tarifaire.service";
import {MessageTool} from "../../commons/MessageTool";

@Component({
    selector: 'epona-tableau-revisions-tarifaires',
    templateUrl: './tableau-revisions-tarifaires.component.html',
    styleUrls: ['./tableau-revisions-tarifaires.component.css'],
    standalone: false
})
export class TableauRevisionsTarifairesComponent implements OnInit {

  static readonly COLUMNS: {[key: string]: TableColumn} = {
    codeMarcheOrion       : new TableColumn({label: 'Marché'}),
    fournisseur           : new TableColumn({label: 'Fournisseur'}),
    dateRevision          : new TableColumn({label: 'Date'})
  };

  colonnesAffichees: string[] = ['codeMarcheOrion','fournisseur','dateRevision'];

  dataSource = new MatTableDataSource<ProchaineRevisionTarifaire>();
  columns = TableauRevisionsTarifairesComponent.COLUMNS;

  @ViewChild(MatSort, {static: true})
  sort!: MatSort;

  constructor(private revisionTarifaireService: RevisionTarifaireService,
              private messageTool: MessageTool) {
  }

  private sortingDataAccessor(data: ProchaineRevisionTarifaire, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'codeMarcheOrion' : return SortHelper.stringToString(data.codeMarcheOrion);
      case 'fournisseur'     : return SortHelper.stringToString(data.fournisseur?.nom);
      case 'dateRevision'    : return SortHelper.dateToNumber(data.dateRevision);
      default: return 0;
    }
  }

  private loadProchainesRevisionsTarifaires(): void {
    let search = new ProchaineRevisionTarifaireSearch();
    search.fields = 'fournisseur.nom,dateRevision,codeMarcheOrion';
    search.idCrous = LocalStorageHelper.getIdEtablissement();
    this.revisionTarifaireService.getProchainesRevisionsTarifiaires(search).subscribe(data => {
      this.dataSource.data = data;
    }, error => {
      this.messageTool.sendError(error);
    })
  }

  ngOnInit(): void {
    this.loadProchainesRevisionsTarifaires();

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }
}
