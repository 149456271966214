import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {CommandeEntete} from '../../model/epona-api/CommandeEntete';
import {Lieu} from '../../model/epona-api/Lieu';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogConfirmComponent} from '../../commons/dialog-confirm/dialog-confirm.component';
import {MessageTool} from '../../commons/MessageTool';
import {CodeEtatCommande} from "../../commons/constants/CodeEtatCommande";
import {TypeBoutonCommande} from "../../commons/enum/TypeBoutonCommande";
import {Bouton} from "../../model/epona-ui/Bouton";
import {EntreeSortieEntete} from "../../model/epona-api/EntreeSortieEntete";
import {CodeTypeMouvement} from "../../commons/constants/CodeTypeMouvement";
import {TypeMouvement} from "../../model/TypeMouvement";
import {DialogDataModificationCourrielCommande} from "../../model/epona-ui/DialogDataModificationCourrielCommande";
import {DialogModificationCourrielCommandeComponent} from "../dialog-modification-courriel-commande/dialog-modification-courriel-commande.component";
import {CommandeService} from "../../services/epona/commande.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {UserService} from "../../services/user.service";
import {ExtraInformation} from "../../model/epona-api/ExtraInformation";
import {CommandeFinalisation} from "../../model/epona-ui/CommandeFinalisation";
import {DialogDataEngagementJuridique} from "../../model/epona-ui/DialogDataEngagementJuridique";
import {DialogEngagementJuridiqueComponent} from "../dialog-engagement-juridique/dialog-engagement-juridique.component";
import {CommandeLigne} from "../../model/epona-api/CommandeLigne";
import {PanierEnteteComponent} from "../../paniers/panier-entete/panier-entete.component";
import {
  DialogCommentConfirmComponent,
  DialogCommentConfirmData, DialogCommentConfirmResult
} from "../../commons/dialog-comment-confirm/dialog-comment-confirm.component";
import {TimeFormatPipe} from "../../commons/pipes/TimeFormatPipe";
import {faFile, faPaperPlane, faSave, faStopCircle, faTimesCircle, faTrash, faTruck, faUndo} from '@fortawesome/free-solid-svg-icons';
import {DateHelper} from "../../helpers/date-helper";
import {InstantErrorStateMatcher} from "../../commons/instant-error-state-matcher";
import {CustomValidators} from "../../commons/CustomValidators";
import {CrousService} from "../../services/epona/crous.service";

@Component({
    selector: 'epona-commande-entete',
    templateUrl: './commande-entete.component.html',
    styleUrls: ['./commande-entete.component.css'],
    standalone: false
})
export class CommandeEnteteComponent implements OnInit, OnChanges {
  @Input() lieuCourant: Lieu;
  @Input() entete: CommandeEntete;
  @Input() lignes: CommandeLigne[];
  @Input() isInvalidFormLignes: boolean;
  @Input() modificationLigneEnCours: boolean;
  @Input() listeBordereauxLivraisonLies: Array<EntreeSortieEntete>;

  @Output() readonly envoiSubmitted = new EventEmitter<boolean>();
  @Output() readonly blSubmitted = new EventEmitter<EntreeSortieEntete>();
  @Output() readonly enteteSubmitted = new EventEmitter<CommandeEntete>();
  @Output() readonly suppressionSubmitted = new EventEmitter<boolean>();
  @Output() readonly refusSubmitted = new EventEmitter<boolean>();
  @Output() readonly courrielChanged = new EventEmitter<void>();
  @Output() readonly finalisationCommandeSubmitted = new EventEmitter<boolean>();
  @Output() readonly annulationFinalisationCommandeSubmitted = new EventEmitter<boolean>();
  @Output() readonly envoiEjSubmitted = new EventEmitter<void>();
  @Output() readonly finalisationPreparationReceptionSubmitted = new EventEmitter<CommandeFinalisation>();
  @Output() readonly duplicationSubmitted = new EventEmitter<boolean>();

  commandeEnteteForm: FormGroup;
  enteteAffichee: boolean = true;
  errorStateMatcher = new InstantErrorStateMatcher();
  dateMin: Date|null = DateHelper.tomorrow();

  // Permet d'accèder au FormGroup du DOM et de récupérer notamment la propriété submitted
  // Utile pour contrôler s'il y a eu des modidications lors d'un changement de page
  @ViewChild(FormGroupDirective)
  form: FormGroupDirective;

  listeBoutons: Array<Bouton> = new Array<Bouton>();
  limiteBoutons: number;

  private readonly BOUTON_ENREGISTRER                   = new Bouton(TypeBoutonCommande.ENREGISTRER,           faSave,         'onSubmit', 'isInvalidForm');
  private readonly BOUTON_ENVOYER_MAGASIN               = new Bouton(TypeBoutonCommande.ENVOYER_MAGASIN,       faPaperPlane,   'openDialogEnvoiAuMagasin', 'isModificationLigneEnCours');
  private readonly BOUTON_SUPPRIMER                     = new Bouton(TypeBoutonCommande.SUPPRIMER,             faTrash,        'openDialogSuppression');
  private readonly BOUTON_FINALISER_PREPARATION         = new Bouton(TypeBoutonCommande.FINALISER_PREPARATION, faStopCircle,   ['openDialogFinalisation', [TypeBoutonCommande.FINALISER_PREPARATION]], 'isModificationLigneEnCours');
  private readonly BOUTON_REFUSER_PAR_MAGASIN           = new Bouton(TypeBoutonCommande.REFUSER_PAR_MAGASIN,   faTimesCircle,  'openDialogRefus');
  private readonly BOUTON_CREER_BL                      = new Bouton(TypeBoutonCommande.CREER_BL,              faTruck,        'openDialogCreationBL');
  private readonly BOUTON_FINALISER_RECEPTION           = new Bouton(TypeBoutonCommande.FINALISER_RECEPTION,   faStopCircle,   ['openDialogFinalisation', [TypeBoutonCommande.FINALISER_RECEPTION]]);
  private readonly BOUTON_FINALISER_COMMANDE            = new Bouton(TypeBoutonCommande.FINALISER_COMMANDE,    faStopCircle,   'openDialogFinalisationCommande', 'isModificationLigneEnCours');
  private readonly BOUTON_ANNULER_FINALISATION_COMMANDE = new Bouton(TypeBoutonCommande.ANNULER_FINALISATION_COMMANDE, faUndo, 'openDialogAnnulationFinalisationCommande', 'isModificationLigneEnCours');
  private readonly BOUTON_DUPLIQUER                     = new Bouton(TypeBoutonCommande.DUPLIQUER,             faFile,         'openDialogDuplication', 'isModificationLigneEnCours');

  constructor(private fb: FormBuilder,
              public dialog: MatDialog,
              private commandeService: CommandeService,
              private crousService: CrousService,
              private messageTool: MessageTool,
              private userService: UserService,
              private timeFormat: TimeFormatPipe) {

    this.commandeEnteteForm =  fb.group({
      idCommandeEntete:           fb.control<number|null>(null),
      numeroEj:                   fb.control<string|null>(null),
      externe:                    fb.control<boolean|null>({value: true, disabled: true}, Validators.required),
      dateLivraisonSouhaitee:     fb.control<Date|null>(null, Validators.required),
      heureLivraisonSouhaitee:    fb.control<string|null>(null, Validators.required),
      codeClientDemandeur:        fb.control<string|null>(null),
      fraisPortPrevisionnels:     fb.control<number|null>(null, [Validators.min(0), CustomValidators.nbMaxDecimals(2)]),
      informationsLivraison:      fb.control<string|null>(null),
      instructionsCommande:       fb.control<string|null>(null),
      lieuStockageApresReception: fb.control<Lieu|null>(null)
    });
  }

  ngOnInit() {
    this.commandeEnteteForm.get('externe').valueChanges.subscribe(() => {
      this.updateDateLivraisonCtrl();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entete']) {
      this.initForm();
    }
  }

  initForm() {
    if (this.entete) {
      // Mise à jour des champs avec les informations récupérées
      this.commandeEnteteForm.get('idCommandeEntete').setValue(this.entete.idCommandeEntete);
      this.commandeEnteteForm.get('numeroEj').setValue(this.entete.numeroEj);
      this.commandeEnteteForm.get('externe').setValue(this.entete.externe);
      this.commandeEnteteForm.get('dateLivraisonSouhaitee').setValue(DateHelper.timestampToJsDate(this.entete.dateLivraisonSouhaitee));
      this.commandeEnteteForm.get('heureLivraisonSouhaitee').setValue(this.entete.dateLivraisonSouhaitee ? this.timeFormat.transform(this.entete.dateLivraisonSouhaitee) : null);
      this.commandeEnteteForm.get('codeClientDemandeur').setValue(this.entete.codeClientDemandeur);
      this.commandeEnteteForm.get('fraisPortPrevisionnels').setValue(this.entete.fraisPortPrevisionnels);
      this.commandeEnteteForm.get('informationsLivraison').setValue(this.entete.informationsLivraison);
      this.commandeEnteteForm.get('instructionsCommande').setValue(this.entete.instructionsCommande);
      this.commandeEnteteForm.get('lieuStockageApresReception').setValue(this.entete.lieuStockageApresReception);

      this.updateControls();
    }

    this.initListeBoutons();
  }

  private updateControls() {
    this.commandeEnteteForm.disable();

    if (this.commandeService.droitSaisie()) {
      if (this.estBrouillon()) {
        this.updateDateLivraisonCtrl();
        if (this.estExterne() && this.entete.sousLotZg) {
          this.crousService.getCurrentCrous('modificationLignesEJAutorisee').subscribe({
            next: data => {
              if (data.modificationLignesEJAutorisee) {
                this.commandeEnteteForm.get('fraisPortPrevisionnels').enable();
              }
            }
          });
        }
      }

      if (this.estBrouillon() || this.estPartiellementModifiable()) {
        if (this.estExterne() && this.entete.sousLotZg) {
          this.commandeEnteteForm.get('codeClientDemandeur').enable();
        }
        this.commandeEnteteForm.get('informationsLivraison').enable();
        this.commandeEnteteForm.get('instructionsCommande').enable();
      }
    }
  }

  private updateDateLivraisonCtrl() {
    const dateLivraisonCtrl  = this.commandeEnteteForm.get('dateLivraisonSouhaitee');
    const heureLivraisonCtrl = this.commandeEnteteForm.get('heureLivraisonSouhaitee');

    if (this.estCreation() || this.estBrouillon()) {
      dateLivraisonCtrl.enable();
      heureLivraisonCtrl.enable();
    } else {
      dateLivraisonCtrl.disable();
      heureLivraisonCtrl.disable();
    }
  }

  estCreation() {
    return this.entete === undefined;
  }

  estBrouillon(): boolean {
    return this.commandeService.estBrouillon(this.entete);
  }

  estPartiellementModifiable(): boolean {
    return this.commandeService.estPartiellementModifiable(this.entete);
  }

  estRefuseeParOrion(): boolean {
    return this.commandeService.estRefuseeParOrion(this.entete);
  }

  isInvalidForm() {
    return this.commandeEnteteForm.invalid || !this.lieuCourant || !this.lieuCourant.idLieu;
  }

  isModificationLigneEnCours() {
    return this.modificationLigneEnCours;
  }

  estExterne(): boolean {
    return this.commandeEnteteForm.get("externe").value === true;
  }
  estInterne(): boolean {
    return this.commandeEnteteForm.get("externe").value === false;
  }

  changerAffichage(): void {
    this.enteteAffichee = !this.enteteAffichee;
  }

  /* ***************************** */
  /* Enregistrement de la commande */
  /* ***************************** */

  onSubmit() {
    if (!this.commandeService.droitSaisie()) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else {
      const commandeEntete = new CommandeEntete();

      commandeEntete.lieuDemandeur = this.lieuCourant;
      if (this.estCreation()) {
        commandeEntete.externe              = this.commandeEnteteForm.get('externe').value;
      }
      commandeEntete.dateLivraisonSouhaitee     = this.commandeEnteteForm.get('dateLivraisonSouhaitee').value;
      commandeEntete.codeClientDemandeur        = this.commandeEnteteForm.get('codeClientDemandeur').value;
      commandeEntete.fraisPortPrevisionnels     = this.commandeEnteteForm.get('fraisPortPrevisionnels').value;
      commandeEntete.informationsLivraison      = this.commandeEnteteForm.get('informationsLivraison').value;
      commandeEntete.instructionsCommande       = this.commandeEnteteForm.get('instructionsCommande').value;
      commandeEntete.lieuStockageApresReception = this.commandeEnteteForm.get('lieuStockageApresReception').value;

      // Ajout de l'heure de livraison à la date de livraison
      if (this.commandeEnteteForm.get("heureLivraisonSouhaitee").value) {
        const hours: number = (this.commandeEnteteForm.get("heureLivraisonSouhaitee").value.split(':'))[0];
        const minutes: number = (this.commandeEnteteForm.get("heureLivraisonSouhaitee").value.split(':'))[1];
        commandeEntete.dateLivraisonSouhaitee.setHours(hours, minutes);
      }

      this.enteteSubmitted.emit(commandeEntete);
    }
  }

  /* ******************************* */
  /* Envoi de la commande au magasin */
  /* ******************************* */

  openDialogEnvoiAuMagasin(): void {
    if (this.entete.extra.envoyableMagasin.status) {
      let message = "<p>Confirmez-vous l'envoi de cette commande au magasin&nbsp;?</p>";
      if (this.isInvalidFormLignes || this.commandeEnteteForm.dirty) {
        message += "<p class='alert alert-warning'>Attention&nbsp;: certaines modifications n'ont pas été enregistrées.</p>";
      }

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Envoi de la commande au magasin",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: message};

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut envoyer la commande
          this.envoiSubmitted.emit(true);
        }
      });

      // Sinon, affichage des erreurs
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.envoyableMagasin.details);
    }
  }

  /* **************** */
  /* Création d'un BL */
  /* **************** */

  openDialogCreationBL(): void {
    // Si la génération d'un BL sur la commande est possible, ouverture de la fenêtre de confirmation
    if (this.entete.extra.receptionnable.status) {
      let message = "<p>Confirmez-vous la création d'un bordereau de livraison pour cette commande&nbsp;?</p>";
      // TODO : commenté temporairement car avertissement toujours affiché car this.isInvalidFormLignes toujours à true
      // if (this.isInvalidFormLignes || this.commandeEnteteForm.dirty) {
      //   message += "<p class='alert alert-warning'>Attention : certaines modifications n'ont pas été enregistrées.</p>";
      // }

      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Création d'un bordereau de livraison",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: message
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut créer un BL
          const entete = new EntreeSortieEntete();

          entete.typeMouvement = new TypeMouvement();
          entete.typeMouvement.codeTypeMouvement = CodeTypeMouvement.APPROVISIONNEMENT;
          entete.commande = this.entete;
          entete.lieu = this.lieuCourant;
          entete.lieuStockageApresValidation = this.entete.lieuStockageApresReception;
          entete.dateBordereauLivraison = new Date();

          this.blSubmitted.emit(entete);
        }
      });

    // Sinon, affichage des erreurs
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.receptionnable.details);
    }
  }

  /* ************************** */
  /* Suppression de la commande */
  /* ************************** */

  openDialogSuppression(): void {
    // Si la commande est supprimable, ouverture de la fenêtre de confirmation
    if (this.entete.extra.deletable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Suppression de la commande",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "Confirmez-vous la suppression de cette commande ?"};

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut supprimer la commande
          this.suppressionSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.deletable.details);
    }
  }

  /* *********************************** */
  /* Refus de la commande par le magasin */
  /* *********************************** */

  openDialogRefus(): void {
    // Si la commande est refusable, ouverture de la fenêtre de confirmation
    if (this.entete.extra.refusableMagasin.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Refus de la commande",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "Confirmez-vous le refus de cette commande ?"};

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut refuser la commande
          this.refusSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.refusableMagasin.details);
    }
  }

  /* **************************** */
  /* Finalisation de la commande  */
  /* **************************** */

  openDialogFinalisationCommande(): void {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.commandeEnteteForm.dirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de cette commande avant de la finaliser';
      this.messageTool.sendWarning(message);

    // Si un la commande peut être finalisée, ouverture de la fenêtre de confirmation
    } else if (this.entete.extra.commandeFinalisable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Finalisation de la commande",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: this.buildBodyConfirmationFinalisationCommande()
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut finaliser la commande
          this.finalisationCommandeSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.commandeFinalisable.details);
    }
  }

  private buildBodyConfirmationFinalisationCommande(): string {
    const nbArticlesNonRecents = this.lignes.filter(ligne => ligne.estPrixRecent === false).length;

    let body: string = '';
    if (nbArticlesNonRecents > 0) {
      body += `<p class="alert alert-warning">${PanierEnteteComponent.buildMessageNonRecents(nbArticlesNonRecents)}</p>`;
    }
    body += '<p class="question">Confirmez-vous la finalisation de cette commande&nbsp;?</p>';
    body += '<p>Une fois finalisée, les informations financières de la commande ne seront plus modifiables et un engagement juridique sera généré dans Épona.</p>';
    body += '<p>Cet engagement juridique devra être envoyé vers Orion par une personne habilitée.</p>';

    return body;
  }

  /* ********************************* */
  /* Annulation finalisation commande  */
  /* ********************************* */

  openDialogAnnulationFinalisationCommande(): void {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.commandeEnteteForm.dirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de cette commande avant d\'annuler sa finalisation';
      this.messageTool.sendWarning(message);

    // Si la finalisation de la commande ne peut être annulée, ouverture de la fenêtre de confirmation
    } else if (this.entete.extra.finalisationCommandeAnnulable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Annulation de la finalisation",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "<p class='question'>Confirmez-vous l'annulation de la finalisation de la commande ?</p>" +
          "<p>L'engagement juridique en cours de préparation dans Épona sera supprimé et " +
          "les informations financières de la commande seront de nouveau modifiables.</p>"
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on demande l'annulation de la finalisation
          this.annulationFinalisationCommandeSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.finalisationCommandeAnnulable.details);
    }
  }

  /* *************************************************** */
  /* Finalisation (préparation/réception) de la commande */
  /* *************************************************** */

  openDialogFinalisation(typeBouton: TypeBoutonCommande) {
    let extra: ExtraInformation;
    let commandeFinalisation: CommandeFinalisation = new CommandeFinalisation();

    if (typeBouton === TypeBoutonCommande.FINALISER_PREPARATION) {
      extra = this.entete.extra.preparationFinalisable;
      commandeFinalisation.typeFinalisation = CommandeFinalisation.TYPE_FINALISATION.PREPARATION;
    } else {
      extra = this.entete.extra.receptionFinalisable;
      commandeFinalisation.typeFinalisation = CommandeFinalisation.TYPE_FINALISATION.RECEPTION;
    }

    // Si la finalisation peut être effectuée, ouverture de la fenêtre de confirmation
    if (extra.status) {
      const receptionSansBL = commandeFinalisation.typeFinalisation === CommandeFinalisation.TYPE_FINALISATION.RECEPTION
                              && (!this.listeBordereauxLivraisonLies || this.listeBordereauxLivraisonLies.length === 0);

      let body = `<p class="question">Confirmez-vous la finalisation de la ${commandeFinalisation.typeFinalisation} de cette commande ?</p>`;
      if (receptionSansBL) {
        body += `<p>Merci d'indiquer la raison pour laquelle la réception est finalisée sans bordereau de livraison.</p>`;
      }

      const dialogConfig = new MatDialogConfig<DialogCommentConfirmData>();
      dialogConfig.data = new DialogCommentConfirmData({
        title: `Finalisation de la ${commandeFinalisation.typeFinalisation}`,
        body: body
      });
      dialogConfig.data.commentRequired = receptionSansBL;
      dialogConfig.data.commentMaxLength = 500;

      const dialogRef = this.dialog.open<DialogCommentConfirmComponent, DialogCommentConfirmData, DialogCommentConfirmResult>(DialogCommentConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result && result.confirm) {
            // Envoi au composant parent que l'on finalise la commande
            commandeFinalisation.commentaire = result.comment;
            this.finalisationPreparationReceptionSubmitted.emit(commandeFinalisation);
          }
        }
      });

    } else {
      this.messageTool.sendErrorMessages(extra.details);
    }
  }

  /* ************************** */
  /* Duplication de la commande */
  /* ************************** */

  openDialogDuplication(): void {
    // Si le formulaire d'entête contient des modifications non enregistrées
    if (this.commandeEnteteForm.dirty) {
      const message = 'Merci d\'enregistrer les modifications effectuées dans l\'entête de cette commande avant de la dupliquer';
      this.messageTool.sendWarning(message);

    // Si la commande est duplicable, ouverture de la fenêtre de confirmation
    } else if (this.entete.extra.duplicable.status) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: "Duplication de la commande",
        yesLabel: "Confirmer",
        noLabel: "Annuler",
        body: "<p>Confirmez-vous la duplication de cette commande&nbsp;?</p><p>Vous serez redirigé automatiquement vers la nouvelle commande.</p>"
      };

      const dialogRef = this.dialog.open(DialogConfirmComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // Envoi au composant parent que l'on veut supprimer la commande
          this.duplicationSubmitted.emit(true);
        }
      });
    } else {
      this.messageTool.sendErrorMessages(this.entete.extra.duplicable.details);
    }
  }

  /* ***************************** */
  /* Gestion dynamique des boutons  */
  /* ***************************** */

  initListeBoutons() {
    this.listeBoutons = [];
    this.limiteBoutons = null;

    if (!this.entete) {
      this.listeBoutons.push(this.BOUTON_ENREGISTRER);

    } else if (this.entete.externe === true) {
      this.initListeBoutonsCommandeExterne();

    } else {
      this.initListeBoutonsCommandeInterne();
    }
  }

  private initListeBoutonsCommandeInterne() {
    switch (this.entete.etatCommande.codeEtatCommande) {
      case CodeEtatCommande.BROUILLON:
        this.listeBoutons.push(this.BOUTON_ENREGISTRER);
        this.listeBoutons.push(this.BOUTON_ENVOYER_MAGASIN);
        this.listeBoutons.push(this.BOUTON_SUPPRIMER);
        this.limiteBoutons = 11; // Longueur du libellé "Enregistrer"
        break;

      case CodeEtatCommande.EN_PREPARATION:
        this.listeBoutons.push(this.BOUTON_FINALISER_PREPARATION);
        this.listeBoutons.push(this.BOUTON_REFUSER_PAR_MAGASIN);
        this.limiteBoutons = 9; // Longueur du libellé "Finaliser"
        break;

      case CodeEtatCommande.REFUSEE_MAGASIN:
        break;

      case CodeEtatCommande.EN_ACHEMINEMENT:
        this.listeBoutons.push(this.BOUTON_CREER_BL);
        break;

      case CodeEtatCommande.EN_RECEPTION:
        this.listeBoutons.push(this.BOUTON_CREER_BL);
        this.listeBoutons.push(this.BOUTON_FINALISER_RECEPTION);
        this.limiteBoutons = 12; // Longueur du libellé "Créer un BL…"
        break;

      case CodeEtatCommande.RECEPTIONNEE:
        break;
    }

    this.listeBoutons.push(this.BOUTON_DUPLIQUER);
  }

  private initListeBoutonsCommandeExterne() {
      switch (this.entete.etatCommande.codeEtatCommande) {
        case CodeEtatCommande.BROUILLON:
          this.listeBoutons.push(this.BOUTON_ENREGISTRER);
          this.listeBoutons.push(this.BOUTON_FINALISER_COMMANDE);
          this.listeBoutons.push(this.BOUTON_SUPPRIMER);
          this.limiteBoutons = 11; // Longueur du libellé "Enregistrer"
          break;

        case CodeEtatCommande.PREPARATION_EJ:
          this.listeBoutons.push(this.BOUTON_ENREGISTRER);
          this.listeBoutons.push(this.BOUTON_ANNULER_FINALISATION_COMMANDE);
          // Le bouton d'envoi de l'EJ est dans la fenêtre de l'EJ
          this.limiteBoutons = 11; // Longueur du libellé "Enregistrer"
          break;

        case CodeEtatCommande.EN_ATTENTE_RETOUR_ORION:
          this.listeBoutons.push(this.BOUTON_ENREGISTRER);
          this.limiteBoutons = 11; // Longueur du libellé "Enregistrer"
          break;

        case CodeEtatCommande.REFUSEE_ORION:
          break;

        case CodeEtatCommande.EN_PREPARATION:
        case CodeEtatCommande.EN_RECEPTION:
          this.listeBoutons.push(this.BOUTON_CREER_BL);
          this.listeBoutons.push(this.BOUTON_FINALISER_RECEPTION);
          this.limiteBoutons = 12; // Longueur du libellé "Créer un BL…"
          break;

        case CodeEtatCommande.RECEPTIONNEE:
          break;
      }

    this.listeBoutons.push(this.BOUTON_DUPLIQUER);
  }

  execBouton(bouton: Bouton) {
    if (this.autorisationUtilisateur(bouton.typeBouton)) {
      // Appel depuis le template, on perd le contexte !
      if (bouton.action) {
        if (bouton.parametresAction) {
          this[bouton.action](bouton.parametresAction.join(','));
        } else {
          this[bouton.action]();
        }
      }
    } else {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);
    }
  }

  isDisabledBouton(bouton: Bouton): boolean {
    // Appel depuis le template, on perd le contexte !
    if (bouton.isDisabled) {
      return this[bouton.isDisabled]();
    } else {
      return false;
    }
  }

  private autorisationUtilisateur(typeBouton: TypeBoutonCommande): boolean {
    switch (typeBouton) {
      case TypeBoutonCommande.ENREGISTRER:
        return this.possedeDroit(CodeDroit.COMMANDES_SAISIE);

      case TypeBoutonCommande.ENVOYER_MAGASIN:
        return this.possedeDroit(CodeDroit.COMMANDES_ENVOI_MAGASIN);

      case TypeBoutonCommande.FINALISER_PREPARATION:
      case TypeBoutonCommande.REFUSER_PAR_MAGASIN:
        return this.possedeDroit(CodeDroit.COMMANDES_PREPARATION);

      case TypeBoutonCommande.CREER_BL:
        return this.possedeDroit(CodeDroit.LIVRAISONS_SAISIE);

      case TypeBoutonCommande.FINALISER_RECEPTION:
        return this.possedeDroit(CodeDroit.LIVRAISONS_VALIDATION);

      case TypeBoutonCommande.FINALISER_COMMANDE:
        return this.possedeDroit(CodeDroit.COMMANDES_FINALISATION_COMMANDE);

      case TypeBoutonCommande.ANNULER_FINALISATION_COMMANDE:
        return this.possedeDroit(CodeDroit.COMMANDES_ENVOI_EJ);

      case TypeBoutonCommande.SUPPRIMER:
        return this.possedeDroit(CodeDroit.COMMANDES_SAISIE);

      case TypeBoutonCommande.DUPLIQUER:
        return this.possedeDroit(CodeDroit.COMMANDES_SAISIE);

      default:
        return false;
    }
  }

  private possedeDroit(codeDroit: string): boolean {
    return this.userService.utilisateurCourant.possedeDroit(codeDroit);
  }

  onLieuLieuStockageApresReceptionSubmitted(lieuStockageApresReception: Lieu) {
    if (lieuStockageApresReception && lieuStockageApresReception.idLieu) {
      this.commandeEnteteForm.get('lieuStockageApresReception').setValue(lieuStockageApresReception);
    } else {
      this.commandeEnteteForm.get('lieuStockageApresReception').setValue(null);
    }
    this.commandeEnteteForm.markAsDirty();
  }

  /* ******************* */
  /* Gestion du courriel */
  /* ******************* */

  openDialogCourriel(): void {
    if (this.commandeEnteteForm.dirty) {
      const message = `Merci d'enregistrer les modifications effectuées dans l'entête de cette commande avant de consulter le courriel`;
      this.messageTool.sendWarning(message);

    } else {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = new DialogDataModificationCourrielCommande();
      dialogConfig.data.entete = this.entete;
      dialogConfig.width = '700px';

      const dialogRef = this.dialog.open(DialogModificationCourrielCommandeComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
        // Envoi au composant parent que le courriel a été modifié
        if (dialogConfig.data.changement) {
          this.courrielChanged.emit();
        }
      });
    }
  }

  /* ********************************* */
  /* Gestion de l'engagement juridique */
  /* ********************************* */

  openDialogEngagementJuridique(): void {
    if (!this.possedeDroit(CodeDroit.COMMANDES_PREPARATION_EJ) && !this.possedeDroit(CodeDroit.COMMANDES_ENVOI_EJ)) {
      this.messageTool.sendErrorMessage(MessageTool.ACCES_REFUSE);

    } else if (!this.estCreation() && this.estExterne()) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.data = new DialogDataEngagementJuridique();
      dialogConfig.data.commande = this.entete;
      dialogConfig.data.lignesCommande = this.lignes;
      dialogConfig.minWidth = '1000px';
      dialogConfig.maxWidth = 'none';
      dialogConfig.position = {top: '20px'}; // Pour éviter que la fenêtre se repositionne verticalement lors du changement d'onglet

      const dialogRef = this.dialog.open(DialogEngagementJuridiqueComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(ejEnvoye => {
        if (ejEnvoye) {
          this.envoiEjSubmitted.emit();
        }
      });
    }
  }
}
