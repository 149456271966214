<form [formGroup]="form" (ngSubmit)="onSubmit()" #ngForm>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Recherche</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Identifiant interne</mat-label>

      <input type="number" matInput formControlName="idInventaire" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Clôturés</mat-label>
      <select-boolean formControlName="cloture"
                            defaultLabel="- Tous -"
                            epoHighlightIfNotEmpty>
      </select-boolean>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Année</mat-label>

      <mat-select formControlName="annee" (selectionChange)="onAnneeSelected()" epoHighlightIfNotEmpty>
        <mat-option [value]="DEFAULT">- Toutes -</mat-option>
        <mat-option *ngFor="let annee of listeAnnees" [value]="annee">{{annee}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mois</mat-label>

      <mat-select formControlName="mois" epoHighlightIfNotEmpty>
        <mat-option [value]="DEFAULT">- Tous -</mat-option>
        <mat-option *ngFor="let mois of listeMois" [value]="mois.value">{{mois.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Groupe des articles</mat-label>
      <epona-select-groupe-articles formControlName="groupeArticles"
                                    defaultLabel="- Tous -"
                                    epoHighlightIfNotEmpty></epona-select-groupe-articles>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sous-groupe des articles</mat-label>
      <epona-select-sous-groupe-articles formControlName="sousGroupeArticles"
                                         [groupe]="form.get('groupeArticles').value"
                                         defaultLabel="- Tous -"
                                         epoHighlightIfNotEmpty>
      </epona-select-sous-groupe-articles>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Libellé</mat-label>

      <input matInput formControlName="libelle" epoHighlightIfNotEmpty>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Utilisateur</mat-label>

      <mat-select formControlName="utilisateur" panelClass="select-panel" epoHighlightIfNotEmpty>
        <mat-option [value]="DEFAULT">- Tous -</mat-option>
        <mat-option *ngFor="let utilisateur of listeUtilisateurs" [value]="utilisateur">{{utilisateur}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="filtre-actions">
      <button type="submit" mat-raised-button color="primary" [disabled]="!(onlineService.isOnline | async)">
        <fa-icon icon="search"></fa-icon>
        Rechercher
      </button>
    </div>
   </mat-card>
</form>
