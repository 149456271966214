<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span *ngIf="estAjout">Ajout d'un lieu depuis la BNS</span>
    <span *ngIf="multiple">Modification de plusieurs lieux</span>
    <span *ngIf="!estAjout && !multiple">Modification d'un lieu</span>
    <span class="space"></span>
    <button type="button" mat-icon-button (click)="annuler()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content class="lieu modification-multiple">
  <div class="alert alert-info" role="alert" *ngIf="multiple">
    Cocher les cases des propriétés à modifier
    pour les <strong>{{ data.listeLieux.length }}</strong> lieux sélectionnés
  </div>
  <div class="alert alert-warning" role="alert" *ngIf="multiple && data.pageSelectionneeEntierement">
    Attention&nbsp;! Les modifications apportées ne seront pas prises en compte sur les lieux des autres pages
  </div>

  <form [formGroup]="form">
    <div class="mat-form-field-row" *ngIf="!multiple">
      <mat-form-field class="batiment-bns">
        <mat-label>Bâtiment BNS</mat-label>
        <epona-select-batiment-bns formControlName="batimentBns"
                                   [afficherInactifs]="!estAjout"
                                   [required]="estAjout"></epona-select-batiment-bns>
        <mat-error *ngIf="form.get('batimentBns').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Lieu BNS</mat-label>
        <epona-select-lieu-bns formControlName="lieuBns"
                               [afficherInactifs]="!estAjout"
                               [required]="estAjout"
                               [batiment]="form.get('batimentBns').value"
                               (valueChanged)="onLieuExterneSubmitted($event)"></epona-select-lieu-bns>
        <mat-error *ngIf="form.get('lieuBns').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field>
        <mat-label>Masqué</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="masqueCheck" matTooltip="Modification de la propriété « Masqué »"></mat-checkbox></span>
        <select-boolean formControlName="masque" required></select-boolean>
        <mat-error *ngIf="form.get('masque').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Prise en compte des ventes</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="priseEnCompteVentesCheck" matTooltip="Modification de la propriété « Prise en compte des ventes »"></mat-checkbox></span>
        <select-boolean formControlName="priseEnCompteVentes" required></select-boolean>
        <mat-error *ngIf="form.get('priseEnCompteVentes').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field>
        <mat-label>Accepte les livraisons externes</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="lieuLivraisonCheck" matTooltip="Modification de la propriété « Accepte les livraisons »"></mat-checkbox></span>
        <select-boolean formControlName="lieuLivraison" required></select-boolean>
        <mat-error *ngIf="form.get('lieuLivraison').hasError('required')">Champ obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!multiple" class="nom-lieu-livraison">
        <mat-label>Nom du lieu de livraison</mat-label>
        <input matInput type="text" formControlName="nomLieuLivraison" maxlength="255" />
        <aide matSuffix *ngIf="estAjout">
          <p>S'il est renseigné, ce nom sera affiché sur les bons de commande à la place du nom du bâtiment</p>
        </aide>
        <aide matSuffix *ngIf="!estAjout">
          <p>S'il est renseigné, ce nom sera affiché sur les bons de commande à la place du nom du bâtiment («&nbsp;{{ data.listeLieux[0].nomRu }}&nbsp;»)</p>
          <p *ngIf="form.get('lieuLivraison').value">Adresse de livraison actuelle&nbsp;:</p>
          <pre *ngIf="form.get('lieuLivraison').value">
            <strong>{{ form.get('nomLieuLivraison').value ? form.get('nomLieuLivraison').value : data.listeLieux[0].nomRu }}</strong>
            {{ data.listeLieux[0].adresse1 }}
            {{ data.listeLieux[0].adresse2 }}
            {{ data.listeLieux[0].codePostal }} {{ data.listeLieux[0].ville }}
          </pre>
        </aide>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field>
        <mat-label>Service émetteur par défaut</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="serviceEmetteurCheck" matTooltip="Modification de la propriété « Service émetteur par défaut »"></mat-checkbox></span>
        <epona-select-referentiel-orion formControlName="serviceEmetteur"
                                        [type]="TypeReferentielOrion.SERVICE_EMETTEUR"></epona-select-referentiel-orion>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Destination par défaut</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="destinationCheck" matTooltip="Modification de la propriété « Destination par défaut »"></mat-checkbox></span>
        <epona-select-referentiel-orion formControlName="destination"
                                        [type]="TypeReferentielOrion.DESTINATION"
                                        defaultLabel="- Aucune -"></epona-select-referentiel-orion>
      </mat-form-field>
    </div>

    <div class="mat-form-field-row">
      <mat-form-field>
        <mat-label>Service gestionnaire par défaut</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="serviceGestionnaireCheck" matTooltip="Modification de la propriété « Service gestionnaire par défaut »"></mat-checkbox></span>
        <epona-select-referentiel-orion formControlName="serviceGestionnaire"
                                        [type]="TypeReferentielOrion.SERVICE_GESTIONNAIRE"></epona-select-referentiel-orion>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Type de TVA par défaut</mat-label>
        <span matPrefix *ngIf="multiple"><mat-checkbox formControlName="typeTvaCheck" matTooltip="Modification de la propriété « Type de TVA par défaut »"></mat-checkbox></span>
        <epona-select-referentiel-orion formControlName="typeTva"
                                        [type]="TypeReferentielOrion.TYPE_TVA"></epona-select-referentiel-orion>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="annuler()">Annuler</button>
  <!-- Sauvegarde désactivée si :
        - sauvegarde en cours
        - ou formulaire non valide
        - ou ajout et lieu non sélectionné ou lieu sans identifiant externe
  -->
  <button mat-raised-button color="primary"
          (click)="sauvegarder()"
          [disabled]="sauvegardeEnCours || !form.valid || estAjout && (!lieuExterne || !lieuExterne.idUd && !lieuExterne.idLieuBns)">Sauvegarder</button>
</mat-dialog-actions>
