<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-toolbar color="primary" role="toolbar" cdkDragHandle>
    <span *ngIf="!serviceFait?.envoye">Constatation de service fait non envoyée</span>
    <span *ngIf="serviceFait?.envoye">Service fait n<sup>o</sup> {{ serviceFait.numeroSf }}</span>

    <span class="space"></span>

    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <epona-service-fait [livraison]="data.livraison"
                      [serviceFait]="serviceFait"
                      (formEnteteIsDirty)="onFormEnteteIsDirty($event)"
                      (formLignesIsDirty)="onFormLignesIsDirty($event)">
  </epona-service-fait>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary"
          (click)="openDialogMiseAJourEJ()"
          [disabled]="serviceFait?.codeEtapeOrion !== 'MAJ_EJ'"
          *ngIf="afficherBoutonMajEj">
    <fa-icon icon="redo"></fa-icon> Mettre à jour l'EJ…
  </button>

  <button mat-raised-button color="primary"
          (click)="openDialogEnvoiSF()"
          [disabled]="serviceFait?.codeEtapeOrion !== 'ENV_SF'">
    <fa-icon icon="paper-plane"></fa-icon> Envoyer vers Orion…
  </button>

  <button type="button"
          mat-raised-button color="primary"
          [matMenuTriggerFor]="menu">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button type="button"
            mat-menu-item
            (click)="openDialogSuppressionSF()"
            [disabled]="serviceFait?.envoye">
      <fa-icon icon="trash"></fa-icon> Supprimer…
    </button>
  </mat-menu>
</mat-dialog-actions>
