import {Pipe, PipeTransform} from '@angular/core';
import {SousLotZg} from "../../model/epona-api/SousLotZg";

@Pipe({
    name: 'lotMarcheOrion',
    standalone: false
})
export class LotMarcheOrionPipe implements PipeTransform {
  transform(sousLot: SousLotZg): string {
    return LotMarcheOrionPipe.buildLibelle(sousLot);
  }

  static buildLibelle(sousLot: SousLotZg): string {
    if (sousLot) {
      return sousLot.codeMarcheOrion + '.' + sousLot.numeroSousLot;
    } else {
      return '';
    }
  }
}

