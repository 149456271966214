/* *********************************************************************** */
/* /!\               Fichier issu de angular-app-commons               /!\ */
/* /!\ Merci d'avertir le Cnous si une modification doit être apportée /!\ */
/* *********************************************************************** */

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {LocalStorageHelper} from "../../helpers/local-storage-helper";
import {environment} from "../../../environments/environment";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {UtilisateurTests} from "../../model/bns-api/utilisateur-tests";
import {BnsService} from "../../services/bns/bns.service";

@Component({
    selector: 'epona-login',
    templateUrl: './eppn-form.component.html',
    styleUrls: ['./eppn-form.component.css'],
    standalone: false
})
export class EppnFormComponent implements OnInit {

  static lastSelectedIndex: number = 0;

  selectedIndex: number;
  formEppn: FormGroup;
  formJson: FormGroup;
  listeUtilisateursTests: UtilisateurTests[] = [];

  private returnPage: string = environment.defaultPage;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private bnsService: BnsService,
              private router: Router,
              private route: ActivatedRoute) {
    // Cette page n'est pas sensée être accessible en préprod ou en prod
    if (environment.env !== 'DEV' && environment.env !== 'INT') {
      this.router.navigate(['choix-etablissement']);
    }

    // Récupération des paramètres de requête de l'url pour la redirection
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.keys.length !== 0) {
        const param = paramMap.get('returnPage');
        if (param !== null) {
          this.returnPage = param;
        }
      }
    });

    this.selectedIndex = EppnFormComponent.lastSelectedIndex;

    this.formEppn = this.fb.group({
      eppn : this.fb.control<string|null>(LocalStorageHelper.getEppn(), Validators.required),
      pwd  : this.fb.control<string|null>(null)
    });

    this.formJson = this.fb.group({
      token : this.fb.control<string|null>(LocalStorageHelper.getDevToken(), Validators.required),
      pwd   : this.fb.control<string|null>(null)
    });
  }

  ngOnInit(): void {
    const idEtablissement = LocalStorageHelper.getIdEtablissement();
    if (idEtablissement !== null) {
      this.bnsService.getListeUtilisateursTests(idEtablissement).subscribe({
        next: data => {
          this.listeUtilisateursTests = data.sort(UtilisateurTests.sort);
        }
      });
    }
  }


  onSubmitEppn() {
    EppnFormComponent.lastSelectedIndex = 0;
    const eppn = this.formEppn.get('eppn')!.value;
    const pwd  = this.formEppn.get('pwd')!.value;
    LocalStorageHelper.setEppn(eppn);
    this.userService.devLogin(eppn, null, pwd, this.returnPage);
  }

  onSubmitJson() {
    EppnFormComponent.lastSelectedIndex = 1;
    const devToken = this.formJson.get('token')!.value;
    const pwd      = this.formJson.get('pwd')!.value;
    LocalStorageHelper.setDevToken(devToken);
    this.userService.devLogin(null, devToken, pwd, this.returnPage);
  }

  onSubmitUtilisateurTest(utilisateur: UtilisateurTests) {
    this.formEppn.get('eppn')?.setValue(utilisateur.eppn);
    this.onSubmitEppn();
  }

}
