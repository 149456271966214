import {Component, Input, OnChanges, OnInit,  SimpleChanges, ViewChild} from '@angular/core';
import {TableColumn} from "../../commons/inputs/form-displayed-columns/form-displayed-columns.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CnousTableExporterDirective} from "../../commons/directives/cnous-table-exporter.directive";
import {UserService} from "../../services/user.service";
import {CodeDroit} from "../../commons/constants/CodeDroit";
import {FdncEponaBna} from '../../model/epona-api/fdnc-epona-bna';
import {SortHelper} from '../../helpers/sort-helper';
import {BnaFdncService} from '../../services/bna/bna-fdnc.service';
import {CacheService} from '../../services/cache.service';

@Component({
    selector: 'epona-tableau-fdnc',
    templateUrl: './tableau-fdnc.component.html',
    styleUrls: ['./tableau-fdnc.component.css'],
    standalone: false
})
export class TableauFdncComponent implements OnInit, OnChanges {

  // Définition des colonnes
  static readonly COLUMNS: {[key: string]: TableColumn} = {
    codeBna:                   new TableColumn({label: 'Code', hiddable: false}),
    batiment:                  new TableColumn({label: 'Bâtiment'}),
    lieu:                      new TableColumn({label: 'Lieu de livraison'}),
    commande:                  new TableColumn({label: 'Commande'}),
    fournisseur:               new TableColumn({label: 'Fournisseur', default: false}),
    lotMarche:                 new TableColumn({label: 'Lot-marché',  default: false}),
    bl:                        new TableColumn({label: 'BL'}),
    codeArticle:               new TableColumn({label: 'Code-article', default: false}),
    designationArticle:        new TableColumn({label: 'Désignation'}),
    referenceFournisseur:      new TableColumn({label: 'Référence', tooltip: 'Référence-fournisseur', default: false}),
    numeroLotProduit:          new TableColumn({label: 'Lot', tooltip: 'Numéro du lot produit', default: false}),
    contexte:                  new TableColumn({label: 'Contexte'}),
    dateConstat:               new TableColumn({label: 'Date constat', exportFormat: 'date'}),
    statut:                    new TableColumn({label: 'Statut'}),
    etapeEnCours:              new TableColumn({label: 'Étape en cours'}),
    gravite:                   new TableColumn({label: 'Gravité'}),
    dateEmission:              new TableColumn({label: 'Date émission', default: false, exportFormat: 'datetime'}),
    utilisateurEmission:       new TableColumn({label: 'Déclarant', default: false}),
    dateDerniereModification:  new TableColumn({label: 'Dernière modif.', exportFormat: 'date'}),
    actions:                   new TableColumn({label: 'Actions',                         hiddable: false, export: false})
  };

  @Input() listeFdnc: Array<FdncEponaBna> = [];
  @Input() colonnesAffichees: string[] = [];


  dataSource = new MatTableDataSource<FdncEponaBna>();
  columns = TableauFdncComponent.COLUMNS;     // Définition des colonnes pour le template

  droitConsultation = false;
  droitSaisie = false;

  @ViewChild(MatSort, {static: true})
  sort!: MatSort;

  // Permet d'accéder à l'exporter de tableau
  @ViewChild(CnousTableExporterDirective)
  cnousTableExporter!: CnousTableExporterDirective;

  constructor(private userService: UserService,
              private bnaFdncService: BnaFdncService,
              private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = TableauFdncComponent.sortingDataAccessor;

    this.droitConsultation = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_CONSULTATION);
    this.droitSaisie       = this.userService.utilisateurCourant.possedeDroit(CodeDroit.FDNC_SAISIE);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listeFdnc']) {
      if (this.listeFdnc) {
        this.listeFdnc.forEach(f => {
          if (f.contexte && !f.contexte.libelle && f.contexte.idContexteFdnc) {
            const cacheKey = 'contexte_' + f.contexte.idContexteFdnc;
            if (this.cacheService.has(cacheKey)) {
              f.contexte = this.cacheService.get(cacheKey);
            } else {
              this.bnaFdncService.getContextesFdnc(f.contexte.idContexteFdnc).subscribe({
              next:data => {
                this.cacheService.set(cacheKey, data);
                f.contexte = data;
              }
            });
            }
          }
        });
        this.dataSource.data = this.listeFdnc;
      }
    }
  }

  /* ************************* */
  /* Ordonnancement des lignes */
  /* ************************* */

  private static sortingDataAccessor(data: FdncEponaBna, sortHeaderId: string): string|number {
    switch (sortHeaderId) {
      case 'codeBna':                  return data.codeBna ? data.codeBna : data.idFdnc;
      case 'batiment':                 return data.batiment;
      case 'lieu':                     return data.lieu;
      case 'commande':                 return data.commande.numeroEj;
      case 'fournisseur':              return SortHelper.stringToString(data.commande.fournisseur?.nom);
      case 'lotMarche':                return data.commande.sousLotZg?.codeMarcheOrion;
      case 'bl':                       return data.bl?.codeBordereauLivraison;
      case 'codeArticle':              return data.codeArticle;
      case 'designationArticle':       return SortHelper.stringToString(data.designationArticle);
      case 'referenceFournisseur':     return data.referenceFournisseur;
      case 'numeroLotProduit':         return data.numeroLotProduit;
      case 'contexte':                 return SortHelper.stringToString(data.contexte.libelle);
      case 'dateConstat':              return SortHelper.dateToNumber(data.dateConstat);
      case 'dateEmission':             return SortHelper.dateToNumber(data.emission);
      case 'utilisateurEmission':      return SortHelper.stringToString(data.declarant);
      case 'dateDerniereModification': return SortHelper.dateToNumber(data.dateDerniereModification);
      case 'gravite':                  return data.gravite;
      case 'statut':                   return data.statut.libelle;
      case 'etapeEnCours':             return data.etapeEnCours.libelle;
      default:                         return 0;
    }
  }
}
