import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessageTool} from "../../commons/MessageTool";
import {DialogDataCommentaireCommande} from "../../model/epona-ui/DialogDataCommentaireCommande";
import {CommandeService} from "../../services/epona/commande.service";
import {Article} from "../../model/epona-api/Article";
import {ArticleTools} from "../../commons/ArticleTools";

@Component({
    selector: 'app-dialog-commentaire-commande',
    templateUrl: '../../commons/dialog-comment/dialog-comment.component.html',
    styleUrls: ['../../commons/dialog-comment/dialog-comment.component.css'],
    standalone: false
})
export class DialogCommentaireCommandeComponent implements OnInit {

  commentaireCtrl: UntypedFormControl;
  article: Article;
  readonly dlc = null; // toujours null dans le cas d'une commande mais nécessaire pour le template (commun avec les inventaires)
  fieldName: string;
  utilisation: string;

  constructor(public dialog: MatDialogRef<DialogCommentaireCommandeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataCommentaireCommande,
              private commandeService: CommandeService,
              private messageTool: MessageTool) {

    this.commentaireCtrl = new UntypedFormControl('');
  }

  ngOnInit(): void {
    this.commentaireCtrl.setValue(this.data.ligne[this.data.field]);
    this.article = this.data.ligne.article;
    this.fieldName = this.data.field === 'commentaireMagasin' ? 'Commentaire magasin' : 'Commentaire';
    this.utilisation = this.data.utilisation;
  }

  close() {
    this.dialog.close();
  }

  save() {
    const previousValue = this.data.ligne[this.data.field];

    if (this.commentaireCtrl.value !== previousValue && this.commentaireCtrl.value !== undefined) {
      this.data.ligne[this.data.field] = this.commentaireCtrl.value;

      this.commandeService.putLigne(this.data.entete.idCommandeEntete, this.data.ligne).subscribe(() => {
        const designation = ArticleTools.getDesignation(this.article, this.utilisation);
        this.messageTool.sendSuccess(`La ligne de l'article ${ designation } a été mise à jour avec succès`);

        this.data.ligneUpdated = this.data.ligne;

        this.dialog.close();

      }, err => {
        this.data.ligne[this.data.field] = previousValue;
        this.messageTool.sendError(err);
      });
    } else {
      this.dialog.close();
    }
  }
}
