import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, NgControl, UntypedFormControl} from "@angular/forms";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {MessageTool} from "../../MessageTool";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {SousGroupe} from "../../../model/epona-api/SousGroupe";
import {BnaService} from "../../../services/epona/bna.service";
import {Groupe} from "../../../model/epona-api/Groupe";

@Component({
    selector: 'epona-select-sous-groupe-articles',
    templateUrl: './select-sous-groupe-articles.component.html',
    styleUrls: ['./select-sous-groupe-articles.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectSousGroupeArticlesComponent }],
    standalone: false
})
export class SelectSousGroupeArticlesComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<SousGroupe> {

  @Input() defaultLabel: string = '- Aucun -';
  @Output() readonly valueChanged = new EventEmitter<SousGroupe>();

  public readonly DEFAULT = Const.DEFAULT;
  public readonly UNDEFINED = ''+Const.UNDEFINED;

  formCtrl: UntypedFormControl;
  liste: SousGroupe[];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private bnaService: BnaService,
              private messageTool: MessageTool,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new UntypedFormControl(Const.DEFAULT);
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: SousGroupe): void {
    this.formCtrl.setValue(obj ? obj.idSousGroupeArticles : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    this.valueChanged.emit(this.getCurrentItem());
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): SousGroupe {
    return this.getCurrentItem();
  }
  set value(value: SousGroupe) {
    this.formCtrl.setValue(value ? value.idSousGroupeArticles : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid && this.ngControl.touched;
  }

  focused: boolean = false;

  // TODO ?
  readonly id: string;
  readonly placeholder: string;
  readonly shouldLabelFloat: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  private _groupe: Groupe = null;
  @Input()
  get groupe(): Groupe {
    return this._groupe;
  }
  set groupe(value: Groupe) {
    this._groupe = value;
    this.loadListe();
  }

  getCurrentItem(): SousGroupe {
    return this.liste && this.liste.find(item => item.idSousGroupeArticles === this.formCtrl.value);
  }

  private loadListe() {
    // La liste des sous-groupes dépend du groupe sélectionné
    if (this._groupe && this._groupe.code !== this.UNDEFINED) {
      // Chargement des sous-groupes depuis Épona
      this.bnaService.getSousGroupes(this._groupe.code).subscribe(data => {
        // Tri et stockage de la liste chargée
        this.tri(data);
        this.liste = data;

        // Si le sous-groupe sélectionné n'existe pas dans la nouvelle liste, il est vidé
        if (!this.getCurrentItem()) {
          this.clearCurrentItem();
        }

      }, err => {
        this.messageTool.sendError(err);
      });

    // Si aucun groupe n'est sélectionné alors la liste des sous-groupes est vide et aucun sous-groupe n'est sélectionné
    } else {
      this.liste = [];
      this.clearCurrentItem();
    }
  }

  private clearCurrentItem() {
    this.formCtrl.setValue(Const.DEFAULT);
    this.selectionChanged();
  }

  tri(liste: Array<SousGroupe>) {
    liste.sort((a, b) => {
      return a.libelle.localeCompare(b.libelle);
    });
  }
}
