import {Groupe} from "../epona-api/Groupe";
import {SousGroupe} from "../epona-api/SousGroupe";

export class InventaireFilter {
  idInventaire: number;
  utilisateur: string;
  libelle: string;
  cloture: boolean;
  annee: number;
  mois: number;
  groupeArticles: Groupe;
  sousGroupeArticles: SousGroupe;
}
