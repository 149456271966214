import {CommandeEntete} from "./CommandeEntete";
import {CommandeEntetePATCH} from "./CommandeEntetePATCH";
import {LieuDTO} from "./LieuDTO";

export class CommandeEntetePUT extends CommandeEntetePATCH {
  dateLivraisonSouhaitee: Date;
  lieuStockageApresReception: LieuDTO;
  codeUf: string;
  nombreClientsEstimatif: number;
  fraisPortPrevisionnels: number;

  constructor(commandeEntete: CommandeEntete) {
    super(commandeEntete);
    this.dateLivraisonSouhaitee     = commandeEntete.dateLivraisonSouhaitee;
    this.lieuStockageApresReception = commandeEntete.lieuStockageApresReception ? new LieuDTO(commandeEntete.lieuStockageApresReception) : null;
    this.codeUf                     = commandeEntete.codeUf;
    this.nombreClientsEstimatif     = commandeEntete.nombreClientsEstimatif;
    this.fraisPortPrevisionnels     = commandeEntete.fraisPortPrevisionnels;
    this.informationsLivraison      = commandeEntete.informationsLivraison;
    this.instructionsCommande       = commandeEntete.instructionsCommande;
    this.codeClientDemandeur        = commandeEntete.codeClientDemandeur;
  }
}
