import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self} from '@angular/core';
import {MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, FormControl, NgControl} from "@angular/forms";
import {Etablissement} from "../../../model/bns-api/etablissement";
import {Const} from "../../constants/Const";
import {Subject} from "rxjs";
import {EtablissementService} from "../../../services/bns/etablissement.service";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {LocalStorageHelper} from "../../../helpers/local-storage-helper";

@Component({
    selector: 'select-etablissement',
    templateUrl: './select-etablissement.component.html',
    styleUrls: ['./select-etablissement.component.css'],
    providers: [{ provide: MatFormFieldControl, useExisting: SelectEtablissementComponent }],
    standalone: false
})
export class SelectEtablissementComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Etablissement|null> {

  @Input() defaultLabel: string = '- Aucun -';
  @Output() readonly valueChanged = new EventEmitter<Etablissement>();

  public readonly DEFAULT = Const.DEFAULT;

  formCtrl: FormControl<number>;
  liste: Etablissement[] = [];

  // ControlValueAccessor
  private propagateChange = (_: any) => { };
  private onTouched = () => {};

  // MatFormFieldControl
  stateChanges = new Subject<void>();

  constructor(@Optional() @Self() public ngControl: NgControl,
              private etablissementService: EtablissementService,
              private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>) {

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formCtrl = new FormControl<number>(Const.DEFAULT, {nonNullable: true});
    this.formCtrl.registerOnChange(() => {
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.loadListe();
  }

  ngOnDestroy(): void {
  }



  // ControlValueAccessor
  writeValue(obj: Etablissement): void {
    this.formCtrl.setValue(obj ? obj.idEtablissement : Const.DEFAULT);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.propagateChange(this.getCurrentItem());
    const item = this.getCurrentItem();
    this.valueChanged.emit(item === null ? undefined : item);
  }

  // MatFormFieldControl
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formCtrl.disable() : this.formCtrl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): Etablissement|null {
    return this.getCurrentItem();
  }
  set value(value: Etablissement|null) {
    this.formCtrl.setValue(value !== null ? value.idEtablissement : Const.DEFAULT);
    this.propagateChange(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;


  get empty(): boolean {
    return this.formCtrl.value === Const.DEFAULT;
  }

  get errorState() {
    return !this.ngControl ? false : this.ngControl.invalid === true && this.ngControl.touched === true;
  }

  focused: boolean = false;

  // TODO ?
  readonly id!: string;
  readonly placeholder!: string;
  readonly shouldLabelFloat!: boolean;

  onContainerClick(event: MouseEvent): void {
  }

  setDescribedByIds(ids: string[]): void {
  }

  /* ************************************************************************************************************** */
  /* *************************************************** Métier *************************************************** */
  /* ************************************************************************************************************** */

  getCurrentItem(): Etablissement|null {
    const item = this.liste.find(item => item.idEtablissement === this.formCtrl.value);
    return item === undefined ? null : item;
  }

  private loadListe(): void {
    // Récupération de la liste via l'API
    this.etablissementService.getEtablissementsLite().subscribe({
      next: data => {
        this.liste = data;
        this.liste.sort((a, b) => a.nom.localeCompare(b.nom));
        // Permet de mettre à jour l'établissement sélectionné
        //  car certaines de ses propriétés peuvent avoir changé par rapport à celui repris du localStorage
        this.selectionChanged();
      },
      error: err => {
        // En cas d'impossibilité de récupérer la liste des établissements,
        //  celle-ci est initialisée avec l'établissement du localStorage
        const etablissement = LocalStorageHelper.getEtablissement();
        if (etablissement !== null) {
          this.liste.push(etablissement);
        }
        console.error(err);
      }
    });
  }
}
