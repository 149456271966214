
<epona-select-lieu [lieu]="lieuCourant"
                   (lieuSubmitted)="onLieuSubmitted($event)"
                   (lieuCorrected)="onLieuCorrected($event)"></epona-select-lieu>

<h1>Inventaires</h1>

<div class="crous-recherche">
  <epona-inventaires-filter [filter]="filter"
                            class="crous-recherche-filtre"
                            [lieuCourant]="lieuCourant"
                            (filterSubmitted)="onFilterSubmitted($event)"></epona-inventaires-filter>

  <div class="crous-recherche-liste">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Liste des inventaires</mat-card-title>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div class="table-actions">
        <a routerLink="/creation-inventaire" matTooltip="Créer un nouvel inventaire" [class.disabled]="!(onlineService.isOnline | async)">
          <button type="button" mat-raised-button color="primary" [disabled]="!(onlineService.isOnline | async)"><fa-icon icon="plus"></fa-icon> Créer</button>
        </a>

        <button type="button"
                mat-raised-button color="primary"
                (click)="exportListeInventaires()"
                [disabled]="dataSource.data.length === 0 || !(onlineService.isOnline | async)">
          <fa-icon icon="file-excel"></fa-icon> Exporter
        </button>

        <form-displayed-columns [availableColumns]="COLUMNS"
                                [storeCode]="COLUMNS_STORE_CODE"
                                [(displayedColumns)]="displayedColumns"></form-displayed-columns>

        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>

      <table mat-table [dataSource]="dataSource"
             class="mat-elevation-z8"
             matSort [matSortActive]="orderBy" [matSortDirection]="orderDir" matSortDisableClear="true"
             (matSortChange)="onSortChanged($event)"
             aria-label="Liste d'inventaires">

        <ng-container matColumnDef="idInventaireEntete">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['idInventaireEntete'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.idInventaireEntete}}</td>
        </ng-container>

        <ng-container matColumnDef="batiment">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['batiment'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu.nomRu}}</td>
        </ng-container>

        <ng-container matColumnDef="lieu">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['lieu'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.lieu.nomUd}}</td>
        </ng-container>

        <ng-container matColumnDef="groupeArticles">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['groupeArticles'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.groupeArticles?.libelle}}</td>
        </ng-container>

        <ng-container matColumnDef="sousGroupeArticles">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['sousGroupeArticles'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.sousGroupeArticles?.libelle}}</td>
        </ng-container>

        <ng-container matColumnDef="libelle">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['libelle'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.libelle}}</td>
        </ng-container>

        <ng-container matColumnDef="dateCloture">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['dateCloture'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.dateCloture | dateTimeFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="utilisateurCloture">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col" mat-sort-header>{{ COLUMNS['utilisateurCloture'].label }}</th>
          <td mat-cell *matCellDef="let element">{{element.utilisateurCloture}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="title-container" mat-header-cell *matHeaderCellDef scope="col">{{ COLUMNS['actions'].label }}</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/inventaire', element.idInventaireEntete]" matTooltip="Modifier l'inventaire"
               [class.disabled]="!(onlineService.isOnline | async) && element.utilisateurCloture && element.utilisateurCloture.length > 1 ">
              <button type="button" mat-button><fa-icon icon="edit"></fa-icon></button>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <!-- Ligne de pied de tableau en cas de liste vide -->
        <ng-container matColumnDef="noRecord">
          <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">Liste vide</td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="dataSource.data.length > 0 ? [] : ['noRecord']" [hidden]="dataSource.data.length > 0" class="no-record"></tr>
      </table>

      <div class="table-bottom">
        <mat-paginator [length]="totalRecords"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons="true"
                       (page)="onPageChange($event)"
                       *ngIf="totalRecords > 0">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
