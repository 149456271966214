import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {EntreeSortieFilter} from "../../model/epona-ui/EntreeSortieFilter";
import {EntreeSortieParametrage} from "../../model/epona-ui/EntreeSortieParametrage";
import {CodeStatutES} from "../../commons/constants/CodeStatutES";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
    selector: 'epona-filtre-entrees-sorties',
    templateUrl: './filtre-entrees-sorties.component.html',
    styleUrls: ['./filtre-entrees-sorties.component.css'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                floatLabel: 'always',
                subscriptSizing: 'dynamic'
            }
        }
    ],
    standalone: false
})
export class FiltreEntreesSortiesComponent implements OnChanges {
  form: UntypedFormGroup;

  @Input() readonly params: EntreeSortieParametrage;
  @Input() filter: EntreeSortieFilter;
  @Output() readonly filterSubmitted = new EventEmitter<EntreeSortieFilter>();

  readonly CodeStatutES = CodeStatutES;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      idEntreeSortie:     this.fb.control(''),
      typeMouvement:      this.fb.control(null),
      dateMin:            this.fb.control(null),
      codeBL:             this.fb.control(''),
      lieuEvenement:      this.fb.control(''),
      dateEvenement:      this.fb.control(null),
      referenceEvenement: this.fb.control(''),
      utilisateur:        this.fb.control(''),
      valide:             this.fb.control(false),
      codesStatut:        this.fb.control(null)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && this.filter) {
      this.initFieldsFilter();
    }
  }

  private initFieldsFilter() {
    this.form.get('idEntreeSortie').setValue(this.filter.idEntreeSortie);
    this.form.get('typeMouvement').setValue(this.filter.typeMouvement);
    this.form.get('dateMin').setValue(this.filter.dateMin);
    this.form.get('codeBL').setValue(this.filter.codeBL);
    this.form.get('lieuEvenement').setValue(this.filter.lieuEvenement);
    this.form.get('dateEvenement').setValue(this.filter.dateEvenement);
    this.form.get('referenceEvenement').setValue(this.filter.referenceEvenement);
    this.form.get('utilisateur').setValue(this.filter.utilisateur);
    this.form.get('valide').setValue(this.filter.valide);
    this.form.get('codesStatut').setValue(this.filter.codesStatut);
  }

  onSubmit () {
    const filter = new EntreeSortieFilter();

    filter.idEntreeSortie     = this.nullIfEmptyFormControl('idEntreeSortie');
    filter.typeMouvement      = this.form.get('typeMouvement').value;
    filter.dateMin            = this.form.get('dateMin').value;
    filter.codeBL             = this.nullIfEmptyFormControl('codeBL');
    filter.lieuEvenement      = this.nullIfEmptyFormControl('lieuEvenement');
    filter.dateEvenement      = this.form.get('dateEvenement').value;
    filter.referenceEvenement = this.nullIfEmptyFormControl('referenceEvenement');
    filter.utilisateur        = this.nullIfEmptyFormControl('utilisateur');
    filter.valide             = this.form.get('valide').value;
    filter.codesStatut        = this.form.get('codesStatut').value;

    this.filterSubmitted.emit(filter);
  }

  private nullIfEmptyFormControl(formControlName: any): any {
    return this.form.get(formControlName).value === '' ? null : this.form.get(formControlName).value;
  }
}
